export default {
  state: {
    lastModifiedTimestamp: null,
    modifiedBy: null,
    type: null
  },

  mutations: {
    SET_SYSTEM_CHANGE(state, payload) {
      state.lastModifiedTimestamp = payload.timestamp;
      state.modifiedBy = payload.modifiedBy;
      state.type = payload.type;
    }
  },

  actions: {
    /**
     * Track system changes and sync across tabs
     * @param {Object} payload - Contains change info
     */
    trackSystemChange({ commit }, payload) {
      const change = {
        timestamp: Date.now(),
        modifiedBy: payload.modifiedBy,
        type: payload.type
      };

      console.log("change: ", change);

      // Update store
      commit('SET_SYSTEM_CHANGE', change);

      // Sync with localStorage for cross-tab communication
      localStorage.setItem('thrive_system_changes', JSON.stringify(change));
    }
  }
}; 