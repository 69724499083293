let totalBruto = 14.9936
let totalBrutoWithoutDiscount = 139.99
let totalDiscountVAT = 120.0003
let totalNetto = 13.7556
let totalVAT = 1.1238
let totalVats = [
  { percentageVat: 9, totalVat: 1.238 },
  { percentageVat: 21, totalVat: 0 },
]

// FOR MORE DEMO DATA LOOK AT ./script/receipt/receiptGenerated.js

/**
 * It loops thru the selected items and creates a table with the items and the total VATs
 * @param selectedItems - An array of objects that contain the items that are selected.
 * @param usedModfiers - An array of modifiers that were used in the transaction.
 * @param totalBruto - The total amount of the order, including VAT.
 * @param totalBrutoWithoutDiscount - The total amount of the items without any discount.
 * @param totalDiscountVAT - The total discount in VAT.
 * @param totalNetto - The total amount of the order, excluding VAT.
 * @param totalVAT - The total VAT of the receipt.
 * @param totalVats - [
 * @param validForUseMemberships - Array of memberships that are used in the receipt.
 * @returns A string of html.
 */
 var JsBarcode = require('jsbarcode');
import moment from 'moment'
import store from '../store'
import { autoLanguageSelector } from './autoLanguageSelector'

export function receiptBuilder(
  branchData,
  language,
  receiptTemplate,
  typePayment,
  receiptType,
  selectedItems,
  usedModifiers,
  totalBruto,
  totalBrutoWithoutDiscount,
  totalDiscountVAT,
  totalNetto,
  totalVAT,
  totalVats,
  receiptDocId,
  timestamp,
  type,
  usedMembership
) {
  console.log('usedMembership: ', usedMembership)
  // MEMBERSHIPS
  let filteredMemberships = usedModifiers.filter((usedModifier) => usedModifier.modifierGroup === 'membershipModifier')

  let foundedMemberships = ''

  if (filteredMemberships.length !== 0) {
    foundedMemberships = `
		<tr style="border-top:1px black dashed; border-bottom:1px black dashed">
			<td style="padding: 4px 0"><strong>Abonnement</strong></td>
			<td style="padding: 4px 0" align="right"></td>
		</tr>`

    // Add membership info
    if (usedMembership) {
      // Check if membership has separated discounts
      if (usedMembership.separatedDiscount.active) {
        const insideDiscounts = usedModifiers.filter((modifier) =>
          usedMembership.separatedDiscount.discountsInsideSubscriptionDays.includes(modifier.id)
        )

        const outsideDiscounts = usedModifiers.filter((modifier) =>
          usedMembership.separatedDiscount.discountsOutsideSubscriptionDays.includes(modifier.id)
        )

        // Add membership name and status
        foundedMemberships += `
				<tr>
					<td style="padding: 4px 0">${usedMembership.name.nl}</td>
					<td style="padding: 4px 0" align="right">
						${usedMembership.override ? '(Handmatig)' : '(Actief)'}
					</td>
				</tr>`

        // Add inside subscription discounts
        if (insideDiscounts.length > 0) {
          foundedMemberships += `
					<tr>
						<td style="padding: 2px 0" colspan="2">Kortingen binnen abonnement:</td>
					</tr>`

          insideDiscounts.forEach((discount) => {
            foundedMemberships += `
						<tr>
							<td style="padding: 2px 0 2px 15px">└ ${discount.name}</td>
							<td style="padding: 2px 0" align="right">
								${
                  discount.typeDiscount.discountType === 'percentage'
                    ? `${discount.typeDiscount.discountValue}%`
                    : `-€${discount.typeDiscount.discountValue}`
                }
							</td>
						</tr>`
          })
        }

        // Add outside subscription discounts
        if (outsideDiscounts.length > 0) {
          foundedMemberships += `
					<tr>
						<td style="padding: 2px 0" colspan="2">Extra kortingen:</td>
					</tr>`

          outsideDiscounts.forEach((discount) => {
            foundedMemberships += `
						<tr>
							<td style="padding: 2px 0 2px 15px">└ ${discount.name}</td>
							<td style="padding: 2px 0" align="right">
								${
                  discount.typeDiscount.discountType === 'percentage'
                    ? `-${discount.typeDiscount.discountValue}%`
                    : `-€${discount.typeDiscount.discountValue}`
                }
							</td>
						</tr>`
          })
        }
      }
    }

    foundedMemberships += `<tr style="border-bottom:1px black dashed"><td colspan="2">&nbsp;</td></tr>`
  }

  //ITEMS
  let items =
    language == 'nl'
      ? `
		<tr style="border-bottom:1px black dashed; border-top:1px black dashed">
			<td style="padding: 4px 0"><strong>Artikelen</strong></td>
			<td style="padding: 4px 0" align="right"><strong>Prijs</strong></td>
		</tr>`
      : `
		<tr style="border-bottom:1px black dashed; border-top:1px black dashed">
			<td style="padding: 4px 0"><strong>Items</strong></td>
			<td style="padding: 4px 0" align="right"><strong>Price</strong></td>
		</tr>`

  //Loop Thru items
  selectedItems.forEach((item) => {
    items += `${itemTd(item)}`
  })

  function itemTd(item) {
    /**
     * item specified
     * priceBruto
     * newPriceTotal
     * calculatedDiscountVAT
     */

    let htmlCode = new String()
    let promotionHtml = new String()

    /** 
    
    //Discount Values By Modifiers are the used modifier for that specific item. 

     discountValueByModifiers: [
         {
             modifierId: "Pu3VPBGluUg3HzBfsKPL",
             type: "modifier", 
             vatPercentage: 21,
             amount: 25,
             amountNetto: 20.66115702,
             discountType: "amount", 
             discountValue: 25,
             manualInputDiscount: false,
             addedVia: "manualDiscountMenu"
         },
         {
             modifierId: "S8au1o3zF3EzEAzyP3Vx", 
             type: "modifier",
             vatPercentage: 21, 
             amount: 25,
             amountNetto: 20.66115702,
             discountType: "amount",
             discountValue: 25, 
             manualInputDiscount: false,
             addedVia: "manualDiscountMenu"
         }
     ]
      
    */

    /* Checking if the item is a treatment and if it is a deposit or a withdraw. */
    //Loop Thru Treatments
    if (item.type === 'treatment' && item.typeOfPayment === 'deposit') {
      let treatmentReceiptName = item.treatmentTitleReceipt
        ? autoLanguageSelector(language, store.state.companyLanguages, item.treatmentTitleReceipt)
          ? autoLanguageSelector(language, store.state.companyLanguages, item.treatmentTitleReceipt)
          : autoLanguageSelector(language, store.state.companyLanguages, item.name)
        : autoLanguageSelector(language, store.state.companyLanguages, item.name)

      htmlCode = `<tr><td style="padding: 4px 0"> ${treatmentReceiptName} </td> <td style="padding: 4px 0" align="right"> ${formatReceiptNumber(
        item.priceBruto
      )} </td> </tr>`
      // htmlCode = `<tr><td style="padding: 4px 0"> ${item.name} </td> <td style="padding: 4px 0" align="right"> ${ formatReceiptNumber(item.priceBruto)} </td> </tr>`;

      if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
        //Loop thru related Discount items
        item.discountValueByModifiers.forEach((discountModifier) => {
          console.log(discountModifier)
          let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

          if (foundUsedModifier) {
            promotionHtml += `<tr><td style="padding: 4px 0">&nbsp;-${
              foundUsedModifier.name
            }</td> <td style="padding: 4px 0; width: 70px;" align="right">-${formatReceiptNumber(
              discountModifier.amount ? discountModifier.amount : discountModifier.amount
            )} </td> </tr>`
          }
        })
      }
    }

    //Check for Returning Items Treatment (Retour)
    if (item.type === 'treatment' && item.typeOfPayment === 'withdraw') {
      htmlCode = `<tr><td style="padding: 4px 0">Teruggave: ${
        item.name
      } </td> <td style="padding: 4px 0" align="right"> -${formatReceiptNumber(item.priceBruto)} </td> </tr>`

      //Check for Discount
      if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
        //Loop thru related Discount items
        item.discountValueByModifiers.forEach((discountModifier) => {
          console.log(discountModifier)
          let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

          if (foundUsedModifier) {
            promotionHtml += `<tr><td>&nbsp;-${foundUsedModifier.name}</td> <td align="right">-${formatReceiptNumber(
              discountModifier.amount
            )} </td> </tr>`
          }
        })
      }
    }

    /* Checking if the item is a product and if it is a deposit or a withdraw. */
    //Loop Thru Products
    if (item.type === 'product' && item.typeOfPayment === 'deposit') {
      htmlCode = `<tr><td style="padding: 4px 0"> ${
        item.name
      } </td> <td style="padding: 4px 0" align="right"> ${formatReceiptNumber(item.priceBruto)} </td> </tr>`

      //Check for Discount
      if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
        //Loop thru related Discount items
        item.discountValueByModifiers.forEach((discountModifier) => {
          console.log(discountModifier)
          let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

          if (foundUsedModifier) {
            promotionHtml += `<tr><td>&nbsp;-${foundUsedModifier.name}</td> <td align="right">-${formatReceiptNumber(
              discountModifier.amount
            )} </td> </tr>`
          }
        })
      }
    }

    //Check for Returning Items Product (Retour)
    if (item.type === 'product' && item.typeOfPayment === 'withdraw') {
      htmlCode = `<tr><td style="padding: 4px 0">Teruggave: ${
        item.name
      } </td> <td style="padding: 4px 0" align="right"> -${formatReceiptNumber(item.priceBruto)} </td> </tr>`

      //Check for Discount
      if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
        //Loop thru related Discount items
        item.discountValueByModifiers.forEach((discountModifier) => {
          console.log(discountModifier)
          let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

          if (foundUsedModifier) {
            promotionHtml += `<tr><td style="padding: 4px 0">&nbsp;-${
              foundUsedModifier.name
            }</td> <td style="padding: 4px 0" align="right">-${formatReceiptNumber(
              discountModifier.amount
            )} </td> </tr>`
          }
        })
      }
    }

    /* Checking if the item is a membership and if it is a deposit or withdraw */
    if (item.type === 'membership') {
      if (item.typeOfPayment === 'deposit') {
        htmlCode = `<tr><td style="padding: 4px 0"> ${
          item.name
        } </td> <td style="padding: 4px 0" align="right"> ${formatReceiptNumber(item.priceBruto)} </td> </tr>`

        //Check for Discount
        if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
          //Loop thru related Discount items
          item.discountValueByModifiers.forEach((discountModifier) => {
            console.log(discountModifier)
            let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

            if (foundUsedModifier) {
              promotionHtml += `<tr><td style="padding: 4px 0">&nbsp;-${
                foundUsedModifier.name
              }</td> <td style="padding: 4px 0" align="right">-${formatReceiptNumber(
                discountModifier.amount
              )} </td> </tr>`
            }
          })
        }
      }

      if (item.typeOfPayment === 'withdraw') {
        htmlCode = `<tr><td style="padding: 4px 0">Teruggave: ${
          item.name
        } </td> <td style="padding: 4px 0" align="right"> -${formatReceiptNumber(item.priceBruto)} </td> </tr>`

        //Check for Discount
        if (item.discountValueByModifiers && item.discountValueByModifiers.length !== 0) {
          //Loop thru related Discount items
          item.discountValueByModifiers.forEach((discountModifier) => {
            console.log(discountModifier)
            let foundUsedModifier = usedModifiers.find((modifier) => modifier.id === discountModifier.modifierId)

            if (foundUsedModifier) {
              promotionHtml += `<tr><td style="padding: 4px 0">&nbsp;-${
                foundUsedModifier.name
              }</td> <td style="padding: 4px 0" align="right">-${formatReceiptNumber(
                discountModifier.amount
              )} </td> </tr>`
            }
          })
        }
      }
    }

    return htmlCode + promotionHtml
  }

  /* Creating a table with the total VATs. */

  let logo =
    receiptTemplate &&
    receiptTemplate.receiptLogo &&
    receiptTemplate.receiptLogo.show &&
    receiptTemplate.receiptLogo.url
      ? `<tr><td>&nbsp;</td></tr><tr><td><p class="ma-0 pa-0"  style="text-align: ${
          receiptTemplate.receiptLogo.align
        }"><img style="width: ${receiptTemplate.receiptLogo.width + '%'}" src="${
          receiptTemplate.receiptLogo.url
        }"/></p></td></tr>`
      : ''
  let header =
    receiptTemplate && receiptTemplate.receiptHeader && receiptTemplate.receiptHeader.show
      ? `<tr><td>${convert(
          receiptTemplate.receiptHeader.content[language],
          branchData,
          language
        )}</td></tr><tr><td>&nbsp;</td></tr>`
      : ''
  let footer =
    receiptTemplate && receiptTemplate.receiptFooter && receiptTemplate.receiptFooter.show
      ? `<tr><td>${convert(receiptTemplate.receiptFooter.content[language], branchData, language)}</td></tr>`
      : ''
  let barcode = ''
  let vats = ''
  let vatItems = ''
  let today = moment().format('DD/MM/YYYY')
  let now = moment().format('H:mm')

  let date = moment(timestamp, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY')
  let time = moment(timestamp, 'YYYY-MM-DDTHH:mm').format('H:mm')

  if (receiptTemplate && receiptTemplate.barcode && receiptTemplate.barcode.show && type == 'print') {
    function textToBase64Barcode(text) {
      var canvas = document.createElement('canvas')
      JsBarcode(canvas, text, { format: 'CODE128', displayValue: false })
      var resizedCanvas = document.createElement('canvas')
      var resizedContext = resizedCanvas.getContext('2d')
      resizedCanvas.height = '65'
      resizedCanvas.width = '250'
      resizedContext.drawImage(canvas, 0, 0, 250, 65)

      return resizedCanvas.toDataURL('image/png')
    }

    barcode = `<tr>
			<td><br><img style="max-width: 100%; margin: 0 auto" src="${textToBase64Barcode(receiptDocId)}"/></td>
		</tr>`

    // barcode = `<tr>
    // 	<td><br><img style="max-width: 100%; margin: 0 auto" src="${textToBase64Barcode(receiptDocId)}"/></td>
    // 	<td><p style="padding: 0 0 4px 0; margin: -5px 0 0 0; text-align: center; font-size: 10px!important;">${receiptDocId}</p></td>
    // </tr>`
  }

  let vatName = language == 'nl' ? 'BTW' : 'VAT'

  totalVats.forEach((vat) => {
    vatItems += `<tr><td style="padding: 4px 0 0 0">${vatName} ${
      vat.percentageVat
    }%: </td> <td style="padding: 4px 0 0 0" align="right"> ${formatReceiptNumber(vat.totalVat)}</td></tr>`
  })

  vats = `
		<table border="0" cellpadding="0" width="99%" style="width:100%">
			<tr>
				<td style="padding: 8px 0 4px 0"><strong>${vatName}</strong></td>
				<td style="padding: 8px 0 4px 0" align="right"></td>
				${vatItems}
			</tr>
		</table>`

  // <style type="text/css" media="print"></style>

  let printHeader = `<head>
			<meta content="text/html; charset="UTF-8">
			<title>Receipt</title>
			<style type="text/css"> 
				@page {
					margin: 0;
				}		 
				body {
					margin-left: 0 0 20px 0;
					margin-bottom: 20px;
				}
				tr, tr td span, tr td p, tr td p span, p, p span{
					font-family: Menlo, Consolas, "Lucida Console", monospace!important;
					font-size: 10pt;
				}
				border-top: {
					border-bottom:1px black solid;
				}
				border-bottom: {
					border-top:1px black solid!important;
				}
				table {
					width: 100%;
					display: table;
					border-collapse: collapse;
					border-spacing: 0;
					font-family: monospace, cursive;
				}
			</style>
		</head>
		<body>
		<table border="0" cellpadding="0" width="99%" style="width:100%">`
  let printFooter = `</table></body>`

  let digitalHeader = `<table border="0" cellpadding="0" width="99%" style="width:100%; font-family: Menlo, Consolas, "Lucida Console", monospace!important;font-size:14px;font-style:normal;font-weight:400;line-height:1;text-align:left;text-decoration:none;text-transform:none;color:#333333;">`
  let digitalFooter = `</table>`

  let totalName = language == 'nl' ? 'Totaal' : 'Total'

  let content = `
		${logo}
		<tr><td>&nbsp;</td></tr>
		${header}
		<tr>
			<td>
				<table border="0" cellpadding="0" width="99%" style="width:100%">
					<tr>
						<td style="padding: 4px 0">${date}</td>
						<td style="padding: 4px 0" align="right">${time}u</td>
					</tr>
				</table>
			</td>
		</tr>
		<tr><td>&nbsp;</td></tr>
		<tr>
			<td>
				<table  border="0" cellpadding="0" width="99%" style="width:100%">
					${foundedMemberships}
					${items}
					<tr style="border-bottom:1px black dashed">
						<td style="padding: 8px 0"><strong>${totalName}</strong></td> 
						<td style="padding: 8px 0" align="right"> <strong>${formatReceiptNumber(totalBruto)}</strong></td>
					</tr>
				</table>
			</td>
		</tr>
		${vats}
		<tr><td>&nbsp;</td></tr>
		${footer}
		${barcode}
		<tr><td><span style="color: silver!important">.</span></td></tr>`

  let html = type == 'print' ? printHeader + content + printFooter : digitalHeader + content + digitalFooter

  return Buffer.from(html).toString('base64')
}

function formatReceiptNumber(number) {
  if (number) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(
      number
    )
  } else {
    return '-'
  }
}

function convert(text, branch, language) {
  let branchTimes = {
    Sun:
      branch.branchTime.Sun.start != '00:00' && branch.branchTime.Sun.end != '00:00'
        ? `${branch.branchTime.Sun.start} - ${branch.branchTime.Sun.end}`
        : null,
    Mon:
      branch.branchTime.Mon.start != '00:00' && branch.branchTime.Mon.end != '00:00'
        ? `${branch.branchTime.Mon.start} - ${branch.branchTime.Mon.end}`
        : null,
    Tue:
      branch.branchTime.Tue.start != '00:00' && branch.branchTime.Tue.end != '00:00'
        ? `${branch.branchTime.Tue.start} - ${branch.branchTime.Tue.end}`
        : null,
    Wed:
      branch.branchTime.Wed.start != '00:00' && branch.branchTime.Wed.end != '00:00'
        ? `${branch.branchTime.Wed.start} - ${branch.branchTime.Wed.end}`
        : null,
    Thu:
      branch.branchTime.Thu.start != '00:00' && branch.branchTime.Thu.end != '00:00'
        ? `${branch.branchTime.Thu.start} - ${branch.branchTime.Thu.end}`
        : null,
    Fri:
      branch.branchTime.Fri.start != '00:00' && branch.branchTime.Fri.end != '00:00'
        ? `${branch.branchTime.Fri.start} - ${branch.branchTime.Fri.end}`
        : null,
    Sat:
      branch.branchTime.Sat.start != '00:00' && branch.branchTime.Sat.end != '00:00'
        ? `${branch.branchTime.Sat.start} - ${branch.branchTime.Sat.end}`
        : null,
  }

  let days = {
    Sun: { en: 'Sunday', nl: 'Zondag' },
    Mon: { en: 'Monday', nl: 'Maandag' },
    Tue: { en: 'Tuesday', nl: 'Dinsdag' },
    Wed: { en: 'Wednesday', nl: 'Woensdag' },
    Thu: { en: 'Thursday', nl: 'Donderdag' },
    Fri: { en: 'Friday', nl: 'Vrijdag' },
    Sat: { en: 'Saturday', nl: 'Zaterdag' },
  }

  let openingHours = ''

  let firstItem = true
  for (const [key, value] of Object.entries(branchTimes)) {
    if (value) {
      if (firstItem) {
        openingHours = openingHours + `<span style="font-size: 13px">${days[key][language]}: ${value}u</span>`
        firstItem = false
      } else {
        openingHours = openingHours + `<br><span style="font-size: 13px">${days[key][language]}: ${value}u</span>`
      }
    }
  }

  return text
    .replace(/\[branchName\]/gm, branch.name)
    .replace(/\[vestigingNaam\]/gm, branch.name)
    .replace(
      /\[branchAddress\]/gm,
      `${branch.branchAddress.address} ${branch.branchAddress.nr}<br> ${branch.branchAddress.postalCode} ${branch.branchAddress.city}`
    )
    .replace(
      /\[vestigingAdres\]/gm,
      `${branch.branchAddress.address} ${branch.branchAddress.nr}<br> ${branch.branchAddress.postalCode} ${branch.branchAddress.city}`
    )
    .replace(/\[website\]/gm, branch.url ? branch.url : '')
    .replace(/\[openingstijden\]/gm, openingHours)
    .replace(/\[openingHours\]/gm, openingHours)
}
