
   /**
    * Binds custom input formatting to an input element within a Vuetify component.
    * This function sets up an input event listener that formats the input value
    * as a currency value, ensuring it always has at least three digits and separates
    * the integer part from the decimal part with a comma.
    *
    * TODO: Add support for multiple currencies. By using binding
    * 
    * @param {HTMLElement} el - The element to which the directive is bound.
    * @param {Object} binding - An object containing the directive's binding data.
    * @param {VNode} vnode - The virtual node produced by Vue's compiler.
    */

   export const moneyInput = {
      bind(el, binding, vnode) {
         const input = el.querySelector('input'); // Zoek het daadwerkelijke input element binnen het Vuetify component

         function formatValue(value) {
            value = value.replace(/[^0-9]/g, ''); // Verwijder alle niet-cijfer tekens

            if (value.length <= 2) {
               value = value.padStart(3, '0'); // Dit zorgt minimaal voor drie cijfers
            }

            let integer = value.slice(0, -2); // Neem alle cijfers behalve de laatste twee
            let decimal = value.slice(-2); // Neem de laatste twee cijfers als decimaal

            integer = integer.replace(/^0+/, '') || '0'; // Belangrijk !! Verwijder voorloopnullen, behalve als de hele integer uit nullen bestaat

            return `${integer},${decimal}`; // Formatteer de waarde met een komma
         }

         input.oninput = function (e) {
            if (!e.isTrusted) return;
            let formattedValue = formatValue(e.target.value);
            e.target.value = formattedValue; // Update de waarde van het element
            vnode.componentInstance.$emit('input', formattedValue); // Stuur de bijgewerkte waarde naar de Vue component
         };

      //  input.onblur = function () {
         //TODO voor extra manipulatie
      //  };

   }
}