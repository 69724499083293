<template>
	<div>
		<div v-if="loading">
			<div style="position: relative; padding: 200px 0">
				<div class="thrive-loader"></div>
			</div>
		</div>

		<div class="no-data-message" v-if="!loading && modifiers.length == 0">
			<v-avatar
				size="58"
				light
				class="ma-0 mr-2 custom-avatar-style"
				rounded="lg"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
				:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }"
			>
				<v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-sale</v-icon>
			</v-avatar>
			<p class="ma-0">{{ $t("modifier.noDiscountsFor", $store.state.locale) }} {{ $store.state.activeCompany.name }}.</p>
			<v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addModifier()">
				<v-icon>mdi-plus</v-icon> {{ $t("modifier.newDiscount", $store.state.locale) }}
			</v-btn>
		</div>

		<v-card
			v-if="!loading && modifiers.length > 0"
			flat
			:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
			class="theme-card theme-shadow pa-0"
			:class="$store.state.companyTheme"
		>
			<div v-if="deleting" class="pb-4">
				<div class="loading-dots">
					<div class="loading-dots-loader"></div>
					<p class="loading-dots-text">
						{{ deleteCounter }} {{ $t("conversion.of", $store.state.locale) }} {{ selected.length }}
						{{ $t("conversion.deleted", $store.state.locale) }}.
					</p>
				</div>
			</div>
			<div v-else>
				<v-row class="pa-3">
					<v-col cols="6">
						<v-text-field
							dense
							v-model="search"
							outlined
							background-color="background"
							name="searchfield"
							hide-details
							label="Zoeken"
							append-icon="mdi-magnify"
							single-line
						></v-text-field>
					</v-col>
					<v-col cols="6" class="text-right">
						<v-btn
							dark
							small
							height="40"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							@click.stop="addModifier()"
						>
							<v-icon left>mdi-plus</v-icon> {{ $t("modifier.newDiscount", $store.state.locale) }}
						</v-btn>
						<v-btn
							class="ml-2"
							small
							fab
							dark
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"
							v-if="selected.length > 0"
							@click="showDeleteDialog(null, 'deleteModifiers')"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn
							class="ml-2"
							small
							fab
							dark
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"
							v-if="selected.length > 0"
							@click="toggleActiveState"
						>
							<v-icon>mdi-power-settings</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-divider></v-divider>

				<div class="tab-component-content no-padding">
					<v-data-table
						:headers="headers"
						:items="modifiers"
						v-model="selected"
						show-select
						:search="search"
						:no-results-text="`Geen resultaten gevonden voor: ${search}`"
						dense
						class="dense-table"
						:items-per-page="30"
					>
						<template v-slot:item="{ item, isSelected, select }">
							<tr>
								<td>
									<v-checkbox
										:input-value="isSelected"
										@click="select(!isSelected)"
										hide-details
										dense
									>
										<template v-slot:label>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on"></span>
												</template>
												<span>{{ isSelected ? $t("modifier.deselectDiscount", $store.state.locale) : $t("modifier.selectDiscount", $store.state.locale) }}</span>
											</v-tooltip>
										</template>
									</v-checkbox>
								</td>
								<td>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon
												small
												:color="item.active ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : 'grey'"
												class="mr-2"
												v-bind="attrs"
												v-on="on"
											>
												mdi-circle
											</v-icon>
										</template>
										<span>{{ item.active ? $t("modifier.activeDiscount", $store.state.locale) : $t("modifier.inactiveDiscount", $store.state.locale) }}</span>
									</v-tooltip>
								</td>
								<td>{{ item.name }}</td>
								<td>
									<span v-for="(activeBranch, index) in item.branches" :key="index">
										<v-chip
											:key="index"
											x-small
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											light
											class="mr-2 my-1 custom-chip-style"
											>{{ getBranchName(activeBranch) }}</v-chip
										>
									</span>
								</td>
								<td>
									<span>{{ getModifierGroupName(item.modifierGroup) }}</span>
								</td>
								<td>
									<span v-for="modifierType in modifierTypes" :key="modifierType.id">
										<span v-if="item.type == modifierType.id">{{ modifierType.name }}</span>
									</span>
								</td>
								<td>
									<span v-for="discountType in typeDiscountTypes" :key="discountType.id">
										<span v-if="item.typeDiscount.type == discountType.id">{{ discountType.name }}</span>
									</span>
								</td>
								<td class="d-flex">
									<v-btn icon class="mx-1" @click="editModifier(item)">
										<v-icon small color="text"> mdi-pencil </v-icon>
									</v-btn>
									<v-btn icon class="mx-1" @click="copyModifier(item)">
										<v-icon small color="text"> mdi-content-copy </v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>
				</div>
			</div>
		</v-card>

		<!-- / New Modifier Dialog \ -->
		<modifier-form
			:filterItemActions="filterItemActions"
			:filterProductenTypes="filterProductenTypes"
			:filterTreatmentsTypes="filterTreatmentsTypes"
			:modifierTypes="modifierTypes"
			:modifiers="modifiers"
			:typeDiscountTypes="typeDiscountTypes"
			:numberLimitTypes="numberLimitTypes"
			:discountActionTypes="discountActionTypes"
			:quantityDiscountTypes="quantityDiscountTypes"
			@updateData="loadModifiers()"
		></modifier-form>
		<!-- \ New Modifier Dialog / -->
	</div>
</template>

<script>
import db from "../firebase/init";
import { bus } from "../main";
import moment from "moment";
// import ModifierForm from "../components/modals/ModifierForm.vue";
import ModifierForm from "../components/modals/ModifierFormV2.vue";

export default {
	components: {
		"modifier-form": ModifierForm,
	},
	name: "Modifiers",
	data: () => ({
		modifiers: new Array(),
		loading: true,
		search: "",
		selected: new Array(),
		deleting: false,
		deleteCounter: 0,

		removeModifiersDialog: false,

		pagination: {
			sortBy: "name",
			rowsPerPage: -1,
			descending: false,
		},

		headers: [
			{
				text: "Actief",
				align: "left",
				sortable: true,
				value: "active",
				width: "80px"
			},
			{
				text: "Naam",
				align: "left",
				sortable: true,
				value: "name",
			},
			{
				text: "Vestigingen",
				align: "left",
				value: "branches",
			},
			{
				text: "Kortingsgroep",
				align: "left",
				value: "modifierGroup",
			},
			{
				text: "Toepassing op",
				align: "left",
				value: "type",
			},
			{
				text: "Configuratie",
				align: "left",
				value: "typeDiscount",
			},
			{
				text: "",
				align: "left",
				value: "edit",
				width: "60px",
			},
		],
		filterItemActions: [
			{
				name: "Komt overeen met selectie",
				id: "include",
			},
			{
				name: "Kom niet overeen met selectie",
				id: "exclude",
			},
		],
		filterProductenTypes: [
			{
				name: "Specifieke producten",
				id: "specific",
			},
			{
				name: "Filter op merk",
				id: "brands",
			},
			{
				name: "Filter op categorie",
				id: "categories",
			},
		],
		filterTreatmentsTypes: [
			{
				name: "Specifieke behandelingen",
				id: "specific",
			},
			{
				name: "Filter op categorie",
				id: "categories",
			},
		],
		modifierTypes: [
			{
				name: "Producten en behandelingen",
				id: "all",
			},
			{
				name: "Alleen producten",
				id: "product",
			},
			{
				name: "Alleen behandelingen",
				id: "treatment",
			},
		],
		typeDiscountTypes: [
			{
				name: "Vastgestelde korting",
				id: "fixedAmount",
			},
			// { Legacy
			//   name: "Percentage",
			//   id: "percentage",
			// },
			// {
			// 	name: "Bundelkorting",
			// 	id: "quantityDiscount",
			// },
			{
				name: "Koop X krijg X",
				id: "buyXX",
			},
			{
				name: "Koop X krijg Y",
				id: "buyXY",
			},
		],
		quantityDiscountTypes: [
			{
				name: "Bedrag",
				id: "amount",
			},
			{
				name: "Percentage",
				id: "percentage",
			},
		],
		discountActionTypes: [
			{
				name: "Bedrag",
				id: "amount",
			},
			{
				name: "Percentage",
				id: "percentage",
			},
			{
				name: "Gratis",
				id: "free",
			},
		],
		numberLimitTypes: [
			{
				id: "max",
				name: "Maximaal gebruik van korting",
			},
			{
				id: "maxClient",
				name: "Maximaal gebruik van korting per cliënt",
			},
		],
	}),

	created() {
		bus.$on("companyUpdate", this.loadData);
		bus.$on("deleteModifiers", (id) => {
			this.deleteModifiers();
		});
		this.loadData();
	},

	methods: {
		loadData() {
			this.loading = true;
			this.loadModifiers();
		},

		getModifierGroupName(value) {
			if (value === "defaultModifier") {
				return this.$t("general.default", this.$store.state.locale);
			} else if (value === "membershipModifier") {
				return this.$t("modifier.membershipModifier", this.$store.state.locale);
			} else if (value === "loyaltyModifier") {
				return this.$t("loyalty.points", this.$store.state.locale);
			} else {
				return this.$t("general.unknown", this.$store.state.locale);
			}
		},

		async loadModifiers() {
			await db
				.collection("modifiers")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("deleted", "==", false)
				.orderBy("name", "asc")
				.get()
				.then((snap) => {
					this.modifiers = new Array();
					snap.forEach((doc) => {
						let modifier = doc.data();
						modifier.id = doc.id;
						if (!modifier.barcode) {
							modifier.barcode = {
								type: "undefined",
								code: null,
							};
						}
						this.modifiers.push(modifier);
					});
				});
			this.loading = false;
		},

		/**
		 * Open Modifier Modal
		 */
		addModifier() {
			bus.$emit("addModifierModal");
		},

		/**
		 * Open Modifier Modal
		 * @param {Object} item - All modifier data
		 */
		editModifier(item) {
			bus.$emit("editModifierModal", _.cloneDeep(item));
		},

		/**
		 * Show General Delete Modal
		 *
		 * @param {String} id Specific Document ID to delete (optional)
		 * @param {String} deleteFunction  What function to execute when delete is confirmed
		 */
		showDeleteDialog(id, deleteFunction) {
			let deleteInformation = new Object();
			deleteInformation.id = id;
			deleteInformation.emit = deleteFunction;

			if (this.selected.length > 1) {
				deleteInformation.title = this.$t("modifier.removeModifiers", this.$store.state.locale);
				deleteInformation.info = this.$t("modifier.removeModifiersInfo", this.$store.state.locale);
			} else {
				deleteInformation.title = this.$t("modifier.removeModifier", this.$store.state.locale);
				deleteInformation.info = this.$t("modifier.removeModifierInfo", this.$store.state.locale);
			}

			bus.$emit("deletedModal", deleteInformation, "open");
		},

		/**
		 * Delete selected Modifiers
		 * Shows progress during deletion and displays success message via snackbar
		 */
		async deleteModifiers() {
			this.removeModifiersDialog = false;
			this.deleting = true;
			this.deleteCounter = 0;

			try {
				await Promise.all(
					this.selected.map((element) => {
						return db
							.collection("modifiers")
							.doc(element.id)
							.set(
								{
									deleted: {
										deleted: true,
										deletedBy: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
										deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
										active: false,
									},
								},
								{ merge: true }
							)
							.then(() => {
								this.deleteCounter++;
							});
					})
				);

				// Show success message via snackbar
				this.$store.commit("setSnackBar", {
					text:
						this.selected.length > 1
							? this.$t("modifier.modifiersRemovedSuccessfully", this.$store.state.locale)
							: this.$t("modifier.modifierRemovedSuccessfully", this.$store.state.locale),
					color: "success",
					timeout: 3000,
					show: true,
				});

				this.deleting = false;
				this.selected = new Array();
				this.loadModifiers();
				bus.$emit("deletedModal", null, "close");
			} catch (error) {
				console.error("Error deleting modifiers:", error);
				// Show error via snackbar
				this.$store.commit("setSnackBar", {
					text: this.$t("general.error", this.$store.state.locale),
					color: "error",
					timeout: 3000,
					show: true,
				});
			}
		},

		/**
		 * Get the name of the branch from the Vuex Store. Based on BranchId
		 * @param {String } branchId // Branch Id
		 */
		getBranchName(branchId) {
			let data = this.$store.state.companyBranches.find((branch) => {
				return branch.id == branchId;
			});
			if (data != undefined) {
				return data.name;
			} else {
				return branchId;
			}
		},

		// In methods section:
		/**
		 * Creates a copy of an existing modifier
		 * @param {Object} item - The modifier to copy
		 */
		async copyModifier(item) {
			const modifierCopy = _.cloneDeep(item);

			// Remove id and modify required fields
			delete modifierCopy.id;
			modifierCopy.name = `${item.name} kopie`;
			modifierCopy.active = false;

			try {
				await db.collection("modifiers").add(modifierCopy);
				this.loadModifiers();

				// Use Vuex store snackbar instead of showMessage
				this.$store.commit("setSnackBar", {
					text: this.$t("modifier.copiedSuccessfully", this.$store.state.locale),
					color: "success",
					timeout: 3000,
					show: true,
				});
			} catch (error) {
				console.error("Error copying modifier:", error);

				// Show error via snackbar
				this.$store.commit("setSnackBar", {
					text: this.$t("general.error", this.$store.state.locale),
					color: "error",
					timeout: 3000,
					show: true,
				});
			}
		},

		/**
		 * Toggle the active state of selected modifiers
		 */
		async toggleActiveState() {
			try {
				await Promise.all(
					this.selected.map((element) => {
						return db
							.collection("modifiers")
							.doc(element.id)
							.set(
								{
									active: !element.active,
								},
								{ merge: true }
							);
					})
				);

				// Show success message via snackbar
				this.$store.commit("setSnackBar", {
					text: this.$t("modifier.activeStateToggled", this.$store.state.locale),
					color: "success",
					timeout: 3000,
					show: true,
				});

				this.selected = new Array();
				this.loadModifiers();
			} catch (error) {
				console.error("Error toggling active state:", error);
				// Show error via snackbar
				this.$store.commit("setSnackBar", {
					text: this.$t("general.error", this.$store.state.locale),
					color: "error",
					timeout: 3000,
					show: true,
				});
			}
		},
	},
};
</script>
