<template>
	<v-dialog v-model="showDialog" persistent scrollable max-width="800px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
		<v-card>
			<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
				<h3 v-if="editMode">{{ $t("modifier.editModifier", $store.state.locale) }}</h3>
				<h3 v-else>{{ $t("modifier.newModifier", $store.state.locale) }}</h3>
				<v-spacer></v-spacer>
				<v-btn icon dark @click="closeModifierDialog()">
					<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-divider v-if="feedback || (feedbackDiscounts && feedbackDiscounts.length > 0)"></v-divider>

			<v-alert
				v-if="feedback"
				class="ma-0 pa-2"
				style="border-radius: 0"
				icon="mdi-alert"
				dark
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].warning"
			>
				{{ feedback }}
			</v-alert>

			

			<v-divider></v-divider>

			<v-card-text class="ma-0">
				<v-card
					class="mt-2"
					outlined
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].developer"
					v-if="editMode && $store.state.userRoleNumber == 0 && !$store.state.demoMode"
				>
					<v-btn
						@click="copyTextToClipboard(editModifier.id)"
						class="ma-1 mb-2"
						small
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						style="color: #ffffff; text-transform: none"
						><strong>{{ editModifier.id }}</strong></v-btn
					>
					<div
						style="display: inline-block"
						v-if="modifierIdCopy == false"
						class="pa-2 copy-tag-url"
						@click="copyTextToClipboard(editModifier.id)"
					>
						<v-icon small color="#333">mdi-content-copy</v-icon>Kopieër DocId
					</div>
					<div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
				</v-card>

				<v-card v-if="feedbackDiscounts.length > 0" class="mt-4" flat>
				<v-card-text class="pa-0">
					<v-expansion-panels flat>
						<v-expansion-panel
							v-for="(feedback, index) in feedbackDiscounts"
							:key="index"
							class="mb-2"
						>
							<v-expansion-panel-header
								class="pa-3"
								:class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
							>
								<div class="d-flex align-center">
									<v-icon small left color="warning">mdi-alert-circle</v-icon>
									<div class="body-2">
										{{ feedback.count }} overlappende korting{{ feedback.count > 1 ? 'en' : '' }} gevonden
									</div>
									<v-spacer />
									<!-- <v-icon small>
										{{ $refs[`panel-${index}`] && $refs[`panel-${index}`].isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
									</v-icon> -->
								</div>
							</v-expansion-panel-header>
							
							<v-expansion-panel-content
								:ref="`panel-${index}`"
								:class="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
							>
								<v-list dense class="mt-2">
									<v-list-item
										v-for="(modifier, i) in feedback.modifiers"
										:key="i"
										class="pl-3"
									>
										<v-list-item-content class="py-0">
											<v-list-item-title class="caption">
												• {{ modifier }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</v-card>

				<v-form ref="ModifierForm" lazy-validation @submit.prevent="saveModifier">
					<!-- Tabs for better UX grouping -->
					<v-tabs
						v-model="tab"
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
						show-arrows
						style="border-bottom: thin solid"
						:style="{ 'border-color': $themes[$store.state.companyTheme][$store.state.themeModus].separator }"
					>
						<v-tab>Algemeen</v-tab>
						<v-tab>Beperkingen</v-tab>
						<v-tab>Kortingtype</v-tab>
						<v-tab>Filters</v-tab>
					</v-tabs>

					<v-tabs-items v-model="tab" class="transparant-tabs">
						<!-- Algemeen Tab -->
						<v-tab-item :transition="tabTransition" :reverse-transition="tabTransition">
							<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
								<v-card-text class="pa-4">
									<v-subheader class="pl-0">Algemene Instellingen</v-subheader>

									<div class="text-body-2 text-medium-emphasis px-0 pb-4">
										Hier kun je de basisinstellingen van de korting configureren, zoals de naam en beschrijving. Selecteer op welke
										vestigingen de korting van toepassing is en kies het type korting. Je kunt ook aangeven of de korting automatisch moet
										worden toegepast.
									</div>

									<v-switch
										label="Korting actief"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="editModifier.active"
										hide-details
										class="mt-4"
									>
									</v-switch>

									<v-text-field
										label="Naam"
										dense
										v-model="editModifier.name"
										required
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										hide-details
									></v-text-field>

									<v-textarea
										label="Omschrijving"
										dense
										v-model="editModifier.description"
										required
										outlined
										rows="2"
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										hide-details
									></v-textarea>

									<v-select
										dense
										clearable
										multiple
										chips
										deletable-chips
										small-chips
										:items="$store.state.activeUserBranches"
										item-text="name"
										item-value="id"
										label="Vestigingen"
										v-model="editModifier.branches"
										v-on:change="checkModifiers()"
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									>
									</v-select>

									<v-select
										class="mt-4"
										:items="typesModifier"
										item-value="id"
										:label="$t('modifier.typeModifier', $store.state.locale)"
										v-model="editModifier.modifierGroup"
										v-on:change="checkTypeModifier()"
										outlined
										dense
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									>
										<template v-slot:item="data">
											{{ $t(`modifier.${data.item.id}`, $store.state.locale) }}
										</template>

										<template v-slot:selection="data">
											{{ $t(`modifier.${data.item.id}`, $store.state.locale) }}
										</template>
									</v-select>

									<v-text-field
										v-show="editModifier.modifierGroup === 'loyaltyModifier'"
										prepend-inner-icon="mdi-heart-circle"
										label="Aantal benodigde punten"
										v-model.number="editModifier.loyaltyPointsNeeded"
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										hide-details
									>
									</v-text-field>

									<v-text-field
										v-show="editModifier.modifierGroup === 'defaultModifier' || editModifier.modifierGroup === 'loyaltyModifier'"
										prepend-inner-icon="mdi-barcode"
										label="Barcode"
										v-model.number="editModifier.barcode.code"
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										hide-details
									></v-text-field>

								

									<v-select
										:items="modifierTypes"
										item-text="name"
										item-value="id"
										label="Korting toepasbaar op"
										dense
										v-model="editModifier.type"
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
										v-on:input="resetModifierType()"
									></v-select>
								</v-card-text>
							</v-card>
						</v-tab-item>

						<!-- Beperkingen Tab -->
						<v-tab-item :transition="tabTransition" :reverse-transition="tabTransition">
							<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
								<v-card-text class="pa-4">
									<v-subheader class="pl-0">Beperkingen</v-subheader>

									<div class="text-body-2 text-medium-emphasis px-0 pb-4">
										Stel hier in hoe vaak en wanneer de korting gebruikt mag worden. Je kunt de korting beperken tot een specifieke periode
										of een maximum aantal keer gebruik instellen. Ook kun je bepalen of de korting te combineren is met andere kortingen.
									</div>

									<v-switch
										class="ma-0 mt-4 py-2 ml-2"
										label="Combineren met andere kortingen niet toestaan"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="editModifier.combinationLimit"
										hide-details
									></v-switch>

									<v-card class="mt-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											class="ma-0 py-2 ml-2"
											v-model="editModifier.numberLimit.active"
											hide-details
											v-on:change="resetNumberLimit()"
										>
											<template v-slot:label>
												<span style="margin-right: 5px">Gebruik van korting beperken</span>
												<v-tooltip class="ma-0 pa-0" right>
													<template v-slot:activator="{ on }">
														<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on"
															>mdi-help-circle</v-icon
														>
													</template>
													<span style="color: #fff">Gebruiksaantallen van de korting beheren.</span>
												</v-tooltip>
											</template>
										</v-switch>

										<v-container v-if="editModifier.numberLimit.active" grid-list-md fluid class="ma-0 pa-0">
											<v-layout row wrap class="ma-0 pa-0">
												<v-flex xs12>
													<v-select
														:items="numberLimitTypes"
														item-text="name"
														item-value="id"
														dense
														label="Type beperking"
														v-model="editModifier.numberLimit.type"
														outlined
														class="mt-4"
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														hide-details
													></v-select>
												</v-flex>
												<v-flex xs6>
													<v-text-field
														label="Aantal"
														dense
														v-model.number="editModifier.numberLimit.number"
														required
														outlined
														class="mt-4"
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														hide-details
													></v-text-field>
												</v-flex>
												<v-flex xs6 v-if="editModifier.numberLimit.type == 'max'">
													<v-text-field
														label="Aantal gebruikt"
														v-model="editModifier.numberLimit.numberUsed"
														required
														outlined
														dense
														readonly
														disabled
														class="mt-4"
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														hide-details
													></v-text-field>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>

									<v-card class="mt-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											class="ma-0 py-2 ml-2"
											v-model="editModifier.dateLimit.active"
											hide-details
											v-on:change="resetDateLimit()"
											>
											<template v-slot:label>
												<span style="margin-right: 5px">Korting beperken tot periode</span>
												<v-tooltip class="ma-0 pa-0" right>
													<template v-slot:activator="{ on }">
														<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on"
															>mdi-help-circle</v-icon
														>
													</template>
													<span style="color: #fff">Korting is alleen in deze periode actief</span>
												</v-tooltip>
											</template>
										</v-switch>

										<v-container v-if="editModifier.dateLimit.active" grid-list-md fluid class="ma-0 pa-0">
											<v-layout row wrap class="ma-0 pa-0">
												<v-flex xs6>
													<v-menu
														:close-on-content-click="false"
														v-model="modifierDateLimitStartDialog"
														:nudge-right="40"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="290px"
													>
														<template v-slot:activator="{ on }">
															<v-text-field
																v-on="on"
																v-model="modifierStartdate.readable"
																label="Startdatum"
																prepend-inner-icon="mdi-calendar"
																readonly
																dense
																outlined
																required
																hide-details
																:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="editModifier.dateLimit.startDate"
															no-title
															:min="now"
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
															@input="modifierDateLimitStartDialog = false"
														></v-date-picker>
													</v-menu>
												</v-flex>
												<v-flex xs6>
													<v-menu
														:close-on-content-click="false"
														v-model="modifierDateLimitEndDialog"
														:nudge-right="40"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="290px"
													>
														<template v-slot:activator="{ on }">
															<v-text-field
																v-on="on"
																v-model="modifierEnddate.readable"
																label="Einddatum"
																prepend-inner-icon="mdi-calendar"
																readonly
																dense
																outlined
																required
																hide-details
																:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
																:disabled="!editModifier.dateLimit.startDate"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="editModifier.dateLimit.endDate"
															no-title
															:min="editModifier.dateLimit.startDate"
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
															@input="modifierDateLimitEndDialog = false"
														></v-date-picker>
													</v-menu>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>
								</v-card-text>
							</v-card>
						</v-tab-item>

						<!-- Kortingtype Tab -->
						<v-tab-item :transition="tabTransition" :reverse-transition="tabTransition">
							<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
								<v-card-text class="pa-4">
									<v-subheader class="pl-0">Kortingtype & Waarden</v-subheader>

									<div class="text-body-2 text-medium-emphasis px-0 pb-4">
								   <!-- Fixed amount discount -->
									<div v-if="editModifier.typeDiscount.type ==='fixedAmount'">
										Vaste korting: Trek een specifiek bedrag af van de originele prijs.
										<br>
										<em>Voorbeeld: €5 korting op elk artikel - Een artikel van €25 kost nu €20.</em>
									</div>

									<!-- Buy X Get X discount -->
									<div v-if="editModifier.typeDiscount.type === 'buyXX'">
										Koop X, krijg X gratis: Koop een aantal van hetzelfde artikel en ontvang extra exemplaren gratis.
										<br>
										<em>Voorbeeld: "3 halen, 2 betalen" - Koop 2 artikelen, krijg het 3e gratis.</em>
									</div>

									<!-- Buy X Get Y discount -->
									<div v-if="editModifier.typeDiscount.type === 'buyXY'">
										Koop X, krijg Y korting: Koop een specifiek aantal artikelen en krijg korting op extra artikelen.
										<br>
										<em>Voorbeeld: "2e artikel 50% korting" of "3e artikel gratis".</em>
  									</div>
									</div>

									<v-switch
										v-if="editModifier.modifierGroup !== 'membershipModifier'"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="editModifier.automatic"
										hide-details
										class="mb-2"
									>
										<template v-slot:label>
											<span style="margin-right: 5px">Automatisch toepassen van korting</span>
											<v-tooltip class="ma-0 pa-0" right>
												<template v-slot:activator="{ on }">
													<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">
														mdi-help-circle
													</v-icon>
												</template>
												<span style="color: #fff">
													Korting wordt automatisch toegepast wanneer aan de voorwaarden wordt voldaan
												</span>
											</v-tooltip>
										</template>
									</v-switch>
									<!-- New hint message with proper styling -->
									<div 
										v-if="editModifier.modifierGroup !== 'membershipModifier'"
										class="text-body-2 text-medium-emphasis pl-9"
										style="font-style: italic;"
									>
										<v-icon
											small
											color="info"
											class="mr-1"
										>mdi-information</v-icon>
										Bij complexere kortingen is automatisering aan te raden om medewerkers te ontlasten en fouten te voorkomen.
									</div>

									<v-select
										:items="typeDiscountTypes"
										item-text="name"
										dense
										item-value="id"
										label="Kortingtype"
										v-model="editModifier.typeDiscount.type"
										v-on:input="resetDiscountType()"
										outlined
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									></v-select>

									<v-switch
										v-if="editModifier.typeDiscount.type == 'fixedAmount' && editModifier.typeDiscount.discountType == 'amount'"
										label="Repetitief berekenen"
										v-model="editModifier.typeDiscount.repetitive"
										hide-details
										class="mt-4"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									>
										<template v-slot:label>
											<span style="margin-right: 5px">Per item korting volledig opnieuw berekenen</span>
											<v-tooltip class="ma-0 pa-0" right>
												<template v-slot:activator="{ on }">
													<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on"
														>mdi-help-circle</v-icon
													>
												</template>
												<span style="color: #fff"
													>Wanneer deze optie uitgevinkt is, wordt het bedrag over de betreffende items berekend.</span
												>
											</v-tooltip>
										</template>
									</v-switch>

									<v-card
										class="py-4 px-4 mt-4"
										flat
										v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
									>
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											class="ma-0 py-2"
											v-model="editModifier.useLimit.active"
											hide-details
											v-on:change="resetUseNumberLimit()"
										>
											<template v-slot:label>
												<span style="color: #333; margin-right: 5px">Maximaal aantal keer toepassen bij afrekenen</span>
												<v-tooltip class="ma-0 pa-0" bottom>
													<template v-slot:activator="{ on }">
														<v-icon color="#687387" small v-on="on">mdi-help-circle</v-icon>
													</template>
													<span style="color: #fff"
														>Bij het toevoegen van meer producten of behandelingen wordt de korting slechts het ingegeven aantal keer
														toegepast.</span
													>
												</v-tooltip>
											</template>
										</v-switch>

											<v-text-field
											v-if="editModifier.useLimit.active"
											label="Maximaal aantal keer toepassen bij afrekenen"
											v-model="editModifier.useLimit.number"
												required
												outlined
											dense
											suffix="x"
												class="mt-4"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
												hide-details
											></v-text-field>
									</v-card>

									<v-text-field
										v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'"
										class="mt-4"
										label="Aantal te kopen"
										v-model.number="editModifier.typeDiscount.buyQuantity"
										hide-details
										type="number"
										outlined
										required
										dense
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									></v-text-field>

											<v-text-field
										v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'"
										class="mt-4"
										label="Aantal waar actie op geldt"
										v-model.number="editModifier.typeDiscount.getQuantity"
										hide-details
										type="number"
										outlined
												dense
												required
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											></v-text-field>

									<v-select
										class="mt-4"
										v-if="editModifier.typeDiscount.type == 'quantityDiscount'"
										:items="quantityDiscountTypes"
										item-text="name"
										dense
										item-value="id"
										label="Type korting"
										v-model="editModifier.typeDiscount.discountType"
										outlined
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									></v-select>

											<v-select
										class="mt-4"
										v-if="
											editModifier.typeDiscount.type == 'buyXX' ||
											editModifier.typeDiscount.type == 'buyXY' ||
											editModifier.typeDiscount.type == 'fixedAmount'
										"
										:items="discountActionTypes"
												item-text="name"
												item-value="id"
										label="Type actie"
										v-model="editModifier.typeDiscount.discountType"
										outlined
												dense
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									></v-select>

									<v-text-field
										v-if="
											(editModifier.typeDiscount.discountType == 'amount' || editModifier.typeDiscount.discountType == 'percentage') &&
											editModifier.typeDiscount.type !== 'quantityDiscount'
										"
										class="mt-4"
										:label="discountInfo.label"
										v-model.number="editModifier.typeDiscount.discountValue"
										:prefix="discountInfo.prefix"
										:suffix="discountInfo.suffix"
										hide-details
										dense
										type="number"
												outlined
										required
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									></v-text-field>

									<div v-if="editModifier.typeDiscount.type == 'quantityDiscount'" class="text-center">
										<v-card flat class="mt-4 pa-2">
											<div
												class="discount-fields-wrapper"
												v-for="(quantityRule, index) in editModifier.typeDiscount.quantityRules"
												:key="index"
											>
												<div class="discount-fields-input">
													<v-container grid-list-md fluid class="ma-0 pa-0">
														<v-layout row wrap class="ma-0 mb-2 pa-0">
															<v-flex xs6>
																<v-text-field
																	label="Aantal"
																	v-model="quantityRule.number"
																	type="number"
																	dense
												hide-details
																	outlined
																	required
																	:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
																></v-text-field>
															</v-flex>
															<v-flex xs6>
																<v-text-field
																	:label="discountInfo.label"
																	v-model.number="quantityRule.discount"
																	:prefix="discountInfo.prefix"
																	:suffix="discountInfo.suffix"
																	hide-details
																	dense
																	type="number"
																	outlined
																	required
																	:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
																	:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																></v-text-field>
															</v-flex>
														</v-layout>
													</v-container>
												</div>
												<div class="discount-fields-remove">
													<v-btn
														:disabled="editModifier.typeDiscount.quantityRules.length < 3"
														icon
														color="text"
														class="mt-1"
														@click="removeQuantityDiscount(index)"
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</div>
											</div>
									</v-card>

										<v-btn
											class="mt-4"
											fab
											dark
											small
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											elevation="0"
											@click="addNewQuantityDiscount()"
										>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</div>

					
									

								</v-card-text>
							</v-card>
						</v-tab-item>

						<!-- Filters Tab -->
						<v-tab-item :transition="tabTransition" :reverse-transition="tabTransition">
							<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
								<v-card-text class="pa-4">
									<v-subheader class="pl-0">Filters</v-subheader>

									<div class="text-body-2 text-medium-emphasis px-0 pb-4">
										Specificeer hier op welke producten of behandelingen de korting van toepassing is. Je kunt filteren op specifieke items,
										categorieën of merken. Dit zorgt ervoor dat de korting alleen geldt voor de door jou geselecteerde items.
									</div>

									<!-- Product Filters -->
									<v-card
										class="mt-4"
										flat
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
										v-if="editModifier.type == 'all' || editModifier.type == 'product'"
									>
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											class="ma-0 py-2 pb-4"
											v-model="editModifier.filterProducts.active"
											hide-details
											v-on:change="resetProductDiscount()"
										>
											<template v-slot:label>
												<span style="margin-right: 5px">Korting beperken tot specifieke producten</span>
												<v-tooltip class="ma-0 pa-0" right>
													<template v-slot:activator="{ on }">
														<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">
															mdi-help-circle
														</v-icon>
													</template>
													<span style="color: #fff">Filter specifieke producten</span>
												</v-tooltip>
											</template>
										</v-switch>

										<v-container v-if="editModifier.filterProducts.active" grid-list-md fluid class="ma-0 pa-0">
											<v-layout row wrap class="ma-0 pa-0">
												<v-flex xs12>
											<v-select
														:items="filterProductenTypes"
												item-text="name"
												item-value="id"
														label="Filteren op"
														v-model="editModifier.filterProducts.type"
												outlined
														dense
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
														v-on:input="resetProductDiscount()"
													></v-select>
												</v-flex>

												<v-flex xs12 v-if="editModifier.filterProducts.type != 'all'">
											<v-select
														:items="filterItemActions"
												item-text="name"
												item-value="id"
														v-model="editModifier.filterProducts.itemsAction"
												outlined
														dense
												class="mt-4"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
													></v-select>
												</v-flex>
											</v-layout>

											<v-layout row wrap class="ma-0 pa-0" v-if="propductDataLoaded">
												<!-- Specific Products Filter -->
												<v-flex xs12 v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'specific'">
													<!-- Keep existing Algolia autocomplete -->
													<v-autocomplete
														v-model="selectedProduct"
														:search-input.sync="searchProducts"
														:items="algoliaProducts"
												item-text="name"
												item-value="id"
														label="Kies product(en) voor de filter"
												outlined
														dense
														:loading="isSearching"
												class="mt-4"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
														@change="addProduct"
														@update:search-input="searchAlgoliaProducts"
														return-object
													>
														<template v-slot:item="{ item }">
															<v-list-item-content>
																<v-list-item-title v-text="item.name + ' - ' + item.content"></v-list-item-title>
															</v-list-item-content>
														</template>

														<template v-slot:no-data>
															<v-list-item>
																<v-list-item-content>
																	<v-list-item-title> Begin met typen om producten te zoeken </v-list-item-title>
																</v-list-item-content>
															</v-list-item>
														</template>
													</v-autocomplete>

													<!-- Separate chips display -->
													<div class="mt-2">
														<v-chip
															v-for="itemId in editModifier.filterProducts.items"
															:key="itemId"
															small
															class="mr-1 mb-1"
															close
															@click:close="removeProduct(itemId)"
														>
															{{ getProductName(itemId) }}
														</v-chip>
													</div>
												</v-flex>

												<!-- Brands Filter -->
												<v-flex xs12 v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'brands'">
													<v-select
														clearable
														multiple
														chips
														deletable-chips
														small-chips
														:items="productsSuppliers"
														item-text="name"
														item-value="id"
														label="Merken"
														v-model="editModifier.filterProducts.items"
														v-on:change="checkModifiers()"
														outlined
														dense
														class="mt-4"
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														hide-details
													></v-select>
												</v-flex>

												<!-- Categories Filter -->
												<v-flex xs12 v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'categories'">
													<v-select
														clearable
														multiple
														chips
														dense
														deletable-chips
														small-chips
														:items="productCategories"
														:item-text="item => item.name[($store.state.locale).toLowerCase()]"
														item-value="id"
														label="Categorieën"
														v-model="editModifier.filterProducts.items"
														v-on:change="checkModifiers()"
														outlined
														class="mt-4"
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														hide-details
													></v-select>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>

									<!-- Treatment Filters -->
									<v-card
										class="mt-4"
										flat
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
										v-if="editModifier.type == 'all' || editModifier.type == 'treatment'"
									>
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											class="ma-0 py-2 pb-4"
											v-model="editModifier.filterTreatments.active"
											hide-details
											v-on:change="resetTreatmentDiscount()"
										>
											<template v-slot:label>
												<span style="margin-right: 5px">Korting beperken tot specifieke behandelingen</span>
												<v-tooltip class="ma-0 pa-0" right>
													<template v-slot:activator="{ on }">
														<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">
															mdi-help-circle
														</v-icon>
													</template>
													<span style="color: #fff">Filter specifieke behandelingen</span>
												</v-tooltip>
											</template>
										</v-switch>

										<v-container v-if="editModifier.filterTreatments.active" grid-list-md fluid class="ma-0 pa-0">
											<v-layout row wrap class="ma-0 pa-0">
												<v-flex xs12>
											<v-select
														:items="filterTreatmentsTypes"
												item-text="name"
												item-value="id"
														label="Filteren op"
														v-model="editModifier.filterTreatments.type"
												outlined
														dense
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
														v-on:input="resetTreatmentDiscount()"
													></v-select>
												</v-flex>

												<v-flex xs12 v-if="editModifier.filterTreatments.type != 'all'">
											<v-select
														:items="filterItemActions"
												item-text="name"
												item-value="id"
														v-model="editModifier.filterTreatments.itemsAction"
												outlined
														dense
												class="mt-4"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
													></v-select>
												</v-flex>
											</v-layout>

											<v-layout row wrap class="ma-0 pa-0" v-if="treatmentDataLoaded">
												<v-flex xs12 v-if="editModifier.filterTreatments.type != 'all' && editModifier.filterTreatments.type == 'specific'">
											<v-select
												clearable
												multiple
												chips
												deletable-chips
												small-chips
														:items="filteredTreatments"
												item-text="displayName"
												item-value="id"
														label="Behandelingen"
														v-model="editModifier.filterTreatments.items"
														v-on:change="checkModifiers()"
												outlined
														dense
												class="mt-4"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
												:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												hide-details
											>
														<!-- Custom template voor items in de dropdown -->
														<template v-slot:item="{ item }">
															{{ getTreatmentName(item) }}, 
														</template>

														<!-- Custom template voor geselecteerde items -->
														<template v-slot:selection="{ item }">
															{{ getTreatmentName(item) }}, 
														</template>
											</v-select>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-form>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-container grid-list-md fluid class="ma-0 pa-0">
					<v-layout row wrap class="ma-0 pa-0">
						<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
						<v-btn
								outlined
								depressed
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
							@click="closeModifierDialog()"
							>
								{{ $t("general.cancel", $store.state.locale) }}
							</v-btn>
						</v-flex>
						<v-flex xs12 md8 class="text-right">
							<v-btn
								v-if="editMode"
								:disabled="!changed"
								:depressed="!changed"
								:dark="changed"
								:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								@click="saveModifier()"
						>
								<div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
								<div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>
							</v-btn>
						<v-btn
								v-else
								dark
								:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							@click="saveModifier()"
							>
								{{ $t("general.add", $store.state.locale) }}
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</v-container>
					</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import db from "../../firebase/init";
import { bus } from "../../main";
import moment from "moment";
import { getCorrelatedModifiers } from "../../modules/correlatedModifiers";
import treatmentSelector from "@/components/elements/treatmentSelector.vue";
import algoliasearch from "algoliasearch/lite";
import { autoLanguageSelector } from '@/modules/autoLanguageSelector'

export default {
	name: "ModifierForm",
	props: {
		loadedModifiers: Array,
		filterItemActions: Array,
		filterProductenTypes: Array,
		filterTreatmentsTypes: Array,
		modifierTypes: Array,
		typeDiscountTypes: Array,
		numberLimitTypes: Array,
		discountActionTypes: Array,
		quantityDiscountTypes: Array,
		modifiers: Array,
	},
	components: {
		treatmentSelector: treatmentSelector,
	},
	data() {
		return {
			editMode: false,
			showDialog: false,
			modifierIdCopy: false,
			feedback: null,
			feedbackDiscounts: new Array(),
			modifierDateLimitEndDialog: false,
			modifierDateLimitStartDialog: false,
			now: null,
			treatments: [],
			products: [],
			productCategories: [],
			productsSuppliers: [],
			propductDataLoaded: false,
			treatmentDataLoaded: false,
			searchTreatments: null,
			searchProducts: null,
			tab: 0, // For the tabs

			typesModifier: [
				{ id: "defaultModifier", name: "Algemene korting" },
				{ id: "membershipModifier", name: "Abonnementskorting" },
				{ id: "loyaltyModifier", name: "Spaarpunten" },
			],
			editModifier: {
				branches: new Array(),
				numberLimit: new Object(),
				useLimit: new Object(),
				barcode: new Object(),
				dateLimit: new Object(),
				typeDiscount: {
					quantityRules: new Array(),
				},
				filterProducts: {
					items: new Array(),
				},
				filterTreatments: {
					items: new Array(),
				},
			},
			editModifierOriginal: new Object(),
			tabTransition: false,
			algoliaClient: null,
			algoliaIndex: null,
			algoliaProducts: [],
			isSearching: false,
			selectedProduct: null,
			filterChangeTimeout: null,
		};
	},

	created() {
		// Initialize Algolia client
		this.algoliaClient = algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc");
		this.algoliaIndex = this.algoliaClient.initIndex("thrive_products");
	},

	mounted() {
		this.now = moment().format("YYYY-MM-DD");
		bus.$on("addModifierModal", () => {
			this.resetModifierDialog();
			this.editMode = false;
			this.showDialog = true;
			this.getProductData();
			this.getTreatmentData();
		});

		bus.$on("editModifierModal", (item) => {
			this.resetModifierDialog();
			this.editMode = true;
			this.showDialog = true;
			this.editModifier = item;
			this.editModifierOriginal = _.cloneDeep(this.editModifier);
			this.getProductData();
			this.getTreatmentData();
		});
	},

	methods: {
		closeModifierDialog() {
			this.showDialog = false;
		},

		resetModifierDialog() {
			this.editModifier = {
				name: "",
				automatic: false,
				description: "",
				loyaltyPointsNeeded: 0,
				branches: [],
				active: true,
				modifierGroup: "defaultModifier",
				combinationLimit: false,

				numberLimit: {
					number: null,
					numberUsed: null,
					type: "max",
					active: false,
				},
				useLimit: {
					active: false,
					number: 0,
				},
				barcode: {
					type: "undefined",
					code: 0,
				},
				dateLimit: {
					startDate: null,
					endDate: null,
					active: false,
				},
				type: "all",
				typeModifier: "default",
				typeDiscount: {
					type: "fixedAmount",
					uniqueItems: true,
					buyQuantity: null,
					getQuantity: null,
					discountType: "percentage",
					quantityRules: [],
					discountValue: null,
				},

				filterProducts: {
					type: "specific",
					itemsAction: "include",
					items: [],
					active: false,
					itemInfo: [],
				},
				filterTreatments: {
					type: "specific",
					itemsAction: "include",
					items: [],
					active: false,
					itemInfo: [],
				},
			};
		},

		copyTextToClipboard(text) {
			if (!navigator.clipboard) {
				fallbackCopyTextToClipboard(text);
				return;
			}

			navigator.clipboard.writeText(text).then(
				() => {
					this.modifierIdCopy = true;
					setTimeout(() => {
						this.modifierIdCopy = false;
					}, 1000);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		},

		checkTypeModifier() {
			if (this.editModifier.modifierGroup === 'membershipModifier') {
				// Force automatic to false for membership modifiers
				this.editModifier.automatic = false;
			}
		},

		checkModifiers() {
			this.feedbackDiscounts = [];
			const correlatedModifiersData = getCorrelatedModifiers(
				this.products,
				this.treatments,
				this.modifiers,
				this.editModifier
			);

			// Combine product and treatment modifiers
			const allCorrelatedIds = [
				...new Set([
					...correlatedModifiersData.correlatedProductModifiersId,
					...correlatedModifiersData.correlatedTreatmentModifiersId
				])
			];

			// Get unique modifier names
			const allCorrelatedNames = allCorrelatedIds.map(id => 
				this.modifiers.find(m => m.id === id)?.name
			).filter(Boolean);

			// Only show feedback if there are correlated modifiers
			if (allCorrelatedNames.length > 0) {
				const isAllProducts = 
					!this.editModifier.filterProducts.active || 
					this.editModifier.filterProducts.items.length === 0;

				const isAllTreatments = 
					!this.editModifier.filterTreatments.active || 
					this.editModifier.filterTreatments.items.length === 0;

				const message = 
					isAllProducts && isAllTreatments ? 'Modifier applies to all products and treatments' :
					isAllProducts ? 'Modifier applies to all products' :
					isAllTreatments ? 'Modifier applies to all treatments' :
					'Overlapping modifiers found';

				this.feedbackDiscounts.push({
					message: message,
					modifiers: allCorrelatedNames,
					icon: isAllProducts || isAllTreatments ? 'mdi-alert' : 'mdi-alert-circle',
					color: isAllProducts || isAllTreatments ? 'error' : 'warning',
					type: isAllProducts || isAllTreatments ? 'error' : 'warning',
					count: allCorrelatedNames.length
				});
			}
		},

		resetDateLimit() {
			if (!this.editModifier.dateLimit.active) {
				this.editModifier.dateLimit.endDate = null;
				this.editModifier.dateLimit.startDate = null;
			}
		},

		resetNumberLimit() {
			if (!this.editModifier.numberLimit.active) {
				this.editModifier.numberLimit.number = null;
				this.editModifier.numberLimit.type = "max";
			}
		},

		resetModifierType() {
			if (this.editModifier.type == "product") {
				if (this.products.length === 0) {
					this.getProductData();
				}
				this.editModifier.filterTreatments.active = false;
				this.editModifier.filterTreatments.type = "specific";
				this.editModifier.filterTreatments.items = [];
			} else if (this.editModifier.type == "treatment") {
				if (this.treatments.length === 0) {
					this.getTreatmentData();
				}
				this.editModifier.filterProducts.active = false;
				this.editModifier.filterProducts.type = "specific";
				this.editModifier.filterProducts.items = [];
			} else if (this.editModifier.type == "all") {
				if (this.products.length === 0) {
					this.getProductData();
				}
				if (this.treatments.length === 0) {
					this.getTreatmentData();
				}
				this.editModifier.filterTreatments.active = false;
				this.editModifier.filterTreatments.type = "specific";
				this.editModifier.filterTreatments.items = [];
			}
		},

		/**
		 * Resets discount type configuration to default values
		 * Handles reset of:
		 * - Buy/Get quantities
		 * - Discount type
		 * - Quantity rules
		 * - Unique items flag
		 * - Repetitive flag
		 * - Use limit
		 * 
		 * For quantityDiscount type, sets default discount type to percentage
		 * and adds two initial quantity rules
		 */
		resetDiscountType() {
			// Reset basic discount properties
			this.editModifier.typeDiscount.buyQuantity = null;
			this.editModifier.typeDiscount.getQuantity = null;
			this.editModifier.typeDiscount.discountType = "free";
			this.editModifier.typeDiscount.quantityRules = [];
			this.editModifier.typeDiscount.uniqueItems = false;
			this.editModifier.typeDiscount.repetitive = false;

			// Special handling for quantity discount type
			if (this.editModifier.typeDiscount.type == "quantityDiscount") {
				this.editModifier.typeDiscount.discountType = "percentage";
				// Add two default quantity rules
				this.addNewQuantityDiscount();
				this.addNewQuantityDiscount();
			}

			// Reset use limit configuration
			this.editModifier.useLimit.active = false;
			this.resetUseNumberLimit();

			// Update uniqueItems based on new discount type
			this.editModifier.typeDiscount.uniqueItems = this.editModifier.typeDiscount.type === 'buyXY';
		},

		resetUseNumberLimit() {
			this.editModifier.useLimit.number = null;
		},

		removeQuantityDiscount(index) {
			this.editModifier.typeDiscount.quantityRules.splice(index, 1);
		},

		addNewQuantityDiscount() {
			let newQuantityDiscount = {
				number: null,
				discount: null,
			};
			this.editModifier.typeDiscount.quantityRules.push(newQuantityDiscount);
		},

		resetProductDiscount() {
			this.editModifier.filterProducts.items = [];
			this.algoliaProducts = [];
			this.selectedProduct = null;
			this.searchProducts = null;
			this.checkModifiers();
		},

		resetTreatmentDiscount() {
			if (this.editModifier.filterTreatments.active) {
				this.editModifier.filterTreatments.items = [];
				this.getTreatmentData();
			} else {
				this.editModifier.filterTreatments.items = [];
			}
		},

		async getTreatmentData() {
			if (!this.treatmentDataLoaded) {
				Promise.all([this.getTreatments(), this.getTreatmentTypes()]).then(() => {
					this.treatmentDataLoaded = true;
				});
			}
			this.checkModifiers();
		},

		async getProductData() {
			if (!this.propductDataLoaded) {
				Promise.all([this.getProducts(), this.getProductSuppliers(), this.getProductCategories()]).then(() => {
					this.propductDataLoaded = true;
				});
			}
			this.checkModifiers();
		},

		async getTreatments() {
			await db
				.collection("treatments")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("treatmentActive", "==", true)
				.get()
				.then((snap) => {
					this.treatments = [];
					snap.forEach((doc) => {
						let treatment = doc.data();
						treatment.id = doc.id;
						// Add display name for the select component
						treatment.displayName = this.getTreatmentName(treatment);
						this.treatments.push(treatment);
					});
				})
				.catch((err) => {
					console.error("Error getting Data", err);
				});
			return;
		},

		async getTreatmentTypes() {
			await db
				.collection("treatmentTypes")
				.get()
				.then((snap) => {
					this.treatmentTypes = [];
					snap.forEach((doc) => {
						let treatmentType = doc.data();
						treatmentType.id = doc.id;
						this.treatmentTypes.push(treatmentType);
					});
				})
				.catch((err) => {
					console.error("Error getting Data", err);
				});
			return;
		},

		async getProducts() {
			await db
				.collection("products")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("active", "==", true)
				.get()
				.then((snap) => {
					this.products = [];
					snap.forEach((doc) => {
						let product = doc.data();
						product.id = doc.id;
						this.products.push(product);
					});
				})
				.catch((err) => {
					console.error("Error getting Data", err);
				});
			return;
		},

		async getProductSuppliers() {
			await db
				.collection("productSuppliers")
				.where("companies", "array-contains", this.$store.state.activeCompany.id)
				.get()
				.then((snap) => {
					this.productsSuppliers = [];
					snap.forEach((doc) => {
						let productsSupplier = doc.data();
						productsSupplier.id = doc.id;
						this.productsSuppliers.push(productsSupplier);
					});
				})
				.catch((err) => {
					console.error("Error getting Data", err);
				});
		},

		async getProductCategories() {
			await db
				.collection("productCategories")
				.get()
				.then((snap) => {
					this.productCategories = [];
					snap.forEach((doc) => {
						let productCategory = doc.data();
						productCategory.id = doc.id;
						this.productCategories.push(productCategory);
					});
				})
				.catch((err) => {
					console.error("Error getting Data", err);
				});
		},

		async saveModifier() {
			// Add this at the start of the method
			this.checkTypeModifier();
			
			this.feedback = null;
			let numberLimit = false;
			let dateLimit = false;
			let quantityRulesValid = true;
			let quantityRulesNumberValid = true;
			let buyXYValid = true;
			let discountNumberValid = true;

			if (this.editModifier.dateLimit.active) {
				if (this.modifierEnddate.formatted && this.modifierStartdate.formatted) {
					dateLimit = true;
				}
			} else {
				dateLimit = true;
			}

			if (this.editModifier.numberLimit.active) {
				if (this.editModifier.numberLimit.number) {
					numberLimit = true;
				}
			} else {
				numberLimit = true;
			}

			if (this.editModifier.typeDiscount.type == "quantityDiscount") {
				if (this.editModifier.typeDiscount.quantityRules.length > 0) {
					this.editModifier.typeDiscount.quantityRules.forEach((item) => {
						if (!item.number || !item.discount) {
							quantityRulesValid = false;
						}
					});
				}
			}

			if (this.editModifier.typeDiscount.type == "buyXX" || this.editModifier.typeDiscount.type == "buyXY") {
				if (!this.editModifier.typeDiscount.buyQuantity || !this.editModifier.typeDiscount.getQuantity) {
					buyXYValid = false;
				}
				if (this.editModifier.typeDiscount.discountType != "free") {
					if (!this.editModifier.typeDiscount.discountValue) {
						buyXYValid = false;
					}
				}
			}

			if (this.editModifier.typeDiscount.type == "fixedAmount" || this.editModifier.typeDiscount.type == "percentage") {
				if (!this.editModifier.typeDiscount.discountValue) {
					discountNumberValid = false;
				}
			}

			if (this.editModifier.name && numberLimit && dateLimit && discountNumberValid && quantityRulesValid && buyXYValid) {
				let logData = {
					superAdminAction: false,
					actionType: "userAction",
					action: "modifierAdded",
					actionItem: this.editModifier.name,
					extra: [
						{
							name: "name",
							value: this.editModifier.name,
						},
						{
							name: "description",
							value: this.editModifier.description,
						},
						{
							name: "branches",
							value: this.editModifier.branches,
						},
						{
							name: "type",
							value: this.editModifier.type,
						},
						{
							name: "discountType",
							value: `${this.editModifier.typeDiscount.type} (${this.editModifier.typeDiscount.discountValue})`,
						},
					],
				};

				const modifierData = {
					name: this.editModifier.name,
					companyId: this.$store.state.activeCompany.id,
					description: this.editModifier.description,
					branches: this.editModifier.branches,
					active: this.editModifier.active,
					loyaltyPointsNeeded: this.editModifier.loyaltyPointsNeeded,
					modifierGroup: this.editModifier.modifierGroup,
					automatic: this.editModifier.automatic,
					combinationLimit: this.editModifier.combinationLimit,
					numberLimit: this.editModifier.numberLimit,
					useLimit: this.editModifier.useLimit,
					dateLimit: {
						startDate: this.modifierStartdate.formatted,
						endDate: this.modifierEnddate.formatted,
						active: this.editModifier.dateLimit.active,
					},
					barcode: this.editModifier.barcode,
					type: this.editModifier.type,
					typeDiscount: this.editModifier.typeDiscount,
					filterProducts: this.editModifier.filterProducts,
					filterTreatments: this.editModifier.filterTreatments,
					deleted: false,
				};

				try {
					if (this.editMode) {
						await db.collection("modifiers").doc(this.editModifier.id).update(modifierData);
					} else {
						await db.collection("modifiers").add(modifierData);
					}

					await this.$createNewLog("info", logData);
					this.closeModifierDialog();
					this.$emit("updateData");
					console.log("Modifier saved");

					await new Promise((resolve) => setTimeout(resolve, 300));
					this.editMode = false;
					this.editModifier = {
						name: "",
						description: "",
						branches: [],
						automatic: false,
						modifierGroup: "defaultModifier",
						loyaltyPointsNeeded: 0,
						useLimit: {
							active: false,
							number: 0,
						},
						barcode: {
							type: "undefined",
							code: 0,
						},
						combinationLimit: false,
						active: true,
						numberLimit: {
							number: null,
							numberUsed: null,
							type: "max",
							active: false,
						},
						dateLimit: {
							startDate: null,
							endDate: null,
							active: false,
						},
						type: "all",
						typeDiscount: {
							type: "fixedAmount",
							discountValue: null,
						},
						filterProducts: {
							type: "specific",
							itemsAction: "include",
							itemsAction: [],
							items: [],
							itemInfo: [],
							active: false,
						},
						filterTreatments: {
							type: "specific",
							itemsAction: "include",
							items: [],
							itemInfo: [],
							active: false,
						},
					};

           // Dispatch system change
        await this.$store.dispatch('trackSystemChange', {
          type: 'modifier_update',
						modifiedBy: this.$store.state.userId,
						routeState: this.$route.path,
					});

				} catch (error) {
					console.error("Error modifier document save: ", error);
					logData.extra.push({ name: "error", value: JSON.stringify(error) });
					await this.$createNewLog("error", logData);
				}
			} else {
				if (!quantityRulesValid) {
					this.feedback = "Niet alle kortingsregels van de Bundelkorting zijn ingevoerd.";
				} else if (!buyXYValid) {
					this.feedback = "Vul alle velden voor dit kortingstype in om de korting toe te voegen.";
				} else {
					this.feedback = "Vul naam en kortingtype in om de korting toe te voegen.";
				}
			}
		},

		/**
	
		 * Product Search Implementation Algolia
		 *
		 * Here's how it works:
		 *
		 * 1. Search Flow:
		 * - User types in search box (minimum 2 characters)
		 * - Triggers searchAlgoliaProducts()
		 * - Queries Algolia index with filters
		 * - Updates UI with results
		 *
		 * 2. Key Components:
		 * - Algolia client initialized in created() hook
		 * - Search index: 'thrive_products'
		 * - Facet filters for company, active status and branch
		 *
		 * 3. Data Structure:
		 * editModifier.filterProducts = {
		 *   items: ['productId1', 'productId2'], // Product IDs for vuetify chips
		 *   itemInfo: [                          // Product details
		 *     { id: 'productId1', name: 'Product 1' },
		 *     { id: 'productId2', name: 'Product 2' }
		 *   ]
		 * }
		 * @param {string} query - Search query string
		 */
		async searchAlgoliaProducts(query) {
			if (!query || query.length < 2) {
				this.algoliaProducts = [];
				return;
			}

			this.isSearching = true;
			try {
				let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
				let ActiveFilterString = "active:true";
				let ActiveFilterBranch = `branches:${this.$store.state.activeBranch.id}`;

				const { hits } = await this.algoliaIndex.search(query, {
					userToken: this.$store.state.activeCompany.id,
					facetFilters: [FacetFilterString, ActiveFilterString, ActiveFilterBranch],
					hitsPerPage: 10,
				});

				// map the hits to the algoliaProducts array
				this.algoliaProducts = hits.map((hit) => ({
					id: hit.objectID,
					name: hit.name,
					content: hit.content,
				}));
			} catch (error) {
				console.error("Algolia search error:", error);
				this.algoliaProducts = [];
			} finally {
				this.isSearching = false;
			}
		},

		addProduct(item) {
			if (!item) return;

			// Check if product is not already in the list
			const exists = this.editModifier.filterProducts.items.includes(item.id);
			if (!exists) {
				this.editModifier.filterProducts.items.push(item.id);

				// first check if itemInfo exists
				if (!this.editModifier.filterProducts.itemInfo) {
					this.editModifier.filterProducts.itemInfo = [];
				}

				// add the item to the itemInfo array
				this.editModifier.filterProducts.itemInfo.push({
					id: item.id,
					name: item.name,
				});
				this.checkModifiers();
			}

			// Reset selection and search
			this.selectedProduct = null;
			this.searchProducts = null;
			this.algoliaProducts = [];
		},

		/**
		 * Removes a product from both items and itemInfo arrays
		 * Handles backwards compatibility for modifiers without itemInfo
		 * @param {string} itemId - The ID of the product to remove
		 */
		removeProduct(itemId) {
			// Check if filterProducts exists
			if (!this.editModifier.filterProducts) {
				console.warn("filterProducts property not found on modifier");
				return;
			}

			// Remove from items array
			if (Array.isArray(this.editModifier.filterProducts.items)) {
				const itemIndex = this.editModifier.filterProducts.items.indexOf(itemId);
				if (itemIndex >= 0) {
					this.editModifier.filterProducts.items.splice(itemIndex, 1);
				}
			}

			// Remove from itemInfo array if it exists
			if (Array.isArray(this.editModifier.filterProducts.itemInfo)) {
				const itemInfoIndex = this.editModifier.filterProducts.itemInfo.findIndex((info) => info.id === itemId);
				if (itemInfoIndex >= 0) {
					this.editModifier.filterProducts.itemInfo.splice(itemInfoIndex, 1);
				}
			}

			this.checkModifiers();
		},

		/**
		 * Gets the product name from itemInfo array or returns 'Onbekend product'
		 * Handles backwards compatibility for modifiers without itemInfo
		 * @param {string} itemId - The ID of the product to find
		 * @returns {string} The product name or 'Onbekend product' if not found
		 */
		getProductName(itemId) {
			// Check if filterProducts and itemInfo exist before searching
			const itemInfo = this.editModifier.filterProducts?.itemInfo?.find((info) => info.id === itemId);
			return itemInfo ? itemInfo.name : "Onbekend product";
		},

		/**
		 * Handles filter changes and checks for modifier overlaps
		 * @param {String} type - Type of filter changed ('products' or 'treatments')
		 */
		handleFilterChange(type) {
			// Debounce to prevent excessive calls
			if (this.filterChangeTimeout) {
				clearTimeout(this.filterChangeTimeout);
			}
			
			this.filterChangeTimeout = setTimeout(() => {
				this.checkModifiers();
				this.$forceUpdate(); // Ensure UI updates with new feedback
			}, 300); // 300ms debounce
		},

		// Add to existing methods
		getTreatmentName(treatment) {
			return autoLanguageSelector(
				this.$store.state.nativeLanguage, 
				this.$store.state.companyLanguages, 
				treatment.treatmentTitleIntern
			)
		},
	},
	computed: {
		filteredProducts() {
			if (this.searchProducts && this.searchProducts.length > 0) {
				return this.products.filter((product) => {
					return product.name && product.name.toLowerCase().includes(this.searchProducts.toLowerCase());
				});
			} else {
				return this.products;
			}
		},

		filteredTreatments() {
			if (this.searchTreatments && this.searchTreatments.length > 0) {
				return this.treatments.map(treatment => ({
					...treatment,
					displayName: this.getTreatmentName(treatment)
				})).filter((treatment) => {
					const treatmentName = this.getTreatmentName(treatment).toLowerCase();
					return treatmentName.includes(this.searchTreatments.toLowerCase());
				});
			} else {
				return this.treatments.map(treatment => ({
					...treatment,
					displayName: this.getTreatmentName(treatment)
				}));
			}
		},

		changed() {
			return _.isEqual(this.editModifier, this.editModifierOriginal) == false ? true : false;
		},

		modifierStartdate() {
			let date = {
				readable: "",
				formatted: null,
			};

			if (this.editModifier.dateLimit.startDate) {
				date.readable = moment(this.editModifier.dateLimit.startDate).format("D MMMM YYYY");
				date.formatted = moment(this.editModifier.dateLimit.startDate).format("YYYY-MM-DDTHH:mm");
			}
			return date;
		},
		modifierEnddate() {
			let date = {
				readable: "",
				formatted: null,
			};

			if (this.editModifier.dateLimit.endDate) {
				date.readable = moment(this.editModifier.dateLimit.endDate).format("D MMMM YYYY");
				date.formatted = moment(this.editModifier.dateLimit.endDate).format("YYYY-MM-DDTHH:mm");
			}
			return date;
		},

		discountInfo() {
			if (this.editModifier.typeDiscount.type == "fixedAmount" && this.editModifier.typeDiscount.type == "percentage") {
				return {
					prefix: null,
					suffix: "%",
					label: "Kortingspercentage",
				};
			} else if (this.editModifier.typeDiscount.type == "fixedAmount" && this.editModifier.typeDiscount.discountType == "amount") {
				return {
					prefix: "€",
					suffix: null,
					label: "Kortingsbedrag",
				};
			} else if (this.editModifier.typeDiscount.type == "buyXX" || this.editModifier.typeDiscount.type == "buyXY") {
				if (this.editModifier.typeDiscount.discountType == "percentage") {
					return {
						prefix: null,
						suffix: "%",
						label: "Kortingspercentage",
					};
				} else if (this.editModifier.typeDiscount.discountType == "amount") {
					return {
						prefix: "€",
						suffix: null,
						label: "Kortingsbedrag",
					};
				}
			} else if (this.editModifier.typeDiscount.type == "quantityDiscount") {
				if (this.editModifier.typeDiscount.discountType == "percentage") {
					return {
						prefix: null,
						suffix: "%",
						label: "Kortingspercentage",
					};
				} else if (this.editModifier.typeDiscount.discountType == "amount") {
					return {
						prefix: "€",
						suffix: null,
						label: "Kortingsbedrag",
					};
				}
			} else {
				return {
					prefix: null,
					suffix: null,
					label: "-",
				};
			}
		},

		/**
		 * Automatically sets uniqueItems based on discount type
		 * Sets to true for buyXY type, false otherwise
		 */
		uniqueItemsRequired: {
			get() {
				return this.editModifier.typeDiscount.type === 'buyXY';
			},
			set(value) {
				// This setter is required for Vue reactivity but we don't need it
				// since the value is determined by the discount type
			}
		}
	},

	watch: {
		// Add watcher to update uniqueItems when discount type changes
		'editModifier.typeDiscount.type': {
			handler(newType) {
				this.editModifier.typeDiscount.uniqueItems = newType === 'buyXY';
			},
			immediate: true
		}
	}
};
</script>

<style>
.discount-fields-wrapper {
	width: 100%;
}

.discount-fields-wrapper:after {
	display: block;
	clear: both;
	content: "";
}

.discount-fields-input {
	width: calc(100% - 40px);
	float: left;
}

.discount-fields-remove {
	width: 40px;
	float: left;
}
</style>
