<template>
  <div>
    <div v-if="indicator">    
      <v-list class="ma-0 pa-0 no-hover-effect" flat inactive >
        <v-list-item class="pa-0 ma-0 pb-3">
          <v-list-item-content  class="pa-0 ma-0">
            <v-list-item-subtitle>
              <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, $store.state.userLanguage) }}</h4>
            </v-list-item-subtitle>
            <div v-if="indicator.w == 8 || (indicator.w == 4  && indicator.graphType == 'number')">
              <v-list-item-title class="indicator-heading" v-if="indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, $store.state.userLanguage) }}</v-list-item-title>
              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length == 1"> {{ getTreatmentName(indicator.treatments[0]) }} </v-list-item-title>
              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length == 1">  {{ getProductName(indicator.products[0]) }}</v-list-item-title>

              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length > 1">{{ $t(`indicator.selectedTreatmentsFilter`, $store.state.userLanguage) }}</v-list-item-title>
              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length > 1">{{ $t(`indicator.selectedProductsFilter`, $store.state.userLanguage) }}</v-list-item-title>

              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`general.category`, $store.state.userLanguage) }}: {{ getTreatmentTypeName(indicator.treatmentTypes[0]) }}</v-list-item-title>
              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`general.category`, $store.state.userLanguage) }}: {{ getProductTypeName(indicator.productTypes[0]) }}</v-list-item-title>

              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 1">{{ $t(`indicator.selectedTreatmentCategories`, $store.state.userLanguage) }}</v-list-item-title>
              <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 1">{{ $t(`indicator.selectedProductCategories`, $store.state.userLanguage) }}</v-list-item-title>
            </div>
            <div v-else>  
              <v-list-item-title class="indicator-heading" v-if="indicator.dataType == 'revenue'">  
                {{ formatReportNumber(indicator.revenue, 0) }} 
              </v-list-item-title>
              <v-list-item-title class="indicator-heading" v-else>  
                {{ indicator.number }} 
              </v-list-item-title>
            </div>
          </v-list-item-content>
        
          <v-list-item-action class="mt-0 ml-0"  style="z-index: 999">
            <v-menu
              v-if="$store.state.activeUserRoleNumber < 2" 
              transition="slide-y-transition"
              v-model="indicator.menu"
              :close-on-content-click="true"
              offset-y
              open-on-hover
              :content-class="`theme-shadow ${$store.state.companyTheme}`"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
              >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-card max-width="200px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                <v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                  <v-list-item class="ma-0 px-2" @click="editIndicator(indicator)">
                    <v-list-item-icon class="mr-2">
                      <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Bewerken</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item class="ma-0 px-2" >
                    <v-list-item-icon class="mr-2">
                      <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Verwijderen</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div v-if="indicator.w == 4">
        <v-card-text  class="pa-0 ma-0">
          <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-if="indicator.graphType == 'number'" >
            <v-list-item class="pa-0 pl-0 ma-0">
              <v-list-item-avatar
                size="56"
                light
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                >
                <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                <v-icon v-else color="#fff">mdi-label</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="indicator.dataType == 'revenue'">
                  <h2 class="indicator-number">{{ formatReportNumber(indicator.revenue, 0) }} </h2>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <h2 class="indicator-number">{{ indicator.number }} </h2>
                </v-list-item-title>
                <v-list-item-subtitle v-if="indicator.graphType == 'number' && (indicator.treatmentTypes.length == 1 || indicator.productTypes.length == 1)">
                  <v-chip v-if="indicator.treatmentTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentTypeName(indicator.treatmentTypes[0]) }} </v-chip>
                  <v-chip v-if="indicator.productTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getProductTypeName(indicator.productTypes[0]) }} </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-3">
              <apexchart width="100%" height="50" :type="indicator.graphType" :options="barometerChartOptions[indicator.color]"  :series="indicator.chartData"></apexchart>
            </div>

            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin:-10px 0 0 0">
              <apexchart width="100%" height="240" type="radialBar" :options="barometerChartOptions[indicator.color]" :series="indicator.goalRadial"></apexchart>
            </div>
          </div>

          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, $store.state.userLanguage) }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length  > 1">{{ $t(`indicator.datafilter.treatments`, $store.state.userLanguage) }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length  > 1">{{ $t(`general.products`, $store.state.userLanguage) }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`indicator.datafilter.treatmentCategory`, $store.state.userLanguage) }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`indicator.datafilter.productCategory`, $store.state.userLanguage)  }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 1">{{ $t(`indicator.datafilter.treatmentCategories`, $store.state.userLanguage) }}</h5>
          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 1">{{ $t(`indicator.datafilter.productCategories`, $store.state.userLanguage) }}</h5>

          <div class="indictor-items pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 1) || (indicator.graphType == 'number' && indicator.products.length > 1) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'">
            <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
            <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
            <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
            <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
          </div>

          <div v-if="indicator.graphType == 'number'">
            <div v-if="indicator.useGoal" class="px-1">
              <strong>{{ indicator.goal }}%</strong>
              <v-progress-linear :value="indicator.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
            </div>
          </div>

        </v-card-text>
      </div>

      <div v-if="indicator.w == 8">
        <v-layout row wrap>
          <v-flex :class="[{'xs12' :indicator.graphType == 'number' },{'xs6' :indicator.graphType != 'number' }]">
            <v-list class="ma-0 pa-0 pt-0 clear" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :class="{'pt-2': indicator.graphType != 'number'}">
              <v-list-item class="pa-0 pl-0 ma-0">
                <v-list-item-avatar
                  v-if="indicator.graphType == 'number'"
                  size="56"
                  light
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                  >
                  <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                  <v-icon v-else color="#fff">mdi-label</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="pa-0 ma-0">
                  <!-- <v-list-item-subtitle>
                    <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, $store.state.userLanguage) }}</h4>
                  </v-list-item-subtitle> -->
                  <v-list-item-title v-if="indicator.dataType == 'revenue'">
                    <h2 class="indicator-number" :class="'size-'+ indicator.w">{{ formatReportNumber(indicator.revenue, 0) }} </h2>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <h2 class="indicator-number" :class="'size-'+ indicator.w">{{ indicator.number }} </h2>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            
          </v-flex> 
          <v-flex xs6 v-if="indicator.graphType != 'number'">
            <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
              <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-1">
                <apexchart width="100%" height="70" :type="indicator.graphType" :options="barometerChartOptions[indicator.color]"  :series="indicator.chartData"></apexchart>
              </div>

              <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin: -45px 0 0 0">
                <apexchart width="100%" height="270" type="radialBar" :options="barometerChartOptions[indicator.color]" :series="indicator.goalRadial"></apexchart>
              </div>
            </div>

          </v-flex>

          <v-flex xs12 class="indictor-items pt-0 pb-2" v-if="(indicator.treatments.length > 1 || indicator.products.length > 1 || indicator.treatmentTypes.length > 1 || indicator.productTypes.length > 1) && (indicator.graphType == 'number' || indicator.graphType == 'bar' ||  indicator.graphType == 'line' )">
            <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
            <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
            <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
            <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
          </v-flex>
          <v-flex xs12 v-if="indicator.graphType == 'number' && indicator.useGoal" class="pt-0">
            <div v-if="indicator.useGoal" class="px-1">
              <strong>{{ indicator.goal }}%</strong>
              <v-progress-linear :value="indicator.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
            </div>
          </v-flex>
        </v-layout>
      </div>

    </div>
  </div>
</template>

<script>
  import { bus } from "../../main";
  export default {
    props: {
      indicator: Object,
      type: String,
      treatments: Array,
      products: Array,
    },
    data() {
      return {
        barometerChartOptions: new Object(),
      }   
    },
    created(){   
      // Set Default Chart options
      let chartOptions = {   
        title: {
          text: ""
        },
        chart: {
          width: '100%',
          offsetX: 0,
          offsetY: 0,
          sparkline: {
              enabled: true,
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          
        },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: -90,
            endAngle: 90,
            offsetX: 0,
            offsetY: -40,      
            hollow: {
              margin: 0,
            },      
            dataLabels: {

              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '18px'
              }
            },
            
          },
          
        },
        stroke: { curve: 'smooth', lineCap: 'round'},
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: true,
          strokeDashArray: 6,
          position: 'back',
          xaxis: {
            lines: {
                show: false
            }
          },   
          xaxis: {
            lines: {
                show: true
            }
          }, 
          yaxis: {
            lines: {
                show: false
            }
          },
          markers: { size: 1},
          padding: {
            top: 7,
            right: 0,
            bottom: 5,
            left: 0
          },  
        },
        colors: new Array(),   
        xaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
      };
      this.barometerChartOptions = new Object();
      this.barometerChartOptions.primary = JSON.parse(JSON.stringify(chartOptions));
      this.barometerChartOptions.secondary = JSON.parse(JSON.stringify(chartOptions));
      this.barometerChartOptions.tertiary = JSON.parse(JSON.stringify(chartOptions));
      this.barometerChartOptions.quaternary = JSON.parse(JSON.stringify(chartOptions));
      this.barometerChartOptions.primary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary);
      this.barometerChartOptions.secondary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary);
      this.barometerChartOptions.tertiary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].tertiary);
      this.barometerChartOptions.quaternary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary);
    },

    methods:{

      /**
       * Format number with desired number of digits and currency symbol
       * @param { Number } number - Number to convert
       * @param { Number } digits  - Number of digits to use
       */
      formatReportNumber(number, digits){
        if(number > 0.004 || number < -0.004){
          return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: digits == 0 ? 0 : 2, maximumFractionDigits: digits == 0 ? 0 : 2}).format(Number(number));
        }
        else{
          return "€ 0"
        } 
      },

      /**
       * Open Edit Dialog for indicator
       * @param { Object } indicator - All Indicator Data
       */
      editIndicator(indicator){ 
        indicator.menu = false;
        bus.$emit("editIndicator", indicator);
      },

      /**
       * Get the name of the treatment based on the Treatment ID
       * @param {String} id - Treatment ID
       */
      getTreatmentName(id){
        let name = null;
        if(id){       
          let data = this.treatments.find(treatment => {return treatment.id == id })
          if(data != undefined){
            name = this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, data.treatmentTitle);            
          }
        }

        return name;
      },

      /**
       * Get the name of the product based on the product ID
       * @param {String} id - Product ID
       */
      getProductName(id){
        let name = null;
        if(id){   
          let data = this.products.find(product => {return product.id == id })
          if(data != undefined){
            name = data.name;            
          }          
        }
        return name;
      },

      /**
       * Get the name of the treatmentType based on the treatmentType ID
       * @param {String} id - TreatmentType ID
       */
      getTreatmentTypeName(id){
        let name = null;
        if(id){   
          let data = this.$store.state.treatmentCategories.find(treatmentType => {return treatmentType.id == id })
          if(data != undefined){
            name = this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, data.name);            
          }
        }
        return name;
      },

      /**
       * Get the name of the productType based on the productType ID
       * @param {String} id - ProductType ID
       */
      getProductTypeName(id){
        let name = null;
        if(id){   
          let data = this.$store.state.productCategories.find(productType => {return productType.id == id })
          if(data != undefined){
            name = this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, data.name);            
          }
        }
        return name;
      },

    },
  }     
</script>

<style>
  .clear:after{
    display: block;
    content: "";
    clear: both;
  }
  .indicator-heading{
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  .indicator-heading.size-8{
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h4.indicator-subheading{
    font-size: 14px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h5.indicator-subheading{
    font-size: 12px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h2.indicator-number{
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h2.indicator-number.size-8{
    font-weight: 700;
    font-size: 38px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  .indicator-heading.smooth,
  h3.indicator-heading.smooth,
  h4.indicator-subheading.smooth,
  h5.indicator-subheading.smooth{
    color: var(--textSmooth-color)
  }

  .no-hover-effect .v-list-item:hover{
    background: none;
  }
</style>
