/**

 ██████╗ ██╗   ██╗██╗ ██████╗██╗  ██╗    
██╔═══██╗██║   ██║██║██╔════╝██║ ██╔╝    
██║   ██║██║   ██║██║██║     █████╔╝     
██║▄▄ ██║██║   ██║██║██║     ██╔═██╗     
╚██████╔╝╚██████╔╝██║╚██████╗██║  ██╗    
 ╚══▀▀═╝  ╚═════╝ ╚═╝ ╚═════╝╚═╝  ╚═╝    
                                         
██╗    ██╗██╗███╗   ██╗███████╗          
██║    ██║██║████╗  ██║██╔════╝          
██║ █╗ ██║██║██╔██╗ ██║███████╗          
██║███╗██║██║██║╚██╗██║╚════██║          
╚███╔███╔╝██║██║ ╚████║███████║          
 ╚══╝╚══╝ ╚═╝╚═╝  ╚═══╝╚══════╝          
                                         
Quick wins for the project by Sven Bakker & Joe van Ettekoven                                                                    

*/

//DEEP COPY RECURSIVE
export function deepCopy(inputObject) {
  let outputObject, value, key;

  if (typeof inputObject !== "object" || inputObject === null) {
    return inputObject; // Return the value if inputObject is not an object
  }

  // Create an array or object to hold the values with ternary operator
  outputObject = Array.isArray(inputObject) ? [] : {};

  for (key in inputObject) {
    value = inputObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outputObject[key] = deepCopy(value);
  }

  return outputObject;
}

//Find all Vue Observers in an object
export function findObservers(object, path = '') {
  let observerPaths = [];

  // Controleer of het huidige object een Vue Observer is
  if (object && object.__ob__) {
    observerPaths.push(path);
  }

  // Recursief doorlopen van objecten en arrays
  if (object !== null && typeof object === 'object') {
    Object.keys(object).forEach(key => {
      const fullPath = path ? `${path}.${key}` : key;
      const value = object[key];
      if (typeof value === 'object' && value !== null) {
      observerPaths = observerPaths.concat(findObservers(value, fullPath));
      }
    });
  }

  return observerPaths;
  }

  /**
 * Raw value formate like moneyInput bindgin functions but for single variables
 * This function sets up an input event listener that formats the  value
 * as a currency value, ensuring it always has at least three digits and separates

 * @param {integer} value - the value to calculate

 */

export function stringToNumber(value) {
  if(typeof value !== 'number'){
    value = Number(value.replace(/,/g, '.'))
  }

  return cashConv(value, "EUR", 2) 
}
  
/**
 * CALCULATE TIP WITH FLOATING POINTS:
 * (-0.2 * 10 - 0.1 * 10) / 10
 * (100 * 10 - 99.1 * 10) / 10
 */


/**
 * Convert formatted String back to number to calculate with

 * @param {integer} value - the value to calculate

 */

export function moneyInputRaw(value) {

  if(value){
    if(typeof value === 'number'){
      value = String(value).replace(".",",");
    }

    // Check of value cijfers achter de comma heeft

    let valueHasDecials = String(value).includes(",");

    if(!valueHasDecials){
      value = value + ",00"
    }
    else{
      let splittedValue = value.split(",");
      if(splittedValue[1].length == 1){
        value = value + "0"
      }
      else if(splittedValue[1].length == 0){
        value = value + "00"
      }
    }

    value = String(value).replace(/[^0-9]/g, ''); // Verwijder alle niet-cijfer tekens
    
    if (value.length <= 2) {
        value = value.padStart(3, '0'); // Dit zorgt minimaal voor drie cijfers
    }

    let integer = value.slice(0, -2); // Neem alle cijfers behalve de laatste twee
    let decimal = value.slice(-2); // Neem de laatste twee cijfers als decimaal
    
    integer = integer.replace(/^0+/, '') || '0'; // Belangrijk !! Verwijder voorloopnullen, behalve als de hele integer uit nullen bestaat
  
    // console.log("integer 2: ", integer)
  
    //console.log("return: ",`${integer},${decimal}`)
  
    return `${integer},${decimal}`; // Formatteer de waarde met een komma
  }
  else{
    return "0,00";
  }
}

/**
 * Convert output safely to a Number without Floating points
 * ALL ABOUNT FLOATING POINTS: https://floating-point-gui.de/
 * 
 * Convert output safely to a Number without Floating points

 * @param {integer} calc - the formula to calculate
 * @param {string} currency - EUR 
 * @param {integer} digits - for decimal calculation
 * @param {string} fieldName - for logging
 * Currency object -> currency: new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 8,
      }),
 * 
 */
export function cashConv(calc, currency, digits, fieldName) {
  
  if(calc && calc != NaN){

    //Log the field name
    if(fieldName) {
      // console.log("fieldName CashConv", fieldName);
    }

    const currencyOBJ = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: digits,
    });

    const cash = new Array();

    //Generate Digits
    let byDigits = 1 + Array(digits).fill(0).reduce((previousValue,currentValue) => previousValue + "" + currentValue) //?
    currencyOBJ.formatToParts(calc).forEach(({ type, value }) => {
      
      if (type == "minusSign") cash.push(value)
      //When is negative
      if (type == "integer" || type == "fraction") cash.push(value);
    
    });
  
    //Reduce the parts as a string to a number safely.
    return cash.reduce((previousValue, currentValue) => previousValue + currentValue) / byDigits
  }
  else{
    return 0;
  }
}

/**
 * Convert output safely to a Number without Floating points
 * TODO Multi Currency Support
 * @param {string} currency - EUR, USD, etc
 * @param {integer} digits - for decimal calculation
 */
export function formatToCurrency(locale, currency, digits) {
 
  if(!locale || !currency || !digits ) {
    return "--"
  }

  const currencyFormat = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });


  return currencyFormat.format(digits);
}



/**
 * This function formats the input to a clean number
 * From 1.111,11 to 1111.11 56,24 -> 56.24 and can then be used in calculations with cashConv
 * @param {string} inputValue 
 */
export function formatToCleanNumber(inputValue) {

  console.log("inputValue", inputValue);



			const locale = 'nl-NL';

			const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
			const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

			let cleanNumber = parseFloat(inputValue
					.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
					.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
			);

    
    console.log("Clean", cleanNumber)
		
    return cleanNumber;


}