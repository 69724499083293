const { cashConv } = require('./quickWins')
const uniqid = require('uniqid')
const moment = require('moment')

// cashConv(4.1245678, "EUR", 2); //?

/**
 * DELETED
 * deleted Modifiers are neccesracy for duplicates
 */
let deletedModifiers = new Array()

/**
 * ADVISED
 * Advised modifiers, for reviewing
 */
let advisedModifiers = new Array()

/**
 * usedModifiers
 * Al added modifers will be added, but will be calculated if calcApproved = true;
 * note: calcApproved must be true for calculation.
 */
// let usedModifiers = new Array();

let errorsArray = new Array()

let selectedItems = new Array()

let modifiers = new Array()

let clientDataObj = new Object()

/**
 * USED
 * Background Modifiers are neccesracy for duplicates
 */
let usedModifiers = new Array()
let sortDiscountOrder = new Array()

let internalUseItems = new Array()

//Calculation percentages always converted to EUR

//FOR TEST PURPOSE

// import testData from './POStestData.js';

// selectedItems = testData.selectedItems;
// usedModifiers = testData.usedModifiers;
// modifiers = testData.modifiers;
// let modifier = {};

// testData.usedModifiers //?

// console.log(usedModifiers)
// usedModifiers = testData.usedModifiers;
// modifiers = testData.modifiers;

// Auto Pupose
// scanDiscounts(selectedItems, true, modifier, modifiers, usedModifiers, deletedModifiers, true); //?

//END FOR TEST PURPOSE

export function quickValidate(items, modifier, alreadyUsed, clientData) {
  clientDataObj = clientData
  selectedItems = items
  usedModifiers = alreadyUsed
  return validationOptions(modifier)
  function validationOptions(modifier) {
    //Block equal discount duplicate blocking
    const discountOnceCheck = discountOnce(modifier.id, modifier.name)
    if (discountOnceCheck !== true) {
      return true
    }

    // console.log("Quick Validate", modifier, modifier.numberLimit, modifier.name, modifier.id)

    const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id)
    if (used !== true) {
      return true
    }

    const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name)
    // console.log("Combination Limit:", combinationCheck);
    if (combinationCheck.fail == true) {
      return true
    }

    //Filter Products or Treatments
    if (!modifier.custom) {
      const filteredItems = filterSys(modifier)
      // console.log("modifierItems:", modifier);
      // console.log("filteredItems:", filteredItems);
      if (filteredItems.fail == true) {
        return true
      }
    }

    return false
  }
}

//Manual Purpose
// scanDiscounts(selectedItems, true, modifiers[0], modifiers, usedModifiers, deletedModifiers, true); //?
// scanDiscounts(selectedItems, false, modifiers, modifiers, usedModifiers, deletedModifiers, true); //?

/**
 *
 * @param {array} items - selectedItems
 * @param {boolean} manual - true or false for adding manual discount.
 * @param {object} modifier - object of the modifier
 * @param {array} items - selectedItems
 * @param {map} alreadyUsed - map of already used items
 * @param {map} deleted - map of already deleted items
 * @param {boolean} debug - true or false
 * @param {boolean} sortDiscountOrder = Array of objects used for sorting Discounts by Weight
 */
export function scanDiscounts(
  items,
  manual,
  modifier,
  allModifiers,
  alreadyUsed,
  deleted,
  debug,
  clientData,
  discountByOrder
) {
  /**
   * The data for scanDiscounts function is always fresh to recalaucate the new values.
   * We use the JSON.parse(JSON.stringify()) to make a deep copy of the object without references
   * or observers from Vue.
   */
  let toCalculate = new Array()
  clientDataObj = JSON.parse(JSON.stringify(clientData))
  selectedItems = JSON.parse(JSON.stringify(items)) //?
  modifiers = JSON.parse(JSON.stringify(modifier)) //?
  usedModifiers = JSON.parse(JSON.stringify(alreadyUsed)) //?
  deletedModifiers = JSON.parse(JSON.stringify(deleted))
  sortDiscountOrder = JSON.parse(JSON.stringify(discountByOrder))
  allModifiers = JSON.parse(JSON.stringify(allModifiers))
  internalUseItems = new Array()
  advisedModifiers = new Array() //<-- always new advise
  errorsArray = new Array()

  console.log('usedModifiers', usedModifiers)

  //Generate INDEX and create uniqid when needed

  // console.log("POS selectedItems", selectedItems);
  selectedItems.map((item, index) => {
    let obj = item
    obj.index = index

    //Generate customDiscount because there is no modifiedId, so it must be a customDiscount
    if (!item.modifiedId) obj.modifiedId = 'customDiscount'
    obj.usedModifierId = new Set() //For unique Modifiers
    obj.usedMembershipId = new Set() //For unique Modifiers

    return obj
  })

  //filter out selectedItems with interalUse === true
  selectedItems = selectedItems.filter((item) => item.internalUse !== true)

  //Save the internalUse items to a new array for later use
  internalUseItems = items.filter((item) => item.internalUse === true)
  internalUseItems.map((item, index) => {
    let obj = item
    obj.usedModifierId = new Array() //Not needed for Internal Use
    obj.usedMembershipId = new Array() //Not needed for Internal Use
    return obj
  })

  if (manual === true) {
    console.log('LEGACY ROUTE')
    //manual add and Recalculate Already UsedModifiers

    /**
     * MANUAL
     */

    // let manualModifier = modifiers;
    // let usedRecalc = new Object();
    // let deleted = new Object();

    // //Calculate the rest of already used modifiers
    // allModifiers.forEach((modi) => {
    // 	//Recalculate if Already Used
    // 	usedRecalc = usedModifiers.find((usedModi) => usedModi.id == modi.id);
    // 	deleted = deletedModifiers.find((deletedModi) => deletedModi.id == modi.id);
    // 	if (!usedRecalc && !deleted) {
    // 		// console.log("usedRecalc !usedRecalc && !deleted", usedRecalc)
    // 		// //Remove by filtering out from usedModifiers and calculate Again
    // 		// usedModifiers = usedModifiers.filter((item) => item.id !== usedRecalc.id);
    // 		// manualModifier.forEach((manualModifier) => toCalculate.push(manualModifier))
    // 		// toCalculate.push(usedRecalc);
    // 	} else if (!usedRecalc && deleted) {
    // 		//Do nothing
    // 	} else {
    // 		//Remove by filtering out from usedModifiers and calculate Again
    // 		usedModifiers = usedModifiers.filter((item) => item.id !== usedRecalc.id);
    // 		toCalculate.push(usedRecalc);
    // 	}
    // });

    // if (toCalculate.length !== 0) {
    // 	toCalculate.forEach((calculateModifier) => {
    // 		typeOfDiscountSwitch(calculateModifier, manual, debug);
    // 	});
    // } else {
    // 	// if (debug) errorsArray.push({ fail: true, reason: "Nothing Manual to calculate", code: 310 });
    // }
  } else {
    /**
     * AUTO
     * only calculate auto or usedModifiers (if needed)
     */
    let countDiscounts = 0
    allModifiers //?
    usedModifiers //?
    allModifiers.forEach((modi) => {
      modi //?
      // console.log("Modifiers console:", modi);
      let used = usedModifiers.find((usedModi) => usedModi.id == modi.id) //?
      used //?
      if (modi.automatic) {
        //automatic and used
        if (used) {
          //Remove by filtering out from usedModifiers and calculate Again
          usedModifiers = usedModifiers.filter((item) => item.id !== used.id)
          countDiscounts++

          toCalculate.push(modi)
        } else {
          //Not used so the first time the discount is added
          countDiscounts++
          modi.addedVia = 'automaticDiscount'
          toCalculate.push(modi)
        }
      }
      //Not automatic but used
      if (!modi.automatic) {
        if (used) {
          //Special by Membership

          //Remove by filtering out from usedModifiers and calculate Again
          usedModifiers = usedModifiers.filter((item) => item.id !== used.id)
          countDiscounts++
          toCalculate.push(modi)
        }
      }
    })

    if (toCalculate.length !== 0) {
      // console.log("toCalculate", toCalculate);

      /* 
        Sort modifiers "toCalculate" by priority
        1. Get all the modifiers that have a priority defined.
        2. Sort them by priority.
        3. Get all the modifiers that don't have a priority defined.
        4. Add them to the end of the array. */

      let toCalculateUndefined = toCalculate.filter(
        (toCalculateModifier) => typeof toCalculateModifier.priority == 'undefined'
      )
      let toCalculatePrioritySorted = toCalculate
        .filter((toCalculateModifier) => typeof toCalculateModifier.priority != 'undefined')
        .sort((a, b) => a.priority - b.priority) //?
      toCalculate = [...toCalculatePrioritySorted, ...toCalculateUndefined] //?

      //Calculate the sorted Modifiers that will be calculated. We call this toCalculate.
      toCalculate.forEach((calculateModifier) => {
        typeOfDiscountSwitch(calculateModifier, manual, debug)
      })
    } else {
      // if (debug) errorsArray.push({ fail: true, reason: "Nothing Manual to calculate", code: 310 });
    }

    //Count Discount and check size if already in use
    if (usedModifiers.size > 0 && countDiscounts == 0)
      errorsArray.push({ fail: true, reason: 'Discount(s) already in use, and or no discount(s) left...', code: 307 })
  }

  /**
   * This function start its proces to validate a modifier and close (when is validated)
   * with usedModfiers
   *
   * @param {object} modi - as modifier
   * @param {boolean} manual
   * @param {boolean} debug
   */

  //Manipulate value from set to array Firebase doesnt read Sets
  for (let index = 0; index < selectedItems.length; index++) {
    selectedItems[index].usedModifierId = [...selectedItems[index].usedModifierId]
    selectedItems[index].usedMembershipId = [...selectedItems[index].usedMembershipId]
  }

  //Everything calculate done? Return / give back the new values.

  //Are there internalUse items? Add the internalUse items to the selectedItems in the right index order
  if (internalUseItems.length > 0) {
    //Sort internalUseItems by index
    internalUseItems.forEach((item) => {
      //add the internalUse items to the selectedItems in the right index order
      selectedItems.splice(item.index, 0, item) // add item to selectedItems at index
    })
  }

  //remove modified field from selectedItems
  // selectedItems.forEach((item) => {
  // 	delete item.modified;
  // });

  return {
    selectedItems: selectedItems,
    usedModifiers: usedModifiers,
    deletedModifiers: deletedModifiers,
    advise: advisedModifiers,
    error: errorsArray,
  }
}

function typeOfDiscountSwitch(modi, manual, debug) {
  console.log('MODI', modi)
  modi.typeDiscount //?
  modi //?
  switch (modi.typeDiscount.type) {
    case 'fixedAmount':
      return discountFixedAmount(modi, manual, debug)

      break

    case 'buyXX':
      buyXX(modi, manual, debug) //?

      break

    case 'buyXY':
      buyXY(modi, manual, debug) //?

      break

    case 'quantityDiscount':
      quantityDiscount(modi, manual, debug)

      break

    // LEGACY
    // case "membership":
    // 	console.log("membership", modi);
    // 	membership(modi, manual, debug);

    // 	break;

    default:
      break
  }
}

/**
 * func: discountFixedAmount
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function discountFixedAmount(modifier, manual, debug) {
  // console.log(modifier, modifier.addedVia);

  //Already Deleted
  const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia)
  if (deletedCheck !== true) {
    if (debug) errorsArray.push(deletedCheck)
    // console.log(deletedCheck.reason);
    return false
  }

  //Block equal discount duplicate blocking
  const discountOnceCheck = discountOnce(modifier.id, modifier.name)
  if (discountOnceCheck !== true) {
    if (debug) errorsArray.push(discountOnceCheck)
    // console.log(discountOnceCheck);
    return false
  }

  const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id)

  if (used !== true) {
    if (debug) errorsArray.push(used)
    // console.log(used.reason);
    return false
  }

  const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name)
  if (combinationCheck.fail == true) {
    if (debug) errorsArray.push(combinationCheck)
    // console.log(combinationCheck.reason);
    return false
  }

  //TODO BEREKENEN MET CUSTOM KORTING, WANNEER NODIG
  /**
   * Filter FixedAmount
   * Could be a custom Filter by ModifiedId OR a fixedAmount modifier preset.
   * This depends if modifier.typeDiscount.custom true
   */
  let filteredItems = null
  let getItems = new Array()

  /**
   * Ensures each item has a unique cartItemId extra compatibility
   * @param {Array} items - Array of selected items
   * @returns {Array} - Items with guaranteed cartItemIds
   */
  function ensureCartItemIds(items) {
    return items.map((item) => {
      if (!item.cartItemId) {
        item.cartItemId = `cart_${item.id}_${uniqid.time()}`
      }
      return item
    })
  }

  /**
   * Find target item for manual discount
   * @param {Object} modifier - The discount modifier
   * @param {Array} selectedItems - Array of items in cart
   * @param {Array} usedModifiers - Array of already used modifiers
   * @returns {Object|null} - Target item or null if not found
   */
  function findTargetItemForDiscount(modifier, selectedItems, usedModifiers) {
    let targetItem = null

    // First ensure all items have cartItemIds
    selectedItems = ensureCartItemIds(selectedItems)

    // Try to find by cartItemId if available
    if (modifier.typeDiscount?.cartItemId) {
      targetItem = selectedItems.find((item) => item.cartItemId === modifier.typeDiscount.cartItemId)
    }

    // // Fallback to index if no match found
    // if (!targetItem && typeof modifier.typeDiscount?.itemFilterIndex !== 'undefined') {
    // targetItem = selectedItems[modifier.typeDiscount.itemFilterIndex];
    // }

    return targetItem
  }

  if (modifier.manualInputDiscount === true) {
    // manualInputDiscount

    /**
     * calcDiscountObj.get look like this:
     * [
     * 	{ id: docIdItemXYZ, index: 0 },
     * ]
     */

    let targetItem = findTargetItemForDiscount(modifier, selectedItems, usedModifiers)

    if (targetItem) {
      getItems.push({
        id: targetItem.id,
        cartItemId: targetItem.cartItemId,
        index: selectedItems.indexOf(targetItem),
      })
    } else {
      if (debug)
        errorsArray.push({
          fail: true,
          reason: 'Selected item not found for discount',
          code: 301,
          modifierName: modifier.name,
        })
      return false
    }
  } else if (modifier.custom) {
    // LEGACY
    const itemFilterIndex = modifier.typeDiscount.itemFilterIndex

    //Push id to getItems for the calcDiscountObj object

    const foundedItem = selectedItems.find((item) => item.index == itemFilterIndex)
    if (foundedItem) {
      // console.log(
      // 	"itemFilterIndex activeer mij",
      // 	itemFilterIndex,
      // 	"filter:",
      // 	selectedItems.find((item) => item.index == itemFilterIndex).id
      // );

      getItems.push(foundedItem.id)
    }
    // getItems.push(selectedItems.find((item) => item.index == itemFilterIndex).id);
  } else {
    // console.log("modifier", modifier);

    //Filter Products or Treatments
    filteredItems = filterSys(modifier)

    if (filteredItems.fail == true && filteredItems.code === 301) {
      if (debug) errorsArray.push(filteredItems)
      console.log(filteredItems.reason)
      return false
    } else if (filteredItems.fail == true && filteredItems.code === 311) {
      //Membership is found but the user added no items. Check filtersys function for more information

      //The function stops here so the rule will be added but not calculated
      usedModifiers.push(filteredItems.modifierObj)
      return false
    } else if (filteredItems.fail == true && filteredItems.code === 312) {
      //No Membership Treatment found but other Discount are included by the membership?

      usedModifiers.push(filteredItems.modifierObj)
      return false
    }
    // Filtered Items found and
    let filteredProducts = filteredItems.filter((item) => item.type == 'product')
    let filteredTreatments = filteredItems.filter((item) => item.type == 'treatment')

    filteredTreatments //?

    //Make the items unique
    if (modifier.type === 'treatment' || modifier.type === 'all' || modifier.type === 'downPayment') {
      let uniqTreatments = [...new Set(filteredTreatments.map((item) => item.id))]
      getItems.push(...uniqTreatments)
    }
    if (modifier.type === 'product' || modifier.type === 'all' || modifier.type === 'downPayment') {
      let uniqProducts = [...new Set(filteredProducts.map((item) => item.id))]
      getItems.push(...uniqProducts)
    }
  }

  //Sorter before calculation Sort by VAT and Price depending on typeDiscount.
  // const sortedFilteredItems = sorter(modifier.typeDiscount.type, filteredItems);

  const modifierType =
    modifier.type === 'downPayment'
      ? 'downPayment'
      : modifier.type === 'product'
      ? 'modifier'
      : modifier.type === 'treatment'
      ? 'modifier'
      : modifier.type === 'all'
      ? 'modifier'
      : 'unknown'

  console.log(modifier)
  //Enroll modifier
  const calcDiscountObj = {
    discountValue: modifier.typeDiscount.discountValue,
    type: 'fixedAmount', //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
    modifierType: modifierType,
    repetitive: modifier.typeDiscount.repetitive == true ? true : false,
    discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
    quantityRules: null,
    get: getItems,
    modifierId: modifier.id,
    manualInputDiscount: modifier.manualInputDiscount ? true : false,
    addedVia: modifier.addedVia ? modifier.addedVia : null,
  }

  console.log('calcDiscountObj', calcDiscountObj)

  //Start Calculation and RETURN remainingAmount
  let calculatedDiscountIntel = calculateDiscount(calcDiscountObj)

  //Give back remainingAmount
  modifier.remainingAmount = calculatedDiscountIntel.remainingAmount

  //Everything looks fine add

  usedModifiers.push(modifier)
  // console.log("TEST VANUIT POS", selectedItems[0]);
  return true
}

/**
 * func: buyXX buy X get X
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function buyXX(modifier, manual, debug) {
  //Already Deleted
  const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia)
  if (deletedCheck !== true) {
    if (debug) errorsArray.push(deletedCheck)
    // console.log(deletedCheck.reason);
    return false
  }

  //Block equal discount duplicate blocking
  const discountOnceCheck = discountOnce(modifier.id, modifier.name)
  if (discountOnceCheck !== true) {
    if (debug) errorsArray.push(discountOnceCheck)
    // console.log(discountOnceCheck);
    return false
  }

  const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id)
  if (used !== true) {
    if (debug) errorsArray.push(used)
    // console.log(used.reason);
    return false
  }

  const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name)
  if (combinationCheck.fail == true) {
    if (debug) errorsArray.push(combinationCheck)
    // // console.log(combinationCheck.reason);
    return false
  }

  //Filter Products or Treatments
  const filteredItems = filterSys(modifier)
  if (filteredItems.fail == true && filteredItems.code === 301) {
    if (debug) errorsArray.push(filteredItems)
    // console.log(filteredItems.reason);
    return false
  } else if (filteredItems.fail == true && filteredItems.code === 311) {
    //Membership is found but the user added no items. Check filtersys function for more information

    //The function stops here so the rule will be added but not calculated
    usedModifiers.push(filteredItems.modifierObj)
    return false
  }

  //Interpreter for getting the right getQuantity
  const getQuantity = mixMatchInterpreter(modifier, filteredItems)
  if (getQuantity === false) return false
  getQuantity //?
  const calcDiscountObj = {
    discountValue: modifier.typeDiscount.discountValue,
    type: 'buyXX', //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
    discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
    modifierType: 'modifier',
    quantityRules: null,
    get: getQuantity,
    modifierId: modifier.id,
    addedVia: modifier.addedVia ? modifier.addedVia : null,
  }

  //TODO Memberships

  calculateDiscount(calcDiscountObj)
  //Everything looks fine add
  usedModifiers.push(modifier)
  return true
}

/**
 * func: buyXY buy X get Y
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function buyXY(modifier, manual, debug) {
  //Already Deleted
  const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia)
  if (deletedCheck !== true) {
    if (debug) errorsArray.push(deletedCheck)
    // console.log(deletedCheck.reason);
    return false
  }

  //Block equal discount duplicate blocking
  const discountOnceCheck = discountOnce(modifier.id, modifier.name)
  if (discountOnceCheck !== true) {
    if (debug) errorsArray.push(discountOnceCheck)
    // console.log(discountOnceCheck);
    return false
  }

  const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id)
  if (used !== true) {
    if (debug) errorsArray.push(used)
    // console.log(used.reason);
    return false
  }

  const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name)
  if (combinationCheck.fail == true) {
    if (debug) errorsArray.push(combinationCheck)
    // // console.log(combinationCheck.reason);
    return false
  }

  //Filter Products or Treatments
  const filteredItems = filterSys(modifier)
  console.log('filteredItems', filteredItems.length)
  if (filteredItems.fail == true && filteredItems.code === 301) {
    if (debug) errorsArray.push(filteredItems)
    // console.log(filteredItems.reason);
    return false
  } else if (filteredItems.fail == true && filteredItems.code === 311) {
    //Membership is found but the user added no items. Check filtersys function for more information

    //The function stops here so the rule will be added but not calculated
    usedModifiers.push(filteredItems.modifierObj)
    return false
  }

  //Interpreter for getting the right getQuantity
  const getQuantity = mixMatchInterpreter(modifier, filteredItems)
  if (getQuantity === false) return false
  //getQuantity; //?

  console.log('getQuantity', getQuantity)

  const calcDiscountObj = {
    discountValue: modifier.typeDiscount.discountValue,
    type: 'buyXY', //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
    discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
    modifierType: 'modifier',
    quantityRules: null,
    get: getQuantity,
    modifierId: modifier.id,
    addedVia: modifier.addedVia ? modifier.addedVia : null,
  }

  //Calculate Discount
  calculateDiscount(calcDiscountObj)
  //Everything looks fine add
  usedModifiers.push(modifier)

  return true
}

/**
 * func: quantityDiscount
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function quantityDiscount(modifier, manual, debug) {
  //Already Deleted
  const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia)
  if (deletedCheck !== true) {
    if (debug) errorsArray.push(deletedCheck)
    // console.log(deletedCheck.reason);
    return false
  }

  //Block equal discount duplicate blocking
  const discountOnceCheck = discountOnce(modifier.id, modifier.name)
  if (discountOnceCheck !== true) {
    if (debug) errorsArray.push(discountOnceCheck)
    // console.log(discountOnceCheck);
    return false
  }

  const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id)
  if (used !== true) {
    if (debug) errorsArray.push(used)
    // console.log(used.reason);
    return false
  }

  const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name)
  if (combinationCheck.fail == true) {
    if (debug) errorsArray.push(combinationCheck)
    // // console.log(combinationCheck.reason);
    return false
  }

  //Filter Products or Treatments
  const filteredItems = filterSys(modifier)
  if (filteredItems.fail == true && filteredItems.code === 301) {
    if (debug) errorsArray.push(filteredItems)
    // console.log(filteredItems.reason);
    return false
  } else if (filteredItems.fail == true && filteredItems.code === 311) {
    //Membership is found but the user added no items. Check filtersys function for more information

    //The function stops here so the rule will be added but not calculated
    usedModifiers.push(filteredItems.modifierObj)
    return false
  }

  //Prepare for calculation

  const acceptedItemsforDiscount = validateQuantityDiscount(modifier, filteredItems)
  if (Array.isArray(acceptedItemsforDiscount) === false) {
    if (debug) errorsArray.push(acceptedItemsforDiscount)
    return false
  }

  const calcDiscountObj = {
    discountValue: null,
    type: 'quantityDiscount', //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
    discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
    quantityRules: modifier.typeDiscount.quantityRules,
    modifierType: 'modifier',
    get: acceptedItemsforDiscount,
    modifierId: modifier.id,
    addedVia: modifier.addedVia ? modifier.addedVia : null,
  }

  calculateDiscount(calcDiscountObj)
  //Everything looks fine add
  usedModifiers.push(modifier)

  return true
}

function membership(modifier, manual, debug) {
  //Already Deleted
  const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia)
  if (deletedCheck !== true) {
    if (debug) errorsArray.push(deletedCheck)
    // console.log(deletedCheck.reason);
    return false
  }

  //Block equal discount duplicate blocking
  const discountOnceCheck = discountOnce(modifier.id, modifier.name)
  if (discountOnceCheck !== true) {
    if (debug) errorsArray.push(discountOnceCheck)
    // console.log(discountOnceCheck);
    return false
  }

  /**
   * Filter FixedAmount
   * Could be a custom Filter by ModifiedId OR a fixedAmount modifier preset.
   * This depends if modifier.typeDiscount.custom true
   */
  let filteredItems = null
  let uniqueItems = new Array()

  if (modifier.typeDiscount.custom) {
    const itemFilterIndex = modifier.typeDiscount.itemFilterIndex
    //Push id to uniqueItems for the calcDiscountObj object
    if (itemFilterIndex.length !== 0) uniqueItems.push(selectedItems.find((item) => item.index == itemFilterIndex).id)
    uniqueItems = [...new Set(filteredItems.map((item) => item.id))]
  } else {
    //Filter Products or Treatments
    filteredItems = filterSys(modifier)
    if (filteredItems.fail == true && filteredItems.code === 312) {
      if (debug) errorsArray.push(filteredItems)
      console.log(filteredItems.reason)

      // return false;
    } else {
      //Membership found calculate Membership
      uniqueItems = [...new Set(filteredItems.map((item) => item.id))]
    }

    //Make unique for FixedAmount
  }

  //Legacy Membership code, new code is for using modifiers in combination with Memberships

  // const calcDiscountObj = {
  //   discountValue: null,
  //   type: "membership", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
  //   discountType: "free", //could be "amount", "percentage",free
  //   quantityRules: null,
  //   get: uniqueItems,
  //   modifierId: modifier.id,
  // };

  //Enroll modifier ^_^
  const calcDiscountObj = {
    discountValue: 0,
    type: 'fixedAmount', //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
    discountType: 'amount', //could be "amount", "percentage",free
    quantityRules: null,
    get: uniqueItems,
    modifierId: modifier.id,
    addedVia: modifier.addedVia ? modifier.addedVia : null,
  }

  console.log('calcDiscountObj', calcDiscountObj)

  // if(modifier.toggle == false) {
  //   console.log("Modifier niet  gevonden!")
  //   usedModifiers = usedModifiers.filter(usedModifer => usedModifer.id !== "Pd9qQo4y3HAPfKrtIxgw")
  //   modifiers = modifiers.filter(usedModifer => usedModifer.id !== "Pd9qQo4y3HAPfKrtIxgw")

  // }

  let foundDiscounts = new Array()
  let peakOrOffPeak = null

  // createdDiscounts.byMembership = true;

  // typeOfDiscountSwitch(createdDiscounts, false, false)

  //Get the right day
  let todayDay = moment().locale('en').format('ddd')

  // console.log("WAT IS DE PEAK STATUS?? POS:", modifier.peakStatus.peak,  moment().locale("en").format("ddd"))

  if (modifier.days[todayDay]) {
    // console.log("Start tijd onderscheid peak", modifier)

    //Function output object: {  peak: "peak" or "offPeak" };

    //Force Override
    if (modifier.override) {
      // console.log("Dit is mijn peak");
      modifier.peakStatus.peak = 'peak'
    } else {
      //Not overrided or new added membership
      peakOrOffPeak = peakSubscriptionCheck(modifier.days[todayDay]) //?
      modifier.peakStatus = peakOrOffPeak
    }

    //PAST DE DALL KORTING TOE

    if (modifier.peakStatus.peak === 'peak') {
      // console.log("REKEN DICOUNT DOOR", modifier)
      //Start Calculation
      // calculateDiscount(calcDiscountObj);
      //Everything looks fine add
      usedModifiers.push(modifier)
    }

    if (modifier.peakStatus.peak === 'offPeak') {
      //Only Add rule
      usedModifiers.push(modifier)
    }

    //Get the right Inside or Outside Peak hours discounts
    if (modifier.separatedDiscount.active === true) {
      if (modifier.peakStatus.peak === 'peak') {
        //foreach  discountsInsideSubscriptionDays: ["Pd9qQo4y3HAPfKrtIxgw"],

        console.log('FOUND PEAK DISCOUNT', modifier.separatedDiscount.discountsInsideSubscriptionDays)
        modifier.separatedDiscount.discountsInsideSubscriptionDays //?

        modifier.separatedDiscount.discountsInsideSubscriptionDays.forEach((discount) => {
          foundDiscounts.push(discount)
          // let foundDiscount =
          //   modifier.separatedDiscount.discountsInsideSubscriptionDays.find(
          //     (insideDiscount) => insideDiscount.id === discount.id
          //   );

          // if (foundDiscount) {
          //   foundDiscounts.push(foundDiscount);
          // }
        })
      }

      if (modifier.peakStatus.peak === 'offPeak') {
        //Is there a split between peak and off peak discounts
        console.log('OUTSIDE PEAK')

        if (modifier.separatedDiscount.splitDiscounts === true) {
          //Get outside Discounts

          modifier.separatedDiscount.discountsOutsideSubscriptionDays.forEach((discount) => {
            // let foundDiscount =
            //   modifier.separatedDiscount.discountsOutsideSubscriptionDays.find(
            //     (insideDiscount) => insideDiscount.id === discount.id
            //   );

            // if (foundDiscount) {
            console.log('OUTSIDE PEAK 1A SPLIT')
            //   foundDiscounts.push(foundDiscount);
            // }
            foundDiscounts.push(discount)
          })
        } else {
          //Post the default inside Subscription days promotions
          //foreach discountsInsideSubscriptionDays: ["Pd9qQo4y3HAPfKrtIxgw"],

          modifier.separatedDiscount.discountsInsideSubscriptionDays.forEach((discount) => {
            // let foundDiscount =
            //   modifier.separatedDiscount.discountsInsideSubscriptionDays.find(
            //     (insideDiscount) => insideDiscount.id === discount.id
            //   );

            // if (foundDiscount) {
            console.log('OUTSIDE PEAK 2A')
            //   foundDiscounts.push(foundDiscount);
            // }
            foundDiscounts.push(discount)
          })
        }
      }
    }
  }
  //Peak Or Off-Peak
  function peakSubscriptionCheck(byDay) {
    // { end: null,
    //   name: 'saturday',
    //   active: false,
    //   allDay: null,
    //   start: null }

    //first check if active is true,

    if (byDay.active) {
      //then check if its Allday is true else look at start and end.
      if (byDay.allDay) {
        //its allDay

        return { peak: 'peak' }
      } else {
        //check start and End time

        if (
          moment().locale('en').format('HH:mm') >= byDay.start &&
          moment().locale('en').format('HH:mm') <= byDay.end
        ) {
          return { peak: 'peak' }
        } else {
          return { peak: 'offPeak' }
        }
      }
    } else {
      return { peak: 'offPeak' }
    }
  }

  // console.log("foundedDiscounts", foundDiscounts)

  //Get Feedback Message Only visible when extra discount rules added
  // if (foundDiscounts.length !== 0) {
  //   if (modifier.peakStatus.peak === "peak") {
  //     advisedModifiers.push({
  //       reason: modifier.peakMessage,
  //       code: 501,
  //       modifierName: modifier.name,
  //     });
  //   }

  //   if (modifier.peakStatus.peak === "offPeak") {
  //     advisedModifiers.push({
  //       reason: modifier.offPeakMessage,
  //       code: 502,
  //       modifierName: modifier.name,
  //     });
  //   }
  // }

  //Check if Membership is toggled on

  //Add the sorted Discount Rules by Peak or Offpeak status

  // console.log("FoundedDiscount", foundDiscounts);

  if (foundDiscounts.length !== 0) {
    foundDiscounts.forEach((discountId) => {
      let foundedDiscount = modifiers.find((allModifiers) => allModifiers.id === discountId)

      console.log('FOUNDED DISCOUNT', foundedDiscount)

      //WHen the discount is found. Side note: A discount could be deleted. So no discounts will be added.
      if (foundedDiscount) {
        //Force Priority Membership Treatment

        foundedDiscount.priority = sortDiscountOrder.find(
          (typeOrder) => typeOrder.typeDiscount == 'membershipDiscount'
        ).priorityNumber

        console.log('FOUNDED DISCOUNT', foundedDiscount)
        foundedDiscount.byMembership = true //?
        typeOfDiscountSwitch(foundedDiscount, false, false)
      } else {
      }
    })
  }

  return true
}

/**
 * Every discount can be deleted, so not use again. This is build for automatic added modifiers.
 * @param {string} id check by id
 */
function deletedDiscount(id, name, addedVia) {
  // console.log("deletedDiscount", id, name, addedVia);

  if (addedVia !== 'automaticDiscount') return true
  if (deletedModifiers.find((item) => item.id == id)) {
    return { fail: true, reason: 'Discount already deleted', code: 305, modifierName: name, addedVia: addedVia }
  } else {
    return true
  }
}

/**
 * Every discount can be used once
 * @param {string} id check by id
 */
function discountOnce(id, name) {
  if (usedModifiers.find((item) => item.id == id)) {
    return { fail: true, reason: 'Discount already active', code: 304, modifierName: name }
  } else {
    return true
  }
}

function numberUsed(numberLimit, name, id) {
  // console.log(numberLimit, name, id);
  if (numberLimit.active === true) {
    if (numberLimit.type === 'max') {
      if (numberLimit.numberUsed > numberLimit.number) {
        return { fail: true, reason: 'Discount maximum limit reached', code: 306, modifierName: name }
      } else {
        return true
      }
    }

    if (numberLimit.type === 'maxClient') {
      let countUsedModifierId = null
      if (clientDataObj.usedModifiers) {
        countUsedModifierId = clientDataObj.usedModifiers.filter((used) => used.id === id)

        if (countUsedModifierId.length > numberLimit.number) {
          advisedModifiers.push({
            reason: `${clientDataObj.name} heeft al ${countUsedModifierId.length} keer gebruik gemaakt van de korting: ${name}`,
            code: 600,
            modifierName: name,
          })

          return { fail: true, reason: 'Discount maximum limit (for client) reached', code: 306, modifierName: name }
        } else {
          return true
        }
      } else {
        //usedModifiers doesnt exist so client doesnt use any modifier before.
        return true
      }
    }
  } else {
    return true
  }
}

/**
 * Combination limit. Could the modifier combined with other modifiers?
 * @param {boolean} combinationLimit check by id
 */
function combinationLimit(combinationLimit, name) {
  //Stop process when combinationLimit true and other discount are available
  if (combinationLimit === true && usedModifiers.size > 0) {
    return { fail: true, reason: 'Combination not allowed', code: 303, modifierName: name }
  } else {
    return true
  }
}

/**
 * filterSys for filtering related items
 * OUTPUT: true gives an object of founded items : false gives an reason
 * @param {*} modifier
 */
function filterSys(modifier) {
  console.log('Modifier FilterSys', modifier)

  let foundItems = new Object()
  foundItems.foundProducts = new Array()
  foundItems.foundTreatments = new Array()
  foundItems.foundMemberships = new Array()
  foundItems.foundPrePayments = new Array()

  //Operators
  if (modifier.modifierGroup === 'downPayment') {
    getAllProducts() //1. Products
    getAllTreatments() //2. Treatments
  }

  //Default Discount || Loyalty Discount || Membership Discount
  if (
    modifier.modifierGroup === 'defaultModifier' ||
    modifier.modifierGroup === 'loyaltyModifier' ||
    modifier.modifierGroup === 'membershipModifier'
  ) {

    if (!modifier.filterProducts.active && !modifier.filterTreatments.active) {
      getAllProducts()
      getAllTreatments()
    } else {
      if (modifier.type === 'product' || modifier.type === 'all') {
        if (modifier.filterProducts.active === true) {
          switch (modifier.filterProducts.type) {
            case 'specific':
              console.log('filterProductsSpecific', modifier.filterProducts.items)
              filterProductsSpecific(modifier)
              break
  
            case 'brands':
              filterProductsBrands(modifier)
              break
  
            case 'categories':
              filterProductsCategories(modifier)
              break
  
            default:
              break
          }
        } else {
          //No filter is specified, get all products;
          getAllProducts()
        }
      } else if (modifier.type === 'treatment' || modifier.type === 'all') {
        // console.log("MODIFIER", modifier);
        //Filter Treatments
  
        if (modifier.filterTreatments.active === true) {
          switch (modifier.filterTreatments.type) {
            case 'specific':
              filterTreatmentsSpecific(modifier)
              break
  
            case 'categories':
              filterTreatmentsCategories(modifier)
              break
  
            default:
              break
          }
        } else {
          //All
          getAllTreatments()
        }
      }
    }
  }

  //Memberships
  // if (modifier.modifierGroup === "membershipModifier") {
  // 	if (modifier.type === "product") {
  // 		if (modifier.filterProducts.active === true) {
  // 			console.log("filterProducts", modifier.filterProducts);
  // 			switch (modifier.filterProducts.type) {
  // 				case "specific":
  // 					console.log("filterProductsSpecific", modifier.filterProducts.items);
  // 					filterProductsSpecific(modifier);
  // 					break;

  // 				case "brands":
  // 					filterProductsBrands(modifier);
  // 					break;

  // 				case "categories":
  // 					filterProductsCategories(modifier);
  // 					break;

  // 				default:
  // 					break;
  // 			}
  // 		} else {
  // 			//No filter is specified, get all products;
  // 			getAllProducts();
  // 		}

  // 	} else if (modifier.type === "treatment") {
  // 		if (modifier.filterTreatments.active === true) {
  // 			switch (modifier.filterTreatments.type) {
  // 				case "specific":
  // 					filterTreatmentsSpecific(modifier);
  // 					break;

  // 				case "categories":
  // 					filterTreatmentsCategories(modifier);
  // 					break;

  // 				default:
  // 					break;
  // 			}
  // 		} else {
  // 			//All
  // 			getAllTreatments();
  // 		}
  // 	} else {
  // 		// All for other future purposes

  // 		console.log("No Product nor Treatment to filter ", modifier);

  // 		// if (modifier.filterTreatments.active === true) {
  // 		// 	switch (modifier.filterTreatments.type) {
  // 		// 		case "specific":
  // 		// 			filterMembershipTreatments(modifier);
  // 		// 			break;
  // 		// 		// case "brands":
  // 		// 		//   filterTreatmentsBrands(modifier);
  // 		// 		//   break;
  // 		// 		// case "categories":
  // 		// 		//   filterTreatmentsCategories(modifier);
  // 		// 		//   break;

  // 		// 		default:
  // 		// 			break;
  // 		// 	}
  // 		// }
  // 	}
  // }

  //Pre Payments
  if (modifier.modifierGroup === 'prePaymentModifier') {
    console.log('modifier', modifier)

    if (modifier.type === 'product') {
      if (modifier.filterProducts.active === true) {
        switch (modifier.filterTreatments.type) {
          case 'all':
            filterPrePaymentsProducts(modifier)
            break

          // case "brands":
          //   filterTreatmentsBrands(modifier);
          //   break;
          // case "categories":
          //   filterTreatmentsCategories(modifier);
          //   break;

          default:
            break
        }
      }
    } else if (modifier.type === 'treatment') {
      if (modifier.filterTreatments.active === true) {
        switch (modifier.filterTreatments.type) {
          case 'all':
            filterPrePaymentsTreatments(modifier)
            break

          case 'discountPrePaid':
            filterPrePaymentsTreatmentsDiscount(modifier)
            break

          // case "brands":
          //   filterTreatmentsBrands(modifier);
          //   break;
          // case "categories":
          //   filterTreatmentsCategories(modifier);
          //   break;

          default:
            break
        }
      } else {
        // All
      }
    }
  }

  //Filter memberships

  //TODO also with exclude feature
  function filterMembershipTreatments(modifier) {
    if (modifier.filterTreatments.itemsAction === 'include') {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.id === item
          ) {
            const data = selectedItem
            // data.index = index;
            // console.log("Membership Found!", data)
            foundItems.foundMemberships.push(data)
          }
        })
      })
    }
  }

  //Filter prePayments

  function filterPrePaymentsTreatments(modifier) {
    if (modifier.filterTreatments.itemsAction === 'include') {
      //Continuely add new treatments from highest price to lowest price

      /**
       * The foundPrePayments variable is assigned to the selectedItems array, filtered to only
      include treatments with a typeOfPayment of "deposit", and sorted by the newPriceTotal property
      in descending order.
       */

      let foundTreatments = selectedItems
        .filter((selectedItem) => selectedItem.type == 'treatment' && selectedItem.typeOfPayment === 'deposit')
        .sort((a, b) => b.newPriceTotal - a.newPriceTotal)

      foundItems.foundPrePayments = [...foundItems.foundPrePayments, ...foundTreatments]
    }
  }

  function filterPrePaymentsProducts(modifier) {
    if (modifier.filterProducts.itemsAction === 'include') {
      //Continuely add new products from highest price to lowest price

      /**
       * The foundPrePayments variable is assigned to the selectedItems array, filtered to only
      include treatments with a typeOfPayment of "deposit", and sorted by the newPriceTotal property
      in descending order.
       */

      let foundProducts = selectedItems
        .filter((selectedItem) => selectedItem.type == 'product' && selectedItem.typeOfPayment === 'deposit')
        .sort((a, b) => b.newPriceTotal - a.newPriceTotal)

      foundItems.foundPrePayments = [...foundItems.foundPrePayments, ...foundProducts]
    }
  }

  //Extra Discount Filter PrePayments Discount
  function filterPrePaymentsTreatmentsDiscount(modifier) {
    //Condition itemsAction
    if (modifier.filterTreatments.itemsAction === 'include') {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          selectedItem.index //?
          item //?
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.id === item
          ) {
            const data = selectedItem //?
            // data.index = index;
            foundItems.foundPrePayments.push(data)
          }
        })
      })
    } else {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.id !== item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundPrePayments.push(data)
          }
        })
      })
    }
  }

  /**
   * The function getAllProducts() filters the selectedItems array and returns all the items that are
   * of type "product" and typeOfPayment "deposit"
   */
  function getAllProducts() {
    foundItems.foundProducts = selectedItems.filter(
      (selectedItem) => selectedItem.type === 'product' && selectedItem.typeOfPayment === 'deposit'
    )
  }

  /**
   * The function getAllTreatments() filters the selectedItems array and returns all the items that are
   * of type "treatment" and typeOfPayment "deposit"
   */
  function getAllTreatments() {
    foundItems.foundTreatments = selectedItems.filter(
      (selectedItem) => selectedItem.type === 'treatment' && selectedItem.typeOfPayment === 'deposit'
    ) //?
  }

  //Filter Products
  function filterProductsSpecific(modifier) {
    //Condition itemsAction
    if (modifier.filterProducts.itemsAction === 'include') {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          console.log('selectedItem', selectedItem.name)
          if (selectedItem.type === 'product' && selectedItem.typeOfPayment === 'deposit' && selectedItem.id === item) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    } else {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (selectedItem.type === 'product' && selectedItem.typeOfPayment === 'deposit' && selectedItem.id !== item) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    }
  }
  //Filter Products
  function filterTreatmentsSpecific(modifier) {
    //Condition itemsAction
    if (modifier.filterTreatments.itemsAction === 'include') {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          selectedItem.index //?
          item //?
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.id === item
          ) {
            const data = selectedItem //?
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    } else {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.id !== item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    }
  }

  //Filter Products by Brands
  function filterProductsBrands(modifier) {
    //Condition itemsAction
    if (modifier.filterProducts.itemsAction === 'include') {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'product' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.supplier === item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    } else {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'product' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.supplier !== item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    }
  }

  //Filter Treatments by Brands
  function filterTreatmentsBrands(modifier) {
    //Condition itemsAction
    if (modifier.filterTreatments.itemsAction === 'include') {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.supplier === item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    } else {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.supplier !== item
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    }
  }

  //Filter Products by Categorie
  function filterProductsCategories(modifier) {
    //Condition itemsAction
    if (modifier.filterProducts.itemsAction === 'include') {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem, index) => {
          if (
            selectedItem.type === 'product' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.category.includes(item)
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    } else {
      modifier.filterProducts.items.forEach((item) => {
        selectedItems.filter((selectedItem, index) => {
          if (
            selectedItem.type === 'product' &&
            selectedItem.typeOfPayment === 'deposit' &&
            !selectedItem.category.includes(item)
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundProducts.push(data)
          }
        })
      })
    }
  }

  //Filter Treatments by Categorie
  function filterTreatmentsCategories(modifier) {
    //Condition itemsAction
    if (modifier.filterTreatments.itemsAction === 'include') {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem, index) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            selectedItem.treatmentType.includes(item)
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    } else {
      modifier.filterTreatments.items.forEach((item) => {
        selectedItems.filter((selectedItem, index) => {
          if (
            selectedItem.type === 'treatment' &&
            selectedItem.typeOfPayment === 'deposit' &&
            !selectedItem.treatmentType.includes(item)
          ) {
            const data = selectedItem
            // data.index = index;
            foundItems.foundTreatments.push(data)
          }
        })
      })
    }
  }

  //Qualify restricted filters

  // console.log("foundItems", foundItems);

  if (
    modifier.modifierGroup === 'defaultModifier' ||
    modifier.modifierGroup === 'loyaltyModifier' ||
    modifier.modifierGroup === 'membershipModifier'
  ) {
    // console.log("modifier", modifier);

    if (modifier.type === 'all') {
      const hasProducts = Object.entries(foundItems.foundProducts).length !== 0
      const hasTreatments = Object.entries(foundItems.foundTreatments).length !== 0
      
      // Als geen filters actief, return alle gevonden items
      if (!modifier.filterProducts.active && !modifier.filterTreatments.active) {
        return [...foundItems.foundProducts, ...foundItems.foundTreatments]
      }
      // Anders, check of er items gevonden zijn volgens de filter criteria
      else if (hasProducts && hasTreatments) {
        return [...foundItems.foundProducts, ...foundItems.foundTreatments]
      } else {
        return {
          fail: true,
          reason: modifier.name + ' => Such product and treatment not found...',
          code: 301,
          modifierName: modifier.name,
          modifierGroup: modifier.modifierGroup,
        }
      }
    } else if (modifier.type === 'product') {
      console.log('foundItems.foundProducts', foundItems.foundProducts)
      if (Object.entries(foundItems.foundProducts).length !== 0) {
        return foundItems.foundProducts
      } else {
        return {
          fail: true,
          reason: modifier.name + ' => Such product not found...',
          code: 301,
          modifierName: modifier.name,
          modifierGroup: modifier.modifierGroup,
        }
      }
    } else if (modifier.type === 'treatment') {
      console.log('foundItems.foundTreatments', foundItems.foundTreatments)
      if (Object.entries(foundItems.foundTreatments).length !== 0) {
        return foundItems.foundTreatments
      } else {
        return {
          fail: true,
          reason: modifier.name + ' => Such treatment not found...',
          code: 301,
          modifierName: modifier.name,
          modifierGroup: modifier.modifierGroup,
        }
      }
    } else {
      console.error('failed to filter', modifier)
    }
  }

  //Downpayment
  if (modifier.modifierGroup === 'downPayment') {
    if (
      Object.entries(foundItems.foundProducts).length !== 0 ||
      Object.entries(foundItems.foundTreatments).length !== 0
    ) {
      return [...foundItems.foundProducts, ...foundItems.foundTreatments] //?
    } else {
      return {
        fail: true,
        reason: 'down payment ' + modifier.name + ' => Such product or treatment not found...',
        code: 301,
        modifierName: modifier.name,
        modifierObj: modifier,
      }
    }
  }

  // //Either product AND treatment must be found.
  // if (
  // 	modifier.modifierGroup !== "membershipModifier" &&
  // 	modifier.modifierGroup !== "prePaymentModifier" &&
  // 	modifier.filterTreatments.active === true &&
  // 	modifier.filterProducts.active === true
  // ) {
  // 	if (Object.entries(foundItems.foundProducts).length !== 0 && Object.entries(foundItems.foundTreatments).length !== 0) {
  // 		return [...foundItems.foundProducts, ...foundItems.foundTreatments]; //?
  // 	} else {
  // 		if (modifier.byMembership) {
  // 			return {
  // 				fail: true,
  // 				reason: modifier.name + " => Membership product or treatment not found...",
  // 				code: 311,
  // 				modifierName: modifier.name,
  // 				modifierObj: modifier,
  // 			};
  // 		}
  // 		return { fail: true, reason: modifier.name + " => Such product or treatment not found...", code: 301, modifierName: modifier.name };
  // 	}
  // }

  // //Either product OR treatment must be found.

  // //product
  // if (modifier.modifierGroup !== "membershipModifier" && modifier.modifierGroup !== "prePaymentModifier" && modifier.filterProducts.active == true) {
  // 	if (Object.entries(foundItems.foundProducts).length !== 0) {
  // 		return foundItems.foundProducts;
  // 	} else {
  // 		if (modifier.byMembership) {
  // 			return { fail: true, reason: modifier.name + " => Membership product not found...", code: 311, modifierName: modifier.name, modifierObj: modifier };
  // 		}

  // 		return { fail: true, reason: modifier.name + " => No product found...", code: 301, modifierName: modifier.name };
  // 	}
  // }

  // //treatment
  // if (modifier.modifierGroup !== "membershipModifier" && modifier.modifierGroup !== "prePaymentModifier" && modifier.filterTreatments.active == true) {
  // 	if (Object.entries(foundItems.foundTreatments).length !== 0) {
  // 		return foundItems.foundTreatments;
  // 	} else {
  // 		if (modifier.byMembership) {
  // 			return { fail: true, reason: modifier.name + " => Membership treatment not found...", code: 311, modifierName: modifier.name, modifierObj: modifier };
  // 		}

  // 		return { fail: true, reason: modifier.name + " => No treatment found...", code: 301, modifierName: modifier.name };
  // 	}
  // }
  // //Membership Treatment
  // if (modifier.modifierGroup === "membershipModifier" && modifier.filterTreatments.active == true) {
  // 	if (Object.entries(foundItems.foundMemberships).length !== 0) {
  // 		return foundItems.foundMemberships;
  // 	} else {
  // 		return { fail: true, reason: modifier.name + " => No Membership treatment found...", code: 312, modifierName: modifier.name, modifierObj: modifier };
  // 	}
  // }

  // if (modifier.modifierGroup === "prePaymentModifier") {
  // 	if (Object.entries(foundItems.foundPrePayments).length !== 0) {
  // 		return foundItems.foundPrePayments;
  // 	} else {
  // 		return { fail: true, reason: modifier.name + " => No PrePayment treatment found...", code: 312, modifierName: modifier.name, modifierObj: modifier };
  // 	}
  // }
} //end of filterSys

/**
 * Mix and Match Interpreter
 * 1 Cluster Items
 * 2 Sort Highest amount
 * 3 Calculate Ratio Gives Advice when needed
 * 4 how many times is the discount possible AND/OR check the limits
 * 5 outputs an Array with getQuantity used for object "calcDiscountObj"


 * @param {object} modifier - the discount object
 * @param {array} filteredItems - filteredItems to validate
 * return: object for calculation OR return false when fails with advise
 */
function mixMatchInterpreter(modifier, filteredItems) {
  const buyQuantity = modifier.typeDiscount.buyQuantity
  const getQuantity = modifier.typeDiscount.getQuantity

  switch (modifier.typeDiscount.type) {
    case 'buyXX':
      const getXXquantity = validateQuantity(modifier, filteredItems)
      if (Array.isArray(getXXquantity) === true) {
        return getXXquantity
      } else {
        return false
      }

      break

    case 'buyXY':
      const getXYquantity = validateQuantity(modifier, filteredItems)
      if (Array.isArray(getXYquantity) === true) {
        console.log('getXYquantity', getXYquantity)
        return getXYquantity
      } else {
        return false
      }

      break

    default:
      break
  }

  /**
   * validateQuantity
   * Check X to X, example product must be 1111 OR 2222 not 1113 OR 1234 with respect to Categorie and Brand filter
   * UniqueCheck X to Y, example product must be 1234 OR 1111 depends on "UniqueItems" Boolean not 1113 OR 1234 with respect to Categorie and Brand filter
   * OUTPUT: Calculate Object for Calculation Array
   * @param {object} modifier - the discount object
   * @param {array} filteredItems - filteredItems to validate
   *
   */
  function validateQuantity(modifier, filteredItems) {
    /**
     * uniqueModifier === true : every items needs to be uniq 1234 option A
     * else it must be option B 1111 (getSameItems) or option C 1123 (filtereItemsMapped)
     * Wich option between B or C depends on most founded Item.
     * When every option is not equal to "buyQuantitiy" it will return with false and (if needed) advice.
     */

    // console.log("filteredItems", filteredItems.length);

    // console.log("filteredItems", {
    // 	length: filteredItems.length,
    // 	newPriceTotals: filteredItems.map((item) => item.newPriceTotalNetto),
    // 	priceNettos: filteredItems.map((item) => item.priceNetto),
    // });

    if (modifier.typeDiscount.uniqueItems === true) {
      //OPTION A if this option fails, every item must be unique

      // IS X To Y (UniqueItems)
      return optionXY(modifier, filteredItems) //?
    } else {
      console.log('filteredItems C', filteredItems)

      //Is X To X (same items)
      return optionXX(filteredItems, modifier)
    }

    /**
     * Processes items for XY discount where items must be unique within each set (uniqueItems = true)
     * The function optimizes for giving discounts to the cheapest available items while ensuring
     * each discount set contains unique items (e.g., different products).
     *
     * Algorithm:
     * 1. Groups items by their unique ID to handle multiple instances of the same product
     * 2. For each possible discount set:
     *    - Combines the cheapest available item with the most expensive available item
     *    - Ensures items in a set are unique (different IDs)
     *    - Applies discount to the cheapest item in the set from low to high
     * 3. Continues until useLimit is reached or no more valid combinations are possible
     *
     * Example with buyQuantity=2, getQuantity=1, useLimit=3:
     * Cart: [Max Waxx €36.30, Max Waxx €36.30, Hair Duffy €60.50, Genesis €100, Ultra €181.50]
     * Results in:
     * Set 1: Max Waxx + Ultra = Discount on Max Waxx
     * Set 2: Max Waxx + Genesis = Discount on Max Waxx
     * Set 3: Hair Duffy + Genesis = Discount on Hair Duffy
     *
     * @param {Object} modifier - The discount modifier object
     * @param {number} modifier.typeDiscount.buyQuantity - Number of unique items needed to qualify (e.g., 2)
     * @param {number} modifier.typeDiscount.getQuantity - Number of items to receive discount (e.g., 1)
     * @param {Object} modifier.useLimit - Limit settings for discount usage
     * @param {boolean} modifier.useLimit.active - Whether a usage limit is active
     * @param {number} modifier.useLimit.number - Maximum number of times discount can be applied
     * @param {Array} filteredItems - Array of items that passed initial filtering
     * @param {string} filteredItems[].id - Unique identifier for the item
     * @param {number} filteredItems[].index - Original index in cart
     * @param {number} filteredItems[].priceNetto - Net price of the item
     * @param {string} filteredItems[].name - Name of the item
     *
     * @returns {Array|Object} Either an array of items selected for discount (success) or
     *                        an object with failure information if requirements aren't met
     *
     * @example
     * const result = optionA({
     *   typeDiscount: { buyQuantity: 2, getQuantity: 1 },
     *   useLimit: { active: true, number: 3 }
     * }, filteredItems);
     *
     * Success result:
     * [{ id: "item1", index: 0, priceNetto: 36.30 }, ...]
     *
     * Failure result:
     * { fail: true, reason: "Not enough unique items", code: 307 }
     */
    function optionXY(modifier, filteredItems) {
      const buyQuantity = modifier.typeDiscount.buyQuantity
      const getQuantity = modifier.typeDiscount.getQuantity
      const useLimit = modifier.useLimit.active ? parseInt(modifier.useLimit.number) : Infinity

      // console.log(
      // 	"[optionA] Starting with items:",
      // 	filteredItems.map((item) => ({
      // 		name: item.name,
      // 		price: item.priceNetto,
      // 	}))
      // );

      // Group items by their unique ID and sort within groups by price
      const itemGroups = new Map()
      filteredItems.forEach((item) => {
        if (!itemGroups.has(item.id)) {
          itemGroups.set(item.id, [])
        }
        itemGroups.get(item.id).push(item)
      })

      // Sort items within each group by price
      itemGroups.forEach((group) => {
        group.sort((a, b) => a.priceNetto - b.priceNetto)
      })

      let selectedItemsForDiscount = []
      let usedIndexes = new Set()

      function findOptimalCombinations() {
        const validSets = []
        const availableGroups = Array.from(itemGroups.entries())

        // Sort groups by their cheapest item price (ascending)
        const cheapestItems = availableGroups.map(([id, items]) => ({
          id,
          items,
          cheapestPrice: items[0].priceNetto,
        }))

        // Sort by price ascending for finding cheap items for discount
        const sortedCheapest = [...cheapestItems].sort((a, b) => a.cheapestPrice - b.cheapestPrice)

        // Sort by price descending for finding expensive items to combine with
        const sortedExpensive = [...cheapestItems].sort((a, b) => b.cheapestPrice - a.cheapestPrice)

        // Try to combine cheapest with most expensive items
        for (let i = 0; i < sortedCheapest.length && validSets.length < useLimit; i++) {
          const cheapGroup = sortedCheapest[i]

          // Find available cheap items
          const cheapItems = cheapGroup.items.filter((item) => !usedIndexes.has(item.index))

          if (cheapItems.length === 0) continue

          // Find expensive items to combine with
          for (let j = 0; j < sortedExpensive.length; j++) {
            const expensiveGroup = sortedExpensive[j]

            // Skip if same group or already used in this combination
            if (expensiveGroup.id === cheapGroup.id) continue

            const expensiveItems = expensiveGroup.items.filter((item) => !usedIndexes.has(item.index))

            if (expensiveItems.length === 0) continue

            // Create valid combination
            validSets.push({
              items: [cheapItems[0], expensiveItems[0]],
              discountItem: cheapItems[0], // Always discount the cheaper item
            })

            break
          }
        }

        return validSets
      }

      // Process sets until we hit useLimit or run out of valid combinations
      let setCount = 0
      while (setCount < useLimit) {
        const validSets = findOptimalCombinations()
        if (validSets.length === 0) break

        // Process each valid set
        const currentSet = validSets[0]

        // Mark items as used
        currentSet.items.forEach((item) => usedIndexes.add(item.index))

        // Add the discount item to selected items
        selectedItemsForDiscount.push({
          id: currentSet.discountItem.id,
          index: currentSet.discountItem.index,
          priceNetto: currentSet.discountItem.priceNetto,
        })

        //  console.log(`[optionA] Added set ${setCount + 1}:`, {
        // 	  items: currentSet.items.map(item => ({
        // 			name: item.name,
        // 			price: item.priceNetto
        // 	  })),
        // 	  discountItem: {
        // 			name: currentSet.discountItem.name,
        // 			price: currentSet.discountItem.priceNetto
        // 	  }
        //  });

        setCount++
      }

      if (selectedItemsForDiscount.length > 0) {
        return selectedItemsForDiscount
      }

      return calculateRatioOptionA(filteredItems, buyQuantity, getQuantity, modifier)
    }

    /**
     * Calculates ratio and provides advice for discount activation
     * @param {Array} filteredItems - Available items
     * @param {number} buyQuantity - Required quantity to buy
     * @param {number} getQuantity - Quantity to receive discount
     * @param {Object} modifier - Modifier object
     * @returns {Object} Result object with status and advice
     */
    function calculateRatioOptionA(filteredItems, buyQuantity, getQuantity, modifier) {
      const ratio = buyQuantity - 1
      let productAdvice = ''
      let treatmentAdvice = ''
      let bothActive =
        modifier.filterProducts.active === true && modifier.filterTreatments.active === true ? ' en/of ' : ''

      // Generate appropriate advice based on modifier settings
      if (modifier.filterProducts.active === true) {
        if (modifier.filterProducts.type === 'categories') {
          productAdvice = 'unieke producten binnen dezelfde categorie'
        } else if (modifier.filterProducts.type === 'brands') {
          productAdvice = 'unieke producten binnen hetzelfde merk'
        } else if (modifier.filterProducts.type === 'specific') {
          productAdvice = 'unieke producten met dezelfde specificatie'
        }
      }

      if (modifier.filterTreatments.active === true) {
        if (modifier.filterTreatments.type === 'categories') {
          treatmentAdvice = 'unieke behandelingen binnen dezelfde categorie'
        } else if (modifier.filterTreatments.type === 'specific') {
          treatmentAdvice = 'unieke behandelingen met dezelfde specificatie'
        }
      }

      // Check if discount is almost active
      if (filteredItems.length < buyQuantity && filteredItems.length >= ratio) {
        const advice = `De actie is bijna actief! Activeer deze actie door het volgende toe te voegen, ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld iets anders dan ${filteredItems
          .map((item) => 'een ' + item.type + ' ' + item.name)
          .join(' of ')}`

        advisedModifiers.push({
          reason: advice,
          code: 405,
          modifierName: modifier.name,
        })

        return {
          fail: true,
          reason: modifier.name + ' => Discount almost active',
          code: 406,
          modifierName: modifier.name,
        }
      }
      // Check if ratio is too low
      else if (filteredItems.length < ratio) {
        return {
          fail: true,
          reason: modifier.name + ' => Amount below ratio',
          code: 307,
          modifierName: modifier.name,
        }
      }
      // Check if amount is sufficient
      else if (filteredItems.length >= buyQuantity) {
        return true
      }

      return {
        fail: true,
        reason: modifier.name + ' => Not enough items for discount',
        code: 307,
        modifierName: modifier.name,
      }
    }

    /**
     * Processes items for discount based on lowest price (Option C - Buy X Get Y on cheapest items)
     *
     * This function handles discount calculations where the discount should be applied to the cheapest
     * items in a set. It's typically used for "Buy X Get Y" promotions where Y items get discounted,
     * and we want to apply the discount to the Y cheapest items.
     *
     * The function:
     * 1. Sorts items by price (lowest first) with VAT percentage as tie-breaker
     * 2. Validates if items meet discount requirements
     * 3. Calculates how many items qualify for discount
     * 4. Returns IDs of items that should receive the discount
     *
     * @param {Array} filteredItemsOptionC - Array of items that passed initial filtering
     * @param {Object} modifier - Discount modifier object containing rules
     * @param {number} modifier.typeDiscount.buyQuantity - Number of items needed to qualify
     * @param {number} modifier.typeDiscount.getQuantity - Number of items to receive discount
     * @param {Object} modifier.useLimit - Usage limit settings
     *
     * @returns {Array|Object} - Returns either:
     *   - Array of objects with item IDs and indices for discount application
     *   - Object with failure information if requirements aren't met
     *
     * @example
     * Example input
     * const items = [
     *   { id: 'prod1', index: 0, priceNetto: 10.00, percentageVAT: 21 },
     *   { id: 'prod2', index: 1, priceNetto: 15.00, percentageVAT: 21 }
     * ];
     *
     * const modifier = {
     *   typeDiscount: { buyQuantity: 2, getQuantity: 1 },
     *   useLimit: { active: true, number: 3 }
     * };
     *
     * Example output
     * [
     *   { id: 'prod1', index: 0 }
     * ]
     */
    function optionXX(filteredItemsOptionC, modifier) {
      // console.log("filteredItemsOptionC", filteredItemsOptionC);

      // Sort by lowest price first (ascending)
      const sortedByLowestPrice = filteredItemsOptionC.sort((a, b) => {
        // Primary sort by price (ascending)
        const priceDiff = a.priceNetto - b.priceNetto
        if (priceDiff !== 0) return priceDiff

        // Secondary sort by VAT (ascending) as tie-breaker
        return a.percentageVAT - b.percentageVAT
      })

      // console.log("sortedByLowestPrice", sortedByLowestPrice);

      // Validate if the items qualify for discount
      const approvedForDiscount = calculateRatioOptionC(sortedByLowestPrice, buyQuantity, getQuantity, modifier)

      if (approvedForDiscount === true) {
        // Calculate how many items should get the discount
        const getQuantityDiscount = howManyTimesDiscount(sortedByLowestPrice.length, buyQuantity, getQuantity)

        // Get IDs of the lowest priced items that qualify for discount
        const selectedItemIds = sortedByLowestPrice
          .map((item) => {
            return { id: item.id, index: item.index }
          })
          .slice(0, getQuantityDiscount)

        // console.log("Selected items for discount:", {
        // 	numberOfItems: selectedItemIds.length,
        // 	itemIds: selectedItemIds,
        // 	lowestPrice: sortedByLowestPrice[0]?.priceNetto,
        // });

        return selectedItemIds
      } else {
        // Return false if items don't qualify for discount
        return approvedForDiscount
      }

      /**
       * Calculates whether the given items qualify for a discount based on the specified buy and get quantities and the modifier's conditions.
       *
       * @param {Array} sortedItems - An array of items sorted by price and VAT.
       * @param {number} buyQuantity - The number of items required to be purchased to qualify for the discount.
       * @param {number} getQuantity - The number of items to receive at a discounted price.
       * @param {object} modifier - The modifier object containing the discount conditions.
       * @returns {boolean|object} - Returns true if the items qualify for the discount, or an object with a 'fail' property set to true and a 'reason' property if they do not.
       */
      function calculateRatioOptionC(sortedItems, buyQuantity, getQuantity, modifier) {
        let ratio = buyQuantity - 1
        let productAdvice = ''
        let treatmentAdvice = ''
        let bothActive =
          modifier.filterProducts.active === true && modifier.filterTreatments.active === true ? ' en/of ' : ''

        // Check if the number of sorted items is almost enough to qualify for the discount
        if (sortedItems.length < buyQuantity && sortedItems.length >= ratio) {
          // Determine the type of products that need to be added to activate the discount
          if (modifier.filterProducts.active === true) {
            if (modifier.filterProducts.type == 'categories') {
              productAdvice = 'producten binnen dezelfde categorie'
            } else if (modifier.filterProducts.type == 'brands') {
              productAdvice = 'producten binnen hetzelfde merk'
            } else if (modifier.filterProducts.type == 'specific') {
              productAdvice = 'producten met dezelfde specificatie'
            }
          }

          // Determine the type of treatments that need to be added to activate the discount
          if (modifier.filterTreatments.active === true) {
            if (modifier.filterTreatments.type == 'categories') {
              treatmentAdvice = 'behandelingen binnen dezelfde categorie'
            } else if (modifier.filterTreatments.type == 'specific') {
              treatmentAdvice = 'behandelingen met dezelfde specificatie'
            }
          }

          // Add an advisory message indicating how to activate the discount
          advisedModifiers.push({
            reason:
              `De actie is bijna actieff! Activeer deze actie door het volgende toe te voegen. ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld iets anders dan ` +
              sortedItems.map((item) => 'een ' + item.type + ' ' + item.name).join(' of '),
            code: 405,
            modifierName: modifier.name,
          })
          return { fail: true, reason: modifier.name + ' => Discount almost active', code: 406 }
        } else if (sortedItems.length < ratio) {
          // Return a failure if the number of items is below the required ratio
          return { fail: true, reason: modifier.name + ' => Amount below ratio', code: 307 }
        } else if (sortedItems.length >= buyQuantity) {
          // Return true if the number of items is sufficient to qualify for the discount
          return true
        }
      }
    }
  }

  /**
   * Calculates how many times a discount can be applied based on both filtered items and available items for discount
   * @param {number} input - Total number of items available for discount
   * @param {number} buyQuantity - Number of items needed to trigger the discount
   * @param {number} getQuantity - Number of items that get the discount when triggered
   * @returns {number} Number of items that should receive the discount
   */
  function howManyTimesDiscount(input, buyQuantity, getQuantity) {
    // Get number of filtered items that can trigger the discount
    const filteredItemsCount = filteredItems.length

    // Calculate how many complete sets can be triggered based on filtered items
    const possibleSetsByFiltered = Math.floor(filteredItemsCount / buyQuantity)

    // Calculate how many items can receive the discount based on available items
    const possibleSetsByInput = Math.floor(input / getQuantity)

    // Use the minimum of both to ensure we don't exceed either limit
    let possibleSets = Math.min(possibleSetsByFiltered, possibleSetsByInput)

    // Calculate total possible discount items
    let possibleDiscount = possibleSets * getQuantity

    // Apply modifier use limit if active
    if (modifier.useLimit.active === true) {
      possibleDiscount = Math.min(possibleDiscount, modifier.useLimit.number)
    }

    // console.log("Discount calculation:", {
    // 	availableItems: input,
    // 	filteredItems: filteredItemsCount,
    // 	buyQuantity,
    // 	getQuantity,
    // 	possibleSetsByFiltered,
    // 	possibleSetsByInput,
    // 	finalSets: possibleSets,
    // 	useLimit: modifier.useLimit.active ? modifier.useLimit.number : "inactive",
    // 	finalDiscount: possibleDiscount,
    // });

    return possibleDiscount
  }
} //END mixMatchInterpreter

/**
 * Make it Uniq and Validate before calculation
 * @param {object} modifier
 * @param {array} filteredItems
 * OUTPUT: Uniq Array
 */
function validateQuantityDiscount(modifier, filteredItems) {
  //Make it uniq
  let uniqItems = new Set()
  filteredItems.forEach((item) => uniqItems.add(item.id))

  uniqItems = [...uniqItems]

  return checkRatio(uniqItems)

  //Is there enough for giving a quantityDiscount anyway?
  function checkRatio(uniqItems) {
    let acceptedQuantity = new Array()
    uniqItems.forEach((uniqItem) => {
      const getQuantity = selectedItems.filter((item) => item.id === uniqItem).length //?

      //Get the first quantityRule (if possible)
      if (getQuantity >= modifier.typeDiscount.quantityRules[0].number) {
        acceptedQuantity.push(uniqItem)
      }

      if (modifier.typeDiscount.quantityRules.length >= 2) {
        if (
          getQuantity >= modifier.typeDiscount.quantityRules[0].number &&
          getQuantity < modifier.typeDiscount.quantityRules[1].number
        ) {
          const nameQuantities = acceptedQuantity
            .map((itemId) => {
              return selectedItems.find((filterItem) => filterItem.id == itemId).name
            })
            .join(' en / of ') //?
          // selectedItems.filter() uniqWithHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of "),
          advisedModifiers.push({
            reason: `Deze bundel promotie is activeerd, voeg meer van hetzelfde toe bijvoorbeeld een ${nameQuantities} voor meer promotie.`,
            code: 407,
            modifierName: modifier.name,
          })
        }
      }
    })

    acceptedQuantity //?

    if (acceptedQuantity.length !== 0) {
      return acceptedQuantity
    } else {
      return {
        fail: true,
        reason: modifier.name + ' => Not enough quantity for this discount',
        code: 309,
        modifierName: modifier.name,
      }
    }
  }
}

//CALCULATE THE DISCOUNTS
/**
 * Loop thru the get propety of calcDiscountObj
 * @param {object} calcDiscountObj
 */
function calculateDiscount(calcDiscountObj) {
  console.log('calcDiscountObj', calcDiscountObj)
  switch (calcDiscountObj.type) {
    case 'fixedAmount':
      if (calcDiscountObj.repetitive === true) {
        return repetitiveAmountCalc(calcDiscountObj)
      } else if (calcDiscountObj.manualInputDiscount === true && calcDiscountObj.discountType === 'amount') {
        console.log('manualInputDiscount', calcDiscountObj)

        const getFirstIndex = calcDiscountObj.get[0].index //No other index is needed
        const preferredItem = selectedItems[getFirstIndex]

        return manualInputCalc(calcDiscountObj, preferredItem)
      } else if (calcDiscountObj.manualInputDiscount === true && calcDiscountObj.discountType === 'percentage') { 
        console.log('manualInputDiscount', calcDiscountObj)

        const getFirstIndex = calcDiscountObj.get[0].index //No other index is needed
        const preferredItem = selectedItems[getFirstIndex]

        return manualInputCalc(calcDiscountObj, preferredItem)
      } else {
    
        return fixedAmountCalc(calcDiscountObj) //Filter hoogste eerst daar het verschil terug rekenen.
      }

      break

    case 'buyXX':
      // go directly to calculateDiscountType buyXY its already filtered

      /**
       * calcDiscountObj.get look like this:
       * [
       * 	{ id: docIdItemXYZ, index: 0 },
       * ]
       */

      calcDiscountObj.get.forEach((getQuantity) => {
        console.log('getQuantity', getQuantity)
        // get the index of the selectedItem
        let preferredItem = selectedItems[getQuantity.index]
        return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, preferredItem)
      })

      break

    case 'buyXY':
      console.log('calcDiscountObj', calcDiscountObj)

      // go directly to calculateDiscountType buyXY its already filtered

      /**
       * calcDiscountObj.get look like this:
       * [
       * 	{ id: docIdItemXYZ, index: 0 },
       * ]
       */

      calcDiscountObj.get.forEach((getQuantity) => {
        console.log('getQuantity', getQuantity)
        // get the index of the selectedItem
        let preferredItem = selectedItems[getQuantity.index]
        return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, preferredItem)
      })

      break

    case 'quantityDiscount':
      calcDiscountObj.get.forEach((getQuantityId) => {
        iterateHighestPrice(calcDiscountObj, getQuantityId) //Filter hoogste eerst daar het verschil terug rekenen.
      })
      break

    case 'membership':
      calcDiscountObj.get.forEach((getQuantityId) => {
        iterateHighestPrice(calcDiscountObj, getQuantityId) //Filter hoogste eerst daar het verschil terug rekenen.
      })
      break

    default:
      break
  }
}

/**
 * Calculates the remaining amount after applying a manual discount
 * @param {Object} calcDiscountObj - The discount calculation object
 * @param {number} calcDiscountObj.discountValue - The discount value to apply
 * @param {string} calcDiscountObj.discountType - The type of discount ('amount' or 'percentage')
 * @param {Array} calcDiscountObj.get - Array containing item IDs and indexes to apply discount to
 * @returns {Object} Object containing modifierId and remainingAmount
 */
function manualInputCalc(calcDiscountObj) {
  // Get the item we're applying discount to
  const itemIndex = calcDiscountObj.get[0].index
  const item = selectedItems[itemIndex]
  const vatPercentage = item.percentageVAT

  console.log("manualInputCalc", {
    currentPrice: item.newPriceTotal,
    discountType: calcDiscountObj.discountType,
    discountValue: calcDiscountObj.discountValue
  });

  let discountAmountBruto = 0

  // Calculate discount amount (bruto) based on discount type
  if (calcDiscountObj.discountType === 'amount') {
    discountAmountBruto = cashConv(calcDiscountObj.discountValue, 'EUR', 8)
  } else if (calcDiscountObj.discountType === 'percentage') {
    discountAmountBruto = cashConv((item.newPriceTotal * calcDiscountObj.discountValue) / 100, 'EUR', 8)
  }

  // Ensure discount doesn't exceed current price
  discountAmountBruto = Math.min(discountAmountBruto, item.newPriceTotal)

  // Calculate netto discount amount
  const discountAmountNetto = cashConv(
    discountAmountBruto - (discountAmountBruto / (100 + vatPercentage)) * vatPercentage,
    'EUR',
    8
  )

  // Calculate remaining amount (bruto)
  const remainingAmountBruto = cashConv(Math.max(0, item.newPriceTotal - discountAmountBruto), 'EUR', 8)

  // Calculate remaining amount (netto)
  const remainingAmountNetto = cashConv(
    remainingAmountBruto - (remainingAmountBruto / (100 + vatPercentage)) * vatPercentage,
    'EUR',
    8
  )

  // Update the item's prices in selectedItems
  selectedItems[itemIndex].newPriceTotal = remainingAmountBruto
  selectedItems[itemIndex].newPriceTotalNetto = remainingAmountNetto

  // Update discount totals
  selectedItems[itemIndex].calculatedDiscountVAT = cashConv(
    (selectedItems[itemIndex].calculatedDiscountVAT || 0) + discountAmountBruto,
    'EUR',
    8
  )
  selectedItems[itemIndex].calculatedDiscountNetto = cashConv(
    (selectedItems[itemIndex].calculatedDiscountNetto || 0) + discountAmountNetto,
    'EUR',
    8
  )

  // Initialize discountValueByModifiers array if needed
  if (!selectedItems[itemIndex].discountValueByModifiers) {
    selectedItems[itemIndex].discountValueByModifiers = [];
  }

  // Add modifier ID to used modifiers
  selectedItems[itemIndex].usedModifierId.add(calcDiscountObj.modifierId)

  // Add discount info to the item
  selectedItems[itemIndex].discountValueByModifiers.push({
    modifierId: calcDiscountObj.modifierId,
    type: 'modifier',
    vatPercentage: vatPercentage,
    amount: discountAmountBruto,
    amountNetto: discountAmountNetto,
    discountType: calcDiscountObj.discountType,
    discountValue: calcDiscountObj.discountValue,
    manualInputDiscount: true,
    addedVia: 'manualDiscountMenu',
  })

  return {
    modifierId: calcDiscountObj.modifierId,
    remainingAmount: remainingAmountBruto,
  }
}

/**
 * Calculate the till the discountValue runs till zero
 *
 */
function fixedAmountCalc(calcDiscountObj) {
  // console.log("calcDiscountObj", calcDiscountObj);

  //Inverse discount to negative
  let remainingAmount = -Math.abs(calcDiscountObj.discountValue) //?

  if (calcDiscountObj.discountType === 'amount') {
    console.log('calcDiscountObj', calcDiscountObj)
    calcDiscountObj.get.forEach((getQuantityId) => {
      let tmpHighestNewPrice = selectedItems
        .filter((item) => item.id === getQuantityId)
        .sort((a, b) => b.newPriceTotal - a.newPriceTotal) //?
      for (let i = 0; i < tmpHighestNewPrice.length; i++) {
        if (remainingAmount >= 0) break

        //Calculate remainingAmount
        // remainingAmount = (tmpHighestNewPrice[i].newPriceTotal * 10000 + remainingAmount * 10000) / 10000; //?
        remainingAmount = cashConv(tmpHighestNewPrice[i].newPriceTotal + remainingAmount, 'EUR', 4)

        // tmpHighestNewPrice[i].newPriceTotal //?
        // remainingAmount //?
        // tmpHighestNewPrice[i].newPriceTotal - remainingAmount //?
        /**
         * FLOATING POINT WORKAROUND BECAUSE THE NUMBERS GOING NEGATIVE
         */

        if (remainingAmount <= 0) {
          //there is a residual remaining amount left

          let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT
          let calculatedDiscountVAT = cashConv(
            remainingAmount + tmpHighestNewPrice[i].newPriceTotal - remainingAmount,
            'EUR',
            4
          ) //?
          let calculatedDiscountNetto = cashConv(
            calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
            'EUR',
            8
          ) //?

          //Total new Price Bruto
          selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = 0
          //Total new Price Netto
          selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = 0
          //Total given Discount in VAT
          selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT = cashConv(
            selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT + calculatedDiscountVAT,
            'EUR',
            8
          )
          //Total given Discount in Netto
          selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto = cashConv(
            selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto + calculatedDiscountNetto,
            'EUR',
            8
          )
          //Modifier ID
          //Custom Modifier always have a uniqId with 8 Characters
          selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId)
          // selectedItems[tmpHighestNewPrice[i].index].discountValueByModifier.push({modifierId: calcDiscountObj.modifierId, givenDiscount: calcDiscountObj.discountValue, remain: remainingAmount })

          if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
            selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array()

          //

          selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
            modifierId: calcDiscountObj.modifierId,
            type: calcDiscountObj.modifierType,
            vatPercentage: percentageVAT,
            amount: calculatedDiscountVAT,
            amountNetto: calculatedDiscountNetto,
            discountType: calcDiscountObj.discountType,
            discountValue: calcDiscountObj.discountValue,
            manualInputDiscount: calcDiscountObj.manualInputDiscount,
            addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          })

          console.log(
            'remaining < modifierId',
            calcDiscountObj.modifierId,
            'selectedItem Id',
            selectedItems[tmpHighestNewPrice[i].index].id,
            'givenDiscountBTW',
            calculatedDiscountVAT,
            'remain',
            remainingAmount
          )
        }
        if (remainingAmount >= 0) {
          //there is NO a residual amount left, so the modifier is fully used
          //btw = (bedrag incl btw/(100+btw%)) x btw%
          let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT
          let calculatedDiscountVAT = cashConv(tmpHighestNewPrice[i].newPriceTotal - remainingAmount, 'EUR', 8) //?
          let calculatedDiscountNetto = cashConv(
            calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
            'EUR',
            8
          ) //?

          //Total new Price Bruto
          selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = remainingAmount //?
          //Total new Price Netto
          selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = cashConv(
            remainingAmount - (remainingAmount / (100 + percentageVAT)) * percentageVAT,
            'EUR',
            8
          ) //?
          //Total given Discount in VAT
          selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT = cashConv(
            selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT + calculatedDiscountVAT,
            'EUR',
            8
          )
          //Total given Discount in Netto
          selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto = cashConv(
            selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto + calculatedDiscountNetto,
            'EUR',
            8
          )

          //Modifier ID
          //Custom Modifier always have a uniqId with 8 Characters
          selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId)

          if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
            selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array()

          // console.log(
          // 	"calcDiscountObj",
          // 	calcDiscountObj,
          // 	"remaining > modifierId",
          // 	calcDiscountObj.modifierId,
          // 	"selectedItem Id",
          // 	selectedItems[tmpHighestNewPrice[i].index].id,
          // 	"givenDiscountBTW",
          // 	calculatedDiscountVAT,
          // 	"remain",
          // 	remainingAmount
          // );

          selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
            modifierId: calcDiscountObj.modifierId,
            type: calcDiscountObj.modifierType,
            vatPercentage: percentageVAT,
            amount: calculatedDiscountVAT,
            amountNetto: calculatedDiscountNetto,
            discountType: calcDiscountObj.discountType,
            discountValue: calcDiscountObj.discountValue,
            manualInputDiscount: calcDiscountObj.manualInputDiscount,
            addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          })
        }
      }

      // console.log("remainingAmount: ", remainingAmount);
    })
  } //end  amount

  if (calcDiscountObj.discountType === 'percentage') {
    calcDiscountObj.get.forEach((getQuantityId) => {
      // Filter en sorteer items op hoogste prijs
      let itemsToDiscount = selectedItems
        .filter((item) => item.id === getQuantityId)
        .sort((a, b) => b.newPriceTotal - a.newPriceTotal)

      console.log("itemsToDiscount", itemsToDiscount)

      // Bereken korting voor elk item
      itemsToDiscount.forEach((item) => {
        let percentageVAT = item.percentageVAT

        // Bereken korting op basis van huidige newPriceTotal
        let discountAmount = (item.newPriceTotal * calcDiscountObj.discountValue) / 100
        let newPriceTotal = cashConv(item.newPriceTotal - discountAmount, 'EUR', 8)

        // Bereken BTW component van de korting
        let calculatedDiscountVAT = cashConv(discountAmount, 'EUR', 8)
        let calculatedDiscountNetto = cashConv(
          calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item waardes
        selectedItems[item.index].newPriceTotal = newPriceTotal
        selectedItems[item.index].newPriceTotalNetto = cashConv(
          newPriceTotal - (newPriceTotal / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update korting totalen
        selectedItems[item.index].calculatedDiscountVAT = cashConv(
          selectedItems[item.index].calculatedDiscountVAT + calculatedDiscountVAT,
          'EUR',
          8
        )
        selectedItems[item.index].calculatedDiscountNetto = cashConv(
          selectedItems[item.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Voeg modifier ID toe
        selectedItems[item.index].usedModifierId.add(calcDiscountObj.modifierId)

        // Initialize discountValueByModifiers array indien nodig
        if (!selectedItems[item.index].discountValueByModifiers) {
          selectedItems[item.index].discountValueByModifiers = []
        }

        // Voeg korting details toe
        selectedItems[item.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: calculatedDiscountVAT,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
        })
      })
    })
  } //end percentage

  selectedItems[0].calculatedDiscountVAT //?

  return { modifierId: calcDiscountObj.modifierId, remainingAmount: remainingAmount }
}

function repetitiveAmountCalc(calcDiscountObj) {
  if (calcDiscountObj.discountType === 'amount') {
    calcDiscountObj //?
    calcDiscountObj.get.forEach((getQuantityId) => {
      selectedItems.forEach((selectedItem, indexer) => {
        if (selectedItem.id === getQuantityId) {
          //if the selectedItem has the same id as the getQuantityId

          //Note there isn't any highest Item because we will calculate all the selectedItems
          let selectedItem = selectedItems.find((item) => getQuantityId == item.id)

          let discountPrice = selectedItem.newPriceTotal - calcDiscountObj.discountValue
          let percentageVAT = selectedItem.percentageVAT

          if (discountPrice < 0) {
            let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, 'EUR', 8) //?
            let calculatedDiscountNetto = cashConv(
              calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )

            //Total new Price Netto
            selectedItems[indexer].newPriceTotalNetto = 0
            //Total new Price Bruto
            selectedItems[indexer].newPriceTotal = 0
            //Calculated Discount VAT
            selectedItems[indexer].calculatedDiscountVAT = cashConv(
              selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT,
              'EUR',
              8
            )
            //Calculated Discount Netto
            selectedItems[indexer].calculatedDiscountNetto = cashConv(
              selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto,
              'EUR',
              8
            )
            //Modifier ID
            selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId)

            if (!selectedItems[indexer].discountValueByModifiers)
              selectedItems[indexer].discountValueByModifiers = new Array()

            selectedItems[indexer].discountValueByModifiers.push({
              modifierId: calcDiscountObj.modifierId,
              type: calcDiscountObj.modifierType,
              vatPercentage: percentageVAT,
              amount: calculatedDiscountVAT,
              amountNetto: calculatedDiscountNetto,
              discountType: calcDiscountObj.discountType,
              discountValue: calcDiscountObj.discountValue,
              manualInputDiscount: calcDiscountObj.manualInputDiscount,
              addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
            })

            return
          } else {
            let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, 'EUR', 8)
            let calculatedDiscountNetto = cashConv(
              calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )

            //Total new Price Netto
            selectedItems[indexer].newPriceTotalNetto = cashConv(
              discountPrice - (discountPrice / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )
            //Total new Price Bruto
            selectedItems[indexer].newPriceTotal = discountPrice
            //Calculated Discount VAT
            selectedItems[indexer].calculatedDiscountVAT = cashConv(
              selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT,
              'EUR',
              8
            )
            //Calculated Discount Netto
            selectedItems[indexer].calculatedDiscountNetto = cashConv(
              selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto,
              'EUR',
              8
            )

            //Modifier ID
            selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId)

            if (!selectedItems[indexer].discountValueByModifiers)
              selectedItems[indexer].discountValueByModifiers = new Array()

            selectedItems[indexer].discountValueByModifiers.push({
              modifierId: calcDiscountObj.modifierId,
              type: calcDiscountObj.modifierType,
              vatPercentage: percentageVAT,
              amount: calculatedDiscountVAT,
              amountNetto: calculatedDiscountNetto,
              discountType: calcDiscountObj.discountType,
              discountValue: calcDiscountObj.discountValue,
              manualInputDiscount: calcDiscountObj.manualInputDiscount,
              addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
            })

            return
          }
        }
      })
    }) //end get.forEach
  } //end if amount

  //Percentage instead of amount
  if (calcDiscountObj.discountType === 'percentage') {
    calcDiscountObj //?
    calcDiscountObj.get.forEach((getQuantityId) => {
      //Note there isn't any highest Item because we will calculate all the selectedItems
      selectedItems.forEach((selectedItem, indexer) => {
        if (selectedItem.id === getQuantityId) {
          let discountPrice = cashConv(
            selectedItem.newPriceTotal - (selectedItem.priceBruto * calcDiscountObj.discountValue) / 100,
            'EUR',
            8
          )
          let percentageVAT = selectedItem.percentageVAT

          if (discountPrice < 0) {
            let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, 'EUR', 8) //?
            let calculatedDiscountNetto = cashConv(
              calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )

            //Total new Price Netto
            selectedItems[indexer].newPriceTotalNetto = 0
            //Total new Price Bruto
            selectedItems[indexer].newPriceTotal = 0
            //Calculated Discount VAT
            selectedItems[indexer].calculatedDiscountVAT = cashConv(
              selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT,
              'EUR',
              8
            )
            //Calculated Discount Netto
            selectedItems[indexer].calculatedDiscountNetto = cashConv(
              selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto,
              'EUR',
              8
            )
            //Modifier ID
            selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId)

            if (!selectedItems[indexer].discountValueByModifiers)
              selectedItems[indexer].discountValueByModifiers = new Array()

            selectedItems[indexer].discountValueByModifiers.push({
              modifierId: calcDiscountObj.modifierId,
              type: calcDiscountObj.modifierType,
              vatPercentage: percentageVAT,
              amount: calculatedDiscountVAT,
              amountNetto: calculatedDiscountNetto,
              discountType: calcDiscountObj.discountType,
              discountValue: calcDiscountObj.discountValue,
              manualInputDiscount: calcDiscountObj.manualInputDiscount,
              addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
            })
            return
          } else {
            selectedItem.newPriceTotal //?
            let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, 'EUR', 8) //?
            let calculatedDiscountNetto = cashConv(
              calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )

            //Total new Price Netto
            selectedItems[indexer].newPriceTotalNetto = cashConv(
              discountPrice - (discountPrice / (100 + percentageVAT)) * percentageVAT,
              'EUR',
              8
            )
            //Total new Price Bruto
            selectedItems[indexer].newPriceTotal = discountPrice
            //Calculated Discount VAT
            selectedItems[indexer].calculatedDiscountVAT = cashConv(
              selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT,
              'EUR',
              8
            )

            //Calculated Discount Netto
            selectedItems[indexer].calculatedDiscountNetto = cashConv(
              selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto,
              'EUR',
              8
            )

            //Modifier ID
            selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId)

            if (!selectedItems[indexer].discountValueByModifiers)
              selectedItems[indexer].discountValueByModifiers = new Array()

            selectedItems[indexer].discountValueByModifiers.push({
              modifierId: calcDiscountObj.modifierId,
              type: calcDiscountObj.modifierType,
              vatPercentage: percentageVAT,
              amount: calculatedDiscountVAT,
              amountNetto: calculatedDiscountNetto,
              discountType: calcDiscountObj.discountType,
              discountValue: calcDiscountObj.discountValue,
              manualInputDiscount: calcDiscountObj.manualInputDiscount,
              addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
            })
            return
          }
        }
      })
    }) //end get.forEach
  } //end if percentage

  //Free Item
  if (calcDiscountObj.discountType === 'free') {
    calcDiscountObj.get.forEach((getQuantityId) => {
      //Note there isn't any highest Item because we will calculate all the selectedItems
      selectedItems.forEach((selectedItem, indexer) => {
        if (selectedItem.id === getQuantityId) {
          let percentageVAT = selectedItem.percentageVAT

          let calculatedDiscountVAT = selectedItem.newPriceTotal
          let calculatedDiscountNetto = cashConv(
            calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
            'EUR',
            8
          )

          //Total new Price Netto
          selectedItems[indexer].newPriceTotalNetto = 0
          //Total new Price Bruto
          selectedItems[indexer].newPriceTotal = 0
          //Calculated Discount VAT
          selectedItems[indexer].calculatedDiscountVAT = cashConv(
            selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT,
            'EUR',
            8
          )
          //Calculated Discount Netto
          selectedItems[indexer].calculatedDiscountNetto = cashConv(
            selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto,
            'EUR',
            8
          )

          //Modifier ID
          selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId)

          if (!selectedItems[indexer].discountValueByModifiers)
            selectedItems[indexer].discountValueByModifiers = new Array()
          selectedItems[indexer].discountValueByModifiers.push({
            modifierId: calcDiscountObj.modifierId,
            type: calcDiscountObj.modifierType,
            vatPercentage: percentageVAT,
            amount: calculatedDiscountVAT,
            amountNetto: calculatedDiscountNetto,
            discountType: calcDiscountObj.discountType,
            discountValue: calcDiscountObj.discountValue,
            manualInputDiscount: calcDiscountObj.manualInputDiscount,
            addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          })
        }
      })
    }) //end get.forEach
  }

  return { modifierId: calcDiscountObj.modifierId, remainingAmount: 0 }
}

/**
 * Get (highestNewPriceTotal) by highest price and subtract discount. Till the difference is 0
 * @param {*} calcDiscountObj - discount obj
 * @param {*} getQuantityId - get object from discount object (calcDiscountObj)
 * @param {*} difference - amount of difference for recursion
 * @param {boolean} recursion - true or false
 *
 */
function iterateHighestPrice(calcDiscountObj, getQuantityId) {
  //Get the highest newest price left
  let highestNewPriceTotal = Math.max(
    ...selectedItems.filter((PTfilter) => PTfilter.id == getQuantityId).map((element) => element.newPriceTotal)
  )
  // highestNewPriceTotal; //?
  let highestItem = selectedItems.find((item) => getQuantityId == item.id && item.newPriceTotal == highestNewPriceTotal) //?
  // highestItem.name; //?
  return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, highestItem, getQuantityId)
}

/**
 * Identifies and returns the lowest priced item for discount application
 * This function filters items by ID, sorts them by original price (priceBruto),
 * and ensures the item is eligible for a discount.
 *
 * @param {Object} calcDiscountObj - The discount configuration object
 * @param {string} getQuantityId - Product ID to filter items on
 * @param {string} discountGroup - Discount group to filter items on
 * @returns {Object|null} Returns the item to apply discount to, or null if no valid item found
 *
 * @example
 * const discountObj = {
 *   type: 'percentage',
 *   discountValue: 10
 * };
 * const itemId = 'product123';
 * const itemForDiscount = iterateLowestPrice(discountObj, itemId);
 */
function iterateLowestPrice(calcDiscountObj, getQuantityId, discountGroup) {
  console.log('iterateLowestPrice', calcDiscountObj, getQuantityId)
  // Input validation

  // Check if discountGroup is XY
  if (discountGroup === 'XY') {
    // check getQuantity array is empty
    if (calcDiscountObj.get.length === 0) {
      console.error('No product ID provided for discount calculation')
      return null
    }

    // check if there is a type provided
    if (!calcDiscountObj.type) {
      console.error('No type provided for discount calculation, please configure the discount type')
      return null
    }

    // go to calculateDiscountType buyXY
    return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, preferredItem)
  }

  if (!getQuantityId) {
    console.error('No product ID provided for discount calculation')
    return null
  }

  if (!calcDiscountObj || !calcDiscountObj.type) {
    console.error('Invalid discount configuration provided')
    return null
  }

  try {
    // Filter and sort items
    let sortedItems = selectedItems
      .filter((item) => {
        return (
          item.id === getQuantityId &&
          item.newPriceTotal > 0 && // Ensure item still has value for discount
          item.priceBruto > 0
        ) // Ensure item has a valid original price
      })
      .sort((a, b) => {
        // Primary sort by original price (priceBruto)
        const priceDiff = a.priceBruto - b.priceBruto
        if (priceDiff !== 0) return priceDiff

        // Secondary sort by current price if original prices are equal
        return a.newPriceTotal - b.newPriceTotal
      })

    // Get the lowest priced item
    let lowestItem = sortedItems[0]

    if (!lowestItem) {
      console.warn(`No valid items found for discount application with ID: ${getQuantityId}`)
      return null
    }

    //  // Debug logging for transparency
    //  console.log('Selected item for discount:', {
    // 	  name: lowestItem.name,
    // 	  id: lowestItem.id,
    // 	  originalPrice: lowestItem.priceBruto,
    // 	  currentPrice: lowestItem.newPriceTotal,
    // 	  discountType: calcDiscountObj.type,
    // 	  discountValue: calcDiscountObj.discountValue
    //  });

    return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, lowestItem, getQuantityId)
  } catch (error) {
    console.error('Error in iterateLowestPrice:', error)
    return null
  }
}

/**
 * Calculates "buyXX", "buyXY", "quantityDiscount"
 * @param {string} type - discountType "buyXX", "buyXY", "quantityDiscount"
 * @param {object} calcDiscountObj
 * @param {object} preferredItem
 * @param {object} getQuantityId - object discount from function calculateDiscount
 */
function calculateDiscountType(type, calcDiscountObj, preferredItem, getQuantityId) {
  let percentageVAT = preferredItem.percentageVAT

  console.log('calcDiscountObj', calcDiscountObj)
  console.log('preferredItem', preferredItem)

  switch (type) {
    case 'buyXX':
      console.log('calcDiscountObj', calcDiscountObj)

      if (calcDiscountObj.discountType === 'amount') {
        let oldPrice = preferredItem.newPriceTotal
        let discountAmount = calcDiscountObj.discountValue

        // Prevent negative prices - limit discount to current price
        if (discountAmount > oldPrice) {
          discountAmount = oldPrice
        }

        let newPrice = oldPrice - discountAmount
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices
        selectedItems[preferredItem.index].newPriceTotal = newPrice
        selectedItems[preferredItem.index].newPriceTotalNetto = cashConv(
          newPrice - (newPrice / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XX',
        })

        return
      }

      if (calcDiscountObj.discountType === 'percentage') {
        let oldPrice = preferredItem.newPriceTotal

        let percentage = calcDiscountObj.discountValue
        let discountAmount = oldPrice * (percentage / 100)

        // Prevent negative prices
        if (discountAmount > oldPrice) {
          discountAmount = oldPrice
        }

        let newPrice = oldPrice - discountAmount
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices
        selectedItems[preferredItem.index].newPriceTotal = newPrice
        selectedItems[preferredItem.index].newPriceTotalNetto = cashConv(
          newPrice - (newPrice / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XX',
        })

        return
      }

      if (calcDiscountObj.discountType === 'free') {
        let oldPrice = preferredItem.newPriceTotal
        let discountAmount = oldPrice // For free items, discount = full price
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices - set to 0 for free items
        selectedItems[preferredItem.index].newPriceTotal = 0
        selectedItems[preferredItem.index].newPriceTotalNetto = 0

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XX',
        })

        return
      }
      break

    case 'buyXY':
      console.log('calcDiscountObj', calcDiscountObj)
      console.log('preferredItem', preferredItem.index)

      // let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
      if (calcDiscountObj.discountType === 'amount') {
        let oldPrice = preferredItem.newPriceTotal
        let discountAmount = calcDiscountObj.discountValue

        // Prevent negative prices - limit discount to current price
        if (discountAmount > oldPrice) {
          discountAmount = oldPrice
        }

        let newPrice = oldPrice - discountAmount
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices
        selectedItems[preferredItem.index].newPriceTotal = newPrice
        selectedItems[preferredItem.index].newPriceTotalNetto = cashConv(
          newPrice - (newPrice / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XY',
        })

        return
      }

      if (calcDiscountObj.discountType === 'percentage') {
        let oldPrice = preferredItem.newPriceTotal
        let percentage = calcDiscountObj.discountValue
        let discountAmount = oldPrice * (percentage / 100)

        // Prevent negative prices
        if (discountAmount > oldPrice) {
          discountAmount = oldPrice
        }

        let newPrice = oldPrice - discountAmount
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices
        selectedItems[preferredItem.index].newPriceTotal = newPrice
        selectedItems[preferredItem.index].newPriceTotalNetto = cashConv(
          newPrice - (newPrice / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XY',
        })

        return
      }

      if (calcDiscountObj.discountType === 'free') {
        let oldPrice = preferredItem.newPriceTotal
        let discountAmount = oldPrice // For free items, discount = full price
        let percentageVAT = selectedItems[preferredItem.index].percentageVAT

        // Calculate netto discount amount
        let calculatedDiscountNetto = cashConv(
          discountAmount - (discountAmount / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )

        // Update item prices - set to 0 for free items
        selectedItems[preferredItem.index].newPriceTotal = 0
        selectedItems[preferredItem.index].newPriceTotalNetto = 0

        // Update discount totals
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + discountAmount,
          'EUR',
          8
        )
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )

        // Register modifier
        selectedItems[preferredItem.index].usedModifierId.add(calcDiscountObj.modifierId)
        if (!selectedItems[preferredItem.index].discountValueByModifiers) {
          selectedItems[preferredItem.index].discountValueByModifiers = []
        }

        selectedItems[preferredItem.index].discountValueByModifiers.push({
          modifierId: calcDiscountObj.modifierId,
          type: calcDiscountObj.modifierType,
          vatPercentage: percentageVAT,
          amount: discountAmount,
          amountNetto: calculatedDiscountNetto,
          discountType: calcDiscountObj.discountType,
          discountValue: calcDiscountObj.discountValue,
          manualInputDiscount: calcDiscountObj.manualInputDiscount,
          addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
          discountGroup: 'XY',
        })

        return
      }

      break

    case 'quantityDiscount':
      //HIGHEST
      //Check range of quantityRules
      let getQuantity = selectedItems.filter((item) => item.id === getQuantityId).length
      //Iterate thru discount rules and filter / find by total founded quantity

      let discountByRule = new Number()

      /**
       * Check if there is any rule to match equal for the quantitiy (A)
       * Sort by closest (B) and then get the lowest amount.
       * Because we do not want to give too much discount by itself:)
       * So from the sorted rules we will pick the lowest. (C)
       */

      //A
      if (calcDiscountObj.quantityRules.some((rule) => rule.number === getQuantity)) {
        discountByRule = calcDiscountObj.quantityRules.find((rule) => rule.number >= getQuantity).discount
      } else {
        //B
        discountByRule = calcDiscountObj.quantityRules.sort((a, b) => {
          return Math.abs(getQuantity - a.number) - Math.abs(getQuantity - b.number)
        })
        //C
        discountByRule = Math.min(...discountByRule.slice(0, 2).map((rule) => rule.number))
      }

      discountByRule //?

      selectedItems.forEach((itemIterate) => {
        if (itemIterate.id === getQuantityId) {
          if (calcDiscountObj.discountType === 'amount') {
            //If range is found

            let calcQuantityDiscount = itemIterate.newPriceTotal - discountByRule //?

            if (calcQuantityDiscount < 0) {
              let calculatedDiscountVAT = cashConv(calcQuantityDiscount + discountByRule, 'EUR', 8)
              let calculatedDiscountNetto = cashConv(
                calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )
              //Total new Price Netto
              selectedItems[itemIterate.index].newPriceTotalNetto = 0
              //Total new Price Bruto
              selectedItems[itemIterate.index].newPriceTotal = 0
              //Calculated Discount VAT
              selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT,
                'EUR',
                8
              )
              //Calculated Discount Netto
              selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto,
                'EUR',
                8
              )
              //Modifier ID
              selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId)

              if (!selectedItems[preferredItem.index].discountValueByModifiers)
                selectedItems[preferredItem.index].discountValueByModifiers = new Array()

              selectedItems[preferredItem.index].discountValueByModifiers.push({
                modifierId: calcDiscountObj.modifierId,
                type: calcDiscountObj.modifierType,
                vatPercentage: percentageVAT,
                amount: calculatedDiscountVAT,
                amountNetto: calculatedDiscountNetto,
                discountType: calcDiscountObj.discountType,
                discountValue: calcDiscountObj.discountValue,
                manualInputDiscount: calcDiscountObj.manualInputDiscount,
                addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
                discountGroup: 'quantity',
              })

              return
            } else {
              let calculatedDiscountVAT = discountByRule //?
              let calculatedDiscountNetto = cashConv(
                calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )

              //Total new Price Netto
              selectedItems[itemIterate.index].newPriceTotalNetto = cashConv(
                calcQuantityDiscount - (calcQuantityDiscount / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )
              //Total new Price Bruto
              selectedItems[itemIterate.index].newPriceTotal = calcQuantityDiscount
              //Calculated Discount VAT
              selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT,
                'EUR',
                8
              )
              //Calculated Discount Netto
              selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto,
                'EUR',
                8
              )
              //Modifier ID
              selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId)

              selectedItems[preferredItem.index].discountValueByModifiers.push({
                modifierId: calcDiscountObj.modifierId,
                type: calcDiscountObj.modifierType,
                vatPercentage: percentageVAT,
                amount: calculatedDiscountVAT,
                amountNetto: calculatedDiscountNetto,
                discountType: calcDiscountObj.discountType,
                discountValue: calcDiscountObj.discountValue,
                discountGroup: 'quantity',
              })

              return
            }
          }

          if (calcDiscountObj.discountType === 'percentage') {
            let calcQuantityDiscount = itemIterate.newPriceTotal - (itemIterate.priceBruto * discountByRule) / 100 //?

            if (calcQuantityDiscount < 0) {
              itemIterate.newPriceTotal
              let calculatedDiscountVAT = itemIterate.newPriceTotal
              let calculatedDiscountNetto = cashConv(
                calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )

              //Total new Price Netto
              selectedItems[itemIterate.index].newPriceTotalNetto = 0
              //Total new Price Bruto
              selectedItems[itemIterate.index].newPriceTotal = 0
              //Calculated Discount VAT
              selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT,
                'EUR',
                8
              )
              //Calculated Discount Netto
              selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto,
                'EUR',
                8
              )
              //Modifier ID
              selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId)

              if (!selectedItems[preferredItem.index].discountValueByModifiers)
                selectedItems[preferredItem.index].discountValueByModifiers = new Array()

              selectedItems[preferredItem.index].discountValueByModifiers.push({
                modifierId: calcDiscountObj.modifierId,
                type: calcDiscountObj.modifierType,
                vatPercentage: percentageVAT,
                amount: calculatedDiscountVAT,
                amountNetto: calculatedDiscountNetto,
                discountType: calcDiscountObj.discountType,
                discountValue: calcDiscountObj.discountValue,
                manualInputDiscount: calcDiscountObj.manualInputDiscount,
                addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
                discountGroup: 'quantity',
              })

              return
            } else {
              let calculatedDiscountVAT = (itemIterate.newPriceTotal * discountByRule) / 100
              let calculatedDiscountNetto = cashConv(
                calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )

              //Total new Price Netto
              selectedItems[itemIterate.index].newPriceTotalNetto = cashConv(
                calcQuantityDiscount - (calcQuantityDiscount / (100 + percentageVAT)) * percentageVAT,
                'EUR',
                8
              )
              //Total new Price Bruto
              selectedItems[itemIterate.index].newPriceTotal = calcQuantityDiscount
              //Calculated Discount VAT
              selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT,
                'EUR',
                8
              )
              //Calculated Discount Netto
              selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(
                selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto,
                'EUR',
                8
              )
              //Modifier ID
              selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId)

              if (!selectedItems[preferredItem.index].discountValueByModifiers)
                selectedItems[preferredItem.index].discountValueByModifiers = new Array()

              selectedItems[preferredItem.index].discountValueByModifiers.push({
                modifierId: calcDiscountObj.modifierId,
                type: calcDiscountObj.modifierType,
                vatPercentage: percentageVAT,
                amount: calculatedDiscountVAT,
                amountNetto: calculatedDiscountNetto,
                discountType: calcDiscountObj.discountType,
                discountValue: calcDiscountObj.discountValue,
                manualInputDiscount: calcDiscountObj.manualInputDiscount,
                addedVia: calcDiscountObj.addedVia ? calcDiscountObj.addedVia : null,
                discountGroup: 'quantity',
              })

              return
            }
          }
        }
      })

      break

    case 'membership':
      if (calcDiscountObj.discountType === 'free') {
        let calculatedDiscountVAT = preferredItem.newPriceTotal
        let calculatedDiscountNetto = cashConv(
          calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT,
          'EUR',
          8
        )
        //Total new Price Netto
        selectedItems[preferredItem.index].newPriceTotalNetto = 0
        //Total new Price Bruto
        selectedItems[preferredItem.index].newPriceTotal = 0
        //Total given Discount in VAT
        selectedItems[preferredItem.index].calculatedDiscountVAT = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountVAT + calculatedDiscountVAT,
          'EUR',
          8
        )
        //Total given Discount in Netto
        selectedItems[preferredItem.index].calculatedDiscountNetto = cashConv(
          selectedItems[preferredItem.index].calculatedDiscountNetto + calculatedDiscountNetto,
          'EUR',
          8
        )
        //Modifier ID

        selectedItems[preferredItem.index].usedMembershipId.add(calcDiscountObj.modifierId)
        return
      }

      break

    default:
      break
  }
}

//Debug Info
// selectedItems[0].newPriceTotal; //?
// selectedItems[0].calculatedDiscountVAT; //?
// selectedItems[0].name; //?
// selectedItems[0].newPriceTotal; //?
// selectedItems[1].name; //?
// selectedItems[2].newPriceTotal; //?
// selectedItems[2].name; //?
// selectedItems[3].newPriceTotal; //?
// selectedItems[3].name; //?
