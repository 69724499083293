
	import { bus } from "../main";
	import lodash from "lodash";
	import moment, { relativeTimeRounding } from "moment";
	import axios from "axios";
	import db from "../firebase/init";
	import firebase from "firebase/app";
	import "firebase/firestore";
	import { membershipValidation } from "../modules/memberships";
	import { reCalculateLoyalty } from "../modules/loyalty";
	import { scanDiscounts, quickValidate } from "../modules/POS";
	import { receiptBuilder } from "@/modules/receipt";


	import { deepCopy, cashConv, formatToCurrency, formatToCleanNumber } from "../modules/quickWins";
	import uniqid from "uniqid";
	import algoliasearch from "algoliasearch/lite";
	import "instantsearch.css/themes/satellite-min.css";
	import CustomerInformationDialog from "../components/modals/CustomerInformationDialog.vue";
	import membershipInformation from "./membershipInformation.vue";
	import membershipInformationDialog from "./membershipInformationDialog.vue";
	import AddClientCard from "../components/modals/AddClientCard.vue";
	import companySelector from "@/components/elements/companySelector.vue";
	import { generateMeshAvatar } from "@/modules/avatarGen.js";
	import Insights from '@/modules/insights.js'
	import aa from 'search-insights';
	import clientSearch from "@/components/elements/clientSearch.vue";
	import ConnectToWebUSB from '@/components/modals/connectToWebUSB.vue';
	import WebUSBReceiptPrinter from '@/modules/webusb-receipt-printer.esm.js';
	// import print from "print-js";

	export default {
				
		name: "Pay",
		modules: { membershipValidation },
		components: {
			"membership-information": membershipInformation,
			"membership-information-dialog": membershipInformationDialog,
			"customer-information-dialog": CustomerInformationDialog,
			"add-client-form" : AddClientCard,
			clientSearch: clientSearch,
			companySelector: companySelector,
			Insights: Insights,
			ConnectToWebUSB: ConnectToWebUSB
		},

		data() {
			return {
				selectedPrinter: null,
				inputTimer: null,
				today: moment().format("dddd D MMMM YYYY"),
				now: moment().format("YYYY-MM-DD"),

				paymentTable: new Object(),
				searchCompanies: "",
				algoSearch: algoliasearch(
					"B4XU2Z5BAW", 
					"a29d0e9160e36dc8ee83d5888c28cdcc", 
					this.$store.state.activeCompany.id
				),
				idCopy: false, 

				snackbar: {
					show: false,
					color: 'error',
					text: '',
					timeout: 3000
				},

				// algoSearch: {
				// 	apiKey: "a29d0e9160e36dc8ee83d5888c28cdcc",
				// 	applicationID: "B4XU2Z5BAW",
				// 	search(requests) {  }
				// },

				//Discount Module
				usedModifiers: new Array(),
				usedModifiersReceipt: new Array(),
				deletedModifiers: new Array(),
				advicedModifiers: new Array(),
				feedbackArray: new Array(),
				feedbackHistory: false,
				customModifiers: new Array(),
				deletedSelfGeneratedItems: new Array(),
				posLocation: localStorage.getItem("posLocation") ? JSON.parse(localStorage.getItem("posLocation")) : {active: true, name: null, id: "general", deleted: false},
				combinedModifiers: new Array(),
				activeTab: "pay",
				tab: 0,
				sidebarTab: 0,
				tabTransition: false,
				clientReceiptDataLoading: false,
				loadingDataTable: false,
				loadMemberships: false,
				selfGeneratedReceipt: false,
				snapshotListenerloadBookingSummarys: null,
				snapshotListenerloadReceipts: null,
				componentKey: 0,
				tempReceiptKey: 0, //auto refresh new temps
				resources: new Array(),
				step: 1,
				productSearchh: null, //LEGACY
				treatmentSearchh: null, //LEGACY
				companies: new Array(),
				preferredPaymentDevice: null, //First preferedd Payment Option as ID
				dataloaded: false,
				branches: new Array(),

				indexedClients: null,
				indexedClientsFound: new Array(),
				searchClientLegacy: "",
				loaderSearch: false,
				clientnameReceipt: "",
				selectedCashRegister: "general", // Selected register of branch 
				productCategories: new Array(),
				selectedCategoryProducts: new Array(), //Sorted Catogory by Supplier
				productSuppliers: new Array(),
				
				selectedSupplier: "", //Selected Supplier
				selectedSupplierGroup: "",
				selectedSupplierCategory: new Array(), //Categories selected and renderd by Supplier
				selectedSupplierGroups: new Array(), //Categories selected and renderd by Supplier
				productsFiltered: new Array(),
				productsByBranch: new Array(),
				productsByBranchClone: new Array(),
				treatmentsByBranch: new Array(),
				treatmentsByBranchClone: new Array(),
				customRuleByBranch: new Array(),
				customRuleByBranchClone: new Array(),

				filterType: 'customTreatmentType', // Kan later ook category zijn
				treatmentTypes: new Array(),
				treatmentsFiltered: new Array(),
				selectedTreatmentType: null,

				// selectedReceiptId: null,
				selectedItems: new Array(),
				clientId: "", //For Selected ClientId
				clientLoyaltyDocs: new Array(),
				searchboxLabel: "Zoek een klant",
				selectedReceipt: null,
				selectedReceiptId: "", //For updating Drafts
				localBookingsummary: new Array(),
				clients: new Array(), //Unpaid clients
				localBookings: new Array(),
				resources: new Array(), //Branch resources
				byResource: null,
				resourcesChecker: new Array(), //Update with the newest Resource movements
				localReceiptDrafts: new Array(),
				headerReceiptDrafts: new Array(),
				headerReceiptDraftsSearch: new Array(),
				isSearchingHeader: false,
				
				tmpReceiptData: null,
				customRuleOption: 0,

				anonymousClient: {
					dialog: false,
					paymentType: null,
				},

				selectedPrinter: null,
				printerStatus: null,
				printerStateReason: {
					printerStateReasons: [
						{
							reason: "Printer is not installed",
							reasonCode: "printer-not-installed",
							code: 0,
						},
					],
				},
				printAndMailDialog: {
					loaderSettings: true,
					busy: false,
					dialog: false,
					printerReady: false,
					options: new Array(), //printer options in payReceipt() and supplemented in checkPrinterStatus();
					// options: [
					// 	{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
					// 	{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
					// 	{ name: {nl: "Bon<br> <strong>printen</strong>", en: "<strong>Print</strong><br> receipt"}, value: "printReceipt", icon:  "mdi-receipt", color: null },
					// 	{ name: {nl: "Bon <strong>printen</strong><br> en <strong>mailen</strong>", en: "<strong>Print</strong> and <strong>mail</strong> receipt"}, value: "printEmailReceipt", icon:  "mdi-receipt-text-plus", color: null },
									
					// ],
					chosenReceiptOption: "emailReceipt",
				},
				noPaymentOptionsDialog: false,
				toggleProductTreat: false,
				indexer: null, //Indexer selectedItem for modified posInputDiscount
				expander: false,
				modifiers: new Array(),
				modifiersClone: new Array(),
				selectedType: "",
				bookingSumArr: new Array(), //Summary of client
				chosenTreatInvents: new Array(), //All chosen Treatments and inventory
				totalNetto: 0,
				clientSelectedPage: 1,
				totalBruto: 0,
				clientLoading: true,
				totalDiscountVAT: 0,
				totalBrutoWithoutDiscount: 0, //Total price orignial without discounts
				totalVAT: 0,
				totalBrutoTwoDigits: 0, //Total price with 2 digits after comma for Pay Dialog
				totalVats: new Array(),
				totalBrutoAbsolute: 0,
				changeBrutoToClient: 0, //For Refund Cash
				changeForRefund: 0, //Change for Refund
				typeDiscount: ["Percentage", "Bedrag"],

				sortDiscountOrder: [
					{ priorityNumber: 3, typeDiscount: "membershipDiscount" },
					{ priorityNumber: 0, typeDiscount: "prePayment" },
					{ priorityNumber: 1, typeDiscount: "prePaymentDiscount" },
					{ priorityNumber: 2, typeDiscount: "prePaymentManualAdded" },
					{ priorityNumber: 4, typeDiscount: "discount" },
				],
				//Custom POS Rule

				customDialog: false,
				customRule: {
					name: "",
					description: "",
					priceBruto: 0, //bruto,

					amount: 1,
					resourceId: null,
					vat: 0,
				},
				differenceCash: 0,
				differencePin: 0,
				differenceCreditcard: 0,
		

				scanDiscountDialog: false,
				scanDiscountDialogObject: {
					barcode: null,
				},
				discountBarcode: null,
				payDialog: false,
				expensesDialog: false,
				depositDialog: false,
				cashUpDialog: false,
				cashUpFeedback: { box: null, message: null, loading: false },
				cashUpSumCash: 0,
				cashUpSumTotal: 0,
				cashUpSumByCard: {
					byPIN: 0,
					byCreditcard: 0,
				},

				lastCashUpTimestamp: null,
				latestCashUpCash: 0,
				latestCashRounding: 0,
				cashStatementStayManualChanged: false,

				paidReceiptsCashUp: new Array(),

				cashStatement: 0,
				cashStatementStay: {
					value: 0,
					feedback: "",
					error: false,
					depositMoneyToBank: 0,
				},

				pinIncomeStatement: 0,
				cashIncomeStatement: 0,
				cardStatement: 0,
				creditcardIncomeStatement: 0,

				cashUpDifference: 0,
				cashUpDepositToBank: 0,
				viewReceiptData: {
					index: null,
					data: null,
					dialog: false,
					loading:false
				},
				itemsPerPage: -1,
				historyPayments: new Array(),
				historyPaymentsDate: null,
				historyPaymentsDateModal: false,
				receiptsLoading: true,
				viewReceiptDataHeader: [
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "left",
						value: "id",
						sortable: false, 
					},
					{
						text: this.$t('general.type', this.$store.state.locale),
						align: "left",
						value: "typeItem",
						width: "100px",
						sortable: false, 
					},
					{
						text: this.$t('general.vat', this.$store.state.locale),
						align: "left",
						value: "percentageVat",
						width: "80px",
						sortable: false, 
					},
					{
						text: this.$t('payment.transaction', this.$store.state.locale),
						align: "left",
						value: "transaction",
						width: "70px",
						sortable: false,
					},
					// {
					// 	text: "Originele prijs ex. BTW",
					// 	align: "left",
					// 	value: "priceNetto",
					// 	width: "100px",
					// 	sortable: false, 
					// },
					// {
					// 	text: "Originele prijs incl. BTW",
					// 	align: "left",
					// 	value: "priceBruto",
					// 	width: "100px",
					// 	sortable: false, 
					// },
					// {
					// 	text: this.$t('payment.newPriceTotalNetto', this.$store.state.locale),
					// 	align: "right",
					// 	value: "newPriceTotalNetto",
					// 	width: "200px",
					// 	sortable: false, 
					// },
					{
						text: this.$t('payment.newPriceTotalBruto', this.$store.state.locale),
						align: "right",
						value: "newPriceTotalBruto",
						width: "200px",
						sortable: false, 
					},
				],
				viewExpenseDataHeader: [
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "left",
						value: "id",
						sortable: false, 
					},
					{
						text: this.$t('general.vat', this.$store.state.locale),
						align: "left",
						value: "percentageVat",
						width: "80px",
						sortable: false, 
					},
					{
						text: this.$t('payment.transaction', this.$store.state.locale),
						align: "left",
						value: "transaction",
						width: "70px",
						sortable: false,
					},
					{
						text: this.$t('payment.newPriceTotalBruto', this.$store.state.locale),
						align: "right",
						value: "newPriceTotalBruto",
						width: "200px",
						sortable: false, 
					},
				],
				historyPaymentsHeader: [
				{
					text: this.$t('general.date', this.$store.state.locale),
					align: "left",
					value: "created",
					width: "130px",
					sortable: false, 
				},
				{
					text: this.$t('general.time', this.$store.state.locale),
					align: "left",
					value: "created",
					width: "80px",
					sortable: false, 
				},
				{
					text: this.$t('payment.customer', this.$store.state.locale),
					align: "left",
					value: "created",
					sortable: false, 
				},
				{
					text: this.$t('general.employee', this.$store.state.locale),
					align: "left",
					value: "created",
					width: "200px",
					sortable: false, 
				},
				{
					text: this.$t('payment.method', this.$store.state.locale),
					align: "left",
					value: "created",
					width: "140px",
					sortable: false, 
				},
				{
					text: this.$t('general.amount', this.$store.state.locale),
					align: "right",
					value: "month",
					width: "150px",
					sortable: false, 
				},
				{
					text: "",
					align: "center",
					width: "40px",
					sortable: false, 
				},
				{
					text: "",
					align: "center",
					width: "40px",
					sortable: false, 
				}
				],

				mergeReceiptDialog: {
					dialog: false,
					loading: false,
					selectedReceipts: new Array(),
				},

				cashUp: {
					oneEuroCent: 0,
					twoEuroCent: 0,
					fiveEuroCent: 0,
					tenEuroCent: 0,
					twentyEuroCent: 0,
					fiftyEuroCent: 0,
					oneEuro: 0,
					twoEuro: 0,
					fiveEuro: 0,
					tenEuro: 0,
					twentyEuro: 0,
					fiftyEuro: 0,
					hundredEuro: 0,
					twoHundredEuro: 0,
					fiveHundredEuro: 0,
					pin: 0,
					creditcard: 0,
				},

				expenses: {
					chosenTypeExpenses: "",
					typeExpenses: [
						{ id: "other", ledgerNumber: null },
						{ id: "canteenExpense", ledgerNumber: null },
						{ id: "cleaningSupplies", ledgerNumber: null },
						{ id: "lecture", ledgerNumber: null },
						{ id: "officeExpense", ledgerNumber: null },
						{ id: "stamps", ledgerNumber: null },
						{ id: "interiorCost", ledgerNumber: null },
						{ id: "advertisementCost", ledgerNumber: null },
						{ id: "mealAllowance", ledgerNumber: null },
						{ id: "travelReimbursement", ledgerNumber: null },
						{ id: "parkingFee", ledgerNumber: null },
						{ id: "otherPersonnelExpense", ledgerNumber: null },
						{ id: "privateWithdrawel", ledgerNumber: null },
						{ id: "depositToBank", ledgerNumber: null },
						{ id: "cashDifference", ledgerNumber: null },
					],
					amountValue: 0,
					chosenVat: "",
					loading: false,
					feedback: null,
					name: null,
				},
				expensesOrginal: {
					chosenTypeExpenses: "",
					amountValue: 0,
					chosenVat: "",
				},
				deposit: {
					//Add cash
					namePayInto: "",
					payInto: 0,
					loading: false,
					feedback: null,
				},
				depositOrg: {
					namePayInto: "",
					payInto: 0,
					loading: false,
					feedback: null,
				},
				payResourceReady: false,
				transactionsNumber: 0,
				change: 0,
				displayChange: "--", //Display International
				changeCalculated: null,	
				displayChangeCalculated: "--", //Display International
				typePayment: "PIN",
				splitPayment: {
					doneSteps: false,
					transactionId: null,
					splitByCash: 0,
					splitByCard: 0,
					splitActive: false,
				},
				availablePayDevices: new Array(), //
				paymentInfo: {
					nextAppointment: false, //for making a follow-up appointment
					mailReceipt: true,
					chosenPaymentDevice: null,
				},
				delayNumpadCount: 0,
				delayNumpad: 0,
				numpadDot: false,
				typePayment: "",
				clientSearch: "",
				clientSearchFieldFocus: false,
				productSearch: "",
				productSearchFieldFocus: false,
				treatmentSearch: "",
				
				treatmentSearchFieldFocus: false,
				selectedClientData: {},

				//Memberships
				companyMembershipSearch: "",
				companyMemberships: new Array(),
				companyMembershipsClone: new Array(),
				userMemberships: new Array(),
				validForUseMemberships: new Array(),
				notValidForUseMemberships: new Array(),
				selectedMembershipInformation: {
					dialog: false,
					data: new Object()
				},
            	clientMemberships: new Array(),


				//prePayments

				prePayments: new Array(),
				clientPrePayments: new Array(),
				refund: false,

				historyReceipts: new Array(),

				historyHeader: [
					{
						text: this.$t('general.type', this.$store.state.locale),
						align: "left",
						sortable: false,
						width: "16.67%",
						value: "visit",
					},
					{
						text: this.$t('general.date', this.$store.state.locale),
						align: "left",
						width: "33,33%",
						sortable: false,
					},
					{
						text: this.$t('general.amount', this.$store.state.locale),
						align: "left",
						sortable: false,
						width: "16.67%",
					},
					{
						text: "",
						align: "right",
						sortable: false,
						width: "25%",
						value: "",
					},
					{
						text: "",
						align: "right",
						sortable: false,
						width: "8.33%",
						value: "",
					},
				],

				prePaymentHeader: [
					{
						text: this.$t('general.type', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "visit",
					},
					{
						text: this.$t('general.date', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "created",
					},
					{
						text: this.$t('general.amount', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "created",
					},
					{
						text: this.$t('payment.status', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "created",
					},
					{
						text: "",
						align: "left",
						sortable: false,
						value: "created",
					},
				],

				clientFutureHeader: [
					{
						text: this.$t('general.date', this.$store.state.locale),
						align: "left",
						sortable: true,
						width: "250px",
						value: "visit",
					},
					{
						text: this.$t('general.treatment', this.$store.state.locale),
						align: "left",
						sortable: true,
						value: "name",
					},
					{
						text: this.$t('general.branch', this.$store.state.locale),
						align: "left",
						sortable: true,
						width: "250px",
						value: "resourceId",
					},
				],

				membershipHeader: [
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "id",
					},

					{ 
						text: this.$t('general.startDate', this.$store.state.locale),
						value: 'start',
						width: "160px"
					},
					{ 
						text: this.$t('memberships.expiresOn', this.$store.state.locale),
						value: 'expireDate',
						width: "180px"
					},
					{ 
						text: this.$t('memberships.usageNumber', this.$store.state.locale),
						value: 'usageNumber',
						width: "100px"
					},
					{ 
						text: this.$t('memberships.usageLeft', this.$store.state.locale),
						value: 'usageLeft',
						width: "100px"
					},
					{
						text: "",
						align: "right",
						sortable: false,
						width: "150px",
					},
				],

				paymentHeader: [
					{
						text: this.$t('general.remove', this.$store.state.locale),
						align: "left",
						sortable: false,
						width: "40px",
						value: "remove",
					},
					{
						text: this.$t('payment.content', this.$store.state.locale),
						align: "left",
						sortable: false,
						value: "content",
					},
					{
						text: this.$t('payment.collapse', this.$store.state.locale),
						align: "left",
						sortable: false,
						width: "40px",
						value: "collapse",
					},
				],

				headers: [
					{
						text: "ID",
						align: "left",
						sortable: false,
						value: "resourceId",
					},
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "left",
						value: "resourceName",
						sortable: false,
					},
				],
				pagination: {
					rowsPerPage: "-1",
					sortBy: "visit",
					descending: true,
				},
				paymentFloat: false,
				customDiscount: {
					modal: false,
					filterProducts: {
						active: false,
						items: null,
					},

					combinationLimit: true,
					deleted: false,
					name: "Korting",
					description: this.$t('payment.manualAddedDiscount', this.$store.state.locale),
					manualInputHigherThanBruto: false,
					dateLimit: {
						active: false,
						endDate: null,
						startDate: null,
					},
					numberLimit: {
						active: false,
						number: null,
						numberUsed: null,
						type: "max",
					},
					typeDiscount: {
						custom: true,
						type: "fixedAmount",
						discountValue: null,
						discountType: "amount",
					},
					feedback: null,
					type: "all",
					filterTreatments: {
						active: false,
						items: null,
					},
				},
				feedbackPayDialog: {
					feedback: null,
					code: null,
				},

				refundBoolean: false,
				receiptMenu: false,
				receiptExtra: false,
				refundMenu: false,

				paymentAddItemsHeight: 550,

				typeDiscountTypes: [
					{
						name: this.$t('general.amount', this.$store.state.locale),
						id: "amount",
					},
					{
						name: this.$t('payment.percentageData', this.$store.state.locale),
						id: "percentage",
					},
				],
				prepaymentTypes: [
					{
						name: this.$t('payment.downPayment', this.$store.state.locale),
						id: "downPayment",
					},
					{
						name: this.$t('payment.prePayment', this.$store.state.locale),
						id: "prePayment",
					},
				],

				prepaymentReturnTypes: [
					{
						name: this.$t('payment.refundData', this.$store.state.locale),
						id: "refund",
					},
					{
						name: this.$t('payment.settled', this.$store.state.locale),
						id: "settled",
					},
					{
						name: this.$t('payment.compensation', this.$store.state.locale),
						id: "compensation",
					},
					{
						name: this.$t('payment.partialSettled', this.$store.state.locale),
						id: "partialSettled",
					},
				],
				selectedMembership: {
					dialog: false,
					info: {
						name: new Object(),
						posDescription: new Object(),
					},
				},
				paymentWrapperWidth: 0,
				selectedClientName: null,
				customerDialog: false,
				settings: {
					searchParameters: {
						customRanking: ['desc(weight)'],
						ranking: "custom" // Jouw gewenste ranking instellingen
					// Andere instellingen hier
					},
					customRanking: ['desc(weight)'],
					// ranking: "custom" 
					ranking: ['desc(weight)']
					
					// customRanking: ['desc(branchWeight.branchIdOfVFioWmJ4Vt7ytW3EEx)'],
				// ranking: 'custom'
				},
				productSearchFilters: 'deleted.deleted:false OR NOT deleted.deleted:true',
				treatmentSearchFilters: 'deleted.deleted:false OR NOT deleted.deleted:true'
			}
		},

		created() {
			this.loadData();
			
			window.addEventListener("resize", this.getScrollInformation);
			this.getScrollInformation;

			this.historyPaymentsDate = moment().format("YYYY-MM-DD");

			bus.$on('selectClientForPayment', (client)=>{
				client.id = client.id ? client.id : client.objectID
				this.getSelectedClientData(client.id, `${client.name} ${client.surname}`)
				this.clientId = client.id
			});

			bus.$on('deleteReceipt', (id)=>{
				this.deleteReceipt(id)
			});

			// this.connectToPrinter();

			
		},

		mounted() {
			bus.$on("barcodeScannerPay", (barcode)=>{
				this.processBarcode(barcode)
			})
		},

		beforeDestroy() {
			window.removeEventListener("resize", this.getScrollInformation);
			window.removeEventListener("scroll", this.getScrollInformation);
			
		},

		destroyed() {
			this.snapshotListenerloadReceipts = null; // Turn of snapshots
		},

		methods: {

			async connectToPrinter(){

				await navigator.usb.getDevices().then(devices => {
					if(devices.length > 0){
						devices.forEach((device)=>{
							if(device.vendorId == 10032){
								this.selectedPrinter = device;
							}
						})
					}	
				})
				.catch(error => { console.error("Getting device error: ", error); }); 

				// No Devices connected yet
				if(!this.selectedPrinter){
					await navigator.usb.requestDevice({ filters: [{vendorId: 10032}]  })
					.then(device => {
						console.log("Device selected: ", device);
						if(device.codepageMapping){
							console.log("Device selected codepageMapping: ", device.codepageMapping);
						}
						// Hier kun je verder gaan met het aanspreken van het apparaat.
					})
					.catch(error => { 
						console.error("Request device error: ", error); 
					});
				}
				else{
					this.selectedPrinter.open()
					.then(() => {
						this.selectedPrinter.selectConfiguration(1)
						console.log("INTERFACE CLAIMED ")
					})
					.then(() => this.selectedPrinter.claimInterface(0))


					var string = "Thrive 365 Printer Test";
					var encoder = new TextEncoder();
					var data = encoder.encode(string);
					this.selectedPrinter.transferOut(1, data)
					.catch(error => { console.error("Printing: ", error); })
				}
				
				
				// console.log("getUsbDevices: ", devices)
				// console.log("Connect to printer")

				console.log("selectedPrinter: ", this.selectedPrinter)

				// const receiptPrinter = new WebUSBReceiptPrinter();

				// receiptPrinter.connect();


				

				// receiptPrinter.addEventListener('connected', device => {
				// 	console.log(`Connected to ${device.manufacturerName} ${device.productName} (#${device.serialNumber})`);
				
				// 	// printerLanguage = device.language;
				// 	// printerCodepageMapping = device.codepageMapping;
				
				// 	// /* Store device for reconnecting */
				// 	// lastUsedDevice = device;
				// });
			},


			/**
			 * Send Click Event to Algolia
			 * @param item | Object with all data of the clicked item
			 * @param type | String with value of the item type (product, treatment etc)
			 */
			algoliaItemClick(item, type){
				aa("init", {
					appId: "B4XU2Z5BAW",
					apiKey: "a29d0e9160e36dc8ee83d5888c28cdcc",
				});
				aa('sendEvents', [
					{
						userToken: this.$store.state.activeCompany.id,
						authenticatedUserToken: this.$store.state.activeUserId,
						eventType: 'click',
						eventName: `${type} clicked`,
						index: `thrive_${type}s`,
						queryID: item.__queryID,
						objectIDs: [item.objectID],
						positions: [item.__position],
					},
				]);

				console.log("algoliaItemClick: ", {
						userToken: this.$store.state.activeCompany.id,
						authenticatedUserToken: this.$store.state.activeUserId,
						eventType: 'click',
						eventName: `${type} clicked`,
						index: `thrive_${type}s`,
						queryID: item.__queryID,
						objectIDs: [item.objectID],
						positions: [item.__position],
					})
			},

			genAvatar(name){
				return generateMeshAvatar(name);
			},

			processBarcode(barcode){
				console.log("processBarcode", barcode)
				if(this.selectedReceiptId != "") {
					this.productSearch = barcode;
				}
			},



		


			formatToCurrency(locale, currency, digits){
				return formatToCurrency(locale, currency, digits)
			},

			calculateBrutoPrice(priceNetto, vatDocId, branchPriceAdjustments){ // Show the correct Bruto Price in the list of treatments or products
				let vatItem = this.$store.state.vatItems.find((item) => item.id  == vatDocId)
				if(vatItem){
					let brutoPrice = (priceNetto * (100 + vatItem.percentage)) / 100

					if(branchPriceAdjustments && branchPriceAdjustments[this.$store.state.activeBranch.id] && branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType){ // There are price adjustments for this branch
					if(branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType != 'noChanges'){
						brutoPrice = branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto * ((vatItem.percentage / 100)+1);
					}
					}

					return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format(Number(brutoPrice));
				}
				else{
					return ""
				}
			},

			openCustomerDialog() {
				this.selectedClientName = `${this.selectedClientData.name} ${this.selectedClientData.surname}`
				this.customerDialog = true;
				bus.$emit("getClientInformation", this.clientId)
			},

			async getHistoryPaymentsButton(type){
				if(type == 'next'){
					this.historyPaymentsDate = moment(this.historyPaymentsDate).add(1, 'day').format("YYYY-MM-DD")
				}
				else{
					this.historyPaymentsDate = moment(this.historyPaymentsDate).subtract(1, 'day').format("YYYY-MM-DD")
				}
				this.getHistoryPayments()
			},

			async getHistoryPayments(){
				this.receiptsLoading = true;
				this.historyPayments = new Array();

				await db.collection("receiptsPaid") // Get receipts
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("branchId", "==", this.$store.state.activeBranch.id)
				.where("visit", "==", this.historyPaymentsDate)
				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						if(!doc.data().rebuild){
							let historyPayment = doc.data();
							historyPayment.id = doc.id;  
							historyPayment.historyType = 'receipt'
							historyPayment.time = moment(historyPayment.created.seconds, "X").format("YYYY-MM-DDTHH:mm")  
							this.historyPayments.push(historyPayment);
						}
					
					});
				})

				let start = moment(this.historyPaymentsDate, "YYYY-MM-DD").startOf('day').toDate()
				let end =  moment(this.historyPaymentsDate, "YYYY-MM-DD").endOf('day').toDate()

				await db.collection("cashRegister") // Get receipts
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("branchId", "==", this.$store.state.activeBranch.id)
				.where("type", "==", 'expense')
				.where("created", ">=", start)
				.where("created", "<=", end)
				.get()
				.then((snap) => {

					snap.forEach((doc) => {
						let historyPayment = doc.data();
						historyPayment.id = doc.id;  
						historyPayment.historyType = 'expense'  
						historyPayment.time = moment(historyPayment.created.seconds, "X").format("YYYY-MM-DDTHH:mm");
						if(historyPayment.typeExpenses != 'cashDifference' || (this.$store.state.activeUserRoleNumber == 0 && !this.$store.state.demoMode)){
							this.historyPayments.push(historyPayment);
						}
						
					});
				})

				await new Promise((resolve)=>{ // Get Client names
					if(this.historyPayments.length > 0){
						let counter = 0
						this.historyPayments.forEach(receipt => {
							if(receipt.historyType == 'receipt'){
								if(receipt.clientId){
									db.collection("clients").doc(receipt.clientId).get()
									.then((doc) => {
										if(doc.exists){
											receipt.clientName = `${doc.data().name} ${doc.data().surname}`;
										}
										counter++ 
										if(counter == this.historyPayments.length){
											resolve()
										}
									})
								}
								else{
									receipt.clientName = `Onbekend`;
									counter++ 
									if(counter == this.historyPayments.length){
										resolve()
									}
								}
							}
							else{
								receipt.clientName = `Uitgave (${receipt.expenseName})`;
								counter++ 
								if(counter == this.historyPayments.length){
									resolve()
								}
							}
						});
					}
					else{
						resolve()
					}
				})

				await new Promise((resolve)=>{ // Get Employee names
					if(this.historyPayments.length > 0){
					let counter = 0;
					this.historyPayments.forEach(receipt => {
						if(receipt.historyType == 'receipt'){
							if(receipt.paymentTableObj && receipt.paymentTableObj.transactionClosedBy && receipt.paymentTableObj.transactionClosedBy.userId){
								db.collection("users").doc(receipt.paymentTableObj.transactionClosedBy.userId).get()
								.then((doc) => {
									if(doc.exists){
										receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
									}
									counter++ 
									if(counter == this.historyPayments.length){
										resolve()
									}
								})
							}
							else{
								receipt.employeeName = `Onbekend`;
								counter++ 
								if(counter == this.historyPayments.length){
									resolve()
								}
							}
							
						// db.collection("users")
						// .where("user_id", "==", receipt.paymentTableObj.transactionClosedBy.userId)
						// .get()
						// .then((snap) => {
						// 	if(snap.size > 0){
						// 	snap.forEach((doc) => {
						// 		receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
						// 		counter++ 
						// 		if(counter == this.historyPayments.length){
						// 		resolve()
						// 		}
						// 	});
						// 	}
						// 	else{
						// 	counter++ 
						// 	if(counter == this.historyPayments.length){
						// 		resolve()
						// 	}
						// 	}
						// })
						}
						else{
							db.collection("users").doc(receipt.byResource).get()
							.then((doc) => {
								if(doc.exists){
									receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
								}
								counter++ 
								if(counter == this.historyPayments.length){
									resolve()
								}
							})	
						}
					});
					}
					else{
						resolve()
					}
				})
				this.historyPayments.sort((a, b) => a.time.localeCompare(b.time));
				this.receiptsLoading = false;
			},

			refreshAlgolia(){
				this.algoSearch = algoliasearch(
					"B4XU2Z5BAW",
					"a29d0e9160e36dc8ee83d5888c28cdcc"
				);
			},

			loadTab(type){
				this.activeTab = type;
			},

			getScrollInformation() {
				const contentWrapper = document.querySelector("#content");
				const routerWrapper = document.querySelector(".router-view");

				this.paymentAddItemsHeight = this.$store.state.activeUserRoleNumber == 0 && !this.$store.state.demoMode  ? `${contentWrapper.clientHeight - 600}` : `${contentWrapper.clientHeight - 400}`;

				this.paymentWrapperWidth = contentWrapper.clientWidth;

				if (routerWrapper.clientHeight > contentWrapper.clientHeight) {
					this.paymentFloat = true;
				}
				else {
					this.paymentFloat = false;
				}
			},

			subtreatmenStafmembers(treatment) {
				let missingResources = 0;
				let resources = new Array();
				let uniqueResources = null;
				let counter = 0;
				let resourcesReadable = {
					names: null,
					missingNames: null,
				};
				treatment.subTreatments.forEach((subtreatment) => {
					if (subtreatment.resourceId) {
						this.resources.forEach((resource) => {
							if (resource.id == subtreatment.resourceId) {
								resources.push(resource.name);
							}
						});
					} else {
						missingResources++;
					}
					counter++;
					if (counter == treatment.subTreatments.length) {
						resourcesReadable.missingNames = missingResources;
						let uniqResourcesSet = new Set();
						resources.forEach((res) => uniqResourcesSet.add(res));
						// _.uniq(resources);
						uniqueResources = [...uniqResourcesSet];

						let nameStrings = uniqueResources.join(", ");
						resourcesReadable.names = nameStrings.replace(/,(?=[^,]*$)/, " en");
					}
				});
				return resourcesReadable;
			},

			chooseDiscountPrefix(type) {
				// console.log("type: ", type);
				if (type == "Bedrag") {
					return "€";
				} else {
					return null;
				}
			},

			getGender(gender) {
				if (gender == 2) {
					return "Vrouw";
				} else if (gender == 1) {
					return "Man";
				} else {
					return "Onbekend";
				}
			},

			chooseDiscountSuffix(type) {
				if (type == "Percentage") {
					return "%";
				} else {
					return null;
				}
			},

			getPrepaymentType(type) {
				return this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type)
					? this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type).name
					: "";
			},

			getPrepaymentStatus(paymentStatus, status) {

				//partialSettled
				if(paymentStatus == "paid" && status.type == "partialSettled"){
					return {
						type: {
							name: "Gedeeltelijk verrekend",
							id: "partialSettled",
						},
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
				} else if (paymentStatus == "paid" && status.type == "remainingAmount") {
					return {
						type: {
							name: "Nog niet verekkende restanten",
							id: "remainingAmount",
						},
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
				} else if (paymentStatus == "paid" && status.type == "settled") {
					return {
						type: {
							name: "Volledig Verrekend",
							id: "settled",
						},
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
				} 
				else if (paymentStatus == "paid" && status.type == "compensation") {
					return {
						type: {
							name: "Cancel / No-show compensatie ",
							id: "compensation",
						},
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
				} else if (paymentStatus == "paid" && status.type == null) {
					
					if (status && status.returned) {
						// Is verekend
						return {
							type: {
								name: this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type)
									? this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type).name
									: "",
								id: status.type,
							},
							timestamp: status.timestamp ? status.timestamp : null,
							receiptId: status.receiptId ? status.receiptId : null,
						};
					}	else {
						return {
							type: {
								name: "Nog niet verrekend",
								id: "paidNotReturned",
							},
							timestamp: null,
							receiptId: null,
						};
					}
				} else {
					//paymentStatus == "open"
					return {
						type: {
							name: paymentStatus == "expired" ? "Verlopen" : "Betaling nog niet gedaan",
							id: paymentStatus,
						},
						timestamp: null,
						receiptId: null,
					};
				}
			},

			addCustomDiscount() {
				this.customDiscount.feedback = null;
				if (this.customDiscount.typeDiscount.discountValue) {
					//Manipulate

					let newModifier = deepCopy(this.customDiscount);
					newModifier.typeDiscount.itemFilterIndex = this.indexer;
					newModifier.typeDiscount.cartItemId = this.selectedItems[this.indexer].cartItemId;
					newModifier.id = "customDiscount" + uniqid.time();
					newModifier.custom = true; //TODO TEST VANAF HIER
					newModifier.addedVia = "customDiscount";
					newModifier.manualInputDiscount = true; // Thru Fixed Amount via manualInputCalc function POS

					console.log("Add Custom Discount: ", newModifier);

					this.modifiers.push(newModifier);
					this.usedModifiers.push(newModifier);
					this.customModifiers.push(newModifier);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

					this.closeCustomDiscountDialog();
				} else {
					this.customDiscount.feedback = "Voer een bedrag of percentage in om de korting toe te passen.";
				}
			},

			

			/**
			 * Handles manual input of discounts for items in the payment view
			 * @param {string|number} discount - The discount amount entered by user
			 * @param {number} index - Index of the selected item being discounted
			 * @param {number} priceBruto - Original gross price of the item
			 * @param {Object} item - The item object being discounted
			 */
			manualInputDiscount(discount, index, priceBruto, item) {
					
				const cleanNumber = formatToCleanNumber(discount); //Format to clean number to calculate with

				if (cleanNumber > priceBruto) {
					// Stel de korting in op de maximale waarde (priceBruto)
					// console.log("Higher than bruto")
					this.manualInputHigherThanBruto = true;
			
					return;
				}

				//The given discount by calculate the difference
				const givenDiscount = cashConv(this.selectedItems[index].priceBruto - cleanNumber,"EUR",2 );
				
				clearTimeout(this.inputTimer);
				this.inputTimer = setTimeout(() => {


					const customId = "customDiscount" + uniqid.time();

					const newModifier = {

						typeDiscount: {
							itemFilterIndex: index,
							discountValue:  givenDiscount,
							discountType: "amount",
							discountName: "manualInputDiscount",
							type: "fixedAmount",
							cartItemId: item.cartItemId,
							custom: true
						},
						filterProducts: { active: false, items: null },
						filterTreatments: { actice: false, items: null },
						type: 'all',
						name: `Handmatige korting op ${this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, item.name)}`,
						description: "Handmatig toegevoegde korting via input",
						dateLimit: { active: false, endDate: null, startDate: null },
						numberLimit: { active: false, number: null, numberused: null, type: 'max' },
						deleted: false,
						combinationLimit: true,
						id: customId,
						custom: true,
						feedback: null,
						manualInputDiscount: true, // Thru Fixed Amount via manualInputCalc function POS
						addedVia: "manualInput",
					};

					// Wis alleen de kortingen voor dit specifieke item
					if(this.selectedItems[index].discountValueByModifiers) {
						const manualInputModifiersByIndex = this.selectedItems[index].discountValueByModifiers
						.filter(modifier => modifier.manualInputDiscount === true)
						.map(modifier => modifier.modifierId);
						
						// Filter de modifiers op basis van item ID en modifier type
						this.usedModifiers = this.usedModifiers.filter(modifier => 
						!(manualInputModifiersByIndex.includes(modifier.id) && 
							modifier.typeDiscount.itemId === item.id)
						);
					}

					


					// Controleer opnieuw of de korting niet groter is dan priceBruto
					if (this.manualInputHigherThanBruto === true) {

						// console.log("Higher than bruto")

						// Stel de korting in op de maximale waarde (priceBruto)
					
						// Push feedback to feedbackArray
						// this.feedbackArray.push({
						//     modifierName: "Handmatige korting",
						//     explaination: `De korting invoer is hoger dan  €${givenDiscount.toFixed(2)}`,
						//     dismiss: true,
						//     type: "info",
						//     icon: "mdi-cash",
						//     color: "warning",
							// 	 immutable: true,
						// });

						this.manualInputHigherThanBruto = false;
					}

					// Wis de andere kortingen wipe-out other manual input discounts
					if(this.selectedItems[index].discountValueByModifiers){
										
					
						const manualInputModifiersByIndex = this.selectedItems[index].discountValueByModifiers
						.filter(modifier => modifier.manualInputDiscount === true)
					.map(modifier => modifier.modifierId)
					
						console.log("manualInputModifiersByIndex", manualInputModifiersByIndex)

						//remove modifiers by id from usedModifiers
						this.usedModifiers = this.usedModifiers.filter(modifier => !manualInputModifiersByIndex.includes(modifier.id))
						this.customModifiers = this.customModifiers.filter(modifier => !manualInputModifiersByIndex.includes(modifier.id))

					}
			
				
					this.modifiers.push(newModifier);
					this.usedModifiers.push(newModifier);
					this.customModifiers.push(newModifier);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				}, 1000);

				// console.log(newModifier) 
			},

			addCustomDiscountDialog() {
				console.log("uniqID", uniqid.time());
				this.customDiscount.modal = true;
			},

			closeCustomDiscountDialog() {
				this.customDiscount.feedback = null;
				this.customDiscount.modal = false;
				this.customDiscount.typeDiscount.discountType = 'amount';
				this.customDiscount.typeDiscount.discountValue = 0;
				this.customDiscount.combinationLimit = true;
			},

			closePayDialog() {
				this.payDialog = false;
				this.chosenPaymentDevice = null;
				this.transactionsNumber = 0;
				this.splitPay = {
					doneSteps: false,
					transactionId: null,
					splitByCash: 0,
					splitByCard: 0,
					splitActive: false,
				};
			},

			openCustomDialogFunc() {
				this.customDialog = true;
				console.log("Dialog open");
			},

			closeCustomDialog() {
				this.customDialog = false;

				this.customRule = {
					name: "",
					description: "",
					priceBruto: 0, //bruto

					amount: 1,
					resourceId: null,
					vat: 0,
				};
			},

			addCustomRule() {
				let newCustomRuleObject = {
					name: this.customRule.name,
					type: "customRule",
					description: this.customRule.description,
					id: uniqid.time(),
					uniqueIdentifier: uniqid.time(),
					percentageVAT: this.customRule.vat,
					typeOfPayment: this.customRuleOption == 0 ? "deposit" : "withdraw",
					typeItem: "customRule",
					priceBruto: Number(this.customRule.priceBruto),
					priceNetto: Number((this.customRule.priceBruto / `1.${this.customRule.vat}`).toFixed(2)),
					newPriceTotal: Number(this.customRule.priceBruto), //Also Bruto
					newPriceTotalBruto: Number(this.customRule.priceBruto),
					newPriceTotalNetto: Number((this.customRule.priceBruto / `1.${this.customRule.vat}`).toFixed(2)),
					discountNetto: 0,
					discountBruto: 0,
					calculatedDiscountVAT: 0,
					calculatedDiscountNetto: 0,
					userAdded: true,
					modifiedId: uniqid.time(),
					usedModifierId: [],
					usedMembershipId: [],
					category: ["customRule"],
					resourceId: this.customRule.resourceId,
					sub: [],
					selected: false,
				};

				//Push it by selected Amount to SelectedItems
				Array(Number(this.customRule.amount))
					.fill(newCustomRuleObject)
					.forEach((customRule) => this.selectedItems.push(customRule));

				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

				this.closeCustomDialog();
			},

			loadData(type){
				this.closeReceipt();
				this.getRegisterCash();
				this.activeTab = 'pay';
				this.selectedSupplier = null;
				this.selectedTreatmentType = null;
				this.sidebarTab = 0;
				this.historyPaymentsDate = moment().format("YYYY-MM-DD");
				this.availablePayDevices = this.$store.state.activeBranch.paymentOptions;
				this.preferredPaymentDevice = this.$store.state.activeBranch.paymentOptions;
				this.clearLoadedReceipts(); // Clear ReceiptHistory
				this.loadAllResources();

				if(type == 'change'){
					this.$router.push({ // Remove Params of Receipt from url
						path: this.$route.path,
						query: { clientId: undefined, uid: undefined }
					});
				}
			},

			clearLoadedReceipts() {
				this.snapshotListenerloadReceipts = null; // Stop snapshot Listener
				this.headerReceiptDrafts = new Array();
				this.headerReceiptDraftsSearch = new Array();
				this.localReceiptDrafts = new Array();
			},

			/**
			 * Get the name of a company branch based on the ID
			 * @param {String} id - ID of the Branch
			 */
			getBranchName(id){
				let branchData = this.$store.state.companyBranches.find(branch => branch.id == id);
				if(branchData){
				return branchData.name
				}
				else{
				return null
				}
			},

			/**
			 * Find the VAT-percentage based on the ID
			 * @param {String} vatId - ID of the VAT-document
			 */
			getVatPercentage(vatId){
				let vatItem = this.$store.state.vatItems.find((vat)=> { return vat.id == vatId});
				if (vatItem != undefined) {	 // Continue if we have a VAT-item
					return vatItem.percentage
				}
			},

			/**
			 * Calculate the correct netto and bruto prices based on the latest VAT % and possible price adjustments for branches
			 * @param {Object} data - All data of the priceholder 
			 * @param {String} type - Type priceholder Product or treatment
			 */
			calculatePrices(data, type){
				let priceNetto = type == 'product' ? data.priceNetto : data.treatmentPrice
				let vatItem = this.$store.state.vatItems.find((vat)=> { return vat.id == data.vat })
				let price = { netto: 0,	bruto: 0 }

				if (vatItem != undefined) {	 // Continue if we have a VAT-item
					price.netto = cashConv(priceNetto, "EUR", 4);
					price.bruto = cashConv((priceNetto * (100 + vatItem.percentage)) / 100, "EUR", 4); // Example: 32,00 * ((100 + 9) / 100) = 34,88
					
					if(data.branchPriceAdjustments && data.branchPriceAdjustments[this.$store.state.activeBranch.id] && data.branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType){ // There are price adjustments for this branch
						if(data.branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType != 'noChanges'){
							price.netto = cashConv(data.branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto, "EUR", 4);
							price.bruto = cashConv(data.branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto * ((vatItem.percentage / 100)+1), "EUR", 4);
						}
					}			
				}
				return price;	
			},
			/**
			 * Show value in the right amount with the desired digits
			 * @param {Number} number value
			 * @param {Number} digits number of digits to show
			 */
			formatPrice(number, digits){
				if(number){
					return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: digits == 0 ? 0 : 2, maximumFractionDigits: digits == 0 ? 0 : 2}).format(Number(number));
				}
				else{
					return "-"
				}
			},


			async loadProductsByBranch() {
				const products = new Array();

				let index = this.algoSearch.initIndex("thrive_products");
				let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
				let ActiveFilterString = 'active:true';
				let ActiveFilterBranch = `branches:${this.$store.state.activeBranch.id}`;

				await index.search("", {
					userToken: this.$store.state.activeCompany.id,
					facetFilters: [FacetFilterString, ActiveFilterString, ActiveFilterBranch],
				})
				.then(({ hits }) => {
					hits.forEach((product) => {
						if(!product.deleted || !product.deleted.deleted ){
							let obj = product;
							obj.priceBruto = this.calculatePrices(product, 'product').bruto; //New Price Bruto based on amount
							obj.addedDiscount = 0; //Extra Discount
							obj.priceNetto = this.calculatePrices(product, 'product').netto; //New Price with Discount and amount
							obj.newPriceTotalNetto = this.calculatePrices(product, 'product').netto; //New Price with Discount and amount
							obj.newPriceTotal = this.calculatePrices(product, 'product').bruto; //New Bruto TotalPrice
							obj.percentageVAT = this.getVatPercentage(product.vat); //Vat Percentage
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();
							obj.resourceId = null; //Sale by Resource
							obj.type = "product";
							obj.id = product.objectID;
							products.push(obj);
						}
					});
				})
				this.productsByBranch = products;
				this.productsByBranchClone = deepCopy(this.productsByBranch);
				return;
			},

			loadProductCategories() {
				db.collection("productCategories")
				.orderBy("name", "asc")
				.get()
				.then((snap) => {
					this.productCategories = [];
					snap.forEach((doc) => {
						let productCategory = doc.data();
						productCategory.id = doc.id;
						this.productCategories.push(productCategory);
					});
				})
				.then(() => {
					return;
				});
			},

			async loadProductSuppliers() {
				let productSuppliers = new Array();
				await db.collection("productSuppliers")
				.where("companies", "array-contains", this.$store.state.activeCompany.id)
				.orderBy("name", "asc")
				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let productSupplier = doc.data();
						productSupplier.id = doc.id;
						productSupplier.groups = new Array();
						productSuppliers.push(productSupplier);
					});
				})
				
				if(productSuppliers.length > 0){
					productSuppliers.sort((a, b) => a.name.localeCompare(b.name));
					await new Promise((resolve)=>{
						for (let index = 0; index < productSuppliers.length; index++) {
							db.collection("productSuppliers")
							.doc(productSuppliers[index].id)
							.collection("groups")
							.get()
							.then(snap => {
								snap.forEach(doc => {
									let group = doc.data();
									group.id = doc.id;
									group.disabled = doc.data().deleted && doc.data().deleted.deleted ? true : false
									if(!group.disabled){
										productSuppliers[index].groups.push(group);
									}
								});
							})
							.then(()=>{
								productSuppliers[index].groups.sort((a, b) => a.name.localeCompare(b.name));
								if(index == productSuppliers.length -1 ){
									resolve()
								}
							})
						}
					})
				}
				this.productSuppliers = productSuppliers;
				return;
			},

			loadModifiers() {
				this.modifiers = new Array();
				let getToday = moment().format("YYYY-MM-DD");

				db.collection("modifiers")
				.where("branches", "array-contains", this.$store.state.activeBranch.id)
				.where("active", "==", true)
				.where("deleted", "==", false)

				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let obj = doc.data();

						//Set Priority of Modifiers Discounts
						obj.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "discount").priorityNumber;

						obj.id = doc.id;
						this.modifiers.push(obj);
						this.modifiersClone.push(obj); //For removing all Garbage and safety
					});
				})
				.then(() => {
					console.log("Modifiers documents loaded");
					return;
				})
				.catch((error) => {
					console.error("Modifiers documents error:", error);
				});
			},

			loadCompanyMemberships(){
				this.companyMemberships = new Array();


				db.collection("membershipPackages")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("active", "==", true)
				.where("deleted", "==", false)

				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let membership = doc.data();
						membership.id = doc.id;
						membership.disabled =  false;
						if(!membership.branches.all){
							let activeBranch = membership.branches.branches.find(branch => {return branch == this.$store.state.activeBranch.id})
							if(activeBranch == undefined){
								membership.disabled = true;
							}
						}

						membership.price = cashConv(membership.amountBruto * membership.duration, "EUR", 4, "membership.price");

						// //calculated netto from vat
						// let nettoPrice = cashConv(membership.price / ((this.getVatPercentage(membership.vat) / 100) + 1), "EUR", 4);
				
						// // Create membership object with required properties
						
						// // Basic membership info
						
						// membership.name = this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, membership.name)
						// membership.description = membership.description
						// membership.type = "membership"
				

						// 				// Price calculations
						// membership.priceBruto = membership.amountBruto
						// membership.priceNetto = nettoPrice
						// membership.newPriceTotal = membership.price
						// membership.newPriceTotalBruto = membership.amountBruto,
						// membership.newPriceTotalNetto = nettoPrice


						
						
						// // VAT info
						// membership.percentageVAT = this.getVatPercentage(membership.vat),
						// membership.calculatedDiscountVAT = 0,
						// membership.calculatedDiscountNetto = 0,
						
						
						// // Discount tracking
						// membership.addedDiscount = 0,
						// membership.usedModifierId = [],
						// membership.usedMembershipId = [],
						
						// // Additional properties
						// membership.userAdded = true
						// membership.selected = false
						// membership.internalUse = false
						// membership.typeOfPayment = "deposit"
						// membership.resourceId = this.userId
						// membership.category = ["membership"]
					
						// // Membership specific
						// membership.duration = membership.duration
						// membership.paymentTypes = membership.paymentTypes
						// membership.membershipStart = membership.membershipStart
						// membership.autoRenew = membership.autoRenew
					

						if(membership.paymentTypes != 'periodic'){ // Show only memberships who are paid at once in the register
							this.companyMemberships.push(membership); 
						}
						
					});
				})
				.then(() => {
					console.log("Company memberships documents loaded");
					
					this.companyMembershipsClone = deepCopy(this.companyMemberships);
					
					return;
				})
				.catch((error) => {
					console.error("Company memberships documents error:", error);
				});
			},
	


			loadAllResources() {
				//Loading all neccesary resources for Receipt.
				Promise.all([
					this.loadProductCategories(),
					this.loadProductSuppliers(),
					this.loadProductsByBranch(),
					this.loadTreatmentTypes(),
					// this.loadTreatmentsByBranch(),
					this.loadResources(),
					this.loadModifiers(),
					this.loadCompanyMemberships(),
				])
				.then(() => {
					console.log("Everything loaded...");
					this.autoLoadReceipts(); //Auto reload Receipts
				});
			},

			async loadTreatmentsByBranch() {
				let currentDay = moment().format("YYYY-MM-DD");

				let index = this.algoSearch.initIndex("thrive_treatments");
				let FacetFilterString = [`companyId:${this.$store.state.activeCompany.id}`, `branches:${this.$store.state.activeBranch.id}`];

				await index.search("", {
					clickAnalytics: true,
					userToken: this.$store.state.activeCompany.id,
					facetFilters: FacetFilterString,
				})
				.then(({ hits }) => {
					hits.forEach((doc) => {
						
						if(!doc.deleted || !doc.deleted.deleted ){
							let obj = doc;
							obj.priceBruto = doc.treatmentPrice ? this.calculatePrices(doc, 'treatment').bruto: 0;
							obj.addedDiscount = 0; //Extra Discount
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();
							obj.priceNetto = doc.treatmentPrice ? this.calculatePrices(doc, 'treatment').netto: 0; //New Price with Discount and amount and VAT
							obj.newPriceTotalNetto = doc.treatmentPrice ? this.calculatePrices(doc, 'treatment').netto: 0; //New Price with Discount and amount and VAT
							obj.newPriceTotal = doc.treatmentPrice ? this.calculatePrices(doc, 'treatment').bruto : 0; //Bruto TotalPrice
							obj.percentageVAT = doc.treatmentPrice ? this.getVatPercentage(doc.vat) : 0; //Vat Percentage

							obj.name = doc.treatmentTitle;

							obj.type = "treatment";
							obj.id = doc.objectID;
							obj.selected = false; //For toggling

							//When Discount is already Aplied
							if (doc.discount && doc.discount.active) {
								if (currentDay >= doc.discount.start && currentDay <= doc.discount.end) {
									// console.log("Discount is active in date range");

									//Discount Percentage calculation
									if (doc.discount.type == "percentage") {
										obj.discountName = "Percentage";
										obj.addedDiscount = doc.discount.number;
										obj.newPriceTotal = this.currency.format(obj.priceBruto - (obj.priceBruto * obj.addedDiscount) / 100);
										this.treatmentsByBranch.push(obj);
									}

									//Discount Amount calculation
									if (doc.discount.type == "amount") {
										obj.discountName = "Bedrag";
										obj.addedDiscount = doc.discount.number;
										obj.newPriceTotal = this.currency.format(obj.newPriceTotal - obj.addedDiscount);
										this.treatmentsByBranch.push(obj);
									}
								}
								else {
									//Discount is not inside the discount start and end range.

									//Default = percentage OR amount
									if (doc.discount.type == "percentage") {
										obj.discountName = "Percentage";

										this.treatmentsByBranch.push(obj);
									}

									if (doc.discount.type == "amount") {
										obj.discountName = "Bedrag";

										this.treatmentsByBranch.push(obj);
									}
								}
							}
							else {
								this.treatmentsByBranch.push(obj);
							}
						}
					});
				})
					
				console.log("Treatments Succesfully Loaded");
				this.treatmentsByBranchClone = deepCopy(this.treatmentsByBranch);
				return;
			},

			/*

		██╗      ██████╗  █████╗ ██████╗     ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗███████╗
		██║     ██╔═══██╗██╔══██╗██╔══██╗    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝██╔════╝
		██║     ██║   ██║███████║██║  ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   ███████╗
		██║     ██║   ██║██╔══██║██║  ██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   ╚════██║
		███████╗╚██████╔╝██║  ██║██████╔╝    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   ███████║
		╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   ╚══════╝

		*/

			//Getting new Receipts
			async autoLoadReceipts() {
				console.log("autoLoadReceipts")
				let getToday = moment().format("YYYY-MM-DD");
				await new Promise((resolve) => {
					this.snapshotListenerloadReceipts = db
						.collection("receiptsDraft")
						.where("companyId", "==", this.$store.state.activeCompany.id)
						.where("visit", "==", getToday)
						.where("branchId", "==", this.$store.state.activeBranch.id) //Dynamisch maken
						.where("draft", "==", true) //Dynamisch maken binnen halen per branch
						.onSnapshot((snapshot) => {
							snapshot.docChanges().forEach((change) => {
								if (change.type === "added") {
									console.log("New Receipt draft: ", change.doc.id, change.doc.data());
									let data = change.doc.data();
									data.id = change.doc.id;
									this.localReceiptDrafts.push(data);
								}

								if (change.type === "modified") {
									let data = change.doc.data();
									data.id = change.doc.id;
									console.log("Changed Receipt", change.doc.id, change.doc.data())

									let foundIndex = this.headerReceiptDrafts.findIndex((receipt) => receipt.id == data.id);
									this.localReceiptDrafts[foundIndex] = data;

									//If the same Receipt is open
									if (this.selectedReceiptId == data.id) {
										this.headerReceiptDrafts;
										// this.selectedItems = change.doc.data().selectedItems;
										this.getTotal();
									}
								}
								if (change.type === "removed") {
									console.log("Removed Receipt draft: ", change.doc.data());
									let data = change.doc.data();
									data.id = change.doc.id;
									this.localReceiptDrafts = this.localReceiptDrafts.filter((receipt) => receipt.id !== data.id);
								}
							});
							this.headerReceiptDrafts = this.localReceiptDrafts;
							this.headerReceiptDraftsSearch = this.localReceiptDrafts;	

					
							resolve();
						});
				});

				if (this.$route.query.uid) {
					await this.findOrSelectReceipt(this.$route.query.uid, this.$route.query.clientId); // find Receipt with Id
				}

				this.dataloaded = true;
				this.getScrollInformation();
			},

			openCashRegister(){
				bus.$emit("openCashUpModal");
			},

			//Merge Receipts
			async mergeReceiptFunc() {

				this.mergeReceiptDialog.loading = true;

				//get the receipt with selectedOpenReceipt is the receipt that is open for merging
				let selectedOpenReceipt = this.localReceiptDrafts.find((receipt) => receipt.id == this.selectedReceiptId);


				//If the receipt is not found, return
				if (!selectedOpenReceipt) {
					console.error("Bon niet gevonden.")
					return;
				}

				let arrayOfBookingSummariesIds = selectedOpenReceipt.bookingSummaryIds;
				

				this.mergeReceiptDialog.selectedReceipts.forEach(receipt =>{
					//Merge selectedItems from mergeReceiptDialog to the selectedItems of selectedOpenReceipt
					selectedOpenReceipt.selectedItems = selectedOpenReceipt.selectedItems.concat(receipt.selectedItems);
					//Merge usedModifiers from mergeReceiptDialog to the usedModifiers of selectedOpenReceipt
					selectedOpenReceipt.usedModifiers = selectedOpenReceipt.usedModifiers.concat(receipt.usedModifiers);
					//Custom Modifiers
					selectedOpenReceipt.customModifiers = selectedOpenReceipt.customModifiers.concat(receipt.customModifiers);
					//Deleted Modifiers
					selectedOpenReceipt.deletedModifiers = selectedOpenReceipt.deletedModifiers.concat(receipt.deletedModifiers);

					//There are no booking summaries by a front-end receipt draft
					if(!receipt.draftMadeAtFrontEnd) {
						//Add bookingSummaryIds to arrayOfBookingSummaries
						arrayOfBookingSummariesIds = arrayOfBookingSummariesIds.concat(receipt.bookingSummaryIds);
					}
				})

				selectedOpenReceipt.bookingSummaryIds = arrayOfBookingSummariesIds;

				console.log("Selected Open Receipt", selectedOpenReceipt);
				console.log("Array of Booking Summaries", arrayOfBookingSummariesIds);

				//update the object inside this.localReceiptDrafts with the new selectedOpenReceipt
				
				this.localReceiptDrafts = this.localReceiptDrafts.map((receipt) => {
					if (receipt.id == selectedOpenReceipt.id) {
						console.log("Receipt found, updating receipt.");
						let newReceiptObject = receipt;
						newReceiptObject.selectedItems = selectedOpenReceipt.selectedItems;
						newReceiptObject.usedModifiers = selectedOpenReceipt.usedModifiers;
						newReceiptObject.customModifiers = selectedOpenReceipt.customModifiers;
						newReceiptObject.deletedModifiers = selectedOpenReceipt.deletedModifiers;
						newReceiptObject.bookingSummaryIds = selectedOpenReceipt.bookingSummaryIds;
						console.log("New Receipt Object", newReceiptObject);
						return newReceiptObject;
					} else {
						return receipt;
					}
				});

							
				if(arrayOfBookingSummariesIds.length > 0) {
					await updateBookingSummariesAndBookings();
				}

				//Update the booking summaries and bookings
				async function updateBookingSummariesAndBookings() {
					try {
						const bookingSummaryCollection = db.collection("bookingsummary");
						const bookingCollection = db.collection("bookingen");

						for (const bookingSummaryId of arrayOfBookingSummariesIds) {
							await bookingSummaryCollection.doc(bookingSummaryId).set({
								uniqIdStamp: selectedOpenReceipt.uniqIdStamp
							}, { merge: true });

							const bookingSnapshot = await bookingCollection.where("bookingId", "==", bookingSummaryId).get();
							bookingSnapshot.forEach(async (doc) => {
								await bookingCollection.doc(doc.id).set({
									uniqIdStamp: selectedOpenReceipt.uniqIdStamp
								}, { merge: true });
							});
						}
						console.log("Booking summaries and bookings updated successfully.");
					} catch (error) {
						console.error("Error updating booking summaries and bookings: ", error);
					}
				}
				
				//Update the selectedOpenReceipt

				await db.collection("receiptsDraft").doc(selectedOpenReceipt.id).set({
					selectedItems: JSON.parse(JSON.stringify(selectedOpenReceipt.selectedItems)),
					usedModifiers: selectedOpenReceipt.usedModifiers,
					customModifiers: selectedOpenReceipt.customModifiers,
					deletedModifiers: selectedOpenReceipt.deletedModifiers,
					bookingSummaryIds: selectedOpenReceipt.bookingSummaryIds
				}, { merge: true })
				.then(() => {
					console.log("Receipt updated successfully.");
				})
				.catch((error) => {
					console.error("Error updating receipt: ", error);
				});

				//Delete the mergeReceiptDialog.selectedReceipt from the database
				for (const receipt of this.mergeReceiptDialog.selectedReceipts) {
					await db.collection("receiptsDraft").doc(receipt.id).delete()
					.then(() => {
						console.log("Receipt deleted successfully.");
					})
					.catch((error) => {
						console.error("Error deleting receipt: ", error);
					});
				}

				console.log("Receipts before merge", this.localReceiptDrafts);

				//remove from localReceiptDrafts and HeaderReceiptDrafts filtered by .id
				this.localReceiptDrafts = this.localReceiptDrafts.filter(receipt => {
				
					console.log(receipt.id)
					if(this.mergeReceiptDialog.selectedReceipts.find(selectedReceipt => selectedReceipt.id == receipt.id)){
					
						//remove
						return false;
					}
					else{
			
						return true;
					}
				});

				this.headerReceiptDrafts = this.localReceiptDrafts.filter(receipt => {
					if(this.mergeReceiptDialog.selectedReceipts.find(selectedReceipt => selectedReceipt.id == receipt.id)){
						//remove
						return false;
					}
					else{
						return true;
					}
				});
				

				console.log("Receipts after merge", this.localReceiptDrafts);

				this.openReceipt(selectedOpenReceipt.id, this.localReceiptDrafts.findIndex((receipt) => receipt.id == selectedOpenReceipt.id));

				this.mergeReceiptDialog.dialog = false;
				this.mergeReceiptDialog.selectedReceipts = new Array();
				this.mergeReceiptDialog.loading = false;
			},



			async findOrSelectReceipt(uid, clientId) {
				const receipts = this.localReceiptDrafts.filter(receipt => receipt.uniqIdStamp === uid);

				if (receipts.length > 0) {
					this.openReceipt(receipts[0].id, this.localReceiptDrafts.findIndex(receipt => receipt.id === receipts[0].id));
					return;
				}

				try {
					const snapshot = await db.collection("receiptsDraft")
						.where("companyId", "==", this.$store.state.activeCompany.id)
						.where("branchId", "==", this.$store.state.activeBranch.id)
						.where("draft", "==", true)
						.where("uniqIdStamp", "==", uid)
						.get();

					snapshot.forEach(doc => {
						const receipt = { ...doc.data(), id: doc.id };
						this.headerReceiptDrafts.push(receipt);
						this.headerReceiptDraftsSearch.push(receipt);
					});

					this.headerReceiptDrafts = this.localReceiptDrafts;
					this.headerReceiptDraftsSearch = this.localReceiptDrafts;

					const updatedReceipts = this.localReceiptDrafts.filter(receipt => receipt.uniqIdStamp === uid);

					if (updatedReceipts.length > 0) {
						//Receipt found
						this.openReceipt(
							updatedReceipts[0].id,
							this.localReceiptDrafts.findIndex(receipt => receipt.id === updatedReceipts[0].id)
						);
					} else {
						console.log("Receipt not found in database");
						//Generate a new Receipt
						this.newReceiptDraft(clientId, uid);
					}
				} catch (error) {
					console.error("Error getting Receipt document =>", error);
				}
			},

			downloadLedgerRules() {
				db.collection("companies")
					.doc(this.$store.state.activeCompany)
					.collection("accountancySettings")
					.doc(this.$store.state.activeBranch.id)
					.get()
					.then((doc) => {
						if (doc.exists) {
							this.expenses.typeExpenses = doc.data().expensesNumbers;
							console.log("Ledger Numbers for Branch Found.");
						}
						else {
							// doc.data() will be undefined in this case
							console.log("No ledger numbers for Branch Found");

							db.collection("companies")
							.doc(this.$store.state.activeCompany)
							.collection("accountancySettings")
							.doc(this.$store.state.activeCompany)
							.get()
							.then((doc) => {
								this.expenses.typeExpenses = doc.data().expensesNumbers;

								console.log(doc.data().expensesNumbers);
								console.log("Ledger Numbers for Company Found");
							})
							.catch((err) => {
								console.error("Company Ledger:", err);
							});
						}
					})

					.catch((err) => {
						console.error("Branch Ledger:", err);
					});
			},

			async loadResources() {
				console.log("loadResources in pay.vue")
				//Resources loaded
				// this.resources.push({ name: "Alle Medewerkers", id: null, resourceDocId: null });
				// return await db.collection("employees")
				// .where("companyId", "==", this.$store.state.activeCompany.id)
				// .where("branches", "array-contains", this.$store.state.activeBranch.id)
				// .where("active", "==", true)
				// .get()
				// .then((snap) => {
				// 	snap.forEach((doc) => {
				// 		let obj = new Object();
				// 		obj.name = doc.data().name;
				// 		obj.id = doc.id;
				// 		obj.resourceDocId = doc.id;
				// 		this.resources.push(obj);
				// 	});
				// })
				// .catch((error) => {
				// 	console.error("Error getting Employees =>", error);
				// });
				
				let activeResources = [...this.$store.state.activeBranchEmployees];
				let presentResources = this.$store.state.presentBranchEmployees.map((employee => {return { id: employee.id, name: employee.title, present: true }}));

				activeResources = activeResources.filter(resource => !presentResources.some(presentResource => presentResource.id === resource.id)).map((employee) => { return { id: employee.id, name: employee.name, present: false } });
				this.resources = presentResources.concat(activeResources);
				// console.log("activeResources: ", activeResources);
				// console.log("presentResources: ", presentResources);
				
			},

			//Change Resources SubTreatments
			changeResource(indexSub, changedResourceId) {
				this.selectedItems[this.indexer].subTreatments[indexSub].resourceId = changedResourceId;
				console.log("Resource Changed", this.selectedItems[this.indexer].subTreatments[indexSub]);
				console.log("Selected Items", this.selectedItems);

				db.collection("receiptsDraft")
					.doc(this.selectedReceiptId)
					.set({
						usedModifiers: JSON.parse(JSON.stringify(this.selectedItems)),
						deletedModifiers: this.deletedModifiers,
						modified: new Date(),
						customModifiers: this.customModifiers,
					}, {merge: true})
					.then(() => {
						console.log("%c Receipt Computed and added to DB with ID => ", "background: blue; color: white", this.selectedReceiptId);
						// this.localReceiptDrafts = this.localReceiptDrafts.sort(function(a, b) {
						// // Sortby  modified.seconds
						// // If the first item has a higher number, move it down
						// // If the first item has a lower number, move it up
						// if (a.modified.seconds > b.modified.seconds) return -1;
						// if (a.modified.seconds < b.modified.seconds) return 1;
						// });

						this.tempReceiptKey += 1;

						this.loadingDataTable = false;
						this.getScrollInformation();
					})
					.catch((error) => {
						console.error("Error updating document Receipts Draft : ", error);
					});
			},

			/*

		███╗   ██╗███████╗██╗    ██╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
		████╗  ██║██╔════╝██║    ██║    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
		██╔██╗ ██║█████╗  ██║ █╗ ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
		██║╚██╗██║██╔══╝  ██║███╗██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
		██║ ╚████║███████╗╚███╔███╔╝    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
		╚═╝  ╚═══╝╚══════╝ ╚══╝╚══╝     ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
		*/

			async newReceiptDraft(clientId, uid) {
				this.activeTab = 'pay';

				let clientName = this.$t('payment.anonymous', this.$store.state.locale);
				if (clientId) {
					try {
						const doc = await db.collection("clients").doc(clientId).get();
						if (doc.exists) {
							const clientData = doc.data();
							clientName = `${clientData.name} ${clientData.surname}`.trim();
						}
					} catch (error) {
						console.error("Error getting client document:", error);
					}
				}

				this.refundBoolean = false;
				this.clientId = "";
				this.feedbackArray = new Array();
				this.prePayments = new Array();
				this.clientPrePayments = new Array();
				this.clientLoyaltyDocs = new Array();
				this.selectedClientData = new Object();
				this.selectedItems = new Array();
				this.usedModifiers = new Array();
				this.modifiers = deepCopy(this.modifiersClone);
				this.combinedModifiers = new Array();
				this.customModifiers = new Array();
				this.deletedModifiers = new Array();
				this.validForUseMemberships = new Array();
				this.notValidForUseMemberships = new Array();
				this.treatmentsByBranchClone = new Array();
				this.productsByBranchClone = new Array();

				const receiptObj = {
					draftMadeAtFrontEnd: true,
					clientId: clientId ? clientId : "", //Client Id
					clientname: clientName, //Client Name
					draft: true,
					created: new Date(),
					modified: new Date(),
					visit: moment().format("YYYY-MM-DD"),
					selectedItems: new Array(),
					usedModifiers: new Array(),
					deletedModifiers: new Array(),
					uniqIdStamp: uid ? uid : uniqid(),
					companyId: this.$store.state.activeCompany.id, //Company ID
					branchId: this.$store.state.activeBranch.id, //Branch
					userId: this.byResource, //Resource for Payment
				};
				//Create receipt by ClientId and Clientname
				db.collection("receiptsDraft")
				.add(receiptObj)
				.then((docRef) => {
					// console.log("Document Receipts Draft written with ID: ", docRef.id);
					//For editing the Receipt
					this.selectedReceiptId = docRef.id;
					this.selectedClientData = null;
					this.clientSearchFieldFocus = false;

					//Refresh Header
					this.updateResource();
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
					let index = this.headerReceiptDrafts.length - 1;
					this.openReceipt(docRef.id, index);
				})
				.catch((error) => {
					console.error("Error adding document Receipts Draft : ", error);
				});
			},

			/*
		██████╗ ██████╗ ███████╗███╗   ██╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
		██╔═══██╗██╔══██╗██╔════╝████╗  ██║    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
		██║   ██║██████╔╝█████╗  ██╔██╗ ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
		██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
		╚██████╔╝██║     ███████╗██║ ╚████║    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
		╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝    ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
		
		*/

			async openReceipt(receiptId, index) {
				console.log("OPENING RECEIPT");

				this.isSearchingHeader = false;
				this.selectedReceipt = index;

				this.clientReceiptDataLoading = true;
				this.loadingDataTable = true;
				this.selfGeneratedReceipt = false;
				this.refundBoolean = false;
				this.deletedSelfGeneratedItems =  new Array();

				this.clientId = "";
				this.feedbackArray = new Array();
				this.selectedClientData = new Object();
				this.clientLoyaltyDocs = new Array();

				this.prePayments = new Array();
				this.clientPrePayments = new Array();
				this.modifiers = deepCopy(this.modifiersClone); //Cleaning up Modifiers
				this.userMemberships = new Array();
				this.combinedModifiers = new Array();
				this.validForUseMemberships = new Array();
				this.notValidForUseMemberships = new Array();
				
				this.bookingSumArr = new Array();
				this.selectedItems = new Array();
				this.usedModifiers = new Array();
				this.deletedModifiers = new Array();
				this.customModifiers = new Array();
				this.treatmentsByBranchClone = new Array();
				this.productsByBranchClone = new Array();
				this.historyReceipts = new Array();

				this.getRegisterCash();

				this.selectedReceiptId = receiptId;
				let receiptObj = this.localReceiptDrafts.find((localReceipt) => localReceipt.id == receiptId);

				//if uniqIdCluster represent
				if (receiptObj) {
					// console.log("Found Receipt OBJ: ", receiptObj, index);
					await db
						.collection("receiptsDraft")
						.doc(receiptObj.id)
						.get()
						.then((doc) => {
							receiptObj = doc.data();
							receiptObj.id = doc.id;
							console.log("Receipt OBJ Modifiers", receiptObj);
						})
						.then(() => {
							this.clientId = receiptObj.clientId; //Client Id
							this.clientnameReceipt = receiptObj.clientname; //Full Client name
							this.selectedItems = receiptObj.selectedItems;

							
							this.selectedItems = receiptObj.selectedItems.map(item => {
								if (!item.cartItemId) {
									 item.cartItemId = `cart_${item.id}_${uniqid.time()}`;
								}
								return item;
						  });


							this.deletedSelfGeneratedItems = receiptObj.deletedSelfGeneratedItems ? receiptObj.deletedSelfGeneratedItems : new Array();
							this.usedModifiers = receiptObj.usedModifiers ? receiptObj.usedModifiers : new Array();
							this.customModifiers = receiptObj.customModifiers ? receiptObj.customModifiers : new Array();
							this.deletedModifiers = receiptObj.deletedModifiers ? receiptObj.deletedModifiers : new Array();
							this.validForUseMemberships = receiptObj.validForUseMemberships ? receiptObj.validForUseMemberships : new Array();

							// console.log("VALID MEMBERSHIP A ",receiptObj.validForUseMemberships)

							this.byResource = receiptObj.userId ? receiptObj.userId : null;
							// Recalculate

							// Add the custom Modifier
							this.modifiers = this.modifiers.concat(this.customModifiers);

							// When SelectedItems is empty we don't need to download other items
							return;
						})

						.catch((err) => {
							console.error("Error getting Receipt Draft:", err);
						});

					if (this.selectedItems.length !== 0) {
						console.log("SelectedItems Open Receipt", this.selectedItems.length);
						console.log("downloadProductsAndTreatments")
						await this.downloadProductsAndTreatments();
					}


					if (receiptObj && receiptObj.clientId) {
						this.getSelectedClientData(receiptObj.clientId, "", receiptObj);
						this.clientId = receiptObj.clientId;
					} else {
						this.getTotal();
						this.clientReceiptDataLoading = false;
						this.loadingDataTable = false;
					}

					// //Place the selected Receipt by selectedReceiptid at the top of the list
					this.receiptIndex = this.headerReceiptDrafts.findIndex((receipt) => receipt.id === this.selectedReceiptId);
					
					//Slice the index and place as first in the array 
					this.headerReceiptDrafts.splice(0, 0, this.headerReceiptDrafts.splice(this.receiptIndex, 1)[0]);
					this.headerReceiptDraftsSearch.splice(0, 0, this.headerReceiptDraftsSearch.splice(this.receiptIndex, 1)[0]);
				}
			},

			/**
			 * Used for Receipts with a Uniq Stamp. Generated by the postBooking API
			 * Generate treatments for the receipt by its own uniq id uniqIdStamp
			 */
			async generateByUniqIdStamp(uniqIdStamp, selectedItems, receiptId) {
				let vm = this;

				let itemsUserAdded = selectedItems.filter((selectedProductsTreats) => selectedProductsTreats.userAdded == true);

				this.bookingSumArr = new Array();
				let bookings = new Array();

				let bookingSummary = db
					.collection("bookingsummary")
					.where("branchId", "==", this.$store.state.activeBranch.id)
					.where("uniqIdStamp", "==", uniqIdStamp)
					.where("deleted", "==", false)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							//  console.log("Booking Sum Loaded =>", doc.data());
							let docObj = new Object();
							docObj = doc.data();
							docObj.id = doc.id;
							this.bookingSumArr.push(docObj);
						});
						return;
					})
					.catch((error) => {
						console.error("Error getting booking summary by UniqId: " + error.message);
					});

				let bookingen = db
					.collection("bookingen")
					.where("branchId", "==", this.$store.state.activeBranch.id)
					.where("uniqIdStamp", "==", uniqIdStamp)
					.where("deleted", "==", false)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							// console.log(doc.id, "=> Boooking Loaded");
							let docObj = new Object();
							docObj = doc.data();
							docObj.id = doc.id;
							bookings.push(docObj);
						});
						return;
					})
					.catch((error) => {
						console.error("Error getting booking by UniqId: " + error.message);
					});

				// Get the resources

				await Promise.all([bookingSummary, bookingen]).then((result) => {

					// console.log("Booking Summary", bookingSums);
					// console.log("Booking", bookings);

					if (bookings.length === 0 && vm.bookingSumArr.length === 0) {
						this.selfGeneratedReceipt = false; //Receipt is NOT Self Generated
						console.log("No Booking and booking summary found");
					} else {
						//Booking and booking summary found
						this.selfGeneratedReceipt = true; //Receipt is  Self Generated
						console.log("Booking and booking summary found");
					}
				});

				/**
				* Checking if the receipt is self-generated. If it is not, then it will not be sent to the user.
				*/
				// No other treatments to connect
				if (this.selfGeneratedReceipt == false) {
					return;
				}
				let treatmentsFromBooking = await getTreatments();

				function getTreatments() {
					
					return new Promise((resolve) => {
		
						let countTreatments = 0;
						let treatments = new Array();
						//Without Duplicates 
						//TODO Object van maken en booking Summary meenemen?
						let getTreatmentIds = [...new Set(vm.bookingSumArr.map((bookingSum) => bookingSum.treatmentId))];

						getTreatmentIds.forEach((treatmentId) => {
							//Get firebase document
							db.collection("treatments")
								.doc(treatmentId)
								.get()
								.then((doc) => {
									if(doc.exists){
										let obj = doc.data();

										// obj.amount = 1; //Standard Amount
										obj.priceBruto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').bruto  : 0;
										obj.addedDiscount = 0; //Extra Discount
										obj.calculatedDiscountVAT = 0;
										obj.calculatedDiscountNetto = 0;
										obj.usedModifierId = new Array();
										obj.usedMembershipId = new Array();

										obj.priceNetto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').netto : 0; //New Price with Discount and amount and VAT
										obj.newPriceTotalNetto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').netto : 0; //New Price with Discount and amount and VAT
										obj.newPriceTotal = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').bruto : 0; //Bruto TotalPrice
										obj.percentageVAT = doc.data().treatmentPrice ? vm.getVatPercentage(doc.data().vat) : 0; //Vat Percentage

										obj.cartItemId = `cart_${doc.id}_${uniqid.time()}`; // New Cart Item ID

										obj.name = doc.data().treatmentTitle;

										obj.internalUse = false; //False by default and set to true if internal use via GUI

										obj.type = "treatment";
										obj.id = doc.id;
										obj.selected = false; //For toggling
										obj.typeOfPayment = "deposit";
										treatments.push(obj);
										vm.treatmentsByBranchClone.push(obj);
									}
									
								})
								.then(() => {
									countTreatments++;

									if (countTreatments === getTreatmentIds.length) {
										resolve(treatments);
									}
								})
								.catch((error) => {
									console.error("Error getting treatment", error);
								});
						});
					});
				}

				// console.log("itemsUserAdded", itemsUserAdded);
				// console.log("Treatments:", treatmentsFromBooking);
				// console.log("bookingSums: ", bookingSums);
				// console.log("bookings: ", bookings);

				//Sort by Time
				vm.bookingSumArr.sort(function (a, b) {
					// Sortby  ["start"] ["desc"]
					// If the first item has a higher number, move it down
					// If the first item has a lower number, move it up
					if (a.start < b.start) return -1;
					if (a.start > b.start) return 1;
				});

				let autoGeneratedTreatments = new Array();

				vm.bookingSumArr.forEach((bookingSum) => {

					//Get the Right Treatment
					let foundedTreatment = deepCopy(treatmentsFromBooking.find((treatment) => treatment.id === bookingSum.treatmentId));

					if(foundedTreatment != undefined){
						//Add the BookingSum AND uniqIdStamp to the treatment selectedItem 
						foundedTreatment.bookingSummaryId = bookingSum.id;
						foundedTreatment.uniqIdStamp = uniqIdStamp;

						let foundedTreatmentClone = deepCopy(foundedTreatment); //Clone for manipulation


						foundedTreatmentClone.subTreatments = new Array();

						//Get bookings by BookingSummary
						let bookingsByBookingSum = bookings.filter((booking) => booking.bookingId === bookingSum.id);

						foundedTreatment.subTreatments.map((subTreatment) => {
							// console.log("bookingsByBookingSum", bookingsByBookingSum);
							// console.log("subTreatment", subTreatment);

							//Get bookings with Employee
							let getSubTreatmentBooking = bookingsByBookingSum.find(
								(booking) => booking.description[this.$store.state.locale.toLowerCase()] === subTreatment.description[this.$store.state.locale.toLowerCase()] && booking.resourceType === "employee"
							);

							console.log("getSubTreatmentBooking", getSubTreatmentBooking)

							//If booking of subtreatment is found
							if (getSubTreatmentBooking) {
								//Only Employee
								if (getSubTreatmentBooking.resourceType == "employee") {
									subTreatment.resourceId = getSubTreatmentBooking.resourceDocId;
									foundedTreatmentClone.subTreatments.push(subTreatment);
								} else {
									foundedTreatmentClone.subTreatments.push(subTreatment);
								}
							}
						});
						console.log("foundedTreatmentClone", foundedTreatmentClone);
						autoGeneratedTreatments.push(foundedTreatmentClone);
					}
					
				});

				
				//Remove the that are deleted via the this.deletedSelfGeneratedItems 

				console.log("deletedSelf Generated", this.deletedSelfGeneratedItems, autoGeneratedTreatments);

				//remove item from autoGeneratedTreatments with the same uniqIdStamp and bookingSummaryId from deletedSelfGeneratedItems
				autoGeneratedTreatments = autoGeneratedTreatments.filter((item) => {
					let found = this.deletedSelfGeneratedItems.find((deletedItem) => 
					deletedItem.uniqIdStamp === item.uniqIdStamp && deletedItem.bookingSummaryId === item.bookingSummaryId)
					//if the deleted item is found, return false remove it from the autoGeneratedTreatments, else return true
					if (!found) {
						return true;
					} else {
						return false;
					}
					});
				
				console.log("autoGeneratedTreatments", autoGeneratedTreatments);


				//Add the cartItemId to the autoGeneratedTreatments
				autoGeneratedTreatments = autoGeneratedTreatments.map(item => {
					if (!item.cartItemId) {
						 item.cartItemId = `cart_${item.id}_${uniqid.time()}`;
					}
					return item;
			  });


				this.selectedItems = [...autoGeneratedTreatments, ...itemsUserAdded];

				//Download the necessary treatments and products
				await this.downloadProductsAndTreatments();

				return;
			},

			checkPaymentSettings(typePayment){
				if((typePayment == "PIN" || typePayment == "SPLIT") && this.$store.state.activeBranch.paymentOptions.length == 0){
					this.noPaymentOptionsDialog = true;
				}
				else{
					if(this.clientId){
						this.injectBeforePay(typePayment)
					}
					else{
						this.anonymousClient.paymentType = typePayment;
						this.anonymousClient.dialog = true;
					}
				}
				
			},

			async getRegisterCash(){
				await db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.cashStatement = doc.data().cash;
					}
					else {
						console.error("No CashState document for this branch!");
					}
				})
				.catch((error) => {
					console.error("Error getting Cash Statement", error);
				});
			},

			injectBeforePay(typePayment) {

				if(typePayment == "REFUNDCASH"){
					this.injectBeforeRefund()
				}
				else{
					this.anonymousClient.dialog = false;
					this.anonymousClient.paymentType = null;
					this.tab = 0;
					this.changeCalculated = 0;
					this.change = 0;

					db.collection("cashRegister")
					.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
					.get()
					.then((doc) => {
						if (doc.exists) {
							this.cashStatement = doc.data().cash;
						}
						else {
							console.error("No CashState document for this branch!");
						}
					})
					.catch((error) => {
						console.error("Error getting Cash Statement", error);
					});

					console.log("Inject Before Pay", typePayment);
					//Set settings for PIN
					if (typePayment == "PIN") {
						// this.displayChange = "--";
						// this.changeFunc(this.totalBrutoTwoDigits)
						// this.displayChange = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
						this.change = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
						// this.displayChange = formatToCurrency('nl-NL', null, this.totalBrutoTwoDigits);

						const currencyFormat = new Intl.NumberFormat("nl-NL", {
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});

						this.displayChange = currencyFormat.format(this.totalBrutoTwoDigits);
					
						this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
						this.payDialog = true;
						this.typePayment = "PIN";
						this.splitPayment.transactionId = null;
						this.splitPayment.splitActive = false
						this.splitPayment.splitByCash = null;
					}
					//Set settings for Cash
					if (typePayment == "CASH") {
						this.displayChange = "--";
						this.payDialog = true;
						this.typePayment = "CASH";
						this.splitPayment.transactionId = null;
						this.splitPayment.splitActive = false
						this.splitPayment.splitByCash = null;
					}
					//Set settings for Split
					if (typePayment == "SPLIT") {
						this.displayChange = "--";
						this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
						this.splitPayment.transactionId = uniqid();
						this.splitPayment.splitActive = true;
						this.splitPayment.splitByCash = cashConv(this.totalBruto, "EUR", 2);
					
						this.payDialog = true;
						this.typePayment = "SPLIT";
					}
					//Set settings for Cash
					if (typePayment == "MONEYTRANSFER") {
						this.change = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
						// this.displayChange = formatToCurrency('nl-NL', null, this.totalBrutoTwoDigits);

						const currencyFormat = new Intl.NumberFormat("nl-NL", {
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});

						this.displayChange = currencyFormat.format(this.totalBrutoTwoDigits);
					
						this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
						this.payDialog = true;
						this.typePayment = "MONEYTRANSFER";
						this.splitPayment.transactionId = null;
						this.splitPayment.splitActive = false
						this.splitPayment.splitByCash = null;
					}
					//Set settings for Cash
					if (typePayment == "TIKKIE") {
						this.change = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
						// this.displayChange = formatToCurrency('nl-NL', null, this.totalBrutoTwoDigits);

						const currencyFormat = new Intl.NumberFormat("nl-NL", {
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});

						this.displayChange = currencyFormat.format(this.totalBrutoTwoDigits);
					
						this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
						this.payDialog = true;
						this.typePayment = "TIKKIE";
						this.splitPayment.transactionId = null;
						this.splitPayment.splitActive = false
						this.splitPayment.splitByCash = null;
					}

					if (typePayment == "FREE") {
						this.typePayment == "FREE";
						this.payReceipt();
					}
				}
			},

			injectBeforeRefund(typePayment) {
				
				this.typePayment = "REFUNDCASH";

				//Make it absolute
				this.totalBrutoAbsolute = Math.abs(this.totalBruto);
				this.changeBrutoToClient = deepCopy(Math.abs(this.totalBruto));

				db.collection("cashRegister")
					.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
					.get()
					.then((doc) => {
						if (doc.exists) {
							this.cashStatement = doc.data().cash;
							this.payDialog = true;
							console.log("cash statement loaded", this.cashStatement);

							if (this.totalBrutoAbsolute > this.cashStatement) {
								this.feedbackPayDialog.feedback = "Let op er is niet genoeg contant aanwezig om te retouneren.";
								this.feedbackPayDialog.code = 100;
							}
						}
						else{
							this.createCashRegister('injectBeforeRefund') // Create cashRegister Doc
						}
					})
					.catch((error) => {
						console.error("Error getting Cash Statement", error);
						this.cashUpDialog = false;
					});
			},

			//Delete receipt
			deleteReceiptDraft(selectedReceiptId) {
				this.searchboxLabel = "Zoek een klant";
				this.selectedItems = new Array();
				this.usedModifiers = new Array();
				this.deletedModifiers = new Array();
				//Filter out Receipt
				this.localReceiptDrafts = this.localReceiptDrafts.filter((localReceipt) => localReceipt.id !== selectedReceiptId);

				this.selectedReceiptId = "";
				this.clientId = "";
				this.updateResource();
				this.getTotal();

				db.collection("receiptsDraft")
				.doc(selectedReceiptId)
				.delete()
				.then(() => {
					console.log("Document:", selectedReceiptId, "successfully deleted!");
				})
				.catch((error) => {
					console.error("Error removing document: ", error);
				});
			},

			closeReceipt() {
				this.selectedReceiptId = "";
				this.byResource = null;
				this.clientId = "";
				this.selectedItems = new Array();
			},

			openExpense() {
				this.expensesOrginal = _.cloneDeep(this.expenses);
				this.expensesDialog = true;

				db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.cashStatement = doc.data().cash;
						this.expensesDialog = true;
					}
					else{
						this.createCashRegister('openExpense') // Create cashRegister Doc
					}
				})
				.catch((error) => {
					console.error("Error getting Cash Statement", error);
					this.cashUpDialog = false;
				});
			},

			closeDiscountDialog() {
				this.scanDiscountDialog = false;
				this.scanDiscountDialogObject.barcode = null;
			},

			validateBarcodeAndAdd() {
				let getModifier = this.modifiers.find((modifier) => modifier.barcode.code === this.scanDiscountDialogObject.barcode);

				console.log("getModifier", getModifier);
				if (getModifier) {
					this.usedModifiers.push(getModifier);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				}
			},

			closeExpense() {
				this.expensesDialog = false;

				this.expenses.chosenTypeExpenses = "";
				this.expenses.amountValue = 0;
				this.expenses.chosenVat = "";
				this.expenses.feedback = null;
				this.expenses.buttonDisabled = true;
				this.expenses.name = "";
				this.expenses.loading = false;
			},

			validateExpense(input) {
				this.expenses.amountValue = input; //Number will be cleaned add saveExpense() 

				if (formatToCleanNumber(input) > this.cashStatement) {
					this.expenses.feedback = "Niet genoeg in kas of de kasstaat komt niet overeen";
				} else {
					this.expenses.feedback = null;
				}
			},

			saveExpense() {
				// if (this.expenses.feedback == null) {
				this.expenses.loading = true;

				//Clean the numbers from validateExpense()
				this.expenses.amountValue = formatToCleanNumber(this.expenses.amountValue);


				db.collection("cashRegister")
					.add({
						byResource: this.userId,
						expenseName: this.expenses.name,
						typeExpenses: this.expenses.chosenTypeExpenses,
						expense: this.expenses.amountValue,
						vatId: this.expenses.chosenVat ? this.expenses.chosenVat.id : null,
						register: this.selectedCashRegister,
						branchId: this.$store.state.activeBranch.id,
						companyId: this.$store.state.activeCompany.id,
						created: new Date(),
						type: "expense",
					})
					.then((docRef) => {
						console.log("Pay Into done... =>", docRef.id);

						//Cash update
						db.collection("cashRegister")
							.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
							.update({
								cash: this.cashStatement - this.expenses.amountValue,
								timestamp: new Date(),
							})
							.then(() => {
								console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);

								this.closeExpense();
							})
							.catch((err) => {
								console.error("Cash Error", err);
							});
					})
					.catch((error) => {
						console.error("Error adding document Pay Into : ", error);
					});
				// }
			},

			openDeposit() {		
				this.depositOrg = _.cloneDeep(this.deposit);
				db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.cashStatement = doc.data().cash;
						this.depositDialog = true;
					}
					else{
						this.createCashRegister('openDeposit') // Create cashRegister Doc
					}
					console.log("cash statement loaded", this.cashStatement);
				})
				.catch((error) => {
					console.error("Error getting Cash Statement", error);
				});
			},

			saveDeposit() {
				console.log("this.deposit.namePayInto.length", this.deposit.namePayInto.length, this.deposit.namePayInto);
				if (this.deposit.namePayInto.length < 2) {
					return (this.deposit.feedback = "Vul een juiste naam in.");
				} else {
					this.deposit.feedback = null;
				}

				if (this.deposit.payInto == 0) {
					return (this.deposit.feedback = "Vul een juiste storting waarde in.");
				} else {
					this.deposit.feedback = null;
				}

				if (this.deposit.feedback == null) {
					this.deposit.loading = true;

					db.collection("cashRegister")
						.add({
							byResource: this.userId,
							depositName: this.deposit.namePayInto,
							deposit: this.deposit.payInto,
							depositType: "depositToCash",
							register: this.selectedCashRegister,
							branchId: this.$store.state.activeBranch.id,
							companyId: this.$store.state.activeCompany.id,
							created: new Date(),
							type: "deposit",
						})
						.then((docRef) => {
							console.log("Pay Into done... =>", docRef.id);

							
							// Cash update
							db.collection("cashRegister")
								.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
								.set({						
									cash: firebase.firestore.FieldValue.increment(this.deposit.payInto),
									timestamp: new Date(),
								}, {merge: true})
								.then(() => {
									console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
									this.deposit.loading = false;
									this.depositDialog = false;
									this.deposit = _.cloneDeep(this.depositOrg);
								})
								.catch((err) => {
									console.error("Cash Error", err);
								});
						})
						.catch((error) => {
							console.error("Error adding document Pay Into : ", error);
						});
				}
			},

			closeDeposit() {
				this.depositDialog = false;
				this.cashUp = {
					oneEuroCent: 0,
					twoEuroCent: 0,
					fiveEuroCent: 0,
					tenEuroCent: 0,
					twentyEuroCent: 0,
					fiftyEuroCent: 0,
					oneEuro: 0,
					twoEuro: 0,
					fiveEuro: 0,
					tenEuro: 0,
					twentyEuro: 0,
					fiftyEuro: 0,
					hundredEuro: 0,
					twoHundredEuro: 0,
					fiveHundredEuro: 0,
					pin: 0,
					creditcard: 0,
				};

				this.cashStatement = 0;
				this.cashStatementStay.value = 0;
			},
	
			async createCashRegister(functionName){
				await db.collection("cashRegister").doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`).set({
					cash: 0,
					cashRounding: 0,
					cashUpCash: 0,
					cashUpTimestamp: null,
					timestamp: new Date()
				})
				.catch((error) => {
					console.error("Error creating cashRegister", error);
				});
				this[functionName]()
			},


			messageTranslate(message) {
				console.log("message", message);

				let getLanguage = message.find((mess) => mess.language === this.userLanguage.toLowerCase());

				if (getLanguage) {
					return getLanguage.message;
				} else {
					if (message.length === 0) {
						return "";
					} else {
						message[0].message;
					}
				}
			},

			clearClient() {
				//clearClient is not active or is in conceptstate.

				this.selectedClientData = new Object();
				this.clientId = "";

				//Remove Modifiers and Memberships
				this.usedModifiers = this.usedModifiers.filter((usedModifier) => !usedModifier.modifierGroup === "membershipModifier");
				// this.usedModifiers = this.usedModifiers.filter(usedModifier => !usedModifier.byMembership);
				// this.modifiers = this.modifiers.filter(usedModifier => !usedModifier.type === "memberhsip");
			
				this.validForUseMemberships = new Array();
				
				this.notValidForUseMemberships = new Array();

				//localReceipt remove Client name and Id
				let localReceiptIds = this.localReceiptDrafts.map((localReceipt) => localReceipt.id);
				console.log("localReceiptIds", localReceiptIds, localReceiptIds.indexOf(this.selectedReceiptId));
				let foundedIndex = localReceiptIds.indexOf(this.selectedReceiptId);

				this.localReceiptDrafts[foundedIndex].clientname = "";
				this.localReceiptDrafts[foundedIndex].clientId = "";

				db.collection("receiptsDraft")
				.doc(this.selectedReceiptId)
				.update({
					clientname: this.$t('payment.anonymous', this.$store.state.locale),
					clientId: "",
				})
				.then(() => {
					//Recalculate
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				})
				.catch((error) => {
					console.error("Error updating document Receipts Draft : ", error);
				});
			},

			//Get the selected Client Data
			async getSelectedClientData(clientId, fullName, receiptObj) {
				let vm = this;

				this.clientLoading = true;

				//Memberships
				this.userMemberships = new Array();
				this.validForUseMemberships = new Array();
				this.notValidForUseMemberships = new Array();
				

				//Generated by Agenda or Widget
				if (receiptObj) {
					if (receiptObj.uniqIdStamp) {
						console.log("Activeer generateByUniqIdStamp");
						await this.generateByUniqIdStamp(receiptObj.uniqIdStamp, receiptObj.selectedItems, receiptObj.id);
					}
				}

				//Download History Receipts
				historyReceipts();

				async function historyReceipts() {
					return await db
						.collection("receiptsPaid")
						.where("companyId", "==", vm.$store.state.activeCompany.id)
						.where("clientId", "==", clientId)
						.orderBy("created", "desc")
						.limit(5)

						.get()
						.then((snap) => {
							snap.forEach((doc) => {
								console.log("Found History Receipt", doc.id);
								let historyReceipt = doc.data();
								historyReceipt.id = doc.id;
								vm.historyReceipts.push(historyReceipt);
							});
						})
						.then(() => {
							return;
						})
						.catch((err) => {
							console.error("Error History document", err);
						});
				}

				db.collection("clients")
					.doc(clientId)
					.get()
					.then((doc) => {
						console.log("We have clientData");
						this.selectedClientData = doc.data();
						this.selectedClientData.id = doc.id;
						this.selectedClientData.history = null;
					})
					.then(() => {
						// Get Future Appointments Client
						this.getFutureAppointmentsClient();

						//memberships and prePayments

						this.loadMemberships = true;
						Promise.all([membershipAllBranches(), membershipByBranch(), getClientMemberships(), getPrePayments(), getLoyaltyDocs()]).then(() => {
							/**
							 * MEMBERSHIPS
							 */

							//Data for modifier is manipulated Inside the module
							const clonedUserMemberships = JSON.parse(JSON.stringify(this.userMemberships));
							const clonedSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
							const clonedClientId = this.selectedClientData.id;
							console.log(receiptObj)
							const clonedReceiptObj = receiptObj ? JSON.parse(JSON.stringify(receiptObj)) : new Object;

							let data = membershipValidation(clonedUserMemberships, clonedSelectedItems, clonedClientId, clonedReceiptObj, this.bookingSumArr);

							console.log("membership data", data);

							const today = moment().format('YYYY-MM-DD');

							// Combineer alle memberships en sorteer ze op startDate (nieuwste eerst)
							let allMemberships = [...data.validForUseMemberships, ...data.notValidForUseMemberships]
								.sort((a, b) => moment(b.startDate).valueOf() - moment(a.startDate).valueOf());



							// Filter op huidige en toekomstige memberships
							const currentMemberships = allMemberships.filter(m => 
								moment(m.startDate).format('YYYY-MM-DD') <= today
							);
							const futureMemberships = allMemberships.filter(m => 
								moment(m.startDate).format('YYYY-MM-DD') > today
							);

							// Selecteer één membership
							let selectedMembership;
							if (currentMemberships.length > 0) {
								// Neem altijd de nieuwste van de huidige memberships, ongeacht status
								selectedMembership = currentMemberships[0];
							} else if (futureMemberships.length > 0) {
								// Als er geen huidige memberships zijn, neem de eerste (vroegste) toekomstige
								selectedMembership = futureMemberships[futureMemberships.length - 1];
							}

						
							this.validForUseMemberships = selectedMembership ? [selectedMembership] : [];


							//LEGACY CODE BEGIN
							// this.validForUseMemberships = data.validForUseMemberships;
							// console.log("validForUseMemberships", this.validForUseMemberships);
							// console.log("VALID MEMBERSHIP", data.validForUseMemberships);



							// this.notValidForUseMemberships = data.notValidForUseMemberships;
							

							//Period or Total go to Front for Override

							// //TODO if statement maken om duplicaties te voorkomen
							// this.notValidForUseMemberships.forEach((membership) => {
							// 	//Every status that is not valid is like below. If a  membership is valid the status = true;

							// 	if (membership.status.code === "2A" || membership.status.code === "2B") {
							// 		this.validForUseMemberships.push(membership);
							// 	}
							// });

							// this.validatedByMembershipModule = [...data.validForUseMemberships, ...data.notValidForUseMemberships];
							// this.validatedByMembershipModule = _.uniqBy(this.validatedByMembershipModule, ["id"]);

							// // this.validForUseMemberships.forEach((validMembership) => this.modifiers.push(validMembership));

							/**
							 * prePayments
							 */

							// let newPrePaymentModifier = new Object();
							// let newPrePaymentModifierDiscount = new Object();

							//LEGACY CODE END

							if (this.selfGeneratedReceipt === true) {
								//Start matching with UniqIdStamp

								let foundedPrepayment = this.prePayments.find((prePayment) => prePayment.uniqIdStamp === receiptObj.uniqIdStamp);

								console.log("receiptObj.uniqIdStamp", receiptObj.uniqIdStamp, foundedPrepayment);

								/**
								 *
								 * Generated A fixed Amount Discount for Treatment
								 * 1. Get the highest Price Treatment first and place them in a filter
								 * 2. Generated the fixed amount for Pre Payment.
								 */

								//check if there is a prepayment
								if (!foundedPrepayment || foundedPrepayment.paymentStatus !== "paid") {
									//Nothing to match return; 

									this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

									this.loadingDataTable = false;
									this.clientReceiptDataLoading = false;
									this.loadMemberships = false;

									return;

								}

								function typePrePayment(type) {
									if (type === "prePayment") {
										return "Vooruitbetaling"; //prePayment
									} else {
										return "Aanbetaling"; //downPayment
									}
								}

								if (foundedPrepayment.type === "downPayment") {

									let newDownPaymentModifier = new Object();

									newDownPaymentModifier.name = typePrePayment(foundedPrepayment.type);
									newDownPaymentModifier.id = foundedPrepayment.id;
									newDownPaymentModifier.timestamp = foundedPrepayment.timestamp;
									newDownPaymentModifier.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "prePaymentManualAdded").priorityNumber;

									// newDownPaymentModifier.createdStamp = new Date();

									newDownPaymentModifier.byPrePayment = true;
									newDownPaymentModifier.prePaymentDiscount = false; //Extra PrePaid Discount
									newDownPaymentModifier.manualAddedPrePayment = true;

									newDownPaymentModifier.type = foundedPrepayment.type; //PrePayment or DownPayment
									newDownPaymentModifier.modifierGroup = foundedPrepayment.type //PrePayment or DownPayment
									newDownPaymentModifier.paymentType = foundedPrepayment.type;
									newDownPaymentModifier.automatic = false;
									newDownPaymentModifier.active = true;
									newDownPaymentModifier.addedVia = "downPayment";

									newDownPaymentModifier.typeDiscount = {
										type: "fixedAmount",
										discountType: "amount",
										uniqueItems: false,
										discountValue: foundedPrepayment.amount.number,
									};
									newDownPaymentModifier.numberLimit = {
										active: false,
									};
									newDownPaymentModifier.combinationLimit = false;

									newDownPaymentModifier.useLimit = {
										active: true,
										number: 1,
									};

									newDownPaymentModifier.filterProducts = {
										active: true,
										items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
										itemsAction: "include",
										type: "all",
									};

									newDownPaymentModifier.amount = foundedPrepayment.amount;

									newDownPaymentModifier.filterTreatments = {
										active: true,
										items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
										itemsAction: "include",
										type: "all",
									};

									this.usedModifiers = [newDownPaymentModifier, ...this.usedModifiers]; //We want to load this first.
									this.modifiers.push(newDownPaymentModifier);
									this.customModifiers.push(newDownPaymentModifier);
								}
							}
							//Recalculate after correspondingMembership is found
							this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

							this.loadingDataTable = false;
							this.clientReceiptDataLoading = false;
							this.loadMemberships = false;
						})


						async function membershipAllBranches() {
							const date30DaysAgo = moment().subtract(30, 'days').format("YYYY-MM-DD");

							const [nullEndDateQuery, oldEndDateQuery] = await Promise.all([
								db
									.collection("memberships")
									.where("companyId", "==", vm.$store.state.activeCompany.id)
									.where("clientId", "==", vm.selectedClientData.id)
									.where("branches.all", "==", true)
									.where("status.endDate", "==", null)
									.get(),
								db
									.collection("memberships")
									.where("companyId", "==", vm.$store.state.activeCompany.id)
									.where("clientId", "==", vm.selectedClientData.id)
									.where("branches.all", "==", true)
									.where("status.endDate", ">=", date30DaysAgo)
									.get(),
							]);

							console.log("nullEndDateQuery", nullEndDateQuery, "oldEndDateQuery", oldEndDateQuery);

							[...nullEndDateQuery.docs, ...oldEndDateQuery.docs].forEach(doc => {
								console.log("Found Membership for All Branches", doc.id);
								let userMembership = doc.data();
								userMembership.id = doc.id;
								userMembership.priority = vm.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "membershipDiscount").priorityNumber;
								vm.userMemberships.push(userMembership);
							})
						}

						function  calculateUsage(item){
							let usage = item.usage && item.usage.length > 0 ? item.usage.length : 0;
							let totalUsageNumber = 0;
					  
							if(item.usageRules?.limitType == 'total'){
							  totalUsageNumber = item.usageRules.limitNumber
							}
							else{ 
							  totalUsageNumber = item.usageRules.limitNumber * item.duration
							}
					  
							let usageLeft = (totalUsageNumber - usage) > 0 ? (totalUsageNumber - usage)  : 0
					  
							return [totalUsageNumber,usageLeft]
						  }

						async function membershipByBranch() {

							const date30DaysAgo = moment().subtract(30, 'days').format("YYYY-MM-DD");

							const [nullEndDateQuery, oldEndDateQuery] = await Promise.all([
								db
									.collection("memberships")
									.where("companyId", "==", vm.$store.state.activeCompany.id)
									.where("clientId", "==", vm.selectedClientData.id)
									.where("branches.branches", "array-contains", vm.$store.state.activeBranch.id)
									.where("status.endDate", "==", null)
									.get(),
								db
									.collection("memberships")
									.where("companyId", "==", vm.$store.state.activeCompany.id)
									.where("clientId", "==", vm.selectedClientData.id)
									.where("branches.branches", "array-contains", vm.$store.state.activeBranch.id)
									.where("status.endDate", "<=", date30DaysAgo)
									.get(),
							]);

							
							console.log("nullEndDateQuery", nullEndDateQuery, "oldEndDateQuery", oldEndDateQuery);

							[...nullEndDateQuery.docs, ...oldEndDateQuery.docs].forEach(doc => {
								console.log("Found Membership for specific Branches", doc.id);
								let userMembership = doc.data();
								userMembership.id = doc.id;
								userMembership.priority = vm.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "membershipDiscount").priorityNumber;
								vm.userMemberships.push(userMembership);
							})
						}

                  async function getClientMemberships() {

                     console.log("getClientMemberships");
                     console.log(vm.$store.state.activeCompany.id, vm.selectedClientData.id);

                     vm.clientMemberships = new Array();
                     return await db.collection("memberships")
                     .where("companyId", "==", vm.$store.state.activeCompany.id)
                     .where("clientId", "==", vm.selectedClientData.id)
                     .get()
                     .then((snap) => {
                       snap.forEach((doc) => {
                         let membership = doc.data();
                         membership.id = doc.id;
						 membership.start = moment(membership.startDate).format("YYYY-MM-DD");
						 membership.expireDate = membership.status.endDate ? membership.status.endDate : vm.$t('memberships.autoRenewShort', vm.$store.state.locale);
						[membership.usageNumber, membership.usageLeft] = calculateUsage(membership);
                         vm.clientMemberships.push(membership);
                       });
                     }).then(() => {
                        vm.clientMemberships.sort((a, b) => b.created.seconds - a.created.seconds);
                     }).catch((err) => {
                        console.error("Error memberships", err);
                     });
                  
                  }

						async function getPrePayments() {
							return await db
							.collection("prepayments")
							.where("companyId", "==", vm.$store.state.activeCompany.id)
							// .where("branchId", "==", vm.$store.state.activeBranch.id)
							.where("clientId", "==", vm.selectedClientData.id)
							.where("status.returned", "==", false)

							.get()
							.then((snap) => {
								snap.forEach((doc) => {
									let prePayment = doc.data();
									prePayment.id = doc.id;
									if(!doc.data().branchId || doc.data().branchId == vm.$store.state.activeBranch.id){
										vm.prePayments.push(prePayment);
										vm.clientPrePayments.push(prePayment);
										// vm.prePaymentsListTab.push(prePayment);
									}
								});
							})
							.then(() => {
								//vm.clientPrePayments.sort((a, b) => a.timestamp.seconds.localeCompare(b.timestamp.seconds));
								vm.clientPrePayments.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
								return;
							})
							.catch((err) => {
								console.error("Error prePayments", err);
							});
						}

						//Get all the Loyaty docs for Client X
						async function getLoyaltyDocs() {
							if (vm.$store.state.activeBranch.loyalty && vm.$store.state.activeBranch.loyalty.active === true) {
								//TODO - Get all the loyalty docs for this client

								//async map loop
								return await Promise.all(
									vm.$store.state.activeBranch.loyalty.redeemBranches.map(async (branchId) => {
										//Get all the loyalty docs for Client X
										return await db
											.collection("loyaltyPoints")
											.where("companyId", "==", vm.$store.state.activeCompany.id)
											.where("branchId", "==", branchId)
											.where("clientId", "==", vm.selectedClientData.id)

											.get()
											.then((snap) => {
												snap.forEach((doc) => {
													let loyaltyDoc = doc.data();
													loyaltyDoc.id = doc.id;
													vm.clientLoyaltyDocs.push(loyaltyDoc);
												});
											})
											.then(() => {
												return;
											})
											.catch((err) => {
												console.error("Error loyaltyDocs", err);
											});
									})
								);
							} else {
								return;
							}
						}

						// //Receipt History
						// db.collection("receiptsDraft")
						//   .where("companyId", "==", this.activeComp)
						//   .where("clientId", "==", this.selectedClientData.id)
						//   .where("draft", "==", false)
						//   .get()
						//   .then((snap) => {
						//     snap.forEach((doc) => {
						//       console.log("Item gevonden: bon");
						//       let historyItem = doc.data();
						//       historyItem.id = doc.id;
						//       historyItems.push(historyItem);
						//     });
						//   })
						//   .then(() => {
						//     if (historyItems.length > 0) {
						//       // There are receipts from history
						//       let receiptCounter = 0;
						//       new Promise((resolve, reject) => {
						//         // Loop trough receipts
						//         historyItems.forEach((historyItem) => {
						//           // Loop trough items in receipt
						//           new Promise((res, rej) => {
						//             let itemCounter = 0;
						//             historyItem.selectedItems.forEach((item) => {
						//               let purchasedItem = item;
						//               purchasedItem.date = historyItem.created;
						//               if (item.type == "product") {
						//                 historyItemsProducts.push(purchasedItem);
						//               } else {
						//                 historyItemsTreatments.push(purchasedItem);
						//               }
						//               itemCounter++;
						//               if (itemCounter == historyItem.selectedItems.length) {
						//                 res();
						//               }
						//             });
						//           }).then(() => {
						//             receiptCounter++;
						//             if (receiptCounter == historyItems.length) {
						//               resolve();
						//             }
						//           });
						//         });
						//       })
						//         .then(() => {
						//           this.selectedClientData.history = {
						//             treatments: historyItemsTreatments,
						//             products: historyItemsProducts,
						//           };
						//         })
						//         .then(() => {
						//           this.clientLoading = false;
						//           this.clientReceiptDataLoading = false;
						//         });
						//     } else {
						//       // There are NO receipts from history
						//       this.selectedClientData.history = null;
						//       this.clientLoading = false;
						//       this.clientReceiptDataLoading = false;
						//     }
						//   })
						//   .then(() => {
						//     this.clientLoading = false;
						//     if (fullName) {
						//       this.updateResource(); //Update Resource
						//       this.updateReceipt(this.selectedReceiptId, clientId, fullName); //Update with new/changed Client Data
						//     }
						//   })
						//   .catch((error) => {
						//     this.clientReceiptDataLoading = false;
						//     console.error("Error getting client receipt history: ", error);
						//   });
					})
					.catch((error) => {
						this.clientReceiptDataLoading = false;
						console.error("Error getting clientdata: ", error);
					});
			},

			async downloadProductsAndTreatments() {
				let vm = this;

				//Get All neccesary Items without discount for Recalculations

				let downloadProductsArr = _.uniqBy(
					this.selectedItems
						.filter((selectedItem) => selectedItem.type === "product")
						.map((selectedItem) => {
							return { type: selectedItem.type, id: selectedItem.id };
						}),
					"id"
				);
				let downloadTreatmentsArr = _.uniqBy(
					this.selectedItems
						.filter((selectedItem) => selectedItem.type === "treatment")
						.map((selectedItem) => {
							return { type: selectedItem.type, id: selectedItem.id };
						}),
					"id"
				);
				//Re-Download the original Treatments and Products

				// console.log("downloadProductsArr", downloadProductsArr);
				// console.log("downloadTreatmentsArr", downloadTreatmentsArr);

				Promise.all([downloadTreatments(downloadTreatmentsArr), downloadProducts(downloadProductsArr)]).then(() => {
					console.log("Treatments and Products downloaded...");
					return;
				});

				function downloadTreatments(downloadTreatmentsArr) {
					return new Promise((resolve) => {
						//Resolve if there is nothing
						if (downloadTreatmentsArr.length === 0) resolve();

						let countTreatment = 0;
						downloadTreatmentsArr.forEach((downloadTreatment) => {
							db.collection("treatments")
							.doc(downloadTreatment.id)
							.get()
							.then((doc) => {
								if (doc.exists && (!doc.data().deleted || !doc.data().deleted.deleted)) { // Make sure treatment is not deleted
									let obj = doc.data();

									obj.priceBruto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').bruto  : 0;
									obj.addedDiscount = 0; //Extra Discount
									obj.calculatedDiscountVAT = 0;
									obj.calculatedDiscountNetto = 0;
									obj.usedModifierId = new Array();
									obj.usedMembershipId = new Array();

									obj.priceNetto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').netto  : 0; //New Price with Discount and amount and VAT
									obj.newPriceTotalNetto = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').netto  : 0; //New Price with Discount and amount and VAT
									obj.newPriceTotal = doc.data().treatmentPrice ? vm.calculatePrices(doc.data(), 'treatment').bruto  : 0; //Bruto TotalPrice
									obj.percentageVAT = doc.data().treatmentPrice ? vm.getVatPercentage(doc.data().vat) : 0; //Vat Percentage

									obj.name = doc.data().treatmentTitle;

									obj.internalUse = false; //False by default and set to true if internal use via GUI

									obj.type = "treatment";
									obj.id = doc.id;
									obj.selected = false; //For toggling

									//remove modified field as timestamp
									delete obj.modified;

									vm.treatmentsByBranchClone.push(obj);
								}
								else{ // Treatment is deleted of does not exist. Remove from  selectedItem from receipt
									vm.removeFromSelectedItemsReceipt(downloadTreatment.id ,'treatment') 
								}
							})
							.then(() => {
								countTreatment++;
								if (countTreatment === downloadTreatmentsArr.length) {
									
									resolve();
								}
							})
							.catch((err) => {
								console.error("Error loading Treatment from Database", err);
							});
						});
					});
				}

				function downloadProducts(downloadProductsArr) {
					console.log("downloadProducts")
					return new Promise((resolve) => {
						// Resolve if there is nothing
						if (downloadProductsArr.length === 0) resolve();

						let countProduct = 0;
						downloadProductsArr.forEach((downloadProduct) => {
							db.collection("products")
							.doc(downloadProduct.id)
							.get()
							.then((doc) => {
								if (doc.exists && (!doc.data().deleted || !doc.data().deleted.deleted)) { // Make sure product is not deleted
									let obj = doc.data();
									obj.priceBruto = vm.calculatePrices(doc.data(), 'product').bruto; //New Price Bruto based on amount
									obj.addedDiscount = 0; //Extra Discount
									obj.priceNetto = vm.calculatePrices(doc.data(), 'product').netto ; //New Price with Discount and amount
									obj.newPriceTotalNetto = vm.calculatePrices(doc.data(), 'product').netto ; //New Price with Discount and amount
									obj.newPriceTotal = vm.calculatePrices(doc.data(), 'product').bruto ; //New Bruto TotalPrice
									obj.percentageVAT = vm.getVatPercentage(doc.data().vat); //Vat Percentage
									obj.calculatedDiscountVAT = 0;
									obj.calculatedDiscountNetto = 0;
									obj.usedModifierId = new Array();
									obj.usedMembershipId = new Array();
									obj.userAdded = true; //Add manually
									obj.resourceId = null; //Sale by Resource
									obj.internalUse = false; //False by default and set to true if internal use via GUI
									obj.type = "product";
									obj.id = doc.id;
									obj.selected = false; //For toggling
									vm.productsByBranchClone.push(obj); //Clean without Discount
								}
								else{ // Product is deleted of does not exist. Remove from selectedItem from receipt
									vm.removeFromSelectedItemsReceipt(downloadProduct.id ,'product') 
								}
								
							})
							.then(() => {
								countProduct++;
								if (countProduct === downloadProductsArr.length) {
									resolve();
								}
							})
							.catch((err) => {
								console.error("Error loading Product from Database", err);
							});
						});
					});
				}
			},


			/**
			 *  Product or treatment is deleted. Remove from  selectedItem from receipt
			 *  @param id  String with product or treatmenId
			 *  @param type // type of selectedItem (product or treatmenId)
			 */
			removeFromSelectedItemsReceipt(id , type){
				this.selectedItems = this.selectedItems.filter(item => { return item.id != id })
			},
								
			getFutureAppointmentsClient() {
				let today = new Date();
				this.selectedClientData.future = [];
				db.collection("bookingsummary")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("clientId", "==", this.selectedClientData.id)
				.where("visit", ">", moment(today).format("YYYY-MM-DD"))
				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let historyItem = doc.data();
						historyItem.id = doc.id;
						this.selectedClientData.future.push(historyItem);
					});
				})
				.then(() => {
					this.clientLoading = false;
				});
			},

		/* 

		██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
		██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
		██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗      ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
		██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝      ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
		╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
		╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
																											
		*/

			updateReceipt(selectedReceiptId, clientId, fullName) {
				console.log("updateReceipt: ")
				db.collection("receiptsDraft")
				.doc(selectedReceiptId)
				.set({
					clientId: clientId,
					clientname: fullName,
					selectedItems: this.selectedItems.length !== 0 ? this.selectedItems : new Array(),
				},
				{ merge: true })

				.then(() => {
					this.clientSearchFieldFocus = false;
					console.log("Receipt Updated with ClientId");
				})
				.catch((error) => {
					console.error("Error updating document Receipts Draft : ", error);
				});
			},

			resetProductFilter(type){
				if(type != 'all' && this.selectedSupplier && this.selectedSupplierGroup && this.selectedSupplierGroups.length > 0){
					this.selectSupplier(this.selectedSupplier)
				}
				else{
					this.selectedSupplier = null;
					this.selectedSupplierGroups = new Array();
					this.productsFiltered = new Array();
				}
				this.selectedSupplierGroup = null;
			},

			selectSupplier(idSupplier) {
				console.log("selectSupplier: ", idSupplier);
				this.selectedSupplierGroups = new Array();

				let supplier = this.productSuppliers.find((item)=> item.id == idSupplier)

				if(supplier != undefined){
					if(supplier.groups && supplier.groups.length > 0){
						this.selectedSupplierGroups = supplier.groups
					}
					else{

						this.loadProductsBySelect(idSupplier);
					}
					this.selectedSupplier = idSupplier;
				}
			},

			async loadProductsBySelect(value){
				console.log("selectSupplier: ", value);
				this.productsFiltered = new Array();
				const products = new Array();

				let index = this.algoSearch.initIndex("thrive_products");
				let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
				let ActiveFilterString = 'active:true';
				let ActiveFilterBranch = `branches:${this.$store.state.activeBranch.id}`;

				await index.search(value, {
					userToken: this.$store.state.activeCompany.id,
					facetFilters: [FacetFilterString, ActiveFilterString, ActiveFilterBranch],
				})
				.then(({ hits }) => {
					hits.forEach((product) => {
						if(!product.deleted || !product.deleted.deleted ){
							products.push(product)
						}
					});
				})
				this.productsFiltered = products;
			},

			resetTreatmentFilter(){
				this.selectedTreatmentType = null;;
				this.treatmentsFiltered = new Array();
			},

			async loadTreatmentsByType(value){
				this.selectedTreatmentType = value;
				this.treatmentsFiltered = new Array();
				const treatments = new Array();

				let index = this.algoSearch.initIndex("thrive_treatments");
				let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
				let ActiveFilterString = 'treatmentActive:true';
				let ActiveFilterBranch = `branches:${this.$store.state.activeBranch.id}`;

				await index.search(value, {
					userToken: this.$store.state.activeCompany.id,
					facetFilters: [FacetFilterString, ActiveFilterString, ActiveFilterBranch],
					// filter: ActiveFilterBranch
				})
				.then(({ hits }) => {
					hits.forEach((treatment) => {
						if(!treatment.deleted || !treatment.deleted.deleted ){
							let treatmentType = this.$store.state.generalTreatmentTypes.find(type=>{ return type.id == treatment.treatmentType});
							treatment.treatmentTypeWeight = treatmentType ? treatmentType.weight : 9999;
							treatment.weight = treatment.weight ? treatment.weight : 0;
							treatments.push(treatment)
						}
					});
				})

				treatments.sort(function (a, b) {
					return b.weight - a.weight || a.treatmentTypeWeight - b.treatmentTypeWeight;
				});
				this.treatmentsFiltered = treatments;
			},

			async loadTreatmentTypes() {
				this.treatmentTypes = new Array();

				if(this.filterType == 'customTreatmentType'){
					await db.collection("companies")
					.doc(this.$store.state.activeCompany.id)
					.collection("customTreatmentTypes")
					.where("deleted.deleted", "==", false)
					.get()
					.then(snap => {
						snap.forEach(doc => {
							let treatmentType = doc.data();
							treatmentType.id = doc.id;
							treatmentType.order = doc.data().order ? doc.data().order : 0;
							this.treatmentTypes.push(treatmentType)
						});
					})
					this.treatmentTypes.sort((a,b) => a.order - b.order)
				}
				else{
					await db.collection("treatmentTypes")
					.get()
					.then(snap => {
						snap.forEach(doc => {
							let treatmentType = doc.data();
							treatmentType.id = doc.id;
							this.treatmentTypes.push(treatmentType)
						});
					})
				}
				return
			},
				

			transformHitsItems(items) {
				//Barcode Scanner for adding products
				return items.map((item) => {
					if (item.barcode) {
						if (item.barcode.code == this.productSearch) {
							this.productSearch = "";
							this.posInputAdd(item.objectID, "product", true);
						}
					}
					return item;
				});
			},

			selectCategory(idCategory) {
				console.log("idCatogory:", idCategory);
				this.selectedCategoryProducts = [];
				this.productsByBranch.forEach((o) => {
					console.log("o.category: ", o.category);
					if (_.indexOf(o.category, idCategory) >= 0 && o.supplier == this.selectedSupplier) {
						this.selectedCategoryProducts.push(o);
					}
				});
			},

			//This Function is used for updateing "verkocht door"
			updateResourceProducts() {
				db.collection("receiptsDraft")
				.doc(this.selectedReceiptId)
				.update({
					selectedItems: JSON.parse(JSON.stringify(this.selectedItems)),
					usedModifiers: this.usedModifiers,
					deletedModifiers: this.deletedModifiers,
					modified: new Date(),
					customModifiers: this.customModifiers,
				})
				.then(() => {
					console.log("Document Receipts Draft Resource updated: ", this.selectedReceiptId);
					this.tempReceiptKey += 1;
				})
				.catch((error) => {
					console.error("Error updating updateResourceProducts document Receipts Draft : ", error);
				});
			},

			posInputDelete(indexSelectedItem, isUserAdded) {
				console.log("userAdded", isUserAdded);

				this.loadingDataTable = true;
				this.tab = 0;

				// Item is not added by user 
				if(!isUserAdded) {
					// Pushing the selected item to the deletedSelfGeneratedItems array as an Object
					this.deletedSelfGeneratedItems.push({uniqIdStamp: this.selectedItems[indexSelectedItem].uniqIdStamp, bookingSummaryId: this.selectedItems[indexSelectedItem].bookingSummaryId });
				
					this.selectedItems.splice(indexSelectedItem, 1);
					db.collection("receiptsDraft")
						.doc(this.selectedReceiptId)
						.set({deletedSelfGeneratedItems: this.deletedSelfGeneratedItems}, {merge: true})
						.then(() => {
							console.log("%c Receipt deletedSelfGeneratedItems updated");
						this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

						})
						.catch((error) => {
							console.error("Error updating document Receipts Draft : ", error);
						});

				} else {
					//Item is added by user
					this.selectedItems.splice(indexSelectedItem, 1);
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				}
			},

			posHistoryAdd(id, indexer, fullReceipt) {
				//id of receiptsPaid doc id

				console.log("id history Receipt", id, indexer);

				if (fullReceipt === true) {
					let somethingReturned = false;

					//Find by id
					let getHistoryReceipt = this.historyReceipts.find((historyReceipt) => historyReceipt.id === id);

					getHistoryReceipt.selectedItems.forEach((selectedItem) => {
						//  The above code is checking if the selected item is not in the returned items list and if
						//  the selected item is a deposit. If both of these conditions are true, then the selected
						//  item is returned and the type of payment is changed to withdraw.
						if (
							getHistoryReceipt.returnedItems.map((returnedItem) => returnedItem.uniqueIdentifier).includes(selectedItem.uniqueIdentifier) === false &&
							selectedItem.typeOfPayment === "deposit"
						) {
							somethingReturned = true;
							selectedItem.returned = true;
							selectedItem.typeOfPayment = "withdraw";
							selectedItem.historyDocId = id; //Returned DocId for update the older receiipt
							this.selectedItems.push(selectedItem);
						}
					});

					if (somethingReturned) {
						this.tab = 0;
					} else {
						//Show alert nothing to return
						this.feedbackHistory = true;
					}
				}

				//Only return 1 item
				if (fullReceipt === false) {
					this.tab = 0;

					let getHistoryReceipt = this.historyReceipts.find((historyReceipt) => historyReceipt.id === id);

					if (getHistoryReceipt.selectedItems[indexer].typeOfPayment === "deposit") {
						let newReturnedItem = getHistoryReceipt.selectedItems[indexer];

						newReturnedItem.returned = true;
						newReturnedItem.typeOfPayment = "withdraw";
						newReturnedItem.historyDocId = id;

						console.log(newReturnedItem);

						this.selectedItems.push(newReturnedItem);
					}
				}

				//Recalculate
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			},

			/**
			 *
			 * @param selectedItem | String Unique ID from selectedItems to match with "returnedItems"
			 */
			validateHistoryItem(uniqIdItem, indexHistoryReceipt) {
				// console.log("selectedItem",uniqIdItem, indexHistoryReceipt);
				if (indexHistoryReceipt.returnedItems) {
					/* This is checking if the unique identifier of the item is in the array of returned items 
					OR if the unique identifier of the item is in the array of selected items. */
					
					if (indexHistoryReceipt.returnedItems.map((returnedItem) => returnedItem.uniqueIdentifier).includes(uniqIdItem) || this.selectedItems.map((selectedItem) => selectedItem.uniqueIdentifier).includes(uniqIdItem) ) {
						return true;
					} else {
						return false;
					}
				} else {// returnedItems is undefined
					return false;
				}
			},

		

			addPrePaymentManual(prePaymentId, index) {
				let vm = this;

				this.tab = 0;

				let foundedPrepayment = this.prePayments.find((prePayment) => prePayment.id === prePaymentId);
				console.log("FOUNDED foundedPrepayment", foundedPrepayment);

				function typePrePayment(type) {
					if (type === "prePayment") {
						return "Oudere" + " " + "Vooruitbetaling";
					} else {
						return "Oudere" + " " + "Aanbetaling";
					}
				}

				/**
				 *
				 * Generated A fixed Amount Discount for Treatment
				 * 1. Get the highest Price Treatment first and place them in a filter
				 * 2. Generated the fixed amount for Pre Payment.
				 */

				console.log("foundedPrepayment", foundedPrepayment.type);

				if (foundedPrepayment) {
					//If the foundedPrepayment is not found in usedModifiers then add it manual
					if (!this.usedModifiers.map((usedModifier) => usedModifier.id).includes(foundedPrepayment.id)) {
						let newDownPaymentModifier = new Object();

						newDownPaymentModifier.name = typePrePayment(foundedPrepayment.type);
						newDownPaymentModifier.id = foundedPrepayment.id;
						newDownPaymentModifier.timestamp = foundedPrepayment.timestamp;
						newDownPaymentModifier.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "prePaymentManualAdded").priorityNumber;

						// newDownPaymentModifier.createdStamp = new Date();

						newDownPaymentModifier.byPrePayment = true;
						newDownPaymentModifier.prePaymentDiscount = false; //Extra PrePaid Discount
						newDownPaymentModifier.manualAddedPrePayment = true;

						newDownPaymentModifier.type = foundedPrepayment.type; //PrePayment or DownPayment
						newDownPaymentModifier.modifierGroup = foundedPrepayment.type //PrePayment or DownPayment
						newDownPaymentModifier.paymentType = foundedPrepayment.type;
						newDownPaymentModifier.automatic = false;
						newDownPaymentModifier.active = true;
						newDownPaymentModifier.addedVia = "manualAddedPrePayment"; 

						newDownPaymentModifier.typeDiscount = {
							type: "fixedAmount",
							discountType: "amount",
							uniqueItems: false,
							discountValue: foundedPrepayment.amount.number,
						};
						newDownPaymentModifier.numberLimit = {
							active: false,
						};
						newDownPaymentModifier.combinationLimit = false;

						newDownPaymentModifier.useLimit = {
							active: true,
							number: 1,
						};

						newDownPaymentModifier.filterProducts = {
							active: true,
							items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
							itemsAction: "include",
							type: "all",
						};

						newDownPaymentModifier.amount = foundedPrepayment.amount;

						newDownPaymentModifier.filterTreatments = {
							active: true,
							items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
							itemsAction: "include",
							type: "all",
						};

						console.log("ADDED newDownPaymentModifier", newDownPaymentModifier);

						/**
						 * 1. We create a new modifier object and push it to the array of modifiers.
						 * 2. We push the modifier to the array of used modifiers.
						 */
						this.usedModifiers.push(newDownPaymentModifier);
						this.modifiers.push(newDownPaymentModifier);
						this.customModifiers.push(newDownPaymentModifier);

						this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
					}
				}
			},

			async posInputAdd(objectId, addedType, internalUse) {
				//Distinction bewteen product and treatment
				this.tab = 0;

				let typeOfPayment = "deposit"; //Or withdraw

				if (this.refundBoolean === true) {
					typeOfPayment = "withdraw";
				}

				let vm = this;

				this.loadingDataTable = true;

				if (addedType == "product") {

					await db
					.collection("products")
					.doc(objectId)
					.get()
					.then((doc) => {
						if (doc.exists && (!doc.data().deleted || !doc.data().deleted.deleted)) { // Make sure product is not deleted

							//TODO disable until loaded
							let obj = doc.data();
							obj.priceBruto = this.calculatePrices(doc.data(), 'product').bruto; // New Price Bruto based on amount
							obj.addedDiscount = 0; //Extra Discount
							obj.priceNetto = this.calculatePrices(doc.data(), 'product').netto; // New Price with Discount and amount
							obj.newPriceTotalNetto = this.calculatePrices(doc.data(), 'product').netto; // New Price with Discount and amount
							obj.newPriceTotal = this.calculatePrices(doc.data(), 'product').bruto; // New Bruto TotalPrice
							obj.percentageVAT = this.getVatPercentage(doc.data().vat); // Vat Percentage
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();

							obj.userAdded = true; //Add manually

							obj.resourceId = this.refundBoolean ? this.userId : null; //Sale by Resource (User Id bij teruggave)

							obj.type = "product";
							obj.id = doc.id;
							obj.selected = false; //For toggling
							obj.cartItemId = `cart_${obj.id}_${uniqid.time()}`; //Unique Cart Item Id for manualInputDiscount

							console.log("typeOfPayment", typeOfPayment);
							console.log("obj.resourceId", obj.resourceId);

							obj.internalUse = internalUse ? true : false; //For internal use

							obj.typeOfPayment = typeOfPayment;

							this.selectedItems.push(obj);
							this.productsByBranchClone.push(obj); // Clean without Discount

							console.log("obj", obj);

							this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
						}
						else {
							//TODO Refresh POS
							//Deleted so refresh POS
							console.log("Product not found!");
							this.loadingDataTable = false;
						}
					})
					.catch((err) => {
						console.error("Error loading Product from Database", err);
					});
				}

				if (addedType == "treatment") {
					function recalculateSubtreatments(data) { // If there are price adjustments for this branch recalculate subtreatment prices by RATO
						let vatItem = vm.$store.state.vatItems.find((vat)=> { return vat.id == data.vat })
						if(vatItem != undefined && data.treatmentPrice && data.treatmentPrice > 0 && data.branchPriceAdjustments && data.branchPriceAdjustments[vm.$store.state.activeBranch.id] && data.branchPriceAdjustments[vm.$store.state.activeBranch.id].adjustmentType && data.branchPriceAdjustments[vm.$store.state.activeBranch.id].adjustmentType != 'noChanges'){ // There are price adjustments for this branch
							data.subTreatments.forEach((subTreatment)=>{
								if(subTreatment.priceNetto){ // Subtreatment has price
									subTreatment.priceNetto = cashConv((subTreatment.priceNetto / data.treatmentPrice) * data.branchPriceAdjustments[vm.$store.state.activeBranch.id].totalAmountNetto, "EUR", 4)
									subTreatment.priceBruto = cashConv(subTreatment.priceNetto * ((vatItem.percentage/ 100)+1), "EUR", 4);
								}
							})
							console.log("Return MET aanpassingen in subTreatments: ", data.branchPriceAdjustments[vm.$store.state.activeBranch.id].adjustmentType)
							return data.subTreatments
						}
						else{
							console.log("Return zonder aanpassingen in subTreatments")
							return data.subTreatments
						}
					}

					db.collection("treatments")
					.doc(objectId)
					.get()
					.then((doc) => {
						if (doc.exists && (!doc.data().deleted || !doc.data().deleted.deleted)) { // Make sure treatment is not deleted
							// console.log("Treatment gevonden", objectId);

							let obj = doc.data();

							obj.priceBruto = doc.data().treatmentPrice ? this.calculatePrices(doc.data(), 'treatment').bruto: 0;
							obj.addedDiscount = 0; //Extra Discount
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();

							obj.userAdded = true;

							obj.priceNetto = doc.data().treatmentPrice ? this.calculatePrices(doc.data(), 'treatment').netto : 0; //New Price with Discount and amount and VAT
							obj.newPriceTotalNetto = doc.data().treatmentPrice ? this.calculatePrices(doc.data(), 'treatment').netto : 0; //New Price with Discount and amount and VAT
							obj.newPriceTotal = doc.data().treatmentPrice ? this.calculatePrices(doc.data(), 'treatment').bruto : 0; //Bruto TotalPrice
							obj.percentageVAT = doc.data().treatmentPrice ? this.getVatPercentage(doc.data().vat) : 0; //Vat Percentage
							obj.treatmentPrice = doc.data().treatmentPrice ? this.calculatePrices(doc.data(), 'treatment').netto : 0; //New Price with Discount and amount and VAT

							obj.name = doc.data().treatmentTitle;

							obj.type = "treatment";
							obj.id = doc.id;
							obj.selected = false; //For toggling
							obj.typeOfPayment = typeOfPayment;
							obj.cartItemId = `cart_${obj.id}_${uniqid.time()}`; //Unique Cart Item Id for manualInputDiscount
							obj.internalUse = internalUse ? true : false; //For internal use
							obj.subTreatments = recalculateSubtreatments(doc.data());

							//remove modified field as timestamp
							delete obj.modified;

							this.selectedItems.push(obj);
							this.treatmentsByBranchClone.push(obj); //Clean without Discount

							console.log("obj", obj);

							this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
						} else {
							//TODO Refresh POS
							//Deleted so refresh POS
							console.log("Treatment not found!");
							this.loadingDataTable = false;
						}
					})
					.catch((err) => {
						console.error("Error loading Treatment from Database", err);
					});
				}

				if(addedType == "membership"){
					this.addMembershipToReceipt(objectId, typeOfPayment);
				}

				this.modifiers.forEach((modifier) => {
					quickValidate(this.selectedItems, modifier, this.usedModifiers, this.selectedClientData);
				})

				
				this.refundBoolean = false;

				// //Membership with Treatment variant
				// if (productTreat.type == "treatment") {
				//   let objNewTreatment = deepCopy(
				//     this.treatmentsByBranchClone.find((product) => product.id === id)
				//   );
				//   objNewTreatment.userAdded = true; //For manual input used by function generateByUniqIdStamp
				//   this.selectedItems.push(objNewTreatment);

				//         //Auto Toggle if Treatment is found
				//        let correspondingMembershipsIds = this.correspondingMemberships.map(corresponding => corresponding.id);
				//        this.validForUseMemberships.map(validMembership => {

				//          if(correspondingMembershipsIds.includes(validMembership.id)) {

				//            if(validMembership.status.code === '2A' || validMembership.status.code === '2B') {
				//              validMembership.toggle = false;

				//              //But its offPeak so activate the membership as offPeak
				//               if(validMembership.peakStatus.peak === "offPeak") {
				//                   validMembership.toggle = true;
				//                   this.posUseMembership(validMembership.id)
				//               }

				//            } else {
				//              validMembership.toggle = true;
				//             this.posUseMembership(validMembership.id)
				//            }
				//            return validMembership;
				//          } else {
				//            this.posUseMembership(validMembership.id)
				//            return validMembership;
				//          }

				//        })

				//   this.calculateModule(
				//     this.selectedItems,
				//     false,
				//     this.modifiers,
				//     this.modifiers,
				//     this.usedModifiers,
				//     this.deletedModifiers,
				//     true
				//   );
				// }
			},

			/**
			 * Adds a membership to the receipt with required properties
			 * @param {Object} membership - The membership object to add
			 * @param {String} typeOfPayment - The payment type (deposit/withdraw)
			 */
			addMembershipToReceipt(membershipId, typeOfPayment = "deposit") {
				
				//get membership with id from companyMemberships
				let foundedMembership = this.companyMemberships.find((membership) => membership.id === membershipId);

				if(!foundedMembership){
					console.error("Membership not found in companyMemberships", membershipId);
					this.loadingDataTable = false;
					return;
				}

				//Check if membership is already in selectedItems
				if(this.selectedItems.find((item) => item.type === "membership")){
					console.log("Membership already in selectedItems", membershipId);
					this.loadingDataTable = false;
					this.snackbar = {
						show: true,
						color: 'error',
						text: this.$t('memberships.membershipAlreadyAdded', this.$store.state.locale),
						timeout: 3000
					 };
					return;
				}


				//calculated netto from vat
				let nettoPrice = cashConv(foundedMembership.price / ((this.getVatPercentage(foundedMembership.vat) / 100) + 1), "EUR", 4);
				
				// Create membership object with required properties
				let membershipItem = {
				// Basic membership info
				id: foundedMembership.id,
				name: this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, foundedMembership.name),
				description: foundedMembership.description,
				type: "membership",
	

								// Price calculations
				priceBruto: foundedMembership.price,
				priceNetto: nettoPrice,
				newPriceTotal: foundedMembership.price,
				newPriceTotalBruto: foundedMembership.price, 
				newPriceTotalNetto: nettoPrice,


				// Log to verify all price properties are present
				...(() => {
					console.log('Membership price properties:', {
						priceBruto: foundedMembership.amountBruto,
						priceNetto: nettoPrice,
						newPriceTotal: nettoPrice,
						newPriceTotalBruto: foundedMembership.amountBruto,
						newPriceTotalNetto: nettoPrice
					});
					return {};
				})(),
				
				// VAT info
				percentageVAT: this.getVatPercentage(foundedMembership.vat),
				calculatedDiscountVAT: 0,
				calculatedDiscountNetto: 0,
				
				
				// Discount tracking
				addedDiscount: 0,
				usedModifierId: [],
				usedMembershipId: [],
				
				// Additional properties
				userAdded: true,
				selected: false,
				internalUse: false,
				typeOfPayment: typeOfPayment,
				resourceId: this.userId,
				category: ["membership"],
			
				// Membership specific
				duration: foundedMembership.duration,
				paymentTypes: foundedMembership.paymentTypes,
				membershipStart: foundedMembership.membershipStart,
				autoRenew: foundedMembership.autoRenew
				};
			
				// Add to selectedItems
				this.selectedItems.push(membershipItem);

				// Recalculate totals
				this.calculateModule(
					this.selectedItems,
					false,
					this.modifiers,
					this.modifiers,
					this.usedModifiers,
					this.deletedModifiers,
					true
			
				
			);
		},

			//Change the Resource
			updateResource() {
				//Selected Receipts for header receipts
				if (this.byResource !== null) {
					this.headerReceiptDrafts = this.localReceiptDrafts.filter((receipt) => receipt.userId == this.byResource);
					this.headerReceiptDraftsSearch = this.localReceiptDrafts.filter((receipt) => receipt.userId == this.byResource);
				} else {
					//If null
					this.headerReceiptDrafts = this.localReceiptDrafts;
					this.headerReceiptDraftsSearch = this.localReceiptDrafts;
				}
			},

			async resendReceipt(data, type){
				let receiptTemplate = null;
				let clientData = null;

				await db.collection('clients').doc(data.clientId)
				.get()
				.then(doc => {
					clientData = doc.data();
				})
				let language = clientData && clientData.language ? clientData.language : 'nl';

				if(!this.$store.state.activeBranch.posSettings.receiptTemplate){
					await db.collection('receiptTemplates')
					.where('companyId', '==', 'thrive')
					.where('templateType', '==', 'general')
					.get()
					.then(snap => {
						snap.forEach(doc => {
							receiptTemplate = doc.data();
						});
					})
				}
				else{
					await db.collection('receiptTemplates').doc(this.$store.state.activeBranch.posSettings.receiptTemplate)
					.get()
					.then(doc => {
						receiptTemplate = doc.data();
					})
				}

				let receiptData = receiptBuilder(
					this.$store.state.activeBranch,
					language,
					receiptTemplate,
					data.paymentTableObj.payedBy,
					data.paymentTableObj.receiptType,
					data.selectedItems,
					data.usedModifiers,
					data.paymentTableObj.totalBruto,
					data.paymentTableObj.totalDiscountBrutoWithoutVat,
					data.paymentTableObj.totalDiscountVat,
					data.paymentTableObj.total,
					data.paymentTableObj.totalVat,
					data.paymentTableObj.totalVats,
					data.id,
					data.time,
					type,
					data.usedMembership ? data.usedMembership : null
				);

				let printData = {
					receiptData,
					printerSettings: window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null,
				}

				let digitalReceiptData = {
					doc: "payment",
					branchId: data.paymentTableObj.branchId,
					clientId: data.paymentTableObj.clientId,
					type: "receipt",
					receipt: receiptData
				};

				if(type == 'digital'){
					this.viewReceiptData.resend = true;
					await this.emailReceipt(digitalReceiptData)
					this.viewReceiptData.resend = false;
					this.viewReceiptData.resendMessage = this.$t('clients.emailSuccessfullySent', this.$store.state.locale);
					setTimeout(() => {
						this.viewReceiptData.resendMessage = null;
					}, 1000);
				}
				else if(type == "history"){
					this.viewReceiptData.receipt = Buffer.from(receiptData, 'base64').toString('utf-8')
				}
				else{
					if (window && window.platform && window.platform.name == "Electron") {
						let { ipcRenderer } = require("electron");

						if (printData.printerSettings !== null) {
							ipcRenderer.send("printReceipt", printData);
						}
					}
				}
			},


			/**
			 * tmpReceiptData | string | Receipt Data for function receiptBuilder()
			 * receiptType | string : withdraw or deposit | Receipt Type
			 * receiptOption | string : How to get the receipt by print or by mail or nothing.		
			 */
			async rollOutReceipt(option) {
				this.printAndMailDialog.busy = true;
				let digitalReceipt = (option === "emailReceipt" || option === "printEmailReceipt") ? receiptBuilder(
					this.$store.state.activeBranch,
					this.tmpReceiptData.language,
					this.tmpReceiptData.receiptTemplate,
					this.tmpReceiptData.typePayment,
					this.tmpReceiptData.receiptType,
					this.tmpReceiptData.selectedItems,
					this.tmpReceiptData.usedModifiers,
					this.tmpReceiptData.totalBruto,
					this.tmpReceiptData.totalBrutoWithoutDiscount,
					this.tmpReceiptData.totalDiscountVAT,
					this.tmpReceiptData.totalNetto,
					this.tmpReceiptData.totalVAT,
					this.tmpReceiptData.totalVats,
					this.tmpReceiptData.receiptDocId,
					'digital',
					this.tmpReceiptData.usedMembership ? this.tmpReceiptData.usedMembership : null
				) : ""; 

				let printData = {
					generatedReceipt: receiptBuilder(
						this.$store.state.activeBranch,
						this.tmpReceiptData.language,
						this.tmpReceiptData.receiptTemplate,
						this.tmpReceiptData.typePayment,
						this.tmpReceiptData.receiptType,
						this.tmpReceiptData.selectedItems,
						this.tmpReceiptData.usedModifiers,
						this.tmpReceiptData.totalBruto,
						this.tmpReceiptData.totalBrutoWithoutDiscount,
						this.tmpReceiptData.totalDiscountVAT,
						this.tmpReceiptData.totalNetto,
						this.tmpReceiptData.totalVAT,
						this.tmpReceiptData.totalVats,
						this.tmpReceiptData.receiptDocId,
						'print',
						this.tmpReceiptData.usedMembership ? this.tmpReceiptData.usedMembership : null
					),
					printerSettings: window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null,
				};

				let digitalReceiptData = {
					doc: "payment",
					branchId: this.$store.state.activeBranch.id,
					clientId: this.clientId,
					type: "receipt",
					receipt: digitalReceipt
				};

				if (option === "noReceipt") { // No receipt, close dialog
					console.log("noReceipt ACTION")
					this.closePrintMailDialog();
					return;
				}
				else if (option === "printReceipt") { // Print the receipt, but check for selected printer.			
					if (window && window.platform && window.platform.name == "Electron") {
						let { ipcRenderer } = require("electron");
						if (printData.printerSettings !== null) {
							ipcRenderer.send("printReceipt", printData);
						}
					}
					this.closePrintMailDialog();
					return;
				}
				else if (option === "emailReceipt") {

					// Send Receipt Email
					await this.emailReceipt(digitalReceiptData)
					this.closePrintMailDialog();
					return;
				}
				else if (option === "printEmailReceipt") { // Print and email
					if (window && window.platform && window.platform.name == "Electron") {
						let { ipcRenderer } = require("electron");

						if (printData.printerSettings !== null) {
							ipcRenderer.send("printReceipt", printData);
						}
					}

					// Send Receipt Email
					await this.emailReceipt(digitalReceiptData)
					this.closePrintMailDialog();
					return;
				}
				else {
					return;
				}
				this.clientId = "";
			},

			async emailReceipt(digitalReceiptData){
				console.log("emailReceiptFired")
				const axiosHeaders = {
					headers: { "Content-Type": "application/json" },
				};

				await axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, digitalReceiptData, axiosHeaders)
				.then((response) => {
					if(response) { 
					console.log("Receipt send")
					// Email succesfully sent! 
					}
				})
				.catch((error) => {
					console.error("Error sending receipt: ", error.message);
				});
				return 
			},

			closePrintMailDialog() {
				this.printAndMailDialog.dialog = false;
				this.tmpReceiptData = {};
				this.printAndMailDialog.busy = false;
			},

			checkPrinter() {
				let vm = this;
				//Get printerSetting from localStorage

				this.selectedPrinter = window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null;

				const { ipcRenderer } = require("electron");

				//Printer is setup check for printer settings
				if (this.selectedPrinter !== null) {
					ipcRenderer.send("give-printers", "get printer");

					ipcRenderer.on("give-printers-answer", (event, arg) => {
						// console.log("ARG", arg);

						vm.printerStatus = arg;
						//Set timout 2 second
						// setTimeout(() => {
						checkPrinterStatus();
						// }, 2000);
					});
				}

				function checkPrinterStatus() {
					//Check the selected Printer status via "this.printerStatus" array and check selectedPrinter by factory name and the printer-state-reasons

					let printerStateReasonObject = vm.printerStatus.find((printer) => printer.name === vm.selectedPrinter.factoryName);

					// Checking if the printer is installed and if it is not installed it is giving a message that the
					// printer is not installed.
					if (printerStateReasonObject) {
						let printerstate = printerStateReasonObject.options["printer-state-reasons"];
						let printerstateArray = printerstate.split(",");

						if (printerstate === "none") {
							vm.printerStateReason = {
								printerName: "",
								printerStateReasons: printerstateArray.map((printerState) => {
									const printerStateObject = {
										reason: "Klaar voor gebruik",
										reasonCode: printerState,
										code: 1,
									};
									return printerStateObject;
								}),
							};

							//Extra options supplemented because the printer is ready
							vm.printAndMailDialog.options = [
								{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
								{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
								{ name: {nl: "Bon<br> <strong>printen</strong>", en: "<strong>Print</strong><br> receipt"}, value: "printReceipt", icon:  "mdi-receipt", color: null },
								{ name: {nl: "Bon <strong>printen</strong><br> en <strong>mailen</strong>", en: "<strong>Print</strong> and <strong>mail</strong> receipt"}, value: "printEmailReceipt", icon:  "mdi-receipt-text-plus", color: null }
							];

							//set printer ready
							vm.printAndMailDialog.printerReady = true;
							vm.printAndMailDialog.loaderSettings = false;
						} else {
							// Creating an object with the following structure:
							vm.printerStateReason = {
								printerName: "",
								printerStateReasons: printerstateArray.map((printerState) => {
									const printerStateObject = {
										reason: printerState.replace(/-/g, " "),
										reasonCode: printerState,
										code: 3,
									};
									return printerStateObject;
								}),
							};

							vm.printAndMailDialog.printerReady = false;
							vm.printAndMailDialog.loaderSettings = false;
						}
					}

					if (!printerStateReasonObject) {
						console.error("Printer:", vm.selectedPrinter.printerName + " is not installed.");

						vm.printerStateReason = {
							printerName: vm.selectedPrinter.printerName,
							printerStateReasons: [
								{
									reason: "Printer is not installed",
									reasonCode: "printer-not-installed",
									code: 2,
								},
							],
						};
						vm.printAndMailDialog.loaderSettings = false;
					}
				}
			},

			receiptToClientDialog() {
				//Show get your receipt Dialog
				this.printAndMailDialog.dialog = true;
				this.printAndMailDialog.loaderSettings = true;

				//Default printer select options and will be supplemented later on via checkPrinter if in Electron

				this.printAndMailDialog.options = [
					{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
					{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
				];

				if (window && window.platform && window.platform.name === "Electron") {
					this.checkPrinter();
				} else {
					this.printAndMailDialog.loaderSettings = false;
				}
			},

			handlePrePayments(remainingArray, withoutRemainingArray) {

				console.log("Before remainingPrePayments")
				// Filter out prepayments with remaining amount less than 0
				console.log("remainingPrePayments", remainingArray);

				// If there are any remaining prepayments
				if (remainingArray.length !== 0) {
					//1. Create Remaining prepayments Docs and Update the old ones by partialSettled.
					remainingArray.forEach((remainingPrePaymentModifier) => {
						// Add new prepayment document
						const prepaymentData = {
							timestamp: new Date(),
							clientId: this.clientId,
							companyId: this.$store.state.activeCompany.id,
							branchId: this.$store.state.activeBranch.id,
							type: remainingPrePaymentModifier.paymentType,
							amount: {
								number: Math.abs(remainingPrePaymentModifier.remainingAmount),
								currency: remainingPrePaymentModifier.amount.currency,
							},
							uniqIdStamp: null,
							paymentStatus: "paid",

							status: {
								returned: false,
								type: "remainingAmount",
								timestamp: null,
								receiptId: null,
								newPrePaymentDocId: null,
							},
						};

						const prepaymentsCollection = db.collection("prepayments");
						// Add new prepayment document
						prepaymentsCollection.add(prepaymentData)
							.then((docRef) => {
								const updateData = {

									paymentStatus: "paid",
									status: {
										returned: false,
										type: "partialSettled",
										timestamp: new Date(),
										receiptId: null,
										newPrePaymentDocId: docRef.id,
									},

								};

								console.log("New PrePayment Created", docRef.id);

								// Update old prepayment document
								prepaymentsCollection.doc(remainingPrePaymentModifier.id).update(updateData)
									.then(() => {
										console.log("Old PrePayment Updated");
									})
									.catch((err) => {
										console.error("Error updating old PrePayment", err);
									});
							})
							.catch((err) => {
								console.error("Error creating new Remaining prepayment document", err);
							});
					});
				}

				// 2. Update Old PrePayment docs without remainingAmounts
				console.log("withoutRemainingPrePayments", withoutRemainingArray);

				// If there are any prepayments without remaining amounts it will be settled
				if (withoutRemainingArray.length !== 0) {
					withoutRemainingArray.forEach((withoutRemainingPrePayment) => {

						//test db
						console.log("withoutRemainingPrePayment.id", withoutRemainingPrePayment.id)

						// Update old prepayment document
						db.collection("prepayments")
						.doc(withoutRemainingPrePayment.id)
						.set({
							status: {
								returned: true,
								type: "settled",
								timestamp: new Date(),
								receiptId: this.selectedReceiptId,
								newPrePaymentDocId: null,
							},
						}, { merge: true })
						.then(() => {
							console.log("Old PrePayment Updated");
						})
						.catch((err) => {
							console.error("Error old PrePayment Update", err);
						});
					});
				}
			},


			//Create payed Receipt
			async payReceipt() {
				console.log("payReceipt")
				let cashRegisterExists = false;

				await db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						cashRegisterExists = true;
					}
					else{
						this.createCashRegister('payReceipt') // Create cashRegister Doc
					}
				})

				if(cashRegisterExists){
					let vm = this;
					console.log("Paying Receipt");
					let receiptTemplate = null;

					if(!this.$store.state.activeBranch.posSettings.receiptTemplate){
						await db.collection('receiptTemplates')
						.where('companyId', '==', 'thrive')
						.where('templateType', '==', 'general')
						.get()
						.then(snap => {
							snap.forEach(doc => {
								receiptTemplate = doc.data();
							});
						})
					}
					else{
						await db.collection('receiptTemplates').doc(this.$store.state.activeBranch.posSettings.receiptTemplate)
						.get()
						.then(doc => {
							receiptTemplate = doc.data();
						})
					}

					//Save data for Receipt
					vm.tmpReceiptData = {
						language: this.selectedClientData && this.selectedClientData.language ? this.selectedClientData.language : 'nl',
						receiptTemplate: receiptTemplate,
						typePayment: vm.typePayment,
						receiptType: vm.totalBruto >= 0 ? "deposit" : "withdraw",
						totalBruto: vm.totalBruto,
						totalNetto: vm.totalNetto,
						totalBrutoWithoutDiscount: vm.totalBrutoWithoutDiscount,
						totalDiscountVAT: vm.totalDiscountVAT,
						totalVAT: vm.totalVAT,
						totalVats: vm.totalVats,
						selectedItems: vm.selectedItems,
						usedModifiers: vm.usedModifiers,
						validForUseMemberships: vm.validForUseMemberships,
					
					};


					// Get paymentDevice by ID
					const paymentDevice = this.$store.state.activeCompany.paymentOptions.find((findDevice) => findDevice.id == this.paymentInfo.chosenPaymentDevice);		

					//** ADD PREPAYMENTS and DOWNPAYMENTS TO BQ **//
					let addedPrePayments = this.usedModifiers.filter(
						(usedModifier) => usedModifier.modifierGroup === "prePaymentModifier" || usedModifier.modifierGroup === "downPayment" && usedModifier.remainingAmount !== usedModifier.amount.number
					);

					// check function calculateMembership for output
					const membershipFields = calculateMembership(this.validForUseMemberships);

					console.log("membershipFields", membershipFields);


					let paymentTableObj = {
						clientId: this.clientId ? this.clientId : "anonymous",
						companyId: this.$store.state.activeCompany.id,
						branchId: this.$store.state.activeBranch.id,
						receiptType: this.totalBruto >= 0 ? "deposit" : "withdraw",
						posLocation: {
							locationId: this.posLocation.id === 'general' ? 'general' : this.posLocation.id, 
							locationName: this.posLocation.id === 'general' ? 'general' : this.posLocation.name
						},
						currency: "EUR", //Dynamisch
						loyaltyPoints: null,
						total: Math.abs(this.totalNetto),
						totalBruto: Math.abs(Number(this.totalBruto)), //To Number for Field
						totalDiscountBrutoWithoutVat: Math.abs(this.totalBrutoWithoutDiscount),
						totalDiscountVat: Math.abs(this.totalDiscountVAT),
						totalVat: Math.abs(this.totalVAT),
						totalVats: this.totalVats,
						membershipValueBruto: membershipFields.membershipValueBruto,
						membershipValue: membershipFields.membershipValue,
						membershipUsedId: membershipFields.membershipUsedId,
						membershipForcedUsed: membershipFields.membershipForcedUsed,
						payedBy: this.typePayment,
						payAndMail: "none", //Send receipt per mail?
						transactionClosedBy: {
							userId: this.userId,
							userRole: this.userRole,
							version: this.version,
						},
						pin: this.typePayment === "SPLIT" ? this.splitPayment.splitByCard : 0,
						cash: this.typePayment === "SPLIT" ? this.splitPayment.splitByCash : 0,
						transactionsNumber: 0, // Later uitbreiden als er meerdere transacties mogelijk zijn per bon
						chosenPaymentDevice: paymentDevice
							? {
									id: paymentDevice.id,
									name: paymentDevice.name,
									transactionCost: 0,
									type: null,
									intermediate: paymentDevice.intermediate,
									amount: 0,
								}
							: {
									id: "CASH",
									name: "CASH",
									transactionCost: 0,
									type: null,
									intermediate: false,
									amount: 0,
								},
						internalUse: this.internalUseFunc(this.selectedItems),
						items: new Array(),
					};

					/**
					 * Unique ID Stamp for History and BQ if there is already a stamp dont add a new one.
					 */

					this.selectedItems = this.selectedItems.map((selectedItem) => {
						//If there is no uniqueIdentifier add one. For Returning Items.
						if (!selectedItem.uniqueIdentifier) {
							selectedItem.uniqueIdentifier = uniqid();
						}
						return selectedItem;
					});

					/**
					 * Custom Modifier Name, if the modifierId includes customDiscount, return customDiscount, 
					 * for Big Query
					 * @param {string} modifierId - The ID of the modifier.
					 * @returns {string} The id of the modifier. could be 'customDiscount' or the id of the modifier
					 */
					const checkCustomModifierName = (modifierId) => {
						//if name includes customDiscount
						if(modifierId.includes("customDiscount")){
							return "customDiscount"
						} else {
							return modifierId;
						}
						
					}

					console.log("paymentTableObj", paymentTableObj);
					//Items for Receipt
					paymentTableObj.items = this.selectedItems.map((item) => {
						return {
							id: item.id,
							uniqueIdentifier: uniqid(),
							typeOfPayment: item.typeOfPayment,
							percentageVAT: item.percentageVAT,
							typeItem: item.type, //Product or Treatment or Custom rule
							priceNetto: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.priceNetto : item.treatmentPrice,
							priceBruto: item.priceBruto,
							newPriceTotalNetto: item.newPriceTotalNetto,
							newPriceTotalBruto: item.newPriceTotal,
							discountNetto: item.calculatedDiscountNetto,
							discountBruto: item.calculatedDiscountVAT,
							usedModifierId: item.usedModifierId,
							usedMembershipId: item.usedMembershipId,
							category: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.category[0] : item.treatmentType,
							resourceId: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.resourceId : "check subs",
							discountValueByModifiers: !item.discountValueByModifiers ? [] 
							: item.discountValueByModifiers.map((discountValueByModifier) => {
								return {
									modifierId: checkCustomModifierName(discountValueByModifier.modifierId) || null,
									type: discountValueByModifier.type || "modifier",
									vatPercentage: discountValueByModifier.vatPercentage || 0,
									amount: discountValueByModifier.amount || 0,
									amountNetto: discountValueByModifier.amountNetto || 0,
									discountType: discountValueByModifier.discountType || null,
									discountValue: discountValueByModifier.discountValue || 0,
									manualInputDiscount: discountValueByModifier.manualInputDiscount || false,
									addedVia: discountValueByModifier.addedVia || null
								};
							}),
							sub:
								item.type === "product" || item.type === "customRule" || item.type === "membership"
									? []
									: item.subTreatments.map((subTreatment) => {
											return {
												subUniqueIdentifier: uniqid.time(),
												subSection: subTreatment.type, //What type of sub treatment
												subTypeSection: subTreatment.device, //What kind of planning
												subPriceNetto: subTreatment.priceNetto,
												subPriceBruto: subTreatment.priceBruto,
												subResourceId: subTreatment.resourceId,
											};
										}),
						};
					});

					//** ADD PREPAYMENTS TO BQ **//
					// Added PrePayments if exist
					if (addedPrePayments.length !== 0) {
						addedPrePayments = addedPrePayments.map((addedPrePayment) => {
							let itemObject = new Object();
							itemObject.typeOfPayment = addedPrePayment.paymentType;

							if (addedPrePayment.remainingAmount < 0) {
								// If there is some remaningAmount

								itemObject.newPriceTotalBruto = cashConv((Number(addedPrePayment.amount.number) + addedPrePayment.remainingAmount), "EUR", 4); //?
								itemObject.discountBruto = Math.abs(addedPrePayment.remainingAmount);
							} else {
								// There is no remainingAmount left
								itemObject.newPriceTotalBruto = addedPrePayment.amount.number; //?
								itemObject.discountBruto = addedPrePayment.amount.number; //?
							}

							itemObject.priceBruto = addedPrePayment.amount.number;
							itemObject.typeItem = addedPrePayment.paymentType; //"prePayment" Or "DownPayment"
							itemObject.category = "prePayment";
							return itemObject;
						});

						// Add Prepayment items to BQ
						paymentTableObj.items = [...paymentTableObj.items, ...addedPrePayments];
					}


					/**
					 * Calculate membership details based on valid memberships.
					 * 
					 * @param {Array} validForUseMemberships - Array of valid memberships that can be used.
					 * @returns {Object} membershipFields - Object containing membership details:
					 *   - membershipValueBruto {number|null} - Bruto value of membership, can be null
					 *   - membershipValue {number|null} - Netto value of membership, can be null
					 *   - membershipUsedId {string} - ID of used membership, empty string if no membership used
					 *   - membershipForcedUsed {boolean} - Whether membership was forced to be used
					 */
					 function calculateMembership(validForUseMemberships) {

						const usedMembership = validForUseMemberships.find((validMembership) => validMembership.toggle === true);
						console.log("usedMembership", usedMembership)

						const membershipFields = {
							membershipValueBruto: null, //number
							membershipValue: null, //number
							membershipUsedId: "", //string
							membershipForcedUsed: false, //boolean
						}

						// No membership used
						if(!usedMembership) {
							return membershipFields;
						}

						membershipFields.membershipUsedId = usedMembership.id;
						membershipFields.membershipForcedUsed = usedMembership.override ? true : false;
						membershipFields.membershipValueBruto = usedMembership.amountBruto;

						const vatItem = vm.$store.state.vatItems.find((item) => item.id  == usedMembership.vat)

						// Calculate VAT Backwards from Bruto
						membershipFields.membershipValue = cashConv(membershipFields.membershipValueBruto / (1 + (vatItem.percentage / 100)), "EUR", 4);

						console.log("membershipFields", membershipFields)

						return membershipFields;

					}

				
					if (this.typePayment == "PIN") {
						console.log("paymentDevice.intermediate: ", paymentDevice.intermediate)
						paymentTableObj.pin = this.totalBruto;
						
						if (paymentDevice.intermediate == true) {
							paymentTableObj.chosenPaymentDevice.transactionCost = paymentDevice.transactionCost;
							paymentTableObj.chosenPaymentDevice.type = paymentDevice.type;
							if (paymentTableObj.chosenPaymentDevice.type == "amount") {
								paymentTableObj.chosenPaymentDevice.amount = paymentDevice.transactionCost;
							} else {
								let transactionPercentage = +paymentDevice.transactionCost / 100;
								paymentTableObj.chosenPaymentDevice.amount = + this.change * transactionPercentage;
							}
						}

						//Update CashRegister when changeCalculated is higher than 0
						if(this.changeCalculated > 0) {
							db.collection("cashRegister")
								.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
								.update({
									cash: firebase.firestore.FieldValue.increment(-this.changeCalculated),
									timestamp: new Date(),
								})
								.then(() => {
									console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
								})
								.catch((err) => {
									console.error("Cash Error", err);
								})					
						}
					}

					if (this.typePayment == "CASH") {
						paymentTableObj.cash = this.totalBruto;

						db.collection("cashRegister")
						.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
						.update({
							cash: firebase.firestore.FieldValue.increment(this.totalBruto),
							timestamp: new Date(),
						})
						.then(() => {
							console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
						})
						.catch((err) => {
							console.error("Cash Error", err);
						});
					}

					if (this.typePayment == "SPLIT") {
						paymentTableObj.cash = this.splitPayment.splitByCash;

						db.collection("cashRegister")
							.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
							.update({
								cash: firebase.firestore.FieldValue.increment(this.splitPayment.splitByCash),
								timestamp: new Date(),
							})
							.then(() => {
								console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
							})
							.catch((err) => {
								console.error("Cash Error", err);
							});
					}

					if (this.typePayment == "REFUNDCASH") {
						paymentTableObj.cash = this.totalBruto;

						db.collection("cashRegister")
							.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
							.update({
								cash: firebase.firestore.FieldValue.increment(this.totalBruto), //example -24.4324
								timestamp: new Date(),
							})
							.then(() => {
								console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
							})
							.catch((err) => {
								console.error("Cash Error", err);
							});
					}

					/*
						PREPAYMENTS and DOWNPAYMENTS
						Remaining Amounts prePayments
						1. if there is a reaming amount Create new one and update old preppayment to partialSettled
						2. Update Old PrePayment docs without remainingAmounts

					*/
					
					//1. Handle the PrePayments

					let remainingPrePayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "prePayment" && usedModifier.remainingAmount < 0);
					// Filter out prepayments with remaining amount greater than or equal to 0
					let withoutRemainingPrePayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "prePayment" && usedModifier.remainingAmount >= 0);

					this.handlePrePayments(remainingPrePayments, withoutRemainingPrePayments);

					//2. Handle the DownPayments
					let remainingDownPayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "downPayment" && usedModifier.remainingAmount < 0);
					// Filter out downPayments with remaining amount greater than or equal to 0
					let withoutRemainingDownPayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "downPayment" && usedModifier.remainingAmount >= 0);

					this.handlePrePayments(remainingDownPayments, withoutRemainingDownPayments);

					/* UPDATE RETURNED ITEMS
					Adding the historyDocId to a set, then it is filtering the selectedItems array to only include the
					selectedItems that have a historyDocId. It then loops through the returnedItems array and adds the
					historyDocId to the returnedItems array.
					*/

					let historyReceiptIds = [
						...new Set(
							this.selectedItems
								.filter((selectedItem) => typeof selectedItem.historyDocId != "undefined" && selectedItem.typeOfPayment === "withdraw")
								.map((selectedItem) => selectedItem.historyDocId)
						),
					];

					historyReceiptIds.forEach((historyReceiptId) => {
						let returnedItems = this.selectedItems.filter((selectedItem) => selectedItem.historyDocId === historyReceiptId);

						//TODO HIER PRODUCTEN TERUG OP STOCK ZETTEN

						console.log("returnedItems", returnedItems);

						db.collection("receiptsPaid")
							.doc(historyReceiptId)
							.set(
								{
									returnedItems: firebase.firestore.FieldValue.arrayUnion(...returnedItems),
								},
								{ merge: true }
							)
							.then("Old Receipt Updated with returned Item =>", historyReceiptId)
							.catch((err) => {
								console.error("Error updating History Paid Receipt =>", err);
							});
					});

					console.log("PaymentTable:", paymentTableObj);

					this.paymentTable = paymentTableObj; //For Receipt

					let branchIdViaStore = this.$store.state.activeBranch.id;

					/**
					 * Function to update the stock of products.
					 * This function performs the following operations:
					 * 1. Filters the items to get all the products with useStock set to true.
					 * 2. Reduces or adds the stock by the same id and counts the quantity based on the operation type.
					 * 3. Updates the stock in the database.
					 *
					 * @param {Array} items - Array of selectedItems
					 */
					function updateStock(items) {

						// Filter the items to get all the products with useStock set to true.
						const productsStock = items.filter((item) => item.type === "product" && item.useStock === true);

						// Reduce or add stock by the same id and count the quantity.
						// Start with a countQuantity of 1.
						let countQuantity = 1;
						const productsStockUpdated = productsStock.reduce((acc, item) => {
							const found = acc.find((accItem) => accItem.id === item.id);

							// If the item is found, increment the countQuantity.
							if (found) {
								++countQuantity
							} else {
								// If the item is not found, push it to the accumulator with its countQuantity.
								acc.push({ id: item.id, quantity: countQuantity, typeOfPayment: item.typeOfPayment });
							}
							return acc;
						}, []);

						// Update the updated Stock in Firebase by active Branch.
						//productStockUpdated - Array of objects with id and quantity and typeOfPayment
						productsStockUpdated.forEach((updatedStock) => {

							console.log("Updated Stock:", updatedStock);

							db.collection("products")
							.doc(updatedStock.id)
							.get()
							.then((doc) => {
								if (doc.exists) {
									let stockObject = doc.data().stock;
									console.log("Initial stock object:", stockObject);

									// Calculate the new stock value. It can't be lower than 0, stock can't be negative.
									let newStock;
									if (updatedStock.typeOfPayment === 'deposit') {
										newStock = stockObject[branchIdViaStore].stockValue - updatedStock.quantity;
										console.log("New stock after deposit:", newStock);
										if (newStock < 0) {
											newStock = 0;
											console.log("New stock can't be negative, set to 0");
										}
									} else if (updatedStock.typeOfPayment === 'withdraw') {
										newStock = stockObject[branchIdViaStore].stockValue + updatedStock.quantity;
										console.log("New stock after withdraw:", newStock);
									}
									stockObject[branchIdViaStore].stockValue = newStock;
									console.log("Updated stock object:", stockObject);

									// Update the stock in the database with the whole object.
									db.collection("products")
										.doc(updatedStock.id)
										.set({
											stock: stockObject,
										}, { merge: true })
										.then(() => {
											console.log("Product Stock Updated");
										})
										.catch((err) => {
											console.error("Error updating product stock", err);
										});
								}

							})
							.catch((err) => {
								console.error("Error getting document", err);
							});

						});
					}


					// Check if a Membership is purchased
					let membershipPurchases= this.selectedItems.filter((item)=>{return item.type == "membership" && item.typeOfPayment != 'withdraw'})

					if(membershipPurchases.length > 0){ // Membership(s) are purchased
						membershipPurchases.forEach(item=>{
							purchasesMembership(item.id)
						})

						/**
						 * Add membership with clientId to the database
						 * @param {Srring} id - Company Membership ID
						 */
						function purchasesMembership(id) {
							let membershipStartDate = moment().format("YYYY-MM-DD");
							let selectedSubscription = vm.companyMemberships.find(membership => { return membership.id == id})
							if(selectedSubscription != undefined){
								db.collection("memberships").add({
									created: new Date(),
									companyId: vm.$store.state.activeCompany.id,
									clientId: vm.clientId,
									startDate: membershipStartDate,
									subscription: id,
									freePeriod: selectedSubscription.freePeriod,
									usageRules: selectedSubscription.treatments,
									name: selectedSubscription.name,
									paymentDay: selectedSubscription.paymentDay,
									duration: selectedSubscription.duration,
									paymentPeriod: selectedSubscription.paymentPeriod,
									paymentReminder: selectedSubscription.paymentReminder,
									singlePaymentDiscount: selectedSubscription.singlePaymentDiscount,
									trialPeriod: selectedSubscription.trialPeriod,
									vat: selectedSubscription.vat,
									terms: selectedSubscription.terms,
									periodicPaymentDiscount: selectedSubscription.periodicPaymentDiscount,
									registrationDescription: selectedSubscription.registrationDescription,
									registrationUrl: selectedSubscription.registrationUrl,
									sendInvoice: selectedSubscription.sendInvoice,
									usage: new Array(),
									sepaAttempts: selectedSubscription.sepaAttempts,
									amount: selectedSubscription.amount,
									amountBruto: selectedSubscription.amountBruto,
									separatedDiscount: selectedSubscription.separatedDiscount,
									branches: selectedSubscription.branches,
									days: selectedSubscription.days,
						  
									offPeakMessage: selectedSubscription.offPeakMessage,
									peakMessage: selectedSubscription.peakMessage,
									posDescription: selectedSubscription.posDescription,
						  
									status: {
									  endDate: calculateEndDate(membershipStartDate, selectedSubscription.duration, selectedSubscription.paymentPeriod),
									  status: "active",
									  type: "single"
									}
								})
							};

							/**
							 * Calculate Enddate on single membership
							 * @param {String} membershipStartDate - startDate formatted as YYYY-MM-DD
							 * @param {Number} duration - Number of paymentPeriods for total duration
							 * @param {String} paymentPeriod - Type of Period
							 */
							function calculateEndDate(membershipStartDate, duration, paymentPeriod){
								let types = [
									{id: 'daily', type: 'days', number: 1},
									{id: 'everyWeek', type: 'weeks', number: 1},
									{id: 'everyFourWeeks', type: 'fourWeeks', number: 4},
									{id: 'everyMonth', type: 'months', number: 1},
									{id: 'everyQuarter', type: 'months', number: 3},
									{id: 'everyHalfYear', type: 'months', number: 6},
									{id: 'everyYear', type: 'year', number: 1}
								]
								let number = duration;
								let type = types.find((periodType)=> {return periodType.id == paymentPeriod })
								if(number > 0 && type != undefined){
									return moment(membershipStartDate, "YYYY-MM-DD").add((type.number * number), type.type).format("YYYY-MM-DD")
								}
								else{
									return null;
								}
							}
							


						}
					}

					let membershipsRefund = this.selectedItems.filter((item)=>{ return item.type == "membership" && item.typeOfPayment == 'withdraw'})

					if(membershipsRefund.length > 0){ // Membership(s) are purchased
						membershipsRefund.forEach(item=>{
							removeMembership(item.id, item.historyDocId)
						})

						/**
						 * Delete membership of clientId from the database because of withdraw
						 * @param {Srring} id - Company Membership ID
						 * @param {Srring} historyDocId - ReceiptId of orginal purchased membership
						 */
						function removeMembership(id, historyDocId) {
							console.log("removeMembership: ")
							let clientMemberships =  vm.clientMemberships.sort((b, a) => a.startDate.localeCompare(b.startDate));

							let subscription = clientMemberships.find(membership => { return membership.subscription == id})
							if(subscription != undefined){
								db.collection("memberships").doc(subscription.id).set({
									deleted: {
										deleted: true,
										deletedTime: new Date(),
										receiptId: historyDocId,
										deletedBy:"register",
										extra: "withdraw"
									},
									status: {
										status: 'inactive'
									},
									usageRules: {
										active: false
									}
								}, {merge: true})
							};
						};
					}


					
					// Update Input Draft
					if (this.selectedReceiptId !== "") {		

					   //get usedMembership via validForUseMemberships by toggle true
					   let usedMembership = this.validForUseMemberships.find((membership) => membership.toggle === true);
                 
						usedMembership = usedMembership ? {
							id: usedMembership.id,
							name: usedMembership.name,
							toggle: usedMembership.toggle,
							override: usedMembership.override,
							usage: usedMembership.usage,
							separatedDiscount: usedMembership.separatedDiscount
						} : null;
						

						db.collection("receiptsPaid")
						.add({
							postToTable: "thrive-pos-v1", //COULD CHANGE FOR DEV
							companyId: this.$store.state.activeCompany.id,
							clientId: this.clientId ? this.clientId : "anonymous",
							branchId: this.$store.state.activeBranch.id,
							selectedItems: JSON.parse(JSON.stringify(this.selectedItems)),
							usedModifiers: this.usedModifiers,
							deletedModifiers: this.deletedModifiers,
							returnedItems: new Array(),
							paymentTableObj: paymentTableObj,
							internalUse: this.internalUseFunc(this.selectedItems),
							register: this.selectedCashRegister,
							created: new Date(),
							visit: moment().format("YYYY-MM-DD"),
							usedMembership: usedMembership, // Single membership object instead of array
						})
						.then((docRef) => {
							console.log("Payment to Database with ID => ", docRef.id);
							this.tmpReceiptData.receiptDocId = docRef.id

							if(this.clientId){ // Add lastPayment Date to client
								db.collection("clients")
								.doc(this.clientId)
								.set({
									lastPayment: new Date(),
								},{ merge: true })
								.then(() => {
									console.log("Client Updated with Last Payment");
								})
								.catch((error) => {
									console.error("Error updating document Client : ", error);
								});
							}

							// Update Membership usage
							this.validForUseMemberships.forEach((membership) => { // Write Usage if Membership
							if (membership.toggle === true) { // Only process if membership is toggled on
								let usageObj = { 
										date: moment().format("YYYY-MM-DD"), 
										override: membership.override, 
										receiptId: docRef.id 
								};
								addUsageToMembership(usageObj, membership);
							}
							});

							/**
							 * Updates the membership document in Firestore by adding a new usage record
							 * @param {Object} usageObj - Object containing usage details
							 * @param {string} usageObj.date - Date of usage in YYYY-MM-DD format
							 * @param {boolean} usageObj.override - Whether usage rules were overridden
							 * @param {string} usageObj.receiptId - ID of associated receipt
							 * @param {Object} membership - The membership document to update
							 * @param {string} membership.id - Firestore ID of membership document
							 * @returns {Object|null} Returns null if no valid usage object provided
							 */
							function addUsageToMembership(usageObj, membership) {
							if (!usageObj) return null;
							
							console.log("usageObj: ", usageObj);
							console.log("ADD MEMBERSHIP USAGE");
							
							db.collection("memberships")
								.doc(membership.id)
								.set({ usage: firebase.firestore.FieldValue.arrayUnion(usageObj) }, { merge: true })
								.catch((err) => {
										console.error("Error Updating Membership Usage =>", err);
								});
							}

								//Reduce Product Stock 
								updateStock(this.selectedItems);


								//Write Number Limit / Usage Modifier

								//Add number of use by usedModifiers to Database

								let arrayOfUsedModifiers = new Array();

								this.usedModifiers.forEach((usedModifier) => {
									//max used in total Or maxClient use per client
									arrayOfUsedModifiers.push({
										id: usedModifier.id,
										type: usedModifier.type,
										date: moment().format("YYYY-MM-DD"),
										byUser: this.userId,
									});
								});

								// Update selectedClientData with usedModifier before set.
								// console.log("ARRAy of USED", arrayOfUsedModifiers)
								if (this.selectedClientData.usedModifiers) {
									arrayOfUsedModifiers = [...this.selectedClientData.usedModifiers, ...arrayOfUsedModifiers];
								}

								// console.log("ARRAy of USED 2", arrayOfUsedModifiers)

								if (arrayOfUsedModifiers.length !== 0) {
									// Update / set Clients by adding usedModfiers
									db.collection("clients")
										.doc(this.clientId)
										.set(
											{
												usedModifiers: arrayOfUsedModifiers,
											},
											{ merge: true }
										)
										.catch((err) => {
											console.error("Error updating client card", err);
										});
								}

								// Checking if the usedModifiers array contains any modifiers with the modifierGroup
								// "loyaltyModifier". If it does, it will filter the usedModifiers array and sum up the
								// loyaltyPointsNeeded field. It will then call the reCalculateLoyalty function and pass in the
								// clientLoyaltyDocs array, the activeBranch and the loyaltyPointsNeeded. The reCalculateLoyalty
								// function will then return an array of recalculated loyaltyDocs. The recalculated loyaltyDocs
								// will then be uploaded to firebase.
								const loyaltyFound = this.usedModifiers.some((usedModifier) => {
									if (usedModifier.modifierGroup === "loyaltyModifier") {
										return true;
									} else {
										return false;
									}
								});

								if (loyaltyFound === true) {
									//filter only the usedModifiers with modifiergGroup "loyaltyModifer" and sum up the field lotaltyPointsNeeded
									let loyaltyPointsNeeded = 0;
									this.usedModifiers.forEach((usedModifier) => {
										if (usedModifier.modifierGroup === "loyaltyModifier") {
											loyaltyPointsNeeded += usedModifier.loyaltyPointsNeeded;
										}
									});

									let recalucatedLoyaltyDocs = reCalculateLoyalty(this.clientLoyaltyDocs, this.$store.state.activeBranch.id, loyaltyPointsNeeded);

									//Re-upload and the recalucatedLoyaltyDocs to firebase
									recalucatedLoyaltyDocs.forEach((loyaltyDoc) => {
										db.collection("loyaltyPoints")
											.doc(loyaltyDoc.id)
											.set(loyaltyDoc)
											.then(() => {
												console.log("Loyalty Document Updated =>", loyaltyDoc.id);
											})
											.catch((error) => {
												console.error("Error updating loyalty document =>", error);
											});
									});
								}

								new Promise((resolve) => {
									let getReceiptDraft = this.localReceiptDrafts.find((draft) => draft.id == this.selectedReceiptId);
									if (getReceiptDraft.bookingSummaryIds) {
										let amountBookingSummaries = getReceiptDraft.bookingSummaryIds.length;
										let countSummary = 0;
										getReceiptDraft.bookingSummaryIds.forEach((summaryId) => {
											db.collection("bookingsummary")
												.doc(summaryId)
												.set({ paid: true }, { merge: true })
												.then(() => {
													countSummary++;
													console.log("Booking Summary updated", summaryId);
													if (countSummary === amountBookingSummaries) {
														resolve();
													}
												})
												.catch((error) => {
													console.error("Booking Summary update Error =>", error);
												});
										});
									} else {
										// No Booking Summaries
										resolve();
									}
								})
								.then(() => {
									// Delete Old receipt from local Storage
									this.localReceiptDrafts = this.localReceiptDrafts.filter((localReceipt) => localReceipt.id !== this.selectedReceiptId);

									/**
									 * Print/Mail Receipt Dialog
									 * receiptToClientDialog, chose an option and then rollOutReceipt()
									 */
									vm.receiptToClientDialog();

									db.collection("receiptsDraft")
										.doc(this.selectedReceiptId)
										.delete()
										.then(() => {
											console.log("Receipt Deleted from receipts Draft collection =>", this.selectedReceiptId);

											//Clean up
											this.selectedReceiptId = "";
											this.selectedItems = new Array();
											this.byResource = null;
											this.componentKey++;
											this.tempReceiptKey += 1;
											this.closePayDialog();
											this.feedbackPayDialog = {
												feedback: null,
												code: null,
											};
										})
										.catch((err) => {
											console.error("Error deleting receipts Draft  => ", this.selectedReceiptId, err);
										});
								});
							})
							.catch((error) => {
								console.error("Error Add Payment document Receipts Draft : ", error);
							});
					} //End
				}
			},

			//Select Product or Treatments
			selector(indexed) {
				/* checkBoolean check if is already true, then false.
				* When its false create everything false by loop then activate a new true
				*/

				//Unselect not selectedItems for V-data-list
				this.selectedItems.map((selectedItem, mapIndex) => {
					if (mapIndex !== indexed) {
						selectedItem.selected = false;
					} else {
						selectedItem.selected = true;
					}
					return selectedItem;
				});

				if (this.selectedItems[indexed]) {
					// this.selectedItems[indexed].selected = true;
					if (this.selectedItems[indexed].type == "treatment") {
						this.selectedType = "treatment";
						this.indexer = indexed;
					}
					if (this.selectedItems[indexed].type == "product") {
						this.selectedType = "product";
						this.indexer = indexed;
					}
					if (this.selectedItems[indexed].type == "customRule") {
						this.selectedType = "customRule";
						this.indexer = indexed;
					}
				}
				this.componentKey += 1;
			},

			getResourceName(resourceDocId) {
				// console.log("ResourceDocId", resourceDocId);
				if (resourceDocId !== null) {
					return this.resources.find((resource) => resource.id == resourceDocId).name;
				} else {
					return "Alle Medewerkers";
				}
			},

			/**
			 * How it works: The function will loop through the selectedItems array and check for internalUse boolean true.
			 * If there is a internalUse boolean true it will filter the selectedItems array and return a new array with only
			 * the items that have internalUse boolean true. It will then loop through the new array and create a new array of
			 * objects. The new array of objects will contain the id, number and totalCostPrice of the items with internalUse
			 * boolean true. It will then return the new array of objects.
			 * 
			 * @param {*} items | selectedItems as array
			 */
			internalUseFunc(items) {
				//Filter out internalUse true
				const internalUseItems = items.filter((item) => item.internalUse === true);

				//create array of objects but add them togheter.
				// internalUseProducts: [
				// 	{id: products x by same id added},
				// 	{number: amount by same id added},
				// 	{totalCostPrice: totalCostPrice of all products x by same id added}
				// ]
				let countInternalItem = 1;
				let internalUseProducts = internalUseItems.reduce((acc, item) => {

					let found = acc.find((accItem) => accItem.id === item.id);
					if (found) {

						++countInternalItem;
						found.number = countInternalItem;
						found.totalCostPrice += item.costPrice;
					} else {
						acc.push({
							id: item.id,
							number: countInternalItem,
							totalCostPrice: cashConv(item.costPrice, "EUR", 4),
						});
						countInternalItem = 1;

					}
					return acc;
				}, []);

				return internalUseProducts;
			},

			/**
			 * This function will calculate the total of the receiptDraftItems. It will loop through the receiptDraftItems
			 * and calculate the sumUpNetto, sumUpVAT, sumUptotalBrutoWithoutDiscount and sumUpTotalDiscountVAT. It will then
			 * return the sumUpVAT if it is not null, otherwise it will return the sumUptotalBrutoWithoutDiscount.
			 *
			 * @param {*} receiptDraftItems | Array of receiptDraftItems
			 * @param {*} historyBoolean | Boolean
			 * @returns 
			 */
			getTotal(receiptDraftItems, historyBoolean) {
				//@Param : receiptDraftProducTreats from headerReceiptDraft

				//Calculation for Header Button

				//History Boolean is true when the receiptDraftItems are from the history Receipts

				if (receiptDraftItems && !historyBoolean) {
					let sumUpNetto = null; //Netto Total
					let sumUpVAT = null; //Bruto Total
					let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
					let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
					receiptDraftItems.forEach((selectedItem, i) => {
						
						if (selectedItem.internalUse) {
							return;  // Skip the current iteration of the loop
						}

						cashConv(
							selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
							"EUR",
							4
						);
						cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
								: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
							"EUR",
							4
						);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
								: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
							"EUR",
							4
						);
					});
					//Return function for Header

					if (receiptDraftItems.length === 0) {
						return this.$t('payment.newReceipt', this.$store.state.locale);
					} else {
						return sumUpVAT !== null ? "€" + cashConv(sumUpVAT, "EUR", 2).toFixed(2) : "€" + cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2);
					}
				} else if (receiptDraftItems && historyBoolean) {
					//Collect different VATS
					let percentagesVat = [...new Set(receiptDraftItems.map((draftItem) => draftItem.percentageVAT))];

					// //Calculation for Totals to DB
					let sumUpNetto = null; //Netto Totaal Bedrag
					let sumUpVAT = null; //Bruto sumUp
					let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
					let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
					this.totalVats = new Array();

					receiptDraftItems.forEach((selectedItem) => {
						if (selectedItem.internalUse) {
							return;  // Skip the current iteration of the loop
						}

						cashConv(
							selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
							"EUR",
							4
						);
						cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
								: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
							"EUR",
							4
						);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
								: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
							"EUR",
							4
						);
					});
					

					this.totalNetto = cashConv(sumUpNetto, "EUR", 2); //NETTO
					this.totalDiscountVAT = cashConv(sumUpTotalDiscountVAT, "EUR", 2); //Total discount / promotion from modifier
					this.totalBrutoWithoutDiscount = cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2); //BRUTO Total price orignial without discounts
					this.totalBruto = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
					this.totalBrutoTwoDigits = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
					this.totalVAT = cashConv(this.totalBruto - this.totalNetto, "EUR", 2); //VAT only

					//VAT Calculations
					percentagesVat.forEach((percentageVat) => {
						let sumUpVatPerItem = 0;

						this.selectedItems.filter((draftItem) => {

							if (draftItem.internalUse) {
							return false;  // Skip the current item
							}

							if (draftItem.percentageVAT == percentageVat) {
								if (draftItem.typeOfPayment === "deposit") {
									sumUpVatPerItem += cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
								} else {
									sumUpVatPerItem -= cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
								}
							}
						});

						this.totalVats.push({
							percentageVat: percentageVat,
							totalVat: cashConv(sumUpVatPerItem, "EUR", 4),
						});
					});
				
				}
				else {
					//Collect different VATS
					let percentagesVat = [...new Set(this.selectedItems.map((draftItem) => draftItem.percentageVAT))];

					// //Calculation for Totals to DB
					let sumUpNetto = null; //Netto Totaal Bedrag
					let sumUpVAT = null; //Bruto sumUp
					let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
					let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
					this.totalVats = new Array();

					this.selectedItems.forEach((selectedItem) => {
						if (selectedItem.internalUse) {
							return;  // Skip the current iteration of the loop
						}

						cashConv(
							selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
							"EUR",
							4
						);
						cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
								: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
							"EUR",
							4
						);
						cashConv(
							selectedItem.typeOfPayment === "deposit"
								? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
								: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
							"EUR",
							4
						);
					});

					this.totalNetto = cashConv(sumUpNetto, "EUR", 4); //NETTO
					this.totalDiscountVAT = cashConv(sumUpTotalDiscountVAT, "EUR", 4); //Total discount / promotion from modifier
					this.totalBrutoWithoutDiscount = cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2); //BRUTO Total price orignial without discounts
					this.totalBruto = cashConv(sumUpVAT, "EUR", 4); //BRUTO Total
					this.totalBrutoTwoDigits = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
					this.totalVAT = cashConv(this.totalBruto - this.totalNetto, "EUR", 4); //VAT only

					//VAT Calculations
					percentagesVat.forEach((percentageVat) => {
						let sumUpVatPerItem = 0;

						this.selectedItems.filter((draftItem) => {

							if (draftItem.internalUse) {
							return false;  // Skip the current item
							}

							if (draftItem.percentageVAT == percentageVat) {
								if (draftItem.typeOfPayment === "deposit") {
									sumUpVatPerItem += cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
								} else {
									sumUpVatPerItem -= cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
								}
							}
						});

						this.totalVats.push({
							percentageVat: percentageVat,
							totalVat: cashConv(sumUpVatPerItem, "EUR", 4),
						});
					});
				}
			},

			/**
			 *
			 * @param {array} items - selectedItems
			 * @param {boolean} manual - true or false for adding manual discount.
			 * @param {object} modifier - object of the modifier
			 * @param {map} alreadyUsed - map of already used items
			 * @param {map} deleted - map of already deleted items
			 * @param {boolean} debug - true or false
			 */

			async calculateModule(items, manual, modifier, allModifiers, alreadyUsed, deleted, debug) {

				let vm = this;
				this.loadingDataTable = true;
				//PART A Generate new data manipulated with old data

				const cleanSelectedItems = items.map((item) => {
					// console.log("Items", item.type, item)
					// Return Product / treatment
					if (item.returned && item.typeOfPayment === "withdraw") {
						return item;
					} else {

						//Calculate Clone
						if (item.type === "product") {
							let obj = deepCopy(this.productsByBranchClone.find((product) => product.id === item.id));
							//Respect / keep the following fields intact
							if (obj) {
								if (item.modifiedId) obj.modifiedId = item.modifiedId;
								if (item.userAdded) obj.userAdded = item.userAdded;
								if (item.resourceId) obj.resourceId = item.resourceId;
								if (item.typeOfPayment) obj.typeOfPayment = item.typeOfPayment;
								if (item.returned) obj.returned = item.returned;
								if (item.internalUse) obj.internalUse = item.internalUse;
								// if (item.discountValueByModifiers) obj.discountValueByModifiers = item.discountValueByModifiers;
								if (item.cartItemId) {
									obj.cartItemId = item.cartItemId;
								} else {
									//Backwards compatibility
									obj.cartItemId = `cart_${item.id}_${uniqid.time()}`; // Generate unique cart item ID if not exists
								}
								
								return obj;
							}
						}
						else if (item.type === "treatment") {
							let obj = deepCopy(this.treatmentsByBranchClone.find((treatment) => treatment.id === item.id));

							if (obj) {
								if (item.modifiedId) obj.modifiedId = item.modifiedId;
								if (item.userAdded) obj.userAdded = item.userAdded;
								if (item.typeOfPayment) obj.typeOfPayment = item.typeOfPayment;
								if (item.returned) obj.returned = item.returned;
								if (item.bookingSummaryId) obj.bookingSummaryId = item.bookingSummaryId;
								if (item.uniqIdStamp) obj.uniqIdStamp = item.uniqIdStamp;
								if (item.internalUse) obj.internalUse = item.internalUse;
								// if (item.discountValueByModifiers) obj.discountValueByModifiers = item.discountValueByModifiers;
								if (item.cartItemId) {
									console.log("item.cartItemId", item.cartItemId);
									obj.cartItemId = item.cartItemId;
								 } else {
									obj.cartItemId = `cart_${item.id}_${uniqid.time()}`;

								 }

								obj.subTreatments = item.subTreatments;
								return obj;
							}
						} 
						
						
						else if (item.type === "customRule" ||	item.type === "membership") {
							// No Deepcopies needed because its custom POS rule
							let obj = item;
							return obj;
						}
					}
				});

				// add addedVia field to modifier by matching alreadyUsed modifiers
				alreadyUsed.forEach((usedModifier) => {
					modifier.forEach((modifier) => {
						if (usedModifier.id === modifier.id) {
							modifier.addedVia = usedModifier.addedVia; 
						}
					});
				});

				//PART B Consistent calculation returned with new Data AND CheckUsage
				//Combine 2 arrays //New Custom Modifier with Created Modifiers

				this.combinedModifiers = this.modifiers.concat(this.customModifiers);
				// console.log("combinedModifiers", this.combinedModifiers);
				console.log("cleanSelectedItems", cleanSelectedItems);

				if (this.clientId.length !== 0) {
					let data;
					try {
						data = scanDiscounts(
							cleanSelectedItems,
							manual,
							modifier,
							this.combinedModifiers,
							alreadyUsed,
							deleted,
							true,
							this.selectedClientData,
							this.sortDiscountOrder
						);
					} catch (error) {
						console.error("Error scanning discounts:", error);
						this.feedbackArray.push({ type: "error", message: "Failed to scan discounts." });
						data = { selectedItems: [], usedModifiers: [], deletedModifiers: [], error: [], advise: [] };
					}

					// Add new Data
					this.selectedItems = data.selectedItems;
					this.usedModifiers = data.usedModifiers;
					this.deletedModifiers = data.deletedModifiers;

					this.feedbackArray = this.feedbackArray.filter(obj => obj.hasOwnProperty('immutable'));
					data.error.forEach((error) => this.feedbackInterpreter(error, "error"));
					console.log("Errors", data.error);
					data.advise.forEach((feedback) => this.feedbackInterpreter(feedback, "feedback"));
					console.log("Feedback", data.advise);
				}

				this.componentKey += 1;
				this.getTotal();

				console.log("%c Calculation done! ", "background: #222; color: #bada55");
				//Store to DB
				if (this.selectedReceiptId !== "") {
			
					function updateClientId() {
						//When there is no Client Data linked
						if (_.isEmpty(vm.selectedClientData) === false) {
							
							// console.log("Client Data linked");
							return {

								clientId: vm.clientId,
								clientname: vm.selectedClientData.name + " " + vm.selectedClientData.surname,
								selectedItems: JSON.parse(JSON.stringify(vm.selectedItems)),
								usedModifiers: vm.usedModifiers,
								validForUseMemberships: vm.validForUseMemberships,

								deletedModifiers: vm.deletedModifiers,
								modified: new Date(),
								customModifiers: vm.customModifiers,
							};
						} else {
							// console.log("No Client Data linked");
							return {
								selectedItems: vm.selectedItems,
								usedModifiers: vm.usedModifiers,
								validForUseMemberships: vm.validForUseMemberships,

								deletedModifiers: vm.deletedModifiers,
								modified: new Date(),
								customModifiers: vm.customModifiers,
							};
						}
					}

					// const clientUpdateData = JSON.parse(JSON.stringify(updateClientId())); //Clean up the observers
					const clientUpdateData = await updateClientId();
					console.log("ClientUpdateData", clientUpdateData);

					db.collection("receiptsDraft")
					.doc(this.selectedReceiptId)
					.set(clientUpdateData, {merge: true})
					.then(() => {
						console.log("%c Receipt Computed and added to DB with ID => ", "background: blue; color: white", this.selectedReceiptId);
						// this.localReceiptDrafts = this.localReceiptDrafts.sort(function(a, b) {
						// // Sortby  modified.seconds
						// // If the first item has a higher number, move it down
						// // If the first item has a lower number, move it up
						// if (a.modified.seconds > b.modified.seconds) return -1;
						// if (a.modified.seconds < b.modified.seconds) return 1;
						// });

						this.tempReceiptKey += 1;
						this.loadingDataTable = false;
						this.getScrollInformation();
					})
					.catch((error) => {
						console.error("Error updating document Receipts Draft : ", error);
					});
				}
			},

			//Check if modifiers with modifierGroup loyaltyModifier could be activated
			checkUsageLoyalty(pointsNeeded) {
				//Sum up available points by docs
				let totalUserPoints = this.clientLoyaltyDocs.reduce((acc, doc) => {
					return acc + doc.points; //?
				}, 0); //?

				// console.log("totalUserPoints", totalUserPoints);

				//Sum up used points via usedModifiers
				let usedPoints = 0;
				this.usedModifiers.forEach((modifier) => {
					if (modifier.modifierGroup === "loyaltyModifier") {
						usedPoints += modifier.loyaltyPointsNeeded;
					}
				});

				// console.log("usedPoints", usedPoints);

				// console.log("calculate Points", totalUserPoints - usedPoints);

				/**
				 * If the user has enough points to activate the loyaltyModifier, return true
				 */
				if (totalUserPoints - usedPoints >= pointsNeeded) {
					return true;
				}
				else {
					return false;
				}
			},

			/**
			 * interpreter for feedback or advisement
			 * @param {object} error - Could be an error or advise
			 * @param {string} type - error or Feedback
			 */
			feedbackInterpreter(data, type) {
				console.log("FeedbackArray", data);

				this.feedbackArray = new Array();
				let getReason = null;

				if (type === "feedback") {
					switch (data.code) {
						case 405: //Discount buyXX and buyXY Almost active
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: data.reason,
								dismiss: true,
								type: "info",
								icon: "mdi-emoticon-wink",
								color: "primay",
							});

						case 406: //Discount buyXX and buyXY suggestion
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: data.reason,
								dismiss: true,
								type: "info",
								icon: "mdi-school",
								color: "primay",
							});

						case 407: //Discount already active
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: data.reason,
								dismiss: true,
								type: "info",
								icon: "mdi-school",
								color: "green",
							});

						case 501: //peak message with discounts
							getReason = data.reason.find((reason) => reason.language == this.userLanguage.toLowerCase());
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: getReason ? getReason.message : data.reason[0].message,
								dismiss: true,
								type: "info",
								icon: "mdi-school",
								color: "green",
							});

						case 502: //peak message with discounts
							getReason = data.reason.find((reason) => reason.language == this.userLanguage.toLowerCase());
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: getReason ? getReason.message : data.reason[0].message,
								dismiss: true,
								type: "info",
								icon: "mdi-school",
								color: "green",
							});

						case 600: //peak message with discounts
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: data.reason,
								dismiss: true,
								type: "info",
								icon: "mdi-school",
								color: "primay",
							});
						default:
							break;
					}
				}

				if (type === "error") {
					switch (data.code) {
						// case 304: //Discount already active

						//   return this.feedbackArray.push({modifierName: error.modifierName, explaination: "is al reeds toegevoegd!", dismiss:true, type: "info"})

						//   break;

						case 406: //Discount already active
							return this.feedbackArray.push({
								modifierName: data.modifierName,
								explaination: "is al reeds toegevoegd!",
								dismiss: true,
								type: "info",
							});

							break;

						default:
							break;
					}
				}
			},

			checkDeleted(deleted) {
				if (deleted) {
					return "onderdeel verwijderd";
				}
			},

			cashConvFilter(amount) {
				//For showing the ZERO's
				return cashConv(amount, "EUR", 2);
			},

			/**
			 * Get data of selected Membership Data and how dialog
			 * @param {String} id membershipId
			 */
			showMembershipInformation(id){
				let foundedMembership = this.companyMemberships.find((membership) => membership.id === id);
				if(foundedMembership != undefined){
					bus.$emit("showMembershipInformation", foundedMembership);
				}
			},

			posDeleteModifier(id) {
				//Delete from Used Modifiers
				//TODO MANIPULATE DELETED

				// console.log("posDeleteModifier", id);

				//Custom Mod not deleted to this.deletedModifiers
				if (this.customModifiers.some((customMod) => customMod.id === id)) {
					this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);
					this.customModifiers = this.customModifiers.filter((customModifier) => customModifier.id !== id);
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				} else {
					//Add to deletedModifiers
					let getModifierToDelete = this.usedModifiers.find((modifier) => modifier.id === id);

					// //Delete related by Memberships
					// if(getModifierToDelete.type === "membership") {

					//   let foundRelatedModifiers = [...getModifierToDelete.separatedDiscount.discountsInsideSubscriptionDays, getModifierToDelete.separatedDiscount.discountsOutsideSubscriptionDays]
					//   console.log("found", foundRelatedModifiers)

					//   this.usedModifiers = this.usedModifiers.filter(
					//   (modifier) => modifier.id == foundRelatedModifiers.includes(modifier.id));

					// }

					if (getModifierToDelete.automatic === true) {
						console.log("getModifierToDelete", id, getModifierToDelete);

						if (getModifierToDelete.type == "product" || getModifierToDelete.type == "treatment" || getModifierToDelete == "all") {
							this.deletedModifiers.push(getModifierToDelete);
						}

						this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);

						this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
					} else {
						//not automatic so no push to deletedModifiers

						this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);

						this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
					}
				}
			},

			/**
			 * Toggles the membership status and manages related discounts.
			 * 
			 * This function handles the following operations:
			 * 1. Toggles the membership on/off.
			 * 2. Applies or removes the membership discount.
			 * 3. Manages related extra discounts.
			 * 4. Applies discounts based on peak/off-peak status.
			 * 5. Recalculates the total after all modifications.
			 * 
			 * @param {string} docId - The unique identifier of the membership to toggle.
			 */
			posToggleMembership(docId) {
				let getRelatedMembershipModifier = this.validForUseMemberships.find((validMembership) => validMembership.id === docId);
				console.log("Related Membership", getRelatedMembershipModifier);

				if (getRelatedMembershipModifier.toggle === true) {
					console.log('🔄 Processing membership toggle ON for:', getRelatedMembershipModifier.name);
			
					let relatedDiscounts = new Array();

					// Add separatedDiscounts
					if (getRelatedMembershipModifier.separatedDiscount.active) {
						console.log('📋 Separated discounts are active:', {
							insideDiscounts: getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays,
							outsideDiscounts: getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays
						});

						if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
							console.log('⭐ Peak hours - applying both inside and outside discounts');
							
							const insideDiscounts = this.modifiers.filter(
								(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
							);
							const outsideDiscounts = this.modifiers.filter(
								(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
							);

							console.log('Found inside discounts:', insideDiscounts.length);
							console.log('Found outside discounts:', outsideDiscounts.length);

							relatedDiscounts = [...insideDiscounts, ...outsideDiscounts];
						}

						if (getRelatedMembershipModifier.peakStatus.peak === "offPeak") {
							console.log('⚡ Off-peak hours detected');
                     
							if (getRelatedMembershipModifier.separatedDiscount.splitDiscounts === true) {
								console.log('🔀 Split discounts enabled - applying only outside discounts');
								
								relatedDiscounts = this.modifiers.filter(
									(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
								);

								console.log('Found outside discounts:', relatedDiscounts.length);
							} 
						}
					}

					console.log('🔄 Related discounts:', relatedDiscounts);

					//Add addedVia to related discounts
					relatedDiscounts.forEach((discount) => {
						console.log('✨ Adding membership tag to discount:', discount.name);
						discount.addedVia = "membership";
					});

					console.log('✅ Total related discounts found:', relatedDiscounts.length);

					//Add related discounts to usedModifiers
					this.usedModifiers = [...relatedDiscounts, ...this.usedModifiers];

					
				}
				else {
					//Toggle off
					//Remove all related modifiers from usedModifiers
					console.log("getRelatedMembershipModifier", getRelatedMembershipModifier);

					//Remove separatedDiscounts
					if (getRelatedMembershipModifier.separatedDiscount.active) {
						console.log("getRelatedMembershipModifier.separatedDiscount", getRelatedMembershipModifier.separatedDiscount);

						if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
							this.usedModifiers = this.usedModifiers.filter(
								(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
							);
						}

						if (getRelatedMembershipModifier.peakStatus.peak === "offPeak") {
							if (getRelatedMembershipModifier.separatedDiscount.splitDiscounts === true) {
								
								this.usedModifiers = this.usedModifiers.filter(
									(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
								);

								console.log("usedModifiers", this.usedModifiers);

							} else {
								this.usedModifiers = this.usedModifiers.filter(
									(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
								);
							}
						}
					}
				}

				// Recalculate
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			},

			posManualAddDiscount(docId) {
				let getDiscount = this.modifiers.find((modifier) => modifier.id === docId);

				getDiscount.addedVia = "manualDiscountMenu";
				this.usedModifiers.push(getDiscount);
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			},

	

			/**
			 * Overrides a membership's status and applies related discounts.
			 * 
			 * This function performs the following:
			 * 1. Forces a membership into "peak" status regardless of actual time
			 * 2. Applies all related discounts (both inside and outside subscription periods)
			 * 3. Marks the membership and discounts as overridden
			 * 4. Recalculates totals with the overridden discounts
			 *
			 * @param {string} docId - The ID of the membership to override
			 */
			overrideMembership(docId) {
				// Find the membership to override
				let getRelatedMembershipModifier = this.validForUseMemberships.find((validMembership) => validMembership.id === docId);

				console.log("overrideMembership", getRelatedMembershipModifier.peakStatus);

				// Force membership into peak status and mark as overridden
				getRelatedMembershipModifier.peakStatus.peak = "peak";
				getRelatedMembershipModifier.override = true;
				getRelatedMembershipModifier.toggle = true;

				console.log("overrideMembership after changes:", getRelatedMembershipModifier.peakStatus, getRelatedMembershipModifier);

				// Collect all related discounts
				let relatedMembershipDiscounts = new Array();

				// If membership has separated discounts, collect both inside and outside period discounts
				if (getRelatedMembershipModifier.separatedDiscount.active) {
					if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
							// Combine inside and outside subscription discounts
							relatedMembershipDiscounts = [
								...this.modifiers.filter(
									(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
								),
								...this.modifiers.filter(
									(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
								)
							];
					}
				}

				// Mark all related discounts as overridden and in peak status
				relatedMembershipDiscounts.forEach((discount) => {
					if (!discount.peakStatus) {
						discount.peakStatus = {};
					}
					discount.peakStatus.peak = "peak";
					discount.override = true;
					discount.toggle = true;
					discount.byMembership = true;
					discount.addedVia = "membershipOverride";
				});

				console.log("Related membership discounts:", relatedMembershipDiscounts);

				// Remove any existing membership-related discounts
				console.log("Used modifiers before cleanup:", this.usedModifiers.length);
				this.usedModifiers = this.usedModifiers.filter((usedModifier) => usedModifier.addedVia !== "membership");
				console.log("Used modifiers after cleanup:", this.usedModifiers.length);

				// Add the overridden discounts to used modifiers
				this.usedModifiers = [...relatedMembershipDiscounts, ...this.usedModifiers];

				// Recalculate totals with new discounts
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			},

			checkModifierUsage(id, type) {
				const used = this.usedModifiers.find((modifier) => modifier.id === id);

				switch (type) {
					case "list":
						if (used) {
							return true;
						} else {
							return false;
						}

					case "description":
						if (used) {
							return true;
						} else {
							return false;
						}
					default:
						break;
				}
			},

			checkResources() {
				/** Check if every selected item is a product and if it has a resourceId. */
				let checkResourceProduct = this.selectedItems.filter((selectedItem) => selectedItem.type === "product").every((product) => product.resourceId !== null);

				/** Check if the selected items have a resourceId for each treatment and subTreatment. */
				let checkResourceTreatment = this.selectedItems
					.filter((selectedItem) => selectedItem.type === "treatment")
					.map((treatment) => treatment.subTreatments)
					.flat()
					.map((subTreatment) => subTreatment)
					.every((subTreatment) => typeof subTreatment.resourceId != "undefined");

				// DEPOSIT PIN CASH SPLIT
				if (checkResourceProduct === true && checkResourceTreatment === true) {
					return true;
				}
				else {
					return false;
				}
			},

			checkDeposit() { // Deposit
				if (this.totalBruto > 0) {
					return true;
				} else {
					return false;
				}
			},

			/**
			 * Check if clientId is present and mandatory
			 * A receipt with new Membership purchase can only continue with a clientId
			 * @returns {Boolean} true or false
			 */
			checkMemberships(){
				if(this.clientId){
					return true
				}
				else{
					let membershipPurchases = this.selectedItems.filter((item)=>{return item.type == "membership"})
					if(membershipPurchases.length == 0){
						return true
					}
					else{
						return false
					}
				}
			},

			checkFree() {
				if (this.totalBruto == 0) {
					return true;
				} else {
					return false;
				}
			},

			checkWithdraw() {
				//Withdraw / Refund
				if (this.totalBruto < 0) {
					return true;
				} else {
					return false;
				}
			},

			validateBeforePay(clientId) {
				let vm = this;

				// For validation needs to be false or "memResourcesValidated" for memberships.
				if (typeof clientId === "undefined" || clientId.length === 0) return false; // Validate ClientId
				
				function validateResources() { // Check for resources and Subtreatment resources
					let product = vm.selectedItems.filter((item) => item.type == "product" || item.type == "customRule"); // Product or Custom Rule

					let treatment = vm.selectedItems.filter((item) => item.type == "treatment");

					if (product.length !== 0 && treatment.length !== 0) {
						if (product.some((item) => item.resourceId === null) === true) return false;
						if (
							treatment
								.map((subItem) => subItem.subTreatments)
								.flat()
								.some((subTreatment) => typeof subTreatment.resourceId === "undefined") === true
						)
							return false;
						return true;
					} else if (product.length !== 0) {
						if (product.some((item) => item.resourceId === null) === true) return false;
						return true;
					} else if (treatment.length !== 0) {
						if (
							treatment
								.map((subItem) => subItem.subTreatments)
								.flat()
								.some((subTreatment) => typeof subTreatment.resourceId === "undefined") === true
						)
							return false;
						return true;
					} else {
						return false;
					}
				}

				function validateMembershipResources() {
					if (vm.selectedItems.every((item) => item.usedMembershipId.length !== 0) === true) {
						if (validateResources() === true) {
							return "memResourcesValidated";
						} else {
							return true;
						}
					} else {
						return true;
					}
				}

				if (validateMembershipResources() === "memResourcesValidated") {// For Membership treatment / product found only
					return "memResourcesValidated";
				} else if (validateMembershipResources() !== "memResourcesValidated" && validateResources() === true && this.totalBruto > 0) { // No membershps or membership in combination with treatments or products
					return true;
				} else if (validateResources() === false && validateMembershipResources() === true) {// No treatments or Products with the right resources
					return false;
				} else if (validateResources() === true && validateMembershipResources() === true && this.totalBruto > 0) {// There are treatment or products and memberships.
					return true;
				} else if (this.totalBruto <= 0) {
					return true;
				}
			},

			membershipCompletionButton(selectedItems) {
				if (selectedItems.every((item) => item.usedMembershipId.length !== 0) === true) { // Only Membership Treatments and Products
					if (this.clientId !== "") {
						console.log("this.validateBeforePay(this.clientId)", this.validateBeforePay(this.clientId));
						if (this.validateBeforePay(this.clientId) === false) {
							return true;
						} else { // Resources are not Undefined
							return "memResourcesValidated";
						}
					} else { // Client ID available
						return true;
					}
				} else { // Other Treatments or products found
					return true;
				}
			},

			formatNumber(number){
				if(number){
					return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format(number);
				}
				else{
					return "-"
				} 
			},

			/**
          * Gets the display name for an item based on its type and ID
          * @param {string} id - The unique identifier of the item
          * @param {string} type - The type of item ('treatment', 'product', 'subscription', etc.)
          * @returns {string} The formatted display name of the item
          */
         getItemName(id, type) {
            if(type == 'treatment'){
               let treatment = this.viewReceiptData.data.selectedItems.find(item => {return item.id == id});
               if(treatment){
                  let internalUse = treatment.internalUse ? ` (${this.$t('payment.internalUse', this.$store.state.locale)})` : "";
                  return treatment.name[this.$store.state.locale.toLowerCase()] + internalUse;
               }
            }
            else if(type == 'subscription' && this.viewReceiptData.data.usedMembership){
               // Return membership name in correct language
               const membership = this.viewReceiptData.data.usedMembership;
               return membership.name[this.$store.state.locale.toLowerCase()] || 
                     membership.name['nl']; // Fallback to Dutch if current locale not available
            }
            else {
               let product = this.viewReceiptData.data.selectedItems.find(item => {return item.id == id});
               if(product){
                  let internalUse = product.internalUse ? ` (${this.$t('payment.internalUse', this.$store.state.locale)})` : "";
                  return product.name + internalUse;
               }
            }
         },

   
         getItemType(type) {
            // Handle regular types
            switch (type) {
               case 'treatment':
                  return this.$t('general.treatment', this.$store.state.locale);
               case 'product':
                  return this.$t('general.product', this.$store.state.locale);
               case 'discount':
                  return this.$t('payment.discount', this.$store.state.locale);
					case 'membership': //Als type abonnement verkocht
						return this.$t('memberships.subscription.subscription', this.$store.state.locale);
               case 'subscription': //Als type korting abonnement
                  return this.$t('memberships.subscription.subscription', this.$store.state.locale);
               default:
                  return this.$t('payment.unknown', this.$store.state.locale);
            }
         },

       
            
            copyTextToClipboard(text) {
              if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
              }
          
              navigator.clipboard.writeText(text).then(
                () => {
                  this.idCopy = true;
                  setTimeout(() => {
                    this.idCopy = false;
                  }, 1000);
                },
                function (err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            },
          



			changeSplitCash(inputValue) {
			
				this.displayChange  = inputValue;

				clearTimeout(this.inputTimer);
				this.inputTimer = setTimeout(() => {
					const locale = 'nl-NL';
					const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
					const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

					let parsedInput = parseFloat(inputValue
						.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
						.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
					);

					
					if (parsedInput > this.totalBruto) {
						this.splitPayment.splitByCash = this.totalBruto;
						this.splitPayment.splitByCard = 0;
					} else {
						this.splitPayment.splitByCash = cashConv(parsedInput, "EUR", 2);
						this.splitPayment.splitByCard = cashConv(this.totalBruto - parsedInput, "EUR", 2);

					}

					console.log("this.splitPayment.splitByCash: ", this.splitPayment.splitByCash)
				},300);
			},


			changeBrutoToClientFunc() {
				if (this.changeBrutoToClient >= this.totalBrutoAbsolute) {
					this.changeForRefund = cashConv(this.changeBrutoToClient - this.totalBrutoAbsolute, "EUR", 2);
				} else {
					this.changeForRefund = null;
				}
			},
			
			changeFunc(inputValue) {

				//De invoerwaarde eventueel aanpassen
				console.log("changeFunc: ", inputValue);

				this.displayChange = inputValue;
				
				clearTimeout(this.inputTimer);
				this.inputTimer = setTimeout(() => {
				const locale = 'nl-NL';

				const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
				const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

				this.change = parseFloat(inputValue
						.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
						.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
				);

				if (this.change >= this.totalBruto) {
					this.changeCalculated = cashConv(this.change - this.totalBruto, "EUR", 2);
				} else {
					this.changeCalculated = null;
				}

				},300);
			},

			checkReturnedStatus(item) {
				let returnedItems = item.selectedItems.filter(
					(selectedItem) => this.validateHistoryItem(selectedItem.uniqueIdentifier, item) == true || selectedItem.returned
				);
				if (returnedItems && returnedItems.length == item.selectedItems.length) {
					return true; // Disabled is true, all items are returned
				} else {
					return false;
				}
			},

			getPaymentMethode(type, number){
				if(type == 'PIN'){
					return "PIN"
				}
				else if(type == 'CASH'){
					return "Contant"
				}
				else if(type == 'Creditcard'){
					return "Creditcard"
				}
				else if(type == 'SPLIT'){
					return "CASH/PIN"
				}
				else if(type == 'MONEYTRANSFER'){
					return this.$t('payment.moneyTranfser', this.$store.state.locale)
				}
				else if(type == 'TIKKIE'){
					return "Tikkie"
				}
				else{
					if(number == 0){
						return "-"
					}
					else{
						return "Onbekend"
					}
					
				}
			},

			durationSuffix(item) {
				if (item.paymentPeriod == "daily") {
					if (item.duration == 1) {
						return "dag";
					} else {
						return "dagen";
					}
				} else if (item.paymentPeriod == "everyWeek") {
					if (item.duration == 1) {
						return "week";
					} else {
						return "weken";
					}
				} else if (item.paymentPeriod == "everyFourWeeks") {
					return "vierweken";
				} else if (item.paymentPeriod == "everyMonth") {
					if (item.duration == 1) {
						return "maand";
					} else {
						return "maanden";
					}
				} else if (item.paymentPeriod == "everyQuarter") {
					if (item.duration == 1) {
						return "kwartaal";
					} else {
						return "kwartalen";
					}
				} else if (item.paymentPeriod == "everyHalfYear") {
					if (item.duration == 1) {
						return "halfjaar";
					} else {
						return "halfjaren";
					}
				} else if (item.paymentPeriod == "everyYear") {
					if (item.duration == 1) {
						return "jaar";
					} else {
						return "jaren";
					}
				} else {
					return `${item.paymentPeriod}`;
				}
			},

			closeViewReceipt(){
				this.viewReceiptData = {
					index: null,
					data: null,
					dialog: false,
					loading:false
				}
			},

			getTreatmentTypeName(id){
				let treatmentType = this.$store.state.generalTreatmentTypes.find(type=>{ return type.id == id});
				if(treatmentType && treatmentType != undefined){
					return this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, treatmentType.name);
				}
			},

			/**
			 * Check if document is still in streaming buffer of BQ and cannot be deleted
			 * @param {Object} item - Receipt Data Object
			 */
			checkBQstreamingTimeLimit(item){
				let now = moment();
				let created = moment(item.time);
				let duration = moment.duration(now.diff(created));
				let minutes = duration.asMinutes();

				return{
					status: minutes > 90 ? true : false,
					minutes: Math.ceil(90 - minutes)
				}
			},

			/**
			 * Open and Show General Delete Modal
			 * @param {Object} item - Receipt Data Object
			 */
			deleteReceiptDialog(item){
				let deleteInformation = new Object();
				deleteInformation.id = item.id;                                                                   
				deleteInformation.emit = 'deleteReceipt';                                                                                            
				deleteInformation.title = this.$t('payment.removeReceipt', this.$store.state.locale);  
				deleteInformation.info = this.$t('payment.removeReceiptInfo', this.$store.state.locale);
				
				bus.$emit('deletedModal', deleteInformation, 'open');
			},

			/**
			 *  Set receipt on delete in Firebase and delete Receipt from BigQuery Database		
			 *  @param {String} id - The Receipt ID form the database
			 */
			async deleteReceipt(id){ 		

				// 1. Delete Receipt from BigQuery Database	
				let token = await firebase.auth().currentUser.getIdToken(true)
				let data = JSON.stringify({
					token: token,
					receiptId: id,
				})
				var config = {
					method: 'post',
					url: `${this.$store.state.reportsApi.url}/deleteReceipt`,
					headers: {'Content-Type': 'application/json'},
					data : data
				};
				axios(config)
				.then(response=> {
					// Receipt successfull deleted in BQ
				})
				.catch((error)=>{
					console.error("Error deleting receipt in BQ: ", error)
				})

				// 2. Set receipt on delete in Firebase
				await db.collection("receiptsPaid").doc(id).set({
					deleted: {
						deleted: true,
						timestamp: new Date,
						deletedBy: this.$store.state.activeUserId
					}
				},{merge: true})

				

				// 3. Check if receiptpayment needed change and restore the amount in de cashregister

				let receipt = this.historyPayments.find(payment=>{ return payment.id == id})

				if(receipt && receipt != undefined){
					if(receipt.paymentTableObj.cash >  0){
						// Return Cash to Register
						await db.collection("cashRegister")
						.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
						.set({						
							cash: firebase.firestore.FieldValue.increment(-Number(receipt.paymentTableObj.cash)),
							timestamp: new Date(),
						}, {merge: true})
						.catch((err) => {
							console.error("Error updating cash register", err);
						});
					}
				}
				this.getRegisterCash();
				this.getHistoryPayments();
				// 4. Close General Delete Modal
				bus.$emit('deletedModal', null, 'close');  
			},

			/**
			 * Copy the paid receipt to a new draft receipt
			 * @param {Objectt} item - Receipt data object
			 */
			async duplicateReceiptToDraft(item){

				let receiptData = JSON.parse(JSON.stringify(item))

				receiptData.created = new Date();
				receiptData.modified = new Date();
				receiptData.draft = true;
				receiptData.draftMadeAtFrontEnd = true;
				receiptData.clientname = receiptData.clientName;
				receiptData.visit = moment().format("YYYY-MM-DD");
				delete receiptData["paymentTableObj"];
				delete receiptData["historyType"];
				delete receiptData["id"];
				delete receiptData["employeeName"];
				delete receiptData["register"];
				delete receiptData["clientName"];

				await db.collection("receiptsDraft").add(receiptData)
			},



			/**
			 * Recalculate and  updatePaidReceipt in Firebase and Biqquery
			 * @param {Objectt} item - Receipt data object
			 */
			async updatePaidReceipt(item){
				
				// 1. Volledige bon data ophalen
				// 2. paymentTableObj herbereken (En created date toevoegen als string)
				// 3. Bon met nieuwe paymentTableObj opslaan als nieuwe bon in Firebase
				// 4. Oude bon in Firebase kenmerk meegegeven
				// 5. Oude bon verwijderen in BigQuery en Firebase

				let oldReceiptData = null;
				let oldReceiptId = null;
				
				// 1. Volledige bon data ophalen
				await db.collection("receiptsPaid").doc(item.id).get()
				.then((doc) => {
					if(doc.exists){
						oldReceiptData = doc.data();
						oldReceiptId = doc.id
					}
				})
				let oldReceiptDataClone = JSON.parse(JSON.stringify(oldReceiptData))
				
				// 2. paymentTableObj herbereken (En created date toevoegen als string)
				let newReceiptData = null
				if(oldReceiptData && oldReceiptData.selectedItems.length > 0){ // Only continue if Old Receipt exists
					newReceiptData = {...await this.enrichSelectedItems(oldReceiptData)}
				}
				

				newReceiptData.paymentTableObj.items = this.setPaymentTableObj(newReceiptData.selectedItems);
				newReceiptData.paymentTableObj.created = moment(newReceiptData.created.seconds,"X").format("YYYY-MM-DDTHH:mm:ss")
				newReceiptData.selectedItems = oldReceiptDataClone.selectedItems;
				// console.log("Old PaymentTableObj: ", oldReceiptData.paymentTableObj.items)
				// console.log("setPaymentTableObj: ", this.setPaymentTableObj(oldReceiptData.selectedItems))
				// console.log("oldReceiptData: ", oldReceiptDataClone)
				// console.log("newReceiptData: ", newReceiptData)

				// 3. Bon met nieuwe paymentTableObj opslaan als nieuwe bon in Firebase
				await db.collection("receiptsPaid").doc(oldReceiptId + 'Rebuild').set(newReceiptData)

				// 4. Oude bon in Firebase kenmerk meegegeven
				await db.collection("receiptsPaid").doc(oldReceiptId).set({
					rebuild: true
				},{merge: true})

				// 5. Oude bon verwijderen in BigQuery en Firebase
				await this.deleteReceipt(oldReceiptId)

			},

			/**
			 * Enrich SelectedItems for new receipt structure
			 * @param {*} selectedItems  from receipt
			 */
			async enrichSelectedItems(oldReceiptData){
				for (let i = 0; i < oldReceiptData.selectedItems.length; i++) {
					let item = oldReceiptData.selectedItems[i];
					
					if(item.discountValueByModifiers && item.discountValueByModifiers.length > 0){
						for(let i = 0; i < item.discountValueByModifiers.length; i++){
						let discountItem = item.discountValueByModifiers[i];
							let modifierData = oldReceiptData.usedModifiers.find((modifier)=> { return modifier.id == discountItem.modifierId})
							
							if(modifierData && modifierData.type == 'downPayment' && modifierData.id){
								item.discountValueByModifiers[i].addedVia = 'downPayment';
								item.discountValueByModifiers[i].type = 'downPayment';
								await db.collection("prepayments").doc(modifierData.id).get()
								.then((doc) => {
									modifierData.description = `${this.$t('payment.downPayment', this.$store.state.locale)} (${moment(doc.data().timestamp.seconds, "X").format("YYYY-MM-DD H:mm")}u)`; 
								})
							}
							else if(modifierData && modifierData.type){
								item.discountValueByModifiers[i].addedVia = modifierData.addedVia;
							}
							
							// console.log("modifierData.type: ", modifierData.type)

							// Create discount data object
							let discountData = {
								type: !modifierData ? 'unknown' : (() => {
									// Check if this is a membership discount
									if (oldReceiptData.usedMembership) {
										const insideDiscounts = oldReceiptData.usedMembership.separatedDiscount?.discountsInsideSubscriptionDays || [];
										const outsideDiscounts = oldReceiptData.usedMembership.separatedDiscount?.discountsOutsideSubscriptionDays || [];
										
										if (insideDiscounts.includes(discountItem.modifierId) || 
											outsideDiscounts.includes(discountItem.modifierId)) {
											return 'subscription';
										}
									}   

									// Default discount type logic
									return (modifierData.type == 'all' || 
											modifierData.type == 'product' || 
											modifierData.type == 'treatment') ? 'discount' : modifierData.type;
								})(),
								priceBruto: -discountItem.givenDiscount,
								discountType: discountItem.discountType,
								discountValue: discountItem.discountValue,
								percentageVAT: discountItem.vatPercentage,
								typeOfPayment: item.typeOfPayment,
								description: (() => {
									// If it's a subscription discount, use the membership name
									if (oldReceiptData.usedMembership) {
										const insideDiscounts = oldReceiptData.usedMembership.separatedDiscount?.discountsInsideSubscriptionDays || [];
										const outsideDiscounts = oldReceiptData.usedMembership.separatedDiscount?.discountsOutsideSubscriptionDays || [];
										
										if (insideDiscounts.includes(discountItem.modifierId) || 
											outsideDiscounts.includes(discountItem.modifierId)) {
											return oldReceiptData.usedMembership.name[this.$store.state.locale.toLowerCase()] || 
											oldReceiptData.usedMembership.name['nl']; // Fallback <-- to NL
										}
									}
									
									// Default description logic
									return modifierData && modifierData.description ? 
											modifierData.description : 
											modifierData.name ? modifierData.name : '';
								})()
							};
							oldReceiptData.selectedItems.push(discountData)
							//this.viewReceiptData.data.receiptItems.push(discountData); 
						}
					}
				}
				return oldReceiptData
			},

			/**
			 * Create new setPaymentTableObj for new receipt structure
			 * @param {*} selectedItems  from receipt
			 */
			setPaymentTableObj(selectedItems){
				return selectedItems.map((item) => {
					console.log("discountValueByModifier:", item.type)
					
						if( item.type == "downPayment"){
							return {
								category: "prePayment",
								discountBruto: item.discountValue,
								newPriceTotalBruto: item.discountValue,
								priceBruto: item.discountValue,
								typeItem:  item.type,
								typeOfPayment: item.type
							}
						}
						else if( item.type == "discount"){
							return {
								category: "customDiscount",
								discountBruto: item.discountValue,
								newPriceTotalBruto: item.discountValue,
								priceBruto: -item.discountValue,
								typeItem:  item.type,
								typeOfPayment: 'deposit'
							}
						}
						else{
							return {
								id: item.id,
								uniqueIdentifier: item.uniqueIdentifier ? item.uniqueIdentifier: uniqid(),
								typeOfPayment: item.typeOfPayment,
								percentageVAT: item.percentageVAT,
								typeItem: item.type, //Product or Treatment or Custom rule
								priceNetto: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.priceNetto : item.treatmentPrice,
								priceBruto: item.priceBruto,
								newPriceTotalNetto: item.newPriceTotalNetto,
								newPriceTotalBruto: item.newPriceTotal,
								discountNetto: item.calculatedDiscountNetto,
								discountBruto: item.calculatedDiscountVAT,
								usedModifierId: item.usedModifierId,
								usedMembershipId: item.usedMembershipId,
								category: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.category[0] : item.treatmentType,
								resourceId: item.type === "product" || item.type === "customRule" || item.type === "membership" ? item.resourceId : "check subs",
								discountValueByModifiers: !item.discountValueByModifiers ? [] 
								: item.discountValueByModifiers.map((discountValueByModifier) => {
									return {
										modifierId: discountValueByModifier.modifierId || null,
										type: discountValueByModifier.type || "modifier",
										vatPercentage: discountValueByModifier.vatPercentage || 0,
										amount: discountValueByModifier.amount || Number(discountValueByModifier.discountValue),
										amountNetto: discountValueByModifier.amountNetto ||  cashConv(Number(discountValueByModifier.discountValue) / ((discountValueByModifier.vatPercentage / 100) + 1), "EUR", 8),
										discountType: discountValueByModifier.discountType || null,
										discountValue: discountValueByModifier.discountValue || 0,
										manualInputDiscount: discountValueByModifier.manualInputDiscount || false,
										addedVia: discountValueByModifier.addedVia || null
									};
								}),
								sub:
									item.type === "product" || item.type === "customRule" || item.type === "membership"
										? []
										: item.subTreatments.map((subTreatment) => {
												return {
													subUniqueIdentifier: uniqid.time(),
													subSection: subTreatment.type, //What type of sub treatment
													subTypeSection: subTreatment.device, //What kind of planning
													subPriceNetto: subTreatment.priceNetto,
													subPriceBruto: subTreatment.priceBruto,
													subResourceId: subTreatment.resourceId,
												};
											}),
						};
					};
				});
				
			},



         /**
          * Displays a receipt view dialog with detailed transaction information
          * 
          * This function handles:
          * 1. Loading receipt data from history
          * 2. Calculating totals
          * 3. Processing items and their discounts
          * 4. Handling membership/subscription discounts
          * 5. Managing expense receipts
          * 
          * @param {number} index - The index of the receipt in historyPayments array
          * @returns {Promise<void>}
          */
			async viewReceipt(index){
				if (window && window.platform && window.platform.name === "Electron") {
					this.checkPrinter();
				}
            // Initialize view receipt data
				this.viewReceiptData = {
					index: index,
					data: this.historyPayments[index],
					dialog: true,
					receipt: null,
					loading:true,
					resend: false,
					resendMessage: null
				};


				if(this.viewReceiptData.data.historyType == 'receipt'){
					this.viewReceiptData.data.receiptItems = new Array();
					let total = {
						priceNettoTotal: 0,
						priceBrutoTotal: 0,
						newPriceTotalNettoTotal: 0,
						newPriceTotalBrutoTotal: 0
					};
               // Process selected items and their discounts
					if(this.viewReceiptData.data.selectedItems && this.viewReceiptData.data.selectedItems.length > 0){
						for (let i = 0; i < this.viewReceiptData.data.selectedItems.length; i++) {
							let item = this.viewReceiptData.data.selectedItems[i];
							
							this.viewReceiptData.data.receiptItems.push(item); 
							total.priceNettoTotal = total.priceNettoTotal + Number(item.treatmentPrice);
							total.priceBrutoTotal = total.priceBrutoTotal + Number(item.priceBruto);
							total.newPriceTotalNettoTotal = total.newPriceTotalNettoTotal + Number(item.newPriceTotalNetto);
							total.newPriceTotalBrutoTotal = total.newPriceTotalBrutoTotal + Number(item.newPriceTotal);
							// console.log("total: ", total)

							if(item.discountValueByModifiers && item.discountValueByModifiers.length > 0){
								for(let i = 0; i < item.discountValueByModifiers.length; i++){
								let discountItem = item.discountValueByModifiers[i];
									let modifierData = this.viewReceiptData.data.usedModifiers.find((modifier)=> { return modifier.id == discountItem.modifierId})

									

									if(modifierData && modifierData.type == 'downPayment' && modifierData.id){
										await db.collection("prepayments").doc(modifierData.id).get()
										.then((doc) => {
											modifierData.description = `${this.$t('payment.downPayment', this.$store.state.locale)} (${moment(doc.data().timestamp.seconds, "X").format("YYYY-MM-DD H:mm")}u)`; 
										})
									}
									// console.log("modifierData.type: ", modifierData.type)

                           // Create discount data object
									let discountData = {
                              type: !modifierData ? 'unknown' : (() => {
                                  // Check if this is a membership discount
                                  if (this.viewReceiptData.data.usedMembership) {
                                      const insideDiscounts = this.viewReceiptData.data.usedMembership.separatedDiscount?.discountsInsideSubscriptionDays || [];
                                      const outsideDiscounts = this.viewReceiptData.data.usedMembership.separatedDiscount?.discountsOutsideSubscriptionDays || [];
                                      
                                      if (insideDiscounts.includes(discountItem.modifierId) || 
                                          outsideDiscounts.includes(discountItem.modifierId)) {
                                          return 'subscription';
                                      }
                                  }   
                                  
											 

                                  // Default discount type logic
                                  return (modifierData.type == 'all' || 
                                          modifierData.type == 'product' || 
                                          modifierData.type == 'treatment') ? 'discount' : modifierData.type;
                              })(),
                              priceBruto: discountItem.givenDiscount ? -discountItem.givenDiscount : -discountItem.amount,
                              discountType: discountItem.discountType,
                              discountValue: discountItem.discountValue,
                              percentageVAT: discountItem.vatPercentage,
                              typeOfPayment: item.typeOfPayment,
                              description: (() => {
                                  // If it's a subscription discount, use the membership name
                                  if (this.viewReceiptData.data.usedMembership) {
                                      const insideDiscounts = this.viewReceiptData.data.usedMembership.separatedDiscount?.discountsInsideSubscriptionDays || [];
                                      const outsideDiscounts = this.viewReceiptData.data.usedMembership.separatedDiscount?.discountsOutsideSubscriptionDays || [];
                                      
                                      if (insideDiscounts.includes(discountItem.modifierId) || 
                                          outsideDiscounts.includes(discountItem.modifierId)) {
                                          return this.viewReceiptData.data.usedMembership.name[this.$store.state.locale.toLowerCase()] || 
                                                 this.viewReceiptData.data.usedMembership.name['nl']; // Fallback <-- to NL
                                      }
                                  }
                                  
                                  // Default description logic
                                  return modifierData && modifierData.description ? 
                                         modifierData.description : 
                                         modifierData.name ? modifierData.name : '';
                              })()
                          };
						  console.log("modifierData PUSH: ", discountData)
									this.viewReceiptData.data.receiptItems.push(discountData); 
								}
							}
						}
					}
					this.viewReceiptData.data.total = total;
					await this.resendReceipt(this.viewReceiptData.data, "history");
				}

				if(this.viewReceiptData.data.historyType == 'expense'){
					this.viewReceiptData.data.total = {
						priceNettoTotal: 0,
						priceBrutoTotal: -this.viewReceiptData.data.expense,
						newPriceTotalNettoTotal: 0,
						newPriceTotalBrutoTotal: -this.viewReceiptData.data.expense,
					};
				}
				
				this.viewReceiptData.loading = false;
			},

			openMembershipInfo(membership) {
				// this.selectedMembership.info = membership;
				// this.selectedMembership.dialog = true;
				// setTimeout(() => {
				// 	bus.$emit("openMembershipInformation");
				// }, 2000);

				bus.$emit("openMembershipInformationDialog", membership);
			},

			closeMembershipInfo() {
				this.selectedMembership.dialog = null;
				this.selectedMembership.info = {
					name: new Object(),
					posDescription: new Object(),
				};
			},

			
			/**
			 * Searches and filters header receipts based on user input.
			 * @param {string} input - The search input string.
			 * @returns {Array} - The filtered array of header receipt drafts.
			 */
			searchHeaderReceipts(input) {
				this.isSearchingHeader = input.length > 0;
				console.log("isSearchingHeader: ", this.isSearchingHeader)
				if (input === "") {
					this.$refs.searchHeaderField.lazyValue = ""; //reset by ref
					this.headerReceiptDraftsSearch = this.headerReceiptDrafts;
					return;
				}
				
				if (input.length > 1) {
					this.headerReceiptDraftsSearch = this.headerReceiptDrafts.filter(receipt => 
						receipt.clientname.toLowerCase().includes(input.toLowerCase())
					);
					
					return this.headerReceiptDraftsSearch;
				} else {
					this.headerReceiptDraftsSearch = this.headerReceiptDrafts;
					console.log(this.headerReceiptDraftsSearch);
					
					return this.headerReceiptDraftsSearch;
				}
			},

		},

		watch: {
			total() {
				this.getTotal(); //Trigger calculation
			},
			selectedItems: {
				deep: true,

				// We have to move our method to a handler field
				handler() {
					if (this.selectedItems.length == 0) {
						//this.usedModifiers = new Array();
						this.customModifiers = new Array();
					}
				},
			},
			// 'viewReceiptData.data.selectedItems': {
			// 	handler() {
			// 		console.log("Ik ben veranderd")
			// 		this.getTotal(this.viewReceiptData.data.selectedItems, true);
			// 	},
			// 	deep: true,

			// },
		},

		computed:{
			activeReceiptId() {
				return this.selectedReceiptId;
			},

			hasMembership(){
				return this.selectedItems.find((item) => item.type === "membership") ? true : false;
			},
			
			theme() {
				return this.$vuetify.theme.dark ? "dark" : "light";
			},

			paymentBarFloat() {
				return this.$attrs.scrollStatus != "bottom" ? true : false;
			},

			beforeDeposit() {
				if (this.deposit.namePayInto.length > 1 && this.deposit.payInto !== 0) {
					return false;
				} else {
					return true;
				}
			},

			beforeExpense() {
				if (
					this.expenses.name !== "" &&
					this.expenses.feedback === null &&
					this.expenses.chosenTypeExpenses !== "" &&
					this.expenses.amountValue != 0 &&
					this.expenses.chosenVat !== ""
				) {
					return false;
				} else {
					return true;
				}
			},

			modifiersFiltered(){
				return this.modifiers.filter((modified)=> { return (modified.type == 'product' || modified.type == 'treatment' || (modified.type == 'all' && !modified.custom)) && modified.modifierGroup == 'defaultModifier' })
			},

			loadedMemberships() {
				if (this.validForUseMemberships.length > 0) {
					return this.$t('memberships.singleSubscription', this.$store.state.locale);
				}
				
			},

			customPaymenRuleValid() {
				if (this.customRule.name && this.customRule.resourceId && this.customRule.priceBruto) {
					return true;
				} else {
					return false;
				}
			},

			depositMoneyCalc() {
				this.cashUpDepositToBank = this.cashUpSumCash + this.cashStatement - this.cashStatementStay.value;
				return this.cashUpSumCash + this.cashStatement - this.cashStatementStay.value;
			},

			companyMembershipFiltered(){
				if (this.companyMembershipSearch && this.companyMembershipSearch.length > 0) {
					return this.companyMemberships.filter((membership) => {
						let nameValid = false;
						let priceValid = membership.price.toString().includes(this.companyMembershipSearch.toLowerCase()) ? true : false
						for (const [key, value] of Object.entries(membership.name)) {
							if(value){
								nameValid = value.toLowerCase().includes(this.companyMembershipSearch.toLowerCase()) ? true : false
							}
						  }
						return  priceValid || nameValid;
				  	});
				}
				else {
					return this.companyMemberships;
				}
			},


			paymentDeviceHint() {
				let hint = {
					text: null,
					persistent: false,
				};
				this.availablePayDevices.forEach((device) => {
					let paymentDevice = this.$store.state.activeCompany.paymentOptions.find((findDevice) => findDevice.id == device);

					if (paymentDevice.id == this.paymentInfo.chosenPaymentDevice) {
						if (paymentDevice.intermediate) {
							let transactionCosts = null;
							if (paymentDevice.type == "amount") {
								transactionCosts = "€ " + String(paymentDevice.transactionCost);
							} else {
								transactionCosts = String(paymentDevice.transactionCost) + "%";
							}
							hint.text = "Kosten zijn " + transactionCosts + " per transactie.";
							hint.persistent = true;
						}
					}
				});
				return hint;
			},

			discountInfo() {
				if (this.customDiscount.typeDiscount.discountType == "percentage") {
					return {
						prefix: null,
						suffix: "%",
						label: "Kortingspercentage",
					};
				} else if (this.customDiscount.typeDiscount.discountType == "amount") {
					return {
						prefix: "€",
						suffix: null,
						label: "Kortingsbedrag",
					};
				} else {
					return {
						prefix: null,
						suffix: null,
						label: "-",
					};
				}
			},

			productsByBranchFiltered() {
				if (this.productSearch) {
					return this.productsByBranch.filter((products) => {
						return products.name.toLowerCase().indexOf(this.productSearch.toLowerCase()) >= 0;
					});
				} else {
					return this.productsByBranch;
				}
			},

			treatmentsByBranchFiltered() {
				if (this.treatmentSearch) {
					return this.treatmentsByBranch.filter((treatment) => {
						return this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, treatment.name ).toLowerCase().indexOf(this.treatmentSearch.toLowerCase()) >= 0
					});
				} else {
					return this.treatmentsByBranch;
				}
			},

			noEmployeeSelected() {
				let info = {
					icon: null,
					color: this.$vuetify.theme.themes[this.theme].background,
					dark: false,
				};
				if (!this.byResource) {
					info.icon = "mdi-alert-circle-outline";
					info.color = "warning";
					info.dark = true;
				}
				return info;
			},

			version() {
				return this.$store.state.version;
			},

			companyTheme() {
				return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
			},

			checkSelected() {
				function isTrue(o) {
					if (o.selected == true) return true;
				}
				return this.selectedItems.some(isTrue);
			},
			userLanguage() {
				return this.$store.state.locale ? this.$store.state.locale : "NL";
			},
			userCompanies() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
			},
			userCompany() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
			},
			userFirstName() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
			},
			userLastName() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
			},

			connectedEmployee() {
				return this.$store.state.connectedEmployee;
			},

			employeeConnected() {
				return this.$store.state.connectedEmployee ? true : false;
			},

			userRole() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
			},
			userId() {
				return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
			},
			companySelectDisabled() {
				return this.companies.length > 1 ? false : true;
			},

			companyBranchSelectDisabled() {
				return this.branches.length > 1 ? false : true;
			},

			clientSelector() {
				let clients = {
					items: 0,
					pages: 0,
					selectedPage: 1,
					selectionStart: 0,
					selectionEnd: 0,
					paginationItems: 0,
				};

				// lg3 md4 xs6
				if (this.clients.length > 0) {
					clients.items = this.clients;
					if (this.$vuetify.breakpoint.name == "lg") {
						clients.paginationItems = 4;
						clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
						if (this.clientSelectedPage == 1) {
							clients.selectionStart = 0;
							clients.selectionEnd = clients.paginationItems - 1;
						} else {
							clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
							clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
						}
						return clients;
					}
					if (this.$vuetify.breakpoint.name == "md") {
						clients.paginationItems = 3;
						clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
						if (this.clientSelectedPage == 1) {
							clients.selectionStart = 0;
							clients.selectionEnd = clients.paginationItems - 1;
						} else {
							clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
							clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
						}
						return clients;
					}
					if (this.$vuetify.breakpoint.name == "sm") {
						clients.paginationItems = 2;
						clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
						if (this.clientSelectedPage == 1) {
							clients.selectionStart = 0;
							clients.selectionEnd = clients.paginationItems - 1;
						} else {
							clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
							clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
						}
						return clients;
					}
				} else {
					return clients;
				}
			},

			selectSpaces() {
				if(this.activeRegisters.length > 1){
					return this.companies.length > 1 && this.$store.state.activeUserRoleNumber < 2 ? "md2": "md3";
				}
				else{
					return this.companies.length > 1 && this.$store.state.activeUserRoleNumber < 2 ? "md3": "md6";
				}	
			},
			companiesSpaces() {
				return this.activeRegisters.length > 1 ? "md2": "md3";
			},

			activeRegisters(){
				return this.$store.state.activeBranch.posSettings.locations.filter((item)=> { return !item.deleted && item.active})
			},

			filteredCompanies() {
				if (this.searchCompanies && this.searchCompanies.length > 0) {
					return this.companies.filter((company) => {
						return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
					});
				} else {
					return this.companies;
				}
			},
		},

		filters: {
			//For converting Unix
			timestampConvert: function (unixCode) {
				// return moment.unix(unixCode).format("HH:mm:ss");
				return moment.unix(unixCode).format("HH:mm") + "u";
			},
			clientNameConvert: function (clientName) {
				if (typeof clientName == "undefined") {
					return this.$t('payment.anonymousReceipt', this.$store.state.locale);
				} else {
					return clientName;
				}
			},
		},
	};

