<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    transition="scale-transition"
    v-model="periodModal"
    @input="scrollToSelected()"
    >
    <template v-slot:activator="{ on }">
      <v-card :rounded="roundedType" flat style="border: 1px solid; height: 40px; overflow: hidden; background: none!important"  v-on="on" :style="{'border-color': $themes[companyTheme][theme].separator}">
          <div class="clear">
            <div>
              <v-icon size="25" style="float: left; padding: 7px 0 0 7px" :color="$themes[companyTheme][theme].primary">mdi-calendar-cursor</v-icon>
            </div>
            
            <div style="width: calc(100% - 65px); float: left; padding: 0 0 0 7px" v-html="selectedPeriod()[$store.state.locale.toLowerCase()]">
              
            </div>
            <div style="width: 40px; float: left">
              <v-icon class="pt-2">mdi-chevron-down</v-icon>
            </div>
          </div>
      </v-card>
    </template>
    <v-card style="min-width: 500px;" d-flex fill-height>
      <v-card-text class="pa-0 ma-0">
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap  class="ma-0 pa-0">
            <v-flex md4 d-flex class="pa-0" style="border-right: 1px solid" :style="{'border-color': $themes[companyTheme][theme].separator}"> 
              <div class="mx-1" style="width: 100%">
                <h4 class="my-2 text-center">{{ $t("reports.selectPeriod", $store.state.locale) }}</h4>
                <v-btn v-if="checkLimit('week')" class="my-1" small :color="$themes[companyTheme][theme].primary"  :outlined="dataFilter.type != 'week'" dark block @click="selectWeekView()">{{ $t("reports.periodWeek", $store.state.locale) }}</v-btn>
                <v-btn v-if="checkLimit('fourWeeks')"  class="my-1" small :color="$themes[companyTheme][theme].primary"  :outlined="dataFilter.type != 'fourWeeks'"  dark block @click="selectFourWeekView">{{ $t("reports.periodFourWeeks", $store.state.locale) }}</v-btn>
                <v-btn v-if="checkLimit('month')"  class="my-1" small :color="$themes[companyTheme][theme].primary"  :outlined="dataFilter.type != 'month'"  dark block @click="selectMonthView">{{ $t("reports.periodMonth", $store.state.locale) }}</v-btn>
                <v-btn v-if="checkLimit('quarter')"  class="my-1" small :color="$themes[companyTheme][theme].primary"  :outlined="dataFilter.type != 'quarter'"  dark block @click="selectQuarterView">{{ $t("reports.periodQuarter", $store.state.locale) }}</v-btn>
                <v-btn v-if="checkLimit('year')"  class="my-1" small :color="$themes[companyTheme][theme].primary"  :outlined="dataFilter.type != 'year'" dark block @click="selectYearView"> {{ $t("reports.periodYear", $store.state.locale) }}</v-btn>
                <!-- <v-btn :disabled="dataFilterOne.type != 'custom'" class="my-1" small :color="companyPrimaryColor" :dark="dataFilterOne.type == 'custom'" block :text="dataFilterOne.type != 'custom'" >Aangepast</v-btn> -->
              </div>
            </v-flex>
            <v-flex md8 d-flex class="pa-0 ma-0">
              <v-card flat d-flex style="width: 100%">
                <v-card flat d-flex v-if="dataFilter.type == 'week'" fill-height>
                  <v-layout row wrap class="ma-0 pa-0" >
                    <v-flex md6 class="pa-0 ma-0" :style="{'border-color': $themes[companyTheme][theme].separator}" style="border-right: 1px solid; max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(year, index) in years" :key="index" >
                          <v-list-item :color="$themes[companyTheme][theme].primary"  @click="selectYear('week', year.id)" :input-value="(dataFilter.week.year == year.id)" >
                            <v-list-item-content>
                              <v-list-item-title>{{ year.id }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < years.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                    <v-flex class="pa-0 ma-0" md6 style="max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0" id="weeks-wrapper">
                        <div v-for="(week, index) in weeks" :key="index" :class="`week-${week.id}`">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  :disabled="week.disabled" @click="selectWeek(week.id)" :input-value="(dataFilter.week.number == week.id)">
                            <v-list-item-content>
                              <v-list-item-title>{{ $t("general.week", $store.state.locale) }} {{ week.id }}</v-list-item-title>
                              <v-list-item-subtitle style="font-size: 12px; font-style: italic">{{ week.name[$store.state.locale.toLowerCase()]}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0" v-if="index < weeks.length - 1" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card flat d-flex v-if="dataFilter.type == 'fourWeeks'" fill-height>
                  <v-layout row wrap class="ma-0 pa-0" >
                    <v-flex md6 class="pa-0 ma-0" :style="{borderColor: $themes[companyTheme][theme].separator}" style="border-right: 1px solid; max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(year, index) in years" :key="index">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  @click="selectYear('fourWeeks', year.id)" :input-value="(dataFilter.fourWeeks.year == year.id)" >
                            <v-list-item-content>
                              <v-list-item-title>{{ year.id }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < years.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                    <v-flex class="pa-0 ma-0" md6 style="max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(fourWeek, index) in fourWeeks" :key="index" :class="`fourWeeks-${fourWeek.id}`">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  :disabled="fourWeek.disabled" @click="selectFourWeek(fourWeek.id)" :input-value="(dataFilter.fourWeeks.number == fourWeek.id)">
                            <v-list-item-content>
                              <v-list-item-title>{{ fourWeek.name[$store.state.locale.toLowerCase()]}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0" v-if="index < fourWeeks.length - 1" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card flat d-flex v-if="dataFilter.type == 'month'" fill-height>
                  <v-layout row wrap class="ma-0 pa-0">
                    <v-flex md6 class="pa-0 ma-0" :style="{borderColor: $themes[companyTheme][theme].separator}" style="border-right: 1px solid; max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(year, index) in years" :key="index" > 
                          <v-list-item :color="$themes[companyTheme][theme].primary"  @click="selectYear('month', year.id)" :input-value="(dataFilter.month.year == year.id)" >
                            <v-list-item-content>
                              <v-list-item-title>{{ year.id }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < years.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                    <v-flex class="pa-0 ma-0" md6 style="max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(month, index) in months" :key="index" :class="`month-${month.id}`">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  :disabled="month.disabled" @click="selectMonth(month.id)" :input-value="(dataFilter.month.number == month.id)">
                            <v-list-item-content>
                              <v-list-item-title>{{ month.name[$store.state.locale.toLowerCase()]}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < months.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card flat v-if="dataFilter.type == 'quarter'" >
                  <v-container grid-list-md fluid class="ma-0 pa-0 fill-height">
                  <v-row row wrap class="ma-0 pa-0 d-flex">
                    <v-col md6 class="pa-0 ma-0" :style="{borderColor: $themes[companyTheme][theme].separator}" style="border-right: 1px solid; max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(year, index) in years" :key="index">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  @click="selectYear('quarter', year.id)" :input-value="(dataFilter.quarter.year == year).id" >
                            <v-list-item-content>
                              <v-list-item-title>{{ year.id }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < years.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-col>
                    <v-col class="pa-0 ma-0" md6 :style="{borderColor: $themes[companyTheme][theme].separator}" style="border-right: 1px solid; max-height: 400px; overflow-y: scroll">
                      <v-list dense class="pa-0 ma-0">
                        <div v-for="(quarter, index) in quarters" :key="index">
                          <v-list-item :color="$themes[companyTheme][theme].primary"  :disabled="quarter.disabled" @click="selectQuarter(quarter.id)" :input-value="(dataFilter.quarter.number == quarter.id)">
                            <v-list-item-content>
                              <v-list-item-title>{{ quarter.name[$store.state.locale.toLowerCase()]}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="pa-0 ma-0"  v-if="index < quarters.length" :key="index"></v-divider>
                        </div>
                      </v-list>
                    </v-col>
                  </v-row>
                  </v-container>
                </v-card>
                <v-card flat d-flex v-if="dataFilter.type == 'year'" fill-height>
                  <v-list dense class="pa-0 ma-0">
                    <div v-for="(year, index) in years" :key="index">
                      <v-list-item :color="$themes[companyTheme][theme].primary"  @click="selectYear('year', year.id)" :input-value="(dataFilter.year == year.id)" >
                        <v-list-item-content>
                          <v-list-item-title>{{ year.id }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="pa-0 ma-0"  v-if="index < years.length" :key="index"></v-divider>
                    </div>
                  </v-list>
                </v-card>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <!-- 
        <v-divider></v-divider>
        <v-card-actions>
        <span v-if="dataFilterOne.valid" style="font-size: 12px;">{{ dataFilterOne.startDateFormatted }} t/m {{ dataFilterOne.endDateFormatted }}</span>
        <span v-else style="font-size: 12px;">Selecteer een begin en einddatum</span>
        <v-spacer></v-spacer>
        <v-btn class="mx-1" small color="#bfc5d2" outlined dark @click="cancelSelectedDateOne()">Annuleren</v-btn>
        <v-btn class="mx-1" :disabled="!dataFilterOne.valid"  :depressed="!dataFilterOne.valid" small :color="companySecondaryColor" :dark="dataFilterOne.valid" @click="setSelectedDateOne()">Gebruiken <v-icon right>chevron_right</v-icon></v-btn>  
      </v-card-actions> -->
    </v-card>
  </v-menu>
</template>

<script>
  import moment from "moment";
  import { bus } from "../../main";
  export default {
    props: {
      dataType: String,
      limit: Array,
      typeRounded: Boolean
    },
    data() {
      return {
        feedback:null,
        hidden:false,
        viewType: "week",
        periodModal: false,
        dataFilter: {
          type: "week",
          week: {
            year: null,
            number: null
          },
          fourWeeks: {
            year: null,
            number: null
          },
          month: {
            year: null,
            number: null
          },
          quarter: {
            year: null,
            number: null
          },
          year: {
            number: null
          },
          number: 1
        }, 
      }   
    },
    created(){   
      let filterData = null;
      bus.$on("setFilterData", (data) => { 
        filterData = data
        this.setData(filterData);
      });
      this.setData(filterData);
    },

    methods:{
      setData(data){
        if(data){
           this.viewType = data.type;
          this.dataFilter.type = data.type
          this.dataFilter.week.number = data.type == 'week' ? data.number : null;
          this.dataFilter.week.year = data.year;
          this.dataFilter.fourWeeks.number = data.type == 'fourWeeks' ? data.number : null;
          this.dataFilter.fourWeeks.year = data.year;
          this.dataFilter.month.number = data.type == 'month' ? data.number : null;
          this.dataFilter.month.year = data.year;
          this.dataFilter.quarter.number = data.type == 'quarter' ? data.number : null;
          this.dataFilter.quarter.year = data.year;
          this.dataFilter.year = data.year;
        }
        else{
          this.dataFilter.week.year = moment().year();
          this.dataFilter.week.number = moment().isoWeek();
          this.dataFilter.fourWeeks.year = moment().year();
          this.dataFilter.fourWeeks.number = Math.ceil(Number(moment().isoWeek())/4);
          this.dataFilter.month.year = moment().year();
          this.dataFilter.month.number = Number(moment().format("M"));
          this.dataFilter.quarter.year = moment().year();
          this.dataFilter.quarter.number = Math.ceil(Number(moment().format("M"))/4);
          this.dataFilter.year = Number(moment().format("YYYY"));
          this.dataFilter.number = moment().isoWeek();               
        }
      },
      checkLimit(type){
        if(this.limit && this.limit.length > 0){
          return this.limit.findIndex(item => item == type) >= 0 ? true: false;
        }
        else{
          return true
        }
      },

      /**
       * Scroll to current week month or quarter when opening modal
       */
      scrollToSelected(){
        if(this.periodModal){
          setTimeout(()=>{

            if(this.dataFilter[this.viewType].number){
              let items = document.getElementsByClassName(`${this.viewType}-${this.dataFilter[this.viewType].number}`)
              items.forEach(item=>{
                item.scrollIntoView({
                  behavior: "instant"
                });
              })
            }
          },100)
        }       
      },

      selectWeekView(){
        this.dataFilter.type = "week";
        this.viewType = "week";
        this.scrollToSelected()
      },
      selectFourWeekView(){
        this.dataFilter.type = "fourWeeks";
        this.viewType = "fourWeeks";
        this.dataFilter.fourWeeks.number = this.dataFilter.fourWeeks.number ? this.dataFilter.fourWeeks.number : this.dataFilter.month.year == moment().year() ? Math.ceil(Number(moment().isoWeek())/4) : 1;
        this.scrollToSelected()
      },
      selectMonthView(){
        this.dataFilter.type = "month";
        this.viewType = "month";
        this.dataFilter.month.number = this.dataFilter.month.number ? this.dataFilter.month.number : this.dataFilter.month.year == moment().year() ? moment().format("M") : 1;
        this.scrollToSelected()
      },
      selectQuarterView(){
        this.dataFilter.type = "quarter";
        this.viewType = "quarter";
      },
      selectYearView(){
        this.dataFilter.type = "year";
        this.viewType = "year";
      },
      selectYear(type, year){
        console.log()
        this.dataFilter.year = year;
        if(type == "week"){
          this.dataFilter.week.year = year;
          this.dataFilter.week.number = null;
        }
        if(type == "fourWeeks"){
          this.dataFilter.fourWeeks.year = year;
          this.dataFilter.fourWeeks.number = null;
        }
        if(type == "month"){
          this.dataFilter.month.year = year;
          this.dataFilter.month.number = null;
        } 
        if(type == "quarter"){
          this.dataFilter.quarter.year = year;
          this.dataFilter.quarter.number = null;
        }
        if(type == "year"){  
          this.dataFilter.number = year;
          this.closeDialog();
        }
      },

      selectWeek(week){
        this.dataFilter.week.number = week;
        this.dataFilter.number = week;
        this.closeDialog();
      },

      selectMonth(month){
        this.dataFilter.month.number = month;
        this.dataFilter.number = month;
        this.closeDialog();
      },

      selectFourWeek(fourWeek){
        this.dataFilter.fourWeeks.number = fourWeek;
        this.dataFilter.number = fourWeek;
        this.closeDialog();
      },

      selectQuarter(quarter){
        this.dataFilter.quarter.number = quarter;
        this.dataFilter.number = quarter;
        this.closeDialog();
      },

      closeDialog(){
        this.$emit("updateDataFilter", {dataType: this.dataType, year: this.dataFilter.year, type: this.dataFilter.type, number: this.dataFilter.number});
        this.periodModal = false;
      },

      selectedPeriod(){
        if(this.viewType =='week'){
          if(this.dataFilter.week.number){
            let startDate = ""; 
            let endDate = ""; 
            this.weeks.forEach(week=>{
              if(week.id == this.dataFilter.week.number){
                startDate = week.startDate;
                endDate = week.endDate;
              }
            })
            return {
              nl:`<h4 style="font-size: 14px;">Week ${this.dataFilter.week.number} van ${this.dataFilter.week.year}</h4><p style="font-size: 12px">${startDate} t/m ${endDate}</p>`,
              en:`<h4 style="font-size: 14px;">Week ${this.dataFilter.week.number} of ${this.dataFilter.week.year}</h4><p style="font-size: 12px">${startDate} to ${endDate}</p>`,
              de:`<h4 style="font-size: 14px;">Woche ${this.dataFilter.week.number} von ${this.dataFilter.week.year}</h4><p style="font-size: 12px">${startDate} bis ${endDate}</p>`,
            };
          }
          else{
            return {
              nl:`<h4 style="font-size: 14px;">Week van ${this.dataFilter.week.year}</h4><p style="font-size: 12px">Selecteer week</p>`,
              en:`<h4 style="font-size: 14px;">Week of ${this.dataFilter.week.year}</h4><p style="font-size: 12px">Select week</p>`,
              de:`<h4 style="font-size: 14px;">Woche von${this.dataFilter.week.year}</h4><p style="font-size: 12px">Woche auswählen</p>`,
            };
          }
        };
        if(this.viewType =='fourWeeks'){
          let startDate = ""; 
          let endDate = ""; 
          let startWeek = ""; 
          let endWeek = ""; 
          this.fourWeeks.forEach(fourWeek=>{
            if(fourWeek.id == this.dataFilter.fourWeeks.number){
              startDate = fourWeek.startDate;
              endDate = fourWeek.endDate;
              startWeek = fourWeek.startWeek;
              endWeek = fourWeek.endWeek;
            }
          })
          return {
            nl:`<h4 style="font-size: 14px;">Week ${startWeek} t/m ${endWeek} van ${this.dataFilter.fourWeeks.year}</h4><p style="font-size: 12px">${startDate} t/m ${endDate}</p>`,
            en:`<h4 style="font-size: 14px;">Week ${startWeek} to ${endWeek} of ${this.dataFilter.fourWeeks.year}</h4><p style="font-size: 12px">${startDate} to ${endDate}</p>`,
            de:`<h4 style="font-size: 14px;">Woche ${startWeek} to ${endWeek} of ${this.dataFilter.fourWeeks.year}</h4><p style="font-size: 12px">${startDate} bis ${endDate}</p>`,
          };
        };
        if(this.viewType =='month'){
          let startDate = ""; 
          let endDate = ""; 
          let name = ""; 
          this.months.forEach(month=>{
            if(month.id == this.dataFilter.month.number){
              startDate = month.startDate;
              endDate = month.endDate;
              name = month.name;
            }
          })
          return {
            nl:`<h4 style="font-size: 14px;">${name.nl} ${this.dataFilter.month.year}</h4><p style="font-size: 12px">${startDate} t/m ${endDate}</p>`,
            en:`<h4 style="font-size: 14px;">${name.en} ${this.dataFilter.month.year}</h4><p style="font-size: 12px">${startDate} to ${endDate}</p>`,
            de:`<h4 style="font-size: 14px;">${name.en} ${this.dataFilter.month.year}</h4><p style="font-size: 12px">${startDate} bis ${endDate}</p>`,
          };
        };
        if(this.viewType =='quarter'){
          let startDate = ""; 
          let endDate = ""; 
          let id = ""; 
          this.quarters.forEach(quarter=>{
            if(quarter.id == this.dataFilter.quarter.number){
              startDate = quarter.startDate;
              endDate = quarter.endDate;
              id = quarter.id;
            }
          })
          return {
            nl:`<h4 style="font-size: 14px;">Kwartaal ${id}</h4><p style="font-size: 12px">${startDate} t/m ${endDate}</p>`,
            en:`<h4 style="font-size: 14px;">Quarter ${id}</h4><p style="font-size: 12px">${startDate} to ${endDate}</p>`,
            de:`<h4 style="font-size: 14px;">Quarter ${id}</h4><p style="font-size: 12px">${startDate} bis ${endDate}</p>`,
          };
        };
        if(this.viewType =='year'){
          let startDate = ""; 
          let endDate = ""; 
          let id = ""; 
          this.years.forEach(year=>{
            if(year.id == this.dataFilter.year){
              startDate = year.startDate;
              endDate = year.endDate;
              id = year.id;
            }
          })
          return {
            nl:`<h4 style="font-size: 14px;">${id}</h4><p style="font-size: 12px">${startDate} t/m ${endDate}</p>`,
            en:`<h4 style="font-size: 14px;">${id}</h4><p style="font-size: 12px">${startDate} to ${endDate}</p>`,
            de:`<h4 style="font-size: 14px;">${id}</h4><p style="font-size: 12px">${startDate} bis ${endDate}</p>`,
          };
        };
      }
    },

    computed: {
      roundedType(){
        if(this.typeRounded){
          return "xl"
        }
        else{
          return "xs"
        }
      },

      weeks(){
        let weeks = [
          { id: 1,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 2,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 3,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 4,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 5,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 6,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 7,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 8,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 9,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 10,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 11,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 12,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 13,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 14,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 15,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 16,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 17,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 18,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 19,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 20,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 21,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 22,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 23,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 24,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 25,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 26,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 27,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 28,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 29,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 30,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 31,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 32,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 33,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 34,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 35,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 36,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 37,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 38,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 39,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 40,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 41,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 42,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 43,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 44,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 45,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 46,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 47,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 48,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 49,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 50,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 51,  name: { nl: "", en: "", de: ""}, disabled: false },
          { id: 52,  name: { nl: "", en: "", de: ""}, disabled: false }
        ];

        let startYearCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).format("YYYY") : null;
        let startWeekCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).isoWeek() : null;
        weeks.forEach(week=>{
          let date = moment(this.dataFilter.week.year,"YYYY").isoWeek(week.id);
          let startOfWeek = moment().year(this.dataFilter.week.year).isoWeek(week.id).startOf('week').format("D MMMM");
          let endOfWeek = moment().year(this.dataFilter.week.year).isoWeek(week.id).endOf('week').format("D MMMM");

          week.startDate = moment().year(this.dataFilter.week.year).isoWeek(week.id).day(1).format("D MMMM YYYY");
          week.endDate = moment().year(this.dataFilter.week.year).isoWeek(week.id).day(7).format("D MMMM YYYY");
          week.name.nl = `${startOfWeek} t/m ${endOfWeek}`;
          week.name.en = `${startOfWeek} to ${endOfWeek}`;
          week.name.de = `${startOfWeek} bis ${endOfWeek}`;
          if(moment().year() == this.dataFilter.week.year){
            if(week.id > moment().isoWeek()){
              week.disabled = true;
            }
          }
          if(startYearCompany && startYearCompany == this.dataFilter.week.year){
            if(startWeekCompany && week.id < startWeekCompany){
              week.disabled = true;
            }
          } 
        })
        return weeks;
      },

      fourWeeks(){
        let fourWeeks = [
          { id: 1, startWeek: 1, endWeek: 4, name: { nl: "Week 1 t/m 4", en: "Weeks 1 to 4" , de: "Wochen 1 bies 4"}, disabled: false },
          { id: 2, startWeek: 5, endWeek: 8, name: { nl: "Week 5 t/m 8", en: "Weeks 5 to 8", de: "Wochen 5 bies 8"}, disabled: false },
          { id: 3, startWeek: 9, endWeek: 12, name: { nl: "Week 9 t/m 12", en: "Weeks 9 to 12", de: "Wochen 9 bies 12"}, disabled: false },
          { id: 4, startWeek: 13, endWeek: 16, name: { nl: "Week 13 t/m 16", en: "Weeks 13 to 16", de: "Wochen 13 bies 16"}, disabled: false },
          { id: 5, startWeek: 17, endWeek: 18, name: { nl: "Week 17 t/m 20", en: "Weeks 17 to 20", de: "Wochen 17 bies 20"}, disabled: false },
          { id: 6, startWeek: 19, endWeek: 24, name: { nl: "Week 19 t/m 24", en: "Weeks 19 to 24", de: "Wochen 19 bies 24"}, disabled: false },
          { id: 7, startWeek: 25, endWeek: 28, name: { nl: "Week 25 t/m 28", en: "Weeks 25 to 28", de: "Wochen 25 bies 28"}, disabled: false },
          { id: 8, startWeek: 29, endWeek: 32, name: { nl: "Week 29 t/m 32", en: "Weeks 29 to 32", de: "Wochen 29 bies 32"}, disabled: false },
          { id: 9, startWeek: 33, endWeek: 36, name: { nl: "Week 33 t/m 36", en: "Weeks 33 to 36", de: "Wochen 33 bies 36"}, disabled: false },
          { id: 10, startWeek: 37, endWeek: 40, name: { nl: "Week 37 t/m 40", en: "Weeks 37 to 40", de: "Wochen 37 bies 40"}, disabled: false },
          { id: 11, startWeek: 41, endWeek: 44, name: { nl: "Week 41 t/m 44", en: "Weeks 41 to 44", de: "Wochen 41 bies 44"}, disabled: false },
          { id: 12, startWeek: 45, endWeek: 48, name: { nl: "Week 45 t/m 48", en: "Weeks 45 to 48", de: "Wochen 45 bies 48"}, disabled: false },
          { id: 13, startWeek: 49, endWeek: 52, name: { nl: "Week 49 t/m 52", en: "Weeks 49 to 52", de: "Wochen 49 bies 52"}, disabled: false },
        ];

        let startYearCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).format("YYYY") : null;
        let startWeekCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).isoWeek() : null;

        fourWeeks.forEach(fourWeek=>{
          let start = moment().week(fourWeek.startWeek);
          let end = moment().week(fourWeek.endWeek);
          fourWeek.startDate = moment(start).day(1).format("D MMMM YYYY");
          fourWeek.endDate = moment(end).day(7).format("D MMMM YYYY");

          if(moment().year() == this.dataFilter.fourWeeks.year){
            if(fourWeek.startWeek > moment().isoWeek()){
              fourWeek.disabled = true;
            }
          }
          if(startYearCompany && startYearCompany == this.dataFilter.fourWeeks.year){
            if(startWeekCompany && fourWeek.startWeek < startWeekCompany){
              fourWeek.disabled = true;
            }
          } 

           
        })
        return fourWeeks

      },

      months(){
        let months = [
          { id: 1, name: { nl: "Januari", en: "January", de: "Januar"}, disabled: false },
          { id: 2, name: { nl: "Februari", en: "February", de: "Februar"}, disabled: false },
          { id: 3, name: { nl: "Maart", en: "March", de: "March"}, disabled: false },
          { id: 4, name: { nl: "April", en: "April", de: "Arpil"}, disabled: false },
          { id: 5, name: { nl: "Mei", en: "May", de: "Mai"}, disabled: false },
          { id: 6, name: { nl: "Juni", en: "June", de: "Juni"}, disabled: false },
          { id: 7, name: { nl: "Juli", en: "July", de: "Juli"}, disabled: false },
          { id: 8, name: { nl: "Augustus", en: "August", de: "August"}, disabled: false },
          { id: 9, name: { nl: "September", en: "September", de: "September"}, disabled: false },
          { id: 10, name: { nl: "Oktober", en: "October", de: "Oktober"}, disabled: false },
          { id: 11, name: { nl: "November", en: "November", de: "November"}, disabled: false },
          { id: 12, name: { nl: "December", en: "December", de: "Dezember"}, disabled: false },  
        ];

        let startYearCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).format("YYYY") : null;
        let startMonthCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).format("M") : null;

        months.forEach(month=>{
          let date = moment(`10-${month.id}-${this.dataFilter.month.year}`, "DD-M-YYYY");
          month.startDate = moment(date).startOf('month').format("D MMMM YYYY");
          month.endDate = moment(date).endOf('month').format("D MMMM YYYY");
          if(moment().year() == this.dataFilter.month.year){
            if(month.id > Number(moment().format("M"))){
              month.disabled = true;
            }
          }
          if(startYearCompany && startYearCompany == this.dataFilter.month.year){
            if(startMonthCompany && month.id < startMonthCompany){
              month.disabled = true;
            }
          } 
        })
        return months;
      },
      
      quarters(){
        let quarters = [
          { id: 1, firstMonth: 1, name: { nl: "Kwartaal 1", en: "Quarter 1", de: "Quartal 1"}, disabled: false },
          { id: 2, firstMonth: 4, name: { nl: "Kwartaal 2", en: "Quarter 2", de: "Quartal 2"}, disabled: false },
          { id: 3, firstMonth: 7, name: { nl: "Kwartaal 3", en: "Quarter 3", de: "Quartal 3"}, disabled: false },
          { id: 4, firstMonth: 10, name: { nl: "Kwartaal 4", en: "Quarter 4", de: "Quartal 4"}, disabled: false },
        ];

        let startYearCompany = this.$store.state.activeCompany.startDate ? moment(this.$store.state.activeCompany.startDate).format("YYYY") : null;
        let startQuarterCompany = this.$store.state.activeCompany.startDate ? Math.ceil(Number(moment(this.$store.state.activeCompany.startDate).format("M"))/3) : null;

        quarters.forEach(quarter=>{
          let startDate = moment(`10-${quarter.firstMonth}-${this.dataFilter.quarter.year}`, "DD-M-YYYY");
          let endDate = moment(`10-${quarter.firstMonth + 2}-${this.dataFilter.quarter.year}`, "DD-M-YYYY");
          quarter.startDate = moment(startDate).startOf('month').format("D MMMM YYYY");
          quarter.endDate = moment(endDate).endOf('month').format("D MMMM YYYY");

          if(moment().year() == this.dataFilter.quarter.year){
            if(quarter.firstMonth > Number(moment().format("M"))){
              quarter.disabled = true;
            }
          }
          if(startYearCompany && startYearCompany == this.dataFilter.quarter.year){
            if(startQuarterCompany && quarter.id < startQuarterCompany){
              quarter.disabled = true;
            }
          } 
        })
        return quarters;
      },

      years(){
        let currentYear = Number(moment().year());
        let startDateCompany = this.$store.state.activeCompany.startDate ? this.$store.state.activeCompany.startDate : moment().format("YYYY");
        // console.log("startDateCompany: ", startDateCompany)
        let numberOfYears = moment(currentYear,"YYYY").diff(moment(startDateCompany,"YYYY"), 'years', false) + 1;
        // console.log("numberOfYears: ", numberOfYears)
        let activeYears = Array(numberOfYears).fill(0).map(x => ({id: null, startDate: null, endDate: null}));
        let counter = 0;

        activeYears.forEach((year)=>{
          year.id = currentYear - counter;
          year.startDate = moment(`${year.id}-01,01`, "YYYY-MM-DD").startOf('year').format("D MMMM YYYY");
          year.endDate = moment(`${year.id}-12,31`, "YYYY-MM-DD").endOf('year').format("D MMMM YYYY");
          counter++
        })
        return activeYears.reverse()
      },

      userCompanies() {
        return this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.userRole;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        if(this.$store.state.companyTheme){
          return this.$store.state.companyTheme;
        }
        else{
          return "thrive"
        }
      },
    }
  };
</script>
