const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

/**
 * Finds modifiers that overlap with the selected modifier
 * @param {Array} products - All products
 * @param {Array} treatments - All treatments
 * @param {Array} modifiers - All modifiers
 * @param {Object} selectedModifier - Currently selected modifier
 * @param {Boolean} debug - Enable/disable debug logging
 * @returns {Object} - Correlated modifiers data
 */
const isActiveInDateRange = (selectedModifier, modifier) => {
  if (!selectedModifier.dateLimit.active) return true;
  if (!modifier.dateLimit.active) return true;
  
  const selectedRange = moment.range(
    selectedModifier.dateLimit.startDate,
    selectedModifier.dateLimit.endDate
  );
  const modifierRange = moment.range(
    modifier.dateLimit.startDate,
    modifier.dateLimit.endDate
  );
  return selectedRange.overlaps(modifierRange, { adjacent: true });
};


const hasBranchOverlapFunc = (selectedModifier, modifier) => {
  if (selectedModifier.branches.length === 0) return true;
  if (modifier.branches.length === 0) return true;
  return modifier.branches.some(branch => 
    selectedModifier.branches.includes(branch)
  );
};


export function getCorrelatedModifiers(
  products,
  treatments,
  modifiers,
  selectedModifier,
  debug = true
) {
  const log = (...args) => debug && console.log(...args);

  // Main processing
  const getCorrelatedIds = (type) => {
    // Skip processing if modifier type doesn't match
    if (selectedModifier.type === 'product' && type === 'Treatments') {
      log(`Skipping treatments check for product-only modifier`);
      return [];
    }
    if (selectedModifier.type === 'treatment' && type === 'Products') {
      log(`Skipping products check for treatment-only modifier`);
      return [];
    }

    const selectedFilter = selectedModifier[`filter${type}`];
    const otherModifiers = modifiers
      .filter(modifier => 
        modifier.id !== selectedModifier.id && 
        modifier.active &&
        (modifier.type === 'all' || modifier.type === selectedModifier.type)
      );
  
    const getIdsForModifier = (modifier) => {
      const filter = modifier[`filter${type}`];
      
      // If filter is inactive, consider all items
      if (!filter.active) {
        if (type === 'Products') {
          return new Set(products.map(p => p.id));
        } else {
          return new Set(treatments.map(t => t.id));
        }
      }
  
      // Detailed debug logging
      log(`Processing ${modifier.name} filter (${type})`, {
        filterType: filter.type,
        filterItems: filter.items,
        filterActive: filter.active,
        itemsAction: filter.itemsAction
      });
  
      switch (filter.type) {
        case 'specific':
          return new Set(filter.items);
        case 'categories':
          if (type === 'Products') {
            return new Set(products
              .filter(product => product.category.some(cat => filter.items.includes(cat)))
              .map(product => product.id)
            );
          } else { // Treatments
            return new Set(treatments
              .filter(treatment => filter.items.includes(treatment.treatmentType))
              .map(treatment => treatment.id)
            );
          }
        case 'brands':
          return new Set(products
            .filter(product => filter.items.includes(product.supplier))
            .map(product => product.id)
          );
        default:
          return new Set();
      }
    };
  
    const selectedIds = getIdsForModifier(selectedModifier);
    const correlatedModifiers = new Set();
  
    otherModifiers.forEach(modifier => {
      const modifierIds = getIdsForModifier(modifier);
      
      // Detailed debug logging
      log(`Comparing ${selectedModifier.name} with ${modifier.name}`, {
        selectedIds: [...selectedIds],
        modifierIds: [...modifierIds],
        overlap: selectedFilter.itemsAction === 'include'
          ? [...modifierIds].some(id => selectedIds.has(id))
          : [...modifierIds].every(id => !selectedIds.has(id))
      });
  
      const hasOverlap = selectedFilter.itemsAction === 'include'
        ? [...modifierIds].some(id => selectedIds.has(id))
        : [...modifierIds].every(id => !selectedIds.has(id));
  
      if (hasOverlap) {
        correlatedModifiers.add(modifier.id);
        log(`Overlap detected between ${selectedModifier.name} and ${modifier.name}`);
      }
    });
  
    return [...correlatedModifiers];
  };

  // Execute for both products and treatments
  const correlatedProductIds = selectedModifier.type !== 'treatment' 
    ? getCorrelatedIds('Products') 
    : [];
  
  const correlatedTreatmentIds = selectedModifier.type !== 'product' 
    ? getCorrelatedIds('Treatments') 
    : [];

  log('Correlated Modifiers:', {
    products: correlatedProductIds,
    treatments: correlatedTreatmentIds,
    modifierType: selectedModifier.type
  });

  return {
    correlatedProductModifiersId: correlatedProductIds,
    correlatedTreatmentModifiersId: correlatedTreatmentIds,
    correlatedProductModifiersName: correlatedProductIds.map(id => 
      modifiers.find(m => m.id === id)?.name
    ),
    correlatedTreatmentModifiersName: correlatedTreatmentIds.map(id => 
      modifiers.find((m) => m.id === id)?.name
    ),
  };
}
