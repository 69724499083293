<template>
  <v-dialog v-model="showDialog" scrollable max-width="800px" height="700px">
      <v-card v-if="!loading">
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3 v-if="type == 'new'">{{ $t(`indicator.newIndicator`, $store.state.locale) }}</h3>
          <h3 v-else >{{ $t(`indicator.editIndicator`, $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeIndicatorDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

        <v-divider ></v-divider>

        <v-card-text class="pa-0 ma-0">

          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" class="pa-2" style="width: 100%"  >
            <div class="text-center">
              <v-chip dense x-small label light class="ma-0 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]">
                <v-icon size="14" left> mdi-alert</v-icon> {{ $t(`indicator.exampleData`, $store.state.locale) }}
              </v-chip>
            </div>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex :class="exampleWidth.example" d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-3 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
                    <barometer :indicator="indicator" :type="'barometerDialog'" :products="products" :treatments="treatments"></barometer>
                   </v-card> 
                </v-flex>
                <v-flex :class="exampleWidth.extra" d-flex style="opacity: 0.3">
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-4 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%"> </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-form ref="addIndicatorForm" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-select
                    class="mt-0"
                    outlined
                    dense
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.color"
                    :items="colors"
                    :label="$t(`general.color`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="changePreviewColor()"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.dataType"
                    :items="dataTypes"
                    :label="$t(`indicator.dataType`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="resetDataSubType"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.data"
                    :items="dataSubTypes"
                    :label="$t(`indicator.data`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="resetDataSubType"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' || indicator.data == 'products'"> 
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.dataFilter"
                    :items="dataFilters"
                    :label="$t(`indicator.filter`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="loadDataFilter()"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'products' && indicator.dataFilter == 'selected'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.products"
                    :items="products"
                    :label="$t(`indicator.productsFilter`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    :loading="productsLoading"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'products' && indicator.dataFilter == 'categories'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.productTypes"
                    :items="$store.state.productCategories"
                    :label="$t(`indicator.categories`, $store.state.locale)"
                    :item-text="item => $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name)"
                    item-value="id"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.treatments"
                    :items="treatments"
                    :label="$t(`indicator.treatmentsFilter`, $store.state.locale)"
                    :item-text="item =>  $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.treatmentTitle)"
                    item-value="id"
                    :loading="treatmentsLoading"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.treatmentTypes"
                    :items="$store.state.treatmentCategories"
                    :label="$t(`indicator.categories`, $store.state.locale)"
                     :item-text="item => $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name)"
                    item-value="id"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>  
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.graphType"
                    :items="graphTypes"
                    :label="$t(`indicator.viewType`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-flex> 
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.w"
                    :label="$t(`indicator.size`, $store.state.locale)"
                    :items="sizes"
                    item-text="name"
                    item-value="id"
                    @change="calculateXY()"
                  ></v-select>
                </v-flex> 
                
                <v-card outlined class="pa-4 my-2 mx-1" style="width: 100%">
                  <h3>{{ $t(`clients.goal`, $store.state.locale) }}</h3>
                  <v-layout row wrap>
                    <v-flex md6>
                      <v-switch
                        class="ma-0 mt-2 pa-0"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :label="$t(`indicator.useGoal`, $store.state.locale)"
                        hide-details
                        v-model="indicator.useGoal"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex md6>
                      <v-select
                        v-if="indicator.useGoal"
                        class="mt-4"
                        outlined
                        dense
                        hide-details
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model="indicator.goalType"
                        :items="goalTypes"
                        :label="$t(`indicator.goalType`, $store.state.locale)"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field 
                        v-if="indicator.useGoal && indicator.goalType == 'number'"
                        :label="indicator.dataType == 'revenue' ? $t(`indicator.revenueGoal`, $store.state.locale) : $t(`indicator.numberGoal`, $store.state.locale)"
                        class="mt-4"
                        hide-details
                        dense
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model.number="indicator.goalNumber" 
                        type="number"
                        autocomplete
                        required
                        outlined
                        @input="calculateGoal()"
                        :prefix="indicator.dataType == 'revenue' ? '€' : null"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-select
                        v-if="indicator.useGoal && indicator.goalType == 'number'"
                        class="mt-4"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model="indicator.goalPeriod"
                        :items="periods"
                        persistent-hint
                        :label="$t(`indicator.goalPeriod`, $store.state.locale)"
                        :hint="$t(`indicator.goalPeriodHint`, $store.state.locale)"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-card outlined class="pa-4 my-2 mx-1" style="width: 100%">
                  <h3>{{ $t(`indicator.individualBarometer`, $store.state.locale) }}</h3>
                  <v-layout row wrap>
                    <v-flex md6>
                      <v-switch
                        class="ma-0 mt-2 pa-0"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :label="$t(`indicator.usePersonalGoal`, $store.state.locale)"
                        hide-details
                        v-model="indicator.usePersonalGoal"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-select
                        v-if="indicator.usePersonalGoal"
                        class="mt-4"
                        outlined
                        dense
                        multiple
                        chips
                        small-chips
                        hide-details
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model="indicator.employees"
                        :items="$store.state.allCompanyEmployees"
                        :label="$t(`general.employees`, $store.state.locale)"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-flex md6>
                  <v-switch
                    class="ma-0 mt-2 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t(`indicator.activeHint`, $store.state.locale)"
                    hide-details
                    v-model="indicator.active"
                  ></v-switch>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeIndicatorDialog()">
                   {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn v-if="type == 'new'" :dark="!loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :loading="loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="validateIndicator()">
                  {{ $t(`indicator.addIndicator`, $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>

                <v-btn
                  v-else
                  :disabled="!changed" 
                  :depressed="!changed"  
                  :dark=" changed"  
                  :loading="busy"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="validateIndicator()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>   
              </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import barometer from "@/components/elements/Barometer.vue";

  export default {
    name: "barometer-dialog",
    components: {
      barometer
    },
    data() {
      return {
        showDialog: false,
        type: 'new',
        coordinates: new Object(),
        realIndicatorData:{
          revenue: 0,
          number: 0
        },
        indicator: {
          color: "primary",
          products: new Array(),
          treatments: new Array(),
          dataFilter: 'all',
          graphType: "number",
          data: "general",
          dataType: "revenue",
          useGoal: false,
          goalNumber: null,
          goalType: 'number',
          goalPeriod:"week",
          treatmentTypes: new Array(),
          productTypes: new Array(),
          active: true,
          chartData: new Array(), // Wordt later berekend in het dashboard
          revenue: 0, // Wordt later berekend in het dashboard
          number: 0, // Wordt later berekend in het dashboard
          goal: 0, // Wordt later berekend in het dashboard
          w: 8,
          x: 0,
          y: 0,
          h: 4,
          usePersonalGoal: false,
          employees: new Array()
        },
        indicatorOriginal: new Object(),
        chartColors: ['#E91E63'],
        sampleData: {
          revenue: "8569",
          number: 27,
          goal: 86,
          goalRevenue: "10000",
          goalNumber: 50,
          chartDataWeek: [
            {
              data: [0,9,18,7,0, 3,1]
            }
          ],
          chartDataMonth: [
            {
              data: [0,9,18,7,0, 2,5,0,6,19,0,11,3,0,9,18,7,0,3,1,0,13,9,11,0,3,7,0,0,0]
            }
          ]
        },
        radialChartseries: [44],
        actualChartseries: [{data: [44, 55, 41, 67]}],
        actualChartOptions: {   
          chart: {
            offsetX: 0,
            offsetY: 0,
            sparkline: {
                enabled: true,
            },
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            radialBar: {
              inverseOrder: false,
              startAngle: -90,
              endAngle: 90,
              offsetX: 0,
              offsetY: 0,            
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '18px'
                }
              }
            },
          //   bar: {
          //     borderRadius: 5,
          //     columnWidth: '10px',
          //     barHeight: '70%',
          // distributed: false,
          // rangeBarOverlap: true,
          // rangeBarGroupRows: false,
          //   }
          },
          stroke: {
              curve: 'smooth',
              lineCap: 'round',
            },
          tooltip: {
            enabled: false,
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            padding: {
              bottom: 0
            },
            show: true,
            strokeDashArray: 6,
            position: 'back',
            xaxis: {
              lines: {
                  show: false
              }
            },   
            xaxis: {
              lines: {
                  show: true
              }
            }, 
            yaxis: {
              lines: {
                  show: false
              }
            },
            
            markers: {
              size: 1
            },
 
            padding: {
              top: 0,
              right: 0,
              bottom: 5,
              left: 0
            },  
          },
          
          // colors: new Array(),   
          xaxis: {
            labels: {
              show: false,
              offsetX: 0,
              offsetY: 0,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
          },
            
          yaxis: {
            labels: {
              show: false,
              offsetX: 0,
              offsetY: 0
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
          },
        },

        feedback: null,
        loading: true,
        busy: false,
        colors: [
           {
            id: "primary",
            name: "Primair"
          },
          {
            id: "secondary",
            name: "Secondair"
          },
          {
            id: "tertiary",
            name: "Tertiar"
          },
          {
            id: "quaternary",
            name: "Quaternair"
          }
        ],
        graphTypes: [
          {
            id: "line",
            name: this.$t(`indicator.lineChart`, this.$store.state.locale)
          },
          {
            id: "bar",
            name: this.$t(`indicator.barChart`, this.$store.state.locale)
          },
          {
            id: "number",
            name: this.$t(`indicator.numberChart`, this.$store.state.locale)
          }
        ],
        goalTypes: [
          {
            id: "number",
            name: this.$t(`indicator.numberInput`, this.$store.state.locale)
          },
          {
            id: "schedule",
            name: this.$t(`indicator.scheduleInput`, this.$store.state.locale),
            disabled: true
          }
        ],
        dataTypes: [
          {
            id: "numbers",
            name: this.$t(`indicator.numberType`, this.$store.state.locale)
          },
          {
            id: "revenue",
            name: this.$t(`indicator.revenueType`, this.$store.state.locale)
          }
        ],
        sizes: [
          {
            id: 4,
            name: this.$t(`indicator.smallSize`, this.$store.state.locale)
          },
          {
            id: 8,
            name: this.$t(`indicator.mediumSize`, this.$store.state.locale)
          }
        ],
        periods: [
          {
            id: "week",
            name: this.$t(`general.week`, this.$store.state.locale)
          },
          {
            id: "fourWeeks",
            name: this.$t(`general.fourWeeks`, this.$store.state.locale)
          },
          {
            id: "month",
            name: this.$t(`general.month`, this.$store.state.locale)
          }
        ],
        treatmentsLoading: true,
        productsLoading: true,
        treatments: new Array(),
        products: new Array(),
      };
    },

    created(){
      /**
       * Listens to addIndicator Trigger to open modal with new data
       */
      bus.$on("addIndicator", (coordinates) => { 
        this.type = "new";
        this.coordinates = coordinates;
        this.calculateXY();
        this.loading = false;  
        this.showDialog = true; 
        this.setSampleData();  
      });
      
      /**
       * Listens to addIndicator Trigger to open modal with existing data
       */
      bus.$on("editIndicator", (indicator) => {
        this.loading = true; 
        this.setEditIndicatorData(indicator);
      });
      
      // Set default actualChartOptions
      this.actualChartOptions.colors = new Array();
      this.actualChartOptions.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary);
    },

    methods: {
      /**
       * Open modal with existing data
       * @param { Object } indicator - Original Indicator Data
       */
      async setEditIndicatorData(indicator){
        this.indicator = JSON.parse(JSON.stringify(indicator));
        this.indicatorOriginal = JSON.parse(JSON.stringify(this.indicator));
        this.realIndicatorData.revenue = JSON.parse(JSON.stringify(indicator.revenue));
        this.realIndicatorData.number = JSON.parse(JSON.stringify(indicator.number));

        this.changePreviewColor();
        await new Promise((resolve)=>{
          if(indicator.products.length > 0){
            this.loadProducts().then(()=>{
              resolve()
            })
          }
          else if(indicator.treatments.length > 0){
            this.loadTreatments().then(()=>{
              resolve()
            })
          }
          else{
            resolve()
          }
        })
        this.setSampleData('edit');
        this.loading = false;
        this.showDialog = true;
        this.type = "edit"; 
      },

      /**
       * Close Dialog Modal and reset the incator
       */
      closeIndicatorDialog() { 
        this.showDialog = false;
        this.resetIndicator();
      },
      
      /**
       * Use sampledata to show what the indicator would look like
       */
      setSampleData(){
        this.indicator.revenue =  JSON.parse(JSON.stringify(this.sampleData.revenue));
        this.indicator.number =  JSON.parse(JSON.stringify(this.sampleData.number));
        this.indicator.chartData =  JSON.parse(JSON.stringify(this.sampleData.chartDataWeek));

        this.calculateGoal()
        
        this.indicatorOriginal = JSON.parse(JSON.stringify(this.indicator));   
        
      },

      /**
       * Re-calculate the chart and progressbar if the goal is changed to preview the changes
       */
      calculateGoal(){
        this.indicator.goalRadial = new Array();
        let number =  this.indicator.goalType == 'number' ? this.indicator.number : this.indicator.revenue;
        this.indicator.goal = (typeof number === 'number' && number > 0 && typeof this.indicator.goalNumber  === 'number' && this.indicator.goalNumber  > 0) ? ((number / this.indicator.goalNumber )  * 100).toFixed(0) : 0;  
        this.indicator.goalRadial.push(Number(this.indicator.goal).toFixed(0));
      },

      /**
       * Set all changed data of the indicator to default 
       */
      resetIndicator(){
        this.indicator.color = "primary";
        this.indicator.products = new Array();
        this.indicator.productTypes = new Array();
        this.indicator.treatments = new Array();
        this.indicator.treatmentTypes = new Array();
        this.indicator.dataType = "revenue";
        this.indicator.graphType = "number";
        this.indicator.data = "general";
        this.indicator.useGoal = false;
        this.indicator.goalNumber = null;
        this.indicator.goalType = 'number';
        this.indicator.goalPeriod = "week";
        this.indicator.active = true;
        this.indicator.chartData = new Array();
        this.indicator.revenue = 0;
        this.indicator.number = 0;
        this.indicator.goal = 0;
        this.indicator.w = 8;
        this.indicator.x = 0;
        this.indicator.y = 0;
        this.indicator.h = 4;
        this.coordinates = new Object();
      },

      /**
       * Reset data on change in the edit fields
       */
      resetDataSubType(){
        if(this.indicator.dataType == 'numbers'){
          if(this.indicator.data == 'general'){
            this.indicator.data == 'treatments'
            this.indicator.dataFilter = 'all';
          } 
        }
        else{
          this.indicator.dataFilter = 'all';
          this.indicator.treatments = new Array();
          this.indicator.products = new Array();
          this.indicator.treatmentTypes = new Array();
          this.indicator.productTypes = new Array();
        }
      },

      /**
       * Check if all needed field are present with the correct data before continueing to SAVE or UPDATE
       */
      validateIndicator(){
        this.feedback = null;
        let goalValid = !this.indicator.useGoal || (this.indicator.useGoal && ((this.indicator.goalType == 'number' && this.indicator.goalNumber && this.indicator.goalPeriod) || this.indicator.goalType == 'schedule')) ? true : false;
        let treatmentsValid = this.indicator.data != 'treatments' || this.indicator.data == 'treatments' && this.indicator.dataFilter != 'selected' || (this.indicator.data == 'treatments' && this.indicator.dataFilter == 'selected' && this.indicator.treatments.length > 0) ? true : false;
        let productsValid = this.indicator.data != 'products' ||  this.indicator.data == 'products' && this.indicator.dataFilter != 'selected' || (this.indicator.data == 'products' && this.indicator.dataFilter == 'selected' && this.indicator.products.length > 0) ? true : false;
        let treatmentsTypesValid = this.indicator.data != 'treatments' || this.indicator.data == 'treatments' && this.indicator.dataFilter != 'categories' || (this.indicator.data == 'treatments' && this.indicator.dataFilter == 'categories' && this.indicator.treatmentTypes.length > 0) ? true : false;
        let productsTypesValid = this.indicator.data != 'products' ||  this.indicator.data == 'products' && this.indicator.dataFilter != 'categories' || (this.indicator.data == 'products' && this.indicator.dataFilter == 'categories' && this.indicator.productTypes.length > 0) ? true : false;

        if(goalValid && treatmentsValid && productsValid && treatmentsTypesValid && productsTypesValid){
          if(this.type == 'new'){
            this.addIndicator()
          }
          else{
            this.updateIndicator()
          }
        }
        else{
          if(!treatmentsValid){
            this.feedback = this.$t(`indicator.feedbackTreatments`, this.$store.state.locale); 
          }
          else if(!productsValid){
            this.feedback = this.$t(`indicator.feedbackProducts`, this.$store.state.locale); 
          } 
          else if(!treatmentsTypesValid || !productsTypesValid){
            this.feedback = this.$t(`indicator.feedbackCategories`, this.$store.state.locale); 
          }
          else if(!goalValid){
            this.feedback = this.$t(`indicator.feedbackGoal`, this.$store.state.locale); 
          }
        }
      },

      /**
       * Add indicator to database
       */
      async addIndicator(){
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("indicators")
        .add(this.indicator)
        .then((docRef)=>{
          this.indicator.i = docRef.id;
          this.$emit("updateData", _.cloneDeep(this.indicator));
          
          this.closeIndicatorDialog();
        })
        .catch((err) => {
          console.error("Error adding indicator", err);
        });
      },

       /**
       * Save indicator changes to database
       */
      async updateIndicator(){
        this.busy = true;
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("indicators")
        .doc(this.indicator.i)
        .set({
            color: this.indicator.color,
            products: this.indicator.products,
            productTypes: this.indicator.productTypes,
            treatments: this.indicator.treatments,
            treatmentTypes: this.indicator.treatmentTypes,
            dataType: this.indicator.dataType,
            graphType: this.indicator.graphType,
            data: this.indicator.data,
            useGoal: this.indicator.useGoal,
            goalNumber: this.indicator.useGoal ? this.indicator.goalNumber : null,
            goalType: this.indicator.useGoal ? this.indicator.goalType : null,
            goalPeriod: this.indicator.goalPeriod,
            active: this.indicator.active,
            chartData: this.indicator.chartData,
            revenue: this.indicator.revenue,
            number: this.indicator.number,
            goal: this.indicator.goal,
            w: this.indicator.w,
            usePersonalGoal: this.indicator.usePersonalGoal,
            employees: this.indicator.employees,
          },{merge: true})
        .catch((err) => {
          this.busy = false;
          console.error("Error adding indicator", err);
        });

        this.indicator.revenue = JSON.parse(JSON.stringify(this.realIndicatorData.revenue));
        this.indicator.number = JSON.parse(JSON.stringify(this.realIndicatorData.number));
        this.$emit("updateDataEdit", _.cloneDeep(this.indicator));
        this.closeIndicatorDialog();
        this.busy = false;
      },

      /**
       * Load al nessesary data to choose from in the indicator form
       */
      loadDataFilter(){
        this.indicator.products = new Array();
        this.indicator.productTypes = new Array();
        this.indicator.treatments = new Array();
        this.indicator.treatmentTypes = new Array();

        if(this.indicator.data == 'products' && this.indicator.dataFilter == 'selected'){
          this.loadProducts()
        }
        if(this.indicator.data == 'treatments' && this.indicator.dataFilter == 'selected'){
          this.loadTreatments()
        }
      },

      /**
       * Load all company products from database
       */
      async loadProducts(){
        if(this.productsLoading){ // Only load products once
          this.products = new Array();
          await db.collection("products")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let product = doc.data();
              product.id = doc.id;
              this.products.push(product);
            });
          })
          .then(()=>{
            this.productsLoading = false;
            return
          })
        }
        else{
          return
        }
      },

      /**
       * Load all company treatments from database
       */
      async loadTreatments(){
        if(this.treatmentsLoading){ // Only load treatments once
          console.log("get treatments")
          this.treatments = new Array();
          await db.collection("treatments")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let treatment = doc.data();
              treatment.id = doc.id;
              this.treatments.push(treatment);
            });
          })
          .then(()=>{
            this.treatmentsLoading = false;
            return;
          })
        }
        else{
          return;
        }
      },

      /**
       * Set the Indicator Color
       */
      changePreviewColor(){
        let actualChartOptions = _.cloneDeep(this.actualChartOptions);
        actualChartOptions.colors = new Array();
        actualChartOptions.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus][this.indicator.color]);
        this.actualChartOptions = actualChartOptions
      },

      
      /**
       * Changes the with of the indicator to save
       */
      calculateXY(){
        if(this.type == 'new'){
          if(this.indicator.w == 8 && (this.coordinates.x <= 4 && this.coordinates.w == 4)){
            this.indicator.x = this.coordinates.x + this.coordinates.w;
            this.indicator.y = this.coordinates.y;
          }
          else if(this.indicator.w == 4 && ((this.coordinates.x <= 4 && this.coordinates.w == 4) || (this.coordinates.x == 0 && this.coordinates.w == 8))){
            this.indicator.x = this.coordinates.x + this.coordinates.w;
            this.indicator.y = this.coordinates.y;
          }
          else if(this.coordinates.x == 0 && this.coordinates.y == 0 && this.coordinates.w == 0){
            this.indicator.x = 0;
            this.indicator.y = 0;
          }
          else{
            this.indicator.x = 0;
            this.indicator.y = this.coordinates.y + 4;
          }
        }
        
      },
    },

    computed: {
       /**
       * Changes the with of the indicator to show a preview
       */
      exampleWidth(){
        let sizes = {
          example: "xs8",
          extra: "xs4"
        }

        if(this.indicator.w == 4){
          sizes.example = "xs4";
          sizes.extra = "xs8";
        }
        return sizes;
      },

      /**
       * Set active data items to select from in the indicator form filter
       */
      dataSubTypes(){
        let types=  [
          {
            id: "general",
            name: this.$t(`indicator.generalFilter`, this.$store.state.locale),
            disabled: this.indicator.dataType == 'numbers' ? true : false          
          },
          {
            id: "treatments",
            name: this.$t(`indicator.treatmentsFilter`, this.$store.state.locale)
          },
          {
            id: "products",
            name: this.$t(`indicator.productsFilter`, this.$store.state.locale)
          }
        ]
        return types
      },

      /**
       * Set data items to select from in the indicator form filter
       */
      dataFilters(){
        let filter = [
          {
            id: "all",
            name: this.indicator.data == 'treatments' ? this.$t(`indicator.allTreatmentsFilter`, this.$store.state.locale) : this.$t(`indicator.allProductsFilter`, this.$store.state.locale)   
          },
          {
            id: "categories",
            name: "Categorieën"
          },
          {
            id: "selected",
            name: this.indicator.data == 'treatments' ? this.$t(`indicator.selectTreatmentsFilter`, this.$store.state.locale) : this.$t(`indicator.selectProductsFilter`, this.$store.state.locale)       
          }
        ];
        return filter;
      },

      /**
       * Check if there are changes in the indicator data
       */
      changed(){
       return JSON.stringify(this.indicator) == JSON.stringify(this.indicatorOriginal) ? false : true;
      },
      
    }
  };
</script>


