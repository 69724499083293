<template>
  <v-dialog v-model="createBookingDialog" scrollable persistent :fullscreen="dialogFullscreen" max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card :height="chartHeight">
      <v-card-title class="pr-2 pa-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <v-container grid-list-md class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex md2>
              <v-btn v-if="step > 0 && !bookingPosting" icon dark class="ma-0 pa-0" @click="stepBack()">
                <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-chevron-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md8>
              <h3 class="text-center pt-1">{{ $t('booking.bookTreatment', $store.state.locale) }}</h3>
            </v-flex>
            <v-flex md2 class="text-right">
              <v-btn icon dark @click="closeDialog()">
                <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0" style="width: 100%">
        <div v-if="step == 0">
          <treatmentSelector :source="'bookingWidgetCalendar'"></treatmentSelector>  
        </div>
        <div v-if="step == 1">
          <clientSelector :source="'bookingWidgetCalendar'"></clientSelector>  
        </div>

        <div v-if="step == 2">
          <div class="booking-confirm-wrapper">
            <div class="pt-2 pb-0 px-4 text-center">
              <div class="mb-1" >
                <h4 class="ma-0 pa-0" style="font-size: 16px; display: inline-block">{{ $t('booking.chosenTreatmentFor', $store.state.locale) }} {{ clientData.firstName }} {{ clientData.surname }} </h4>  
                <v-chip dark label x-small v-if="clientData.minor" light class="ma-0 mt-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">
                  {{ $t('general.'+ clientData.minorType, $store.state.locale) }}
                </v-chip> 
              </div>   
              <v-chip dark label small  light class="ma-0 mt-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                {{ chosenTimeslot.dateBooking | moment("dddd") }} {{ chosenTimeslot.dateBooking | moment("D MMMM YYYY") }} 
                {{ $t('booking.at', $store.state.locale) }} {{ chosenTimeslot.start | moment("H:mm") }}u
              </v-chip>  
            </div>

            <v-card  class="ma-4 my-2 pa-0 px-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" style="border: 1px solid" :style="{borderColor: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">              
              <v-list class="ma-0 pa-0" disabled >
                <v-list-item tree-line class="ma-0 pa-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedTreatment.treatment.treatmentTitle) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedTreatment.treatment.treatmentDesc) }}</v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon small class="mt-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon> {{ chosenEmployee.resourceName }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text  >
                    <span v-if="selectedTreatment.treatment.treatmentPrice" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].heading }">
                      <strong>{{ numberWithCommas(selectedTreatment.treatment.treatmentPrice) }}</strong>
                    </span>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-card>

            <div v-if="downPaymentData.downPaymentRequired && downPaymentData.downPaymentAmount > 0"> 
              <v-card  flat class="ma-4 my-2 pa-0 pt-0 pb-0 custom-card-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}">
                <v-list class="ma-0 pa-0">
                  <v-list-item class="ma-0 pa-4 pt-3 pb-1" @click="extraDownPaymentInformationDialog = true">
                    <v-list-item-icon>
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="pt-0">
                      <v-list-item-title class="ma-0 pa-0" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" ><strong>{{ $t('booking.downpaymentRequired', $store.state.locale) }}</strong></v-list-item-title>
                      <v-list-item-subtitle :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}">
                        {{ $t('booking.downpaymentRequiredHint', $store.state.locale) }} <strong> {{ numberWithCommas(downPaymentData.downPaymentAmount) }}</strong>. <br/>
                        <i>{{ $t('booking.downpaymentRequiredEmail', $store.state.locale) }}</i>
                        <div class="pt-2">
                          <v-btn @click="downPaymentException()" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined>Aanbetaling deze keer overslaan</v-btn>
                        </div>
                        
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon >
                      <v-btn icon >
                        <v-icon  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>

            </div>

            <div v-if="(clientData.emails || clientData.phones) && showConfirmMail" class="px-4">
              <h4>{{ $t('booking.appointmentConfirmationTitle', $store.state.locale) }}</h4>
              <v-checkbox         
                class="ma-0 pa-0 pt-2"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :disabled="bookingPosting || downPaymentData.downPaymentRequired"
                :readonly="bookingPosting || downPaymentData.downPaymentRequired"
                style="font-size: 14px"
                :label="$t('booking.appointmentConfirmation', $store.state.locale)"
                v-model="confirmMail"
                hide-details
                ></v-checkbox>

                <v-layout row wrap class="ma-0 pa-0">
                  <v-flex md6 v-if="clientData.emails && clientData.emails.length > 0" class="pr-2">
                    <v-select
                      class="mt-3"
                      @click:append-outer="openSpellingData(clientData.selectedEmail)"
                      append-outer-icon="mdi-alphabetical-variant"
                      :label="$t('general.emailaddress', $store.state.locale)"
                      :items="clientData.emails"
                      :item-text="item=> `${item.email}`"
                      item-value="email"
                      dense
                      return-object
                      :ready-only="clientData.emails.length == 1"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="clientData.selectedEmail"
                      outlined
                      persistent-hint
                      :hide-details="clientData.selectedEmail && clientData.selectedEmail.clientType && clientData.selectedEmail.clientType == 'connected' ? false : true"
                      :hint="clientData.selectedEmail && clientData.selectedEmail.clientType && clientData.selectedEmail.clientType == 'connected' ? `(${$t('clients.connectedEmail', $store.state.locale)})` : ''"
                      >
                      <template v-slot:message="{message, key}">
                        <span :style="[{'font-size': '13px'}, {'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}]">{{ message}}</span>
                      </template>
                    </v-select>

                    <!-- <v-alert v-if="clientData.selectedEmail && !clientData.selectedEmail.primary" type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-4 pa-2" style="font-size: 14px" v-html="$t('employees.branchRemoval', $store.state.locale)"></v-alert> -->

                    <v-card outlined v-if="clientData.selectedEmail && clientData.selectedEmail.score != null && clientData.selectedEmail.score < 4" class="pa-2 mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary + '25'" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary}">
                      <div style="font-size: 12px; padding: 0 0 5px 0; font-weight: bold; text-align: center" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary}">{{ $t('general.checkEmailTitle', $store.state.locale) }}</div>
                      <span style="font-size: 12px;"> {{ $t('general.checkEmailScore', $store.state.locale) }} <span style="font-weight: 500">{{ clientData.selectedEmail.score}}/10</span>. {{ $t('general.checkEmailTitle', $store.state.locale) }}</span>
                      <v-btn @click="openSpellingData()" outlined small block :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"><span style="font-size: 12px;"> {{ $t('general.spellingHelpMe', $store.state.locale) }}</span></v-btn>
                    </v-card>
                    
                    <v-card outlined v-if="clientData.selectedEmail && !clientData.selectedEmail.primary && !clientData.selectedEmail.type != 'connected'" class="pa-2 mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary + '15'">
                      <div style="font-size: 12px; padding: 0 0 5px 0; text-align: center">{{ $t('general.setEmailAsDefault', $store.state.locale) }}</div>
                      <v-btn @click="changePrimaryEmail()" outlined small block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"><span style="font-size: 12px;">{{ $t('general.setAsDefault', $store.state.locale) }}</span></v-btn>
                    </v-card>

                  </v-flex>
                  <v-flex md6 v-if="clientData.phones && clientData.phones.length > 0" class="pl-2">
                    <v-select
                      class="mt-3"
                      :label="$t('general.phone', $store.state.locale)"
                      :items="clientData.phones"
                      item-text="phone.international"
                      item-value="e164"
                      dense
                      return-object
                      :readyonly="clientData.phones.length == 1"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="clientData.selectedPhone"
                      outlined
                      persistent-hint
                      :hide-details="clientData.selectedPhone && clientData.selectedPhone.clientType && clientData.selectedPhone.clientType == 'connected' ? false : true"
                      :hint="clientData.selectedPhone && clientData.selectedPhone.clientType == 'connected' ? `(${$t('clients.connectedPhone', $store.state.locale)})` : ''"
                      >
                      <template v-slot:message="{message, key}">
                        <span :style="[{'font-size': '13px'}, {'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}]">{{ message}}</span>
                      </template>
                    </v-select>
                    <v-card outlined v-if="clientData.selectedPhone && !clientData.selectedPhone.primary && !clientData.selectedPhone.type != 'connected'" class="pa-2 mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary + '15'">
                      <div style="font-size: 12px; padding: 0 0 5px 0; text-align: center">{{ $t('general.setPhoneAsDefault', $store.state.locale) }}</div>
                      <v-btn @click="changePrimaryPhone()" outlined small block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"><span style="font-size: 12px;">{{ $t('general.setAsDefault', $store.state.locale) }}</span></v-btn>
                    </v-card>
                  </v-flex>
                </v-layout>
            </div>

            <div v-else class="px-4">
              <h4 class="pb-2">{{ $t('booking.appointmentConfirmationTitle', $store.state.locale) }}</h4>
              <v-chip v-if="!clientData.emails || !clientData.phones" dark label small  light class="ma-0 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">
                {{ $t('booking.noEmailOrPhone', $store.state.locale) }}
              </v-chip> 
              <div v-else>
                <v-chip v-if="!showConfirmMail" dark label small  light class="ma-0 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">
                {{ $t('booking.bookingInThePast', $store.state.locale) }}
              </v-chip>  
              </div>  
            </div>

            <div class="px-4 mt-4 pt-1">
              <h4>{{ $t('booking.appointmentInformation', $store.state.locale) }}</h4>   
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex md12 class="pr-2">
                  <v-select
                    class="mt-4"
                    :label="$t('booking.preferedPractitioner', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :items="preferredPractitionerOptions"
                    item-text="name"
                    item-value="id"
                    dense
                    v-model="preferredPractitioner"
                    :disabled="bookingPosting"
                    :readonly="bookingPosting"
                    outlined
                    persistent-hint
                    :hint="`${$t('booking.preferedPractitionerHintOne', $store.state.locale)} ${chosenEmployee.resourceName} ${$t('booking.preferedPractitionerHintTwo', $store.state.locale)}`"
                    background-color="background"
                   >
                    <template slot="selection" slot-scope="data">
                      <v-avatar
                        size="26"
                        light
                        class="ma-1 ml-0 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-box</v-icon> 
                      </v-avatar>
                      <span>{{ data.item.name }}</span>
                    </template>
                  </v-select>

                  <v-select
                    class="my-3"
                    :label="$t('booking.appointmentType', $store.state.locale)"
                    :items="bookingTypes"
                    item-text="name"
                    item-value="id"
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="bookingType"
                    :disabled="bookingPosting"
                    :readonly="bookingPosting"
                    outlined
                    hide-details
                    background-color="background"
                    >
                    <template slot="selection" slot-scope="data">
                      <v-avatar
                        size="26"
                        light
                        class="ma-1 ml-0 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <span v-if="bookingType == 'widget'"><strong>O</strong></span>
                        <span v-if="bookingType == 'app'"><strong>A</strong></span>
                        <span v-if="bookingType == 'personal'"><strong>P</strong></span>
                        <span v-if="bookingType == 'telephone'"><strong>T</strong></span>
                      </v-avatar>
                      <span>{{ data.item.name }}</span>
                    </template>
                  </v-select>

                  <v-textarea
                    :label="$t('clients.notes', $store.state.locale)"
                    rows="2"
                    color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    outlined
                    hide-details
                    v-model="notes"
                    >
                  </v-textarea>

                </v-flex>
              </v-layout>      
              
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeDialog()">
                {{ $t("general.cancel", $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                v-if="step == 0 || step == 1"
                :dark="nextValid"
                :depressed="!nextValid"
                :disabled="!nextValid"
                :loading="nextLoading"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="next()"
                >
                {{ $t('general.next', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn v-if="step == 2" dark :loading="bookingPosting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="postBooking()">
                {{ $t('booking.bookTreatments', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>

    <!-- / Authority Dialog \ -->
    <v-dialog v-model="autorisationModal" persistent width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
        <v-card-text class="ma-0 pt-4">
          <div class="text-center">
            <v-avatar 
              size="76"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
            </v-avatar>
            <h3 class="pa-2">{{ $t('booking.noAutorisation', $store.state.locale) }}</h3>
            <p>{{ chosenEmployee.resourceName }} {{ $t('booking.noAutorisationText', $store.state.locale) }}</p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn outlined depressed color="tertiary" @click="autorisationModal = false"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="continueWithoutAutorisation()">
                  {{ $t('general.continue', $store.state.locale) }}<v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Authority Dialog / -->

    <!-- / Availability Dialog \ -->
    <v-dialog v-model="bookingAvailability.dialog" persistent width="450" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">

        <v-card-text class="ma-0 pt-4" v-if="bookingAvailability.deviceTypeMissing || bookingAvailability.roomMissing">
          <div class="text-center">
            <!-- <div class="event-overlap"></div> -->
            <v-avatar 
              size="76"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-magnify</v-icon>
            </v-avatar>

            <h3 class="pa-2" style="line-height: 1.3" v-if="bookingAvailability.roomMissing && !bookingAvailability.deviceTypeMissing">{{ $t('booking.missingRooms', $store.state.locale) }}</h3>
            <h3 class="pa-2" style="line-height: 1.3" v-if="bookingAvailability.deviceTypeMissing && !bookingAvailability.roomMissing">{{ $t('booking.missingDeviceTypes', $store.state.locale) }}</h3>
            <h3 class="pa-2" style="line-height: 1.3" v-if="bookingAvailability.deviceTypeMissing && bookingAvailability.roomMissing">{{ $t('booking.missingRoomsAndDevicesTypes', $store.state.locale) }}</h3>
            
            <p v-if="bookingAvailability.roomMissing && !bookingAvailability.deviceTypeMissing" class="ma-0 mb-1" v-html="$t('booking.bookingMissingRoomText', $store.state.locale)"></p>
            <p v-if="bookingAvailability.deviceTypeMissing && !bookingAvailability.roomMissing" class="ma-0 mb-1" v-html="$t('booking.bookingMissingDeviceTypeText', $store.state.locale)"></p>
            <p v-if="bookingAvailability.deviceTypeMissing && bookingAvailability.roomMissing" class="ma-0 mb-1" v-html="$t('booking.bookingMissingText', $store.state.locale)"></p>  

            <div v-if="bookingAvailability.deviceTypeMissing">
              <p class="ma-0">
                <strong>&#8226; </strong>
                <span v-for="(device, index) in bookingAvailability.missingDeviceTypes" :key="index">
                  <strong>{{ getDeviceTypeName(device) }}</strong>
                  <span v-if="index < bookingAvailability.missingDeviceTypes.length - 2">, </span>
                  <span v-if="index == bookingAvailability.missingDeviceTypes.length - 2"> {{ $t('general.and', $store.state.locale) }} </span>
                </span>
              </p>
            </div>
            <div v-if="bookingAvailability.roomMissing">
              <p class="ma-0">
                <strong>&#8226; </strong>
                <span v-for="(room, index) in bookingAvailability.missingRooms" :key="index">
                  <strong>{{ getRoomName(room.id) }}</strong>
                  <span v-if="index < bookingAvailability.missingRooms.length - 2">, </span>
                  <span v-if="index == bookingAvailability.missingRooms.length - 2"> {{ $t('general.and', $store.state.locale) }} </span>
                </span>
              </p>
            </div>
          </div>
        </v-card-text>


        <v-card-text class="ma-0 pt-4" v-else>
          <div class="text-center">
            <v-avatar 
              size="76"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-multiple</v-icon>
            </v-avatar>
            <h3 class="pa-2" style="line-height: 1.3">{{ $t('booking.bookingOverlap', $store.state.locale) }}</h3>
            <p class="ma-0 mb-1" v-html="$t('booking.bookingOverlapText', $store.state.locale)"></p>
            <p class="ma-0" v-if="bookingAvailability.employeeOverlap">
              <strong>&#8226; {{ chosenEmployee.resourceName }}</strong>
            </p>
            <div v-if="bookingAvailability.deviceOverlap">
              <p class="ma-0">
                <strong>&#8226; </strong>
                <span v-for="(device, index) in bookingAvailability.overlapDevices" :key="index">
                  <strong>{{ device.name }}</strong>
                  <span v-if="index < bookingAvailability.overlapDevices.length - 2">, </span>
                  <span v-if="index == bookingAvailability.overlapDevices.length - 2"> {{ $t('general.or', $store.state.locale) }} </span>
                </span>

                <!-- {{bookingAvailability.overlapDevice.resourceName }} -->
              </p>
            </div>
            <div v-if="bookingAvailability.roomOverlap">
              <p class="ma-0">
                <strong>&#8226; </strong>
                <span v-for="(room, index) in bookingAvailability.overlapRooms" :key="index">
                  <strong>{{ room.name }}</strong>
                  <span v-if="index < bookingAvailability.overlapRooms.length - 2">, </span>
                  <span v-if="index == bookingAvailability.overlapRooms.length - 2"> {{ $t('general.or', $store.state.locale) }} </span>
                </span>

                <!-- {{bookingAvailability.overlapRoom.resourceName }} -->
              </p>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn outlined depressed color="tertiary" @click="bookingAvailability.dialog = false, stepBack()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn :disabled="bookingAvailability.deviceTypeMissing || bookingAvailability.roomMissing"  :dark="!bookingAvailability.deviceTypeMissing && !bookingAvailability.roomMissing" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="continueWithoutAvailability()">
                  {{ $t('general.continue', $store.state.locale) }}<v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Availability Dialog / -->

    <!-- / Extra DownPayment Information Dialog \ -->
    <v-dialog v-model="extraDownPaymentInformationDialog" persistent width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
        <v-card-text class="ma-0 pt-4 pb-0">
          <div class="text-center">
            <v-avatar 
              size="56"
              light
              class="ma-4 mb-2 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon size="28" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-cash</v-icon>
            </v-avatar>
            <h3 class="pa-2">{{ $t('booking.extraDownPaymentInformation', $store.state.locale) }}</h3>
            <p>{{ $t('booking.extraDownPaymentInformationInfo', $store.state.locale) }}</p>

            <v-card outlined>
              <div class="pa-3">
                <h5 class="pb-2">{{ $t('booking.downPaymentReasons', $store.state.locale) }} </h5>
                <div class="pb-1">
                  <v-chip v-for="(downPaymentReason, index ) in downPaymentData.downPaymentReasons" :key="index" class="my-1 mr-2 custom-chip-style" small :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][downPaymentReason.conditionType == 'client' ? 'primary' : 'tertiary']" > 
                    {{ $t(`booking.downPaymentAmountReasons.${downPaymentReason.type}`, $store.state.locale) }}
                    <span v-if="downPaymentReason.type == 'tag'" style="font-size: 12px">: <i>{{ downPaymentReason.name }}</i></span>
                  </v-chip> 
                </div>

                <h5 class="py-2">{{ $t('booking.downPaymentAmountDetails', $store.state.locale) }} </h5>
                <p class="ma-0">
                  <strong>{{ $t('general.percentage', $store.state.locale) }}:</strong>  {{ $store.state.activeBranch.widgetSettings.downPayments.percentage }}% <br> 
                  <strong>{{ $t('branch.downPaymentMinimum', $store.state.locale) }}:</strong>  &euro;{{ $store.state.activeBranch.widgetSettings.downPayments.minimum.toFixed(2) }}<br>
                  <strong> {{ $t('branch.downPaymentMaximum', $store.state.locale) }}:</strong> &euro;{{ $store.state.activeBranch.widgetSettings.downPayments.maximum.toFixed(2) }}
                </p>
              </div>
            </v-card>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 class="text-center">
                <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="extraDownPaymentInformationDialog = false">
                  {{ $t('general.close', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Extra DownPayment Information Dialog  / -->

  </v-dialog>
</template>

<script>
  import db from "../../firebase/init";
  import Moment, { relativeTimeRounding } from "moment";
  import { extendMoment } from "moment-range";
  const moment = extendMoment(Moment);
  import slugify from "slugify";
  import axios from "axios";
  import { bus } from "../../main.js";
  import treatmentSelector from "@/components/elements/treatmentSelector.vue";
  import clientSelector from "@/components/elements/clientSelector.vue";

  export default {
    name: "BookingWidgetCalendar",
    props: {
      givenStartDate: String,
      selectedResourceEvents: Array,
      createBookingDialog: Boolean,
      rooms: Array,
      devices: Array,
    },
    components: {
      treatmentSelector: treatmentSelector,
      clientSelector: clientSelector,
    },

    data() { 
      return {
        autorisationModal: false,
        notes: "",
        preferredPractitioner: false,
        givenResource: null,
        extraDownPaymentInformationDialog: false,
        preferredPractitionerOptions: [
          {
            id: false,
            name: this.$t('booking.clientNoPrefer', this.$store.state.locale)
          },
          {
            id: true,
            name: this.$t('booking.clientPrefer', this.$store.state.locale)
          },
        ],
        bookingType: "personal",
        bookingTypes: [
          {
            id: "personal",
            name: this.$t('booking.personal', this.$store.state.locale)
          },
          {
            id: "telephone",
            name: this.$t('booking.byPhone', this.$store.state.locale)
          },
        ],
        bookingPosting: false,
        feedback: null,
        newClient: false, // Nieuwe client invoeren.
        confirmMail: true, // Client wilt bevestinging ontvangen
        showConfirmMail: true,
        chosenEmployee: {},
        chosenDate: null, // Gekozen door calander
        chosenTimeslot: { // Gekozen timeslots
          start: "",
          end: "",
          dateBooking: "",
        },
        
        companyRooms: new Array(), //Ruimtes
        nextLoading: false,

        clientData: {
          firstName: "",
          firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
          surname: "",
          surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
          emails: new Array(),
          selectedEmail: "",
          id: null,
          language: null,
          phones: new Array(),
          selectedPhone: "",
        },
        valid: false, //Zijn persoongevens goed ingevuld?
        step: 0,
        chartHeight: 650,
        dialogFullscreen: false,
        bookingAvailability: {
          dialog: false,
          employeeOverlap: false,
          deviceOverlap: false,
          roomOverlap: false,
          overlapRooms: new Array(),
          overlapDevices: new Array(),
          overlapRoom: "",
          overlapDevice: "",
          missingRooms: new Array(),
          missingDeviceTypes: new Array(),
          deviceTypeMissing: false,
          roomMissing: false,
        },
        selectedTreatment: {
          type: null,
          treatment: null,
          fullTreatment: null,
        },
        speedSelect: {
          active: false,
          id: null
        },
        deviceTypes: new Array(),
        lead: null,
        preSelectedClientId: null,
        downPaymentData: new Object(),
      }
    },

    mounted() {
      bus.$on("bookTreatment", (data, type, lead, treatment, clientId ) => {
        this.givenResource = data;
        this.extraDownPaymentInformationDialog = false;
        this.settings("", this.givenStartDate, this.givenResource);
        this.calculateCartHeight();
        this.loadDeviceTypes();
        this.loadRooms();

        if(moment().format("YYYY-MM-DDTHH:mm") <= this.chosenTimeslot.start){
          this.showConfirmMail = true;
          this.confirmMail = true;
        }
        else{
          this.showConfirmMail = false;
          this.confirmMail = false;
        }
        
        this.clientData.language = this.availableLanguages[0].id;
        this.preSelectedClientId = clientId ? clientId : null;
        window.addEventListener("resize", this.calculateCartHeight);
        if(type =='trial'){
          if(lead){
            this.lead = lead;
          }
          if(treatment){
            this.speedSelect.active = true;
            this.speedSelect.id = treatment;
          }
        }
        else{
          this.lead = null;
          this.speedSelect.active = false;
        }
      });
      bus.$on("bookingTreatmentSelected", (treatmentData) => {
        this.selectedTreatment = treatmentData;
      });

      bus.$on("bookingClientSelected", (clientData) => {
        this.clientData = clientData;
        this.newClient = false;
        if(this.clientData.minor){
          this.checkForGuardianContactInfo(this.clientData.guardianClientIds)
        }
      });

      bus.$on("bookingClientNewValid", (clientData, valid) => {        
        if(clientData){
          this.newClient = true;
          this.clientData = {...clientData};
        }
        else{
          this.newClient = false;
        }
      });
    },

    methods: {

      openSpellingData(){
        bus.$emit("spellingHelpModal", this.clientData.selectedEmail, 'open', 'bookingWidgetCalendar');
      },

      changePrimaryEmail(){
        this.clientData.emails.map(email => {
          email.email == this.clientData.selectedEmail.email ? email.primary = true : email.primary = false 
        })
        this.clientData.selectedEmail.primary = true;

        this.clientData.emails.sort(function (a, b) {
            return b.primary - a.primary;
        });

        db.collection("clients")
        .doc(this.clientData.id)
        .set({
          emails: this.clientData.emails
        }, {merge: true})
      },

      changePrimaryPhone(){
        this.clientData.phones.map(phone => {
          phone.phone.e164 == this.clientData.selectedPhone.phone.e164 ? phone.primary = true : phone.primary = false 
        })
        this.clientData.selectedPhone.primary = true;
        this.clientData.phones.sort(function (a, b) {
          return b.primary - a.primary;
        });

        db.collection("clients")
        .doc(this.clientData.id)
        .set({
          phones: this.clientData.phones
        }, {merge: true})
      },

      async checkForGuardianContactInfo(guardians){
        let guardianEmails = new Array();
        let guardianPhones = new Array();
        if(guardians && guardians.length > 0){
          for (let index = 0; index < guardians.length; index++) {
            await db.collection("clients").doc(guardians[index]).get()
            .then((doc) => {
              let emails = doc.data().emails
              let phones = doc.data().phones
              console.log("emails guardian: ", emails)
              emails.forEach(email=>{
                email.clientType = 'connected'
              })
              phones.forEach(phone=>{
                phone.clientType = 'connected'
              })
              guardianEmails = guardianEmails.concat(emails)
              guardianPhones = guardianPhones.concat(phones)
            })          
          }
        }
        this.clientData.emails = this.clientData.emails.concat(guardianEmails);
        this.clientData.phones = this.clientData.phones.concat(guardianPhones);
        this.clientData.selectedEmail = this.clientData.emails[0] ? this.clientData.emails[0] : null;
        this.clientData.selectedPhone = this.clientData.phones[0] ? this.clientData.phones[0] : null;
        return
      },

      calculateCartHeight() {
        let windowHeight = document.documentElement.clientHeight;
        if (windowHeight > 700) {
          this.chartHeight = 600;
          this.dialogFullscreen = false;
        }
        else {
          this.chartHeight = "100%";
          this.dialogFullscreen = true;
        }
      },

      numberWithCommas(x) {
        let price = Number(x.toFixed(2));
        return price.toLocaleString("nl-NL", { style: "currency", currency: "EUR" });
      },

      async settings(change, start, resource) {
        this.chosenEmployee.resourceName = resource.title; //Vanuit calendar variable resource
        this.chosenEmployee.resourceDocId = resource.id;
        this.chosenEmployee.docId = resource.extendedProps.docid; //DOC ID van Firebase
        this.chosenTimeslot.start = moment(start).format("YYYY-MM-DD[T]HH:mm");
        this.chosenTimeslot.dateBooking = moment(start).format("YYYY-MM-DD");
        this.chosenDate = moment(start).format("YYYY-MM-DD"); //Standaard voor createBooking.vue
      },

      resetClientData(){
        this.clientData = {
          firstName: "",
          surname: "",
          emails: new Array(),
          selectedEmail: "",
          email: "",
          id: null,
          phones: new Array(),
          selectedPhone: ""
        }
      },

      closeDialog() {
        //Reset alle waardes.
        this.resetClientData();
        this.$emit('BookingEditChange', false)
        bus.$emit('resetTreatmentSelect')

        this.newClient = false;
        this.preferredPractitioner = false;
        this.bookingType = "personal";
        this.clientData.selectIndex = null;
        this.bookingPosting = false;
        this.notes = "";
        this.confirmMail = true;
        this.search =  "";
        this.step = 0;
        this.selectedTreatment = {
          type: null,
          treatment: null,
          fullTreatment: null,
        };

        this.bookingAvailability = {
          dialog: false,
          employeeOverlap: false,
          deviceOverlap: false,
          roomOverlap: false,
          overlapRooms: new Array(),
          overlapDevices: new Array(),
          overlapRoom: "",
          overlapDevice: "",
        };
      },

      downPaymentException(){
        this.downPaymentData.downPaymentAmount = 0;
        this.downPaymentData.downPaymentRequired = false;
      },

      async postBooking() {
        var uniqid = require("uniqid");
        this.bookingPosting = true;
        let uniqId = uniqid();
        let receiptDraft = null

        await this.checkIfReceiptDraftExists().then((data)=>{
          console.log("checkIfReceiptDraftExists: ", data)
          if(data && data.uniqIdStamp){
            receiptDraft = data;
            uniqId = data.uniqIdStamp; // Use uniqId from receipt Draft om te koppelen met bestaande behandelingen
          }
        })
        
        let bookedResIds = new Array(); //New ResIds for Cache
        let treatmentdata = this.selectedTreatment.fullTreatment;
        let treatmentDeviceTypes = new Array();

        treatmentdata.subTreatments.forEach((subTreatment) => {
          if (subTreatment.deviceId) {
            treatmentDeviceTypes.push(subTreatment.deviceId);
          }
        });

        if (this.newClient) {
          // await this.createClient(); // Legacy
          this.clientData.id = await this.$createNewClient(this.clientData);
        }
        let docRefId = null;

        console.log("Post bookingsummary: ", treatmentDeviceTypes);

        await db.collection("bookingsummary")
          .add({
            company: this.$store.state.activeCompany.name,
            companyId: this.$store.state.activeCompany.id,
            created: new Date(),
            createdBy: this.$store.state.activeUserId,
            bookingType: this.bookingType,
            visit: this.chosenTimeslot.dateBooking,
            start: moment(this.chosenTimeslot.start).format("YYYY-MM-DDTHH:mm"),
            end: moment(this.chosenTimeslot.start).add(this.selectedTreatment.treatment.treatmentTime, "minutes").format("YYYY-MM-DDTHH:mm"),
            treatmentTitle: this.selectedTreatment.treatment.treatmentTitle,
            treatmentId: this.selectedTreatment.treatment.id,
            branchId: this.$store.state.activeBranch.id,
            notes: this.notes,
            email: this.clientData.selectedEmail && this.clientData.selectedEmail.email && (!this.clientData.selectedEmail.primary || this.clientData.selectedEmail.clientType && this.clientData.selectedEmail.clientType == 'connected') ? this.clientData.selectedEmail.email : null,
            phone: this.clientData.selectedPhone && this.clientData.selectedPhone.phone && (!this.clientData.selectedPhone.primary || this.clientData.selectedPhone.clientType && this.clientData.selectedPhone.clientType == 'connected') ? this.clientData.selectedPhone : null,
            // employees: [this.chosenEmployee.docId], //LEGACY
            employees: [this.chosenEmployee.resourceDocId],
            treatmentDeviceTypes: treatmentDeviceTypes,
            paid: false,
            deleted: false,
            clientname: this.clientData.firstName + " " + this.clientData.surname,
            clientId: this.clientData.id,
            sendMail: this.confirmMail,
            uniqIdStamp: uniqId,
            leadExtra: this.lead && this.preSelectedClientId ? { leadId: this.lead, cliendId: this.preSelectedClientId } : null, // Post lead info if lead
            downPayment: {
              active: this.downPaymentData.downPaymentRequired && this.downPaymentData.downPaymentAmount > 0 ? true : false,
              paid: false,
            },
          })
        .then((docRef) => {
          docRefId = docRef.id;
        })

        let counter = 0;
        let minutesStart = 0;
        let minutesEnd = 0;
        let bookingItems = new Array();
        
        await new Promise((resolve) => {
          treatmentdata.subTreatments.forEach((subTreatment) => {
            minutesEnd = minutesEnd + subTreatment.time;
            let bookingResourceIdRoom = null;
            let bookingResourceIdDevice = null;

            if (subTreatment.device == 2 || subTreatment.device == 3) { // Device or Employee & Device
              let bookingDeviceId = subTreatment.deviceId ? subTreatment.deviceId : null;
              let device = null;
              if (subTreatment.availableDevice) {
                device = subTreatment.availableDevice;
              } 
              else {
                this.devices.forEach((availableDevice) => {
                  // console.log("device check", availableDevice.resourceName, availableDevice.deviceTypes, _.indexOf(availableDevice.deviceTypes, bookingDeviceId));
                  if (_.indexOf(availableDevice.deviceTypes, bookingDeviceId) >= 0) {
                    device = availableDevice;
                  }
                });
              }
              bookingResourceIdDevice = device ? device.id : null;
              let bookingRoomId = null;
              if (subTreatment.availableRoom) {
                bookingResourceIdRoom = subTreatment.availableRoom.id;
              } 
              else {
                bookingRoomId = bookingResourceIdDevice && device.rooms.length > 0 ? device.rooms[0] : null;
                bookingResourceIdRoom = bookingRoomId ? this.rooms[_.findIndex(this.rooms, ["id", bookingRoomId])].id : null;
              }
            }
            if (subTreatment.device == 5 || subTreatment.device == 6) { // Employee & Room or Room
              if (subTreatment.availableRoom) {
                bookingResourceIdRoom = subTreatment.availableRoom.id;
              } 
              else {
                bookingResourceIdRoom = subTreatment.rooms.length > 0 ? subTreatment.rooms[0].id : null;
              }
            }

            let employeeData = {
              start: minutesStart,
              end: minutesEnd,
              resourceDocId: this.chosenEmployee.resourceDocId,
              bookingOverride: false,
              deviceId: subTreatment.deviceId ? subTreatment.deviceId : null,
              type: "employee",
              description: subTreatment.description,
              eventColor: subTreatment.eventColor,
              withEventBlock: typeof subTreatment.withEventBlock !== "undefined" ? subTreatment.withEventBlock : true 
            };

            let deviceData = {
              start: minutesStart,
              end: minutesEnd,
              description: subTreatment.description,
              resourceDocId: bookingResourceIdDevice,
              deviceId: subTreatment.deviceId,
              type: "device",
              bookingOverride: false,
              eventColor: subTreatment.eventColor,
            };
            let roomData = {
              start: minutesStart,
              end: minutesEnd,
              deviceId: subTreatment.deviceId ? subTreatment.deviceId : null,
              description: subTreatment.description,
              bookingOverride: false,
              resourceDocId: bookingResourceIdRoom,
              type: "room",
              eventColor: subTreatment.eventColor,
            };

            if (subTreatment.device == 1) { // Employee
              bookingItems.push(employeeData);
            } 
            else if (subTreatment.device == 2) { // Device
              bookingItems.push(deviceData);
              if (bookingResourceIdRoom) {
                bookingItems.push(roomData);
              }
            } 
            else if (subTreatment.device == 3) {  // Employee & Device  
              bookingItems.push(employeeData);
              bookingItems.push(deviceData);
              if (bookingResourceIdRoom) {
                bookingItems.push(roomData);
              }
            } 
            else if (subTreatment.device == 4) { // Employee bookingOverride
              employeeData.bookingOverride = true;
              
              if(typeof subTreatment.withEventBlock !== "undefined") { // WithEventBlock Field is active and with withEventBlock === true 
                if(subTreatment.withEventBlock === true) {
                  bookingItems.push(employeeData);
                }
              }
              else { //WithEventBlock Field is undefined
                bookingItems.push(employeeData);
              }
            } 
            else if (subTreatment.device == 5) { // Employee & Room
              bookingItems.push(employeeData);
              bookingItems.push(roomData);
            } 
            else if (subTreatment.device == 6) { // Room
              bookingItems.push(roomData);
            }

            minutesStart = minutesStart + subTreatment.time;
            counter++;
            if (counter == treatmentdata.subTreatments.length) {
              resolve();
            }
          });
        })

        let bookingCounter = 0;
        await new Promise((resolve) => {
          bookingItems.forEach((bookingItem) => {
            //Add to ResIds
            bookedResIds.push(bookingItem.resourceDocId);
            //Add to Database
            db.collection("bookingen")
              .add({
                bookingId: docRefId,
                clientname: this.clientData.firstName + " " + this.clientData.surname,
                firstName: this.clientData.firstName,
                surname: this.clientData.surname,
                email: this.clientData.selectedEmail ? this.clientData.selectedEmail : null,
                start: moment(this.chosenTimeslot.start).add(bookingItem.start, "m").format("YYYY-MM-DD[T]HH:mm"),
                end: moment(this.chosenTimeslot.start).add(bookingItem.end, "m").format("YYYY-MM-DD[T]HH:mm"),
                dateBooking: this.chosenTimeslot.dateBooking,
                title: this.selectedTreatment.treatment.treatmentTitle,
                description: bookingItem.description,
                eventColor: bookingItem.eventColor, // toegevoegd door Sven
                branchId: this.$store.state.activeBranch.id,
                resourceDocId: bookingItem.resourceDocId,
                companyId: this.$store.state.activeCompany.id,
                treatmentId: this.selectedTreatment.treatment.id,
                preferredPractitioner: this.preferredPractitioner,
                resourceType: bookingItem.type,
                deviceId: bookingItem.deviceId,
                type: "booking",
                bookingOverride: bookingItem.bookingOverride,
                clientId: this.clientData.id,
                deleted: false,
                source: this.bookingType,
                uniqIdStamp: uniqId,
                leadExtra: this.lead && this.preSelectedClientId ? { leadId: this.lead, cliendId: this.preSelectedClientId}: null // Post lead info if lead
              })
              .then(() => {
                bookingCounter++;
                if (bookingCounter == bookingItems.length) {
                  resolve();
                }
              });
          });
        })

        if (this.confirmMail) {
          if(this.downPaymentData.downPaymentRequired){
            await axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: docRefId, branchId: this.$store.state.activeBranch.id, type: 'appointmentConfirmDownPayment', amount: this.downPaymentData.downPaymentAmount, test: true})
            .then((response) => {
              if (response) { // Email succesfully sent!
                console.log("Message response: ", response)
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
          }
          else{
            console.log("send message: ", {doc: docRefId, branchId: this.$store.state.activeBranch.id, type: 'appointmentConfirm'})
            await axios
            .post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: docRefId, branchId: this.$store.state.activeBranch.id, type: 'appointmentConfirm'})
            .then((response) => {
              if (response) { // Email succesfully sent!
                console.log("Message response: ", response)
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
          }
        }
        
        console.log("Promise Booking resolved");
        this.bookingPosting = false;

        setTimeout(()=>{
          bus.$emit("cacheUpdate", bookedResIds); //Trigger Lead Cache Off
        }, 2000)
        
        if(this.lead && this.preSelectedClientId ){
          bus.$emit("trialTreatmentBooked", docRefId, this.selectedTreatment.treatment.treatmentTitle, moment(this.chosenTimeslot.start).format("YYYY-MM-DDTHH:mm")); // Go Back to Lead Modal
        }
        bus.$emit("calendarAction", "addBooking");
        this.createReceiptDraft(receiptDraft, docRefId, uniqId);
      },

      async checkIfReceiptDraftExists(){ // Check if receiptsDraft already exist
        let receiptDraft = null;

        await new Promise((resolve)=>{
          db.collection("receiptsDraft")
          .where("clientId","==", this.clientData.id)
          .where("visit","==", this.chosenTimeslot.dateBooking)
          .get()
          .then(snap => {
            if(snap.size == 0){
              resolve()
            }
            else{
              snap.forEach(doc => {
                receiptDraft = doc.data();
                receiptDraft.id = doc.id;
                resolve()
              });
            }
          })
        })
        return receiptDraft;
      },

      createReceiptDraft(receiptDraft, docRefId, uniqId) {
        new Promise((resolve)=>{
          if(receiptDraft){ // ReceiptDraft does exist
            console.log("receiptDraft exist")
            if(receiptDraft.bookingSummaryIds){ // Check if bookingSummaryIds value exist (for older drafts)
              receiptDraft.bookingSummaryIds.push(docRefId);
            }
            else{
              receiptDraft.bookingSummaryIds = [docRefId];
            }
            db.collection("receiptsDraft").doc(receiptDraft.id) // Update ReceiptDraft
            .set({
              bookingSummaryIds: receiptDraft.bookingSummaryIds,
              modified: new Date()
            },{ merge: true})
            .then(()=>{
              resolve()
            })
            .catch((error) => {
              console.error("Error updating Receipts Draft document  : ", error);
            });
          }
          else{ // ReceiptDraft does not exist
            db.collection("receiptsDraft") // Create ReceiptDraft
            .add({
              clientId: this.clientData.id,
              clientname: this.clientData.firstName + " " + this.clientData.surname,
              draft: true,
              created: new Date(),
              modified: new Date(),
              visit: this.chosenTimeslot.dateBooking,
              selectedItems: new Array(),
              bookingSummaryIds: [docRefId],
              companyId: this.$store.state.activeCompany.id, //Company ID
              branchId: this.$store.state.activeBranch.id, //Branch
              uniqIdStamp: uniqId, //uniqId for clustering (same as bookingSummary)
              userId: null,
            })
            .then(()=>{
              resolve()
            })
            .catch((error) => {
              console.error("Error adding document Receipts Draft : ", error);
            });
          }
        })
        .then(()=>{
          this.closeDialog();
        })
      },

      loadDeviceTypes(){
        if(this.deviceTypes.length == 0){
          db.collection("devices").get()
          .then(snap => {
            snap.forEach(doc => {
              let deviceType = doc.data();
              deviceType.id = doc.id;
              this.deviceTypes.push(deviceType);
            });
          })
        }
      },

      loadRooms(){
        if(this.companyRooms.length == 0){
          db.collection("resources")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("resourceType", "==" , "room")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let room = doc.data();
              room.id = doc.id;
              this.companyRooms.push(room);
            });
          })
        }
      },

      getDeviceTypeName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.deviceTypes, {'deviceId': id})
          if(index >= 0){
            name =  this.deviceTypes[index].deviceName;
          }
          return name;
        }
        else{
          return null;
        }
      },

      getRoomName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.companyRooms, {'id': id})
          if(index >= 0){
            name =  this.companyRooms[index].resourceName;
          }
          return name;
        }
        else{
          return null;
        }
      },

      async checkAvailability() { // Check if employees, rooms and devices are available
        this.bookingAvailability.deviceTypeMissing = false;
        this.bookingAvailability.missingDeviceTypes = new Array();
        this.bookingAvailability.roomMissing = false;
        this.bookingAvailability.missingRooms = new Array();
        this.bookingAvailability.employeeOverlap = false;
        this.bookingAvailability.deviceOverlap = false;
        this.bookingAvailability.roomOverlap = false;
        this.bookingAvailability.overlapRooms =new Array();
        this.bookingAvailability.overlapDevices = new Array();
        this.bookingAvailability.overlapRoom = "";
        this.bookingAvailability.overlapDevice = "";

        let devices = {};
        let rooms = {};

        // Get full treatment data
        let treatmentDeviceTypes = new Array();
        await db.collection("treatments")
        .doc(this.selectedTreatment.treatment.id)
        .get()
        .then((doc) => {
          let fullTreatment = doc.data();
          fullTreatment.id = doc.id;
          this.selectedTreatment.fullTreatment = fullTreatment;
        });

        await new Promise((resolve) => { // Check for Devices
          let subtreatmentsDeviceCounter = 0;

          this.selectedTreatment.fullTreatment.subTreatments.forEach((subTreatment) => {
            new Promise((resolve) => {
              let deviceCounter = 0;
              if (subTreatment.deviceId && subTreatment.device == 2 || subTreatment.device == 3) {
                if(this.devices && this.devices.length > 0){
                  this.devices.forEach((device) => {
                    if (!devices[subTreatment.deviceId]) {
                      if (_.indexOf(device.deviceTypes, subTreatment.deviceId) >= 0) {
                        let availableDevice = device;
                        availableDevice.events = [];
                        db.collection("bookingen")
                          .where("resourceDocId", "==", device.id)
                          .where("dateBooking", "==", moment(this.givenStartDate).format("YYYY-MM-DD"))
                          .where("branchId", "==", this.$store.state.activeBranch.id)
                          .where("bookingOverride", "==", false)
                          .get()
                          .then((snapshot) => {
                            snapshot.forEach((doc) => {
                              let resource = new Object();
                              resource.start = doc.data().start;
                              resource.end = doc.data().end;
                              availableDevice.events.push(resource);
                            });
                          })
                          .then(() => {
                            if (devices[subTreatment.deviceId]) {
                              devices[subTreatment.deviceId].push(availableDevice);
                            }
                            else {
                              devices[subTreatment.deviceId] = [];
                              devices[subTreatment.deviceId].push(availableDevice);
                            }
                            deviceCounter++;
                            if (deviceCounter == this.devices.length) {
                              resolve();
                            }
                          });
                      }
                      else {
                        deviceCounter++;
                        if (deviceCounter == this.devices.length) {
                          resolve();
                        }
                      }
                    }
                    else {
                      deviceCounter++;
                      if (deviceCounter == this.devices.length) {
                        resolve();
                      }
                    }
                  });
                }
                else{ // Er zijn geen devices beschikbaar, maar subtreatment heeft wel een device
                  this.bookingAvailability.missingDeviceTypes.push(subTreatment.deviceId)
                  resolve()
                }
              }
              else {
                resolve();
              }
            })
            .then(() => {
              subtreatmentsDeviceCounter++;
              if (subtreatmentsDeviceCounter == this.selectedTreatment.fullTreatment.subTreatments.length) {
                resolve();
              }
            });
          });
        });

        await new Promise((resolve) => {
          let subtreatmentsRoomCounter = 0;

          if(this.rooms && this.rooms.length > 0){
            this.rooms.forEach((room) => {
              room.events = new Array();
  
              db.collection("bookingen")
                .where("resourceType", "==", "room")
                .where("resourceDocId", "==", room.id)
                .where("dateBooking", "==", moment(this.givenStartDate).format("YYYY-MM-DD"))
                .where("branchId", "==", this.$store.state.activeBranch.id)
                .where("bookingOverride", "==", false)
                .get()
                .then((snapshot) => {
                  snapshot.forEach((doc) => {
                    let resource = new Object();
                    resource.start = doc.data().start;
                    resource.end = doc.data().end;
                    resource.id = doc.id;
                    room.events.push(resource);
                  });
                })
                .then(() => {
                  rooms[room.id] = room;
                  subtreatmentsRoomCounter++;
                  if (subtreatmentsRoomCounter == this.rooms.length) {
                    resolve();
                  }
                });
            });
          }
          else{
            resolve()
          }  
        });

        let minutes = 0;
        let start;
        let end;

        await new Promise((resolve) => {
          let subtreatmentsCounter = 0;
          this.selectedTreatment.fullTreatment.subTreatments.forEach((subTreatment) => {
            
            start = moment(this.givenStartDate).add(minutes, "minutes").format("YYYY-MM-DDTHH:mm");
            end = moment(start).add(subTreatment.time, "minutes").format("YYYY-MM-DDTHH:mm");
            // console.log("Start: ", start, " - ", subTreatment.time, "- End: ", end);

            if (subTreatment.device == 1 || subTreatment.device == 3 || subTreatment.device == 5) {
              // Employee
              // console.log("this.selectedResourceEvents", this.selectedResourceEvents);
              for (const event of this.selectedResourceEvents) {
                // let eventStart = moment(event._instance.range.start, "DDD MMM DD HH:mm:SS ZZ").utcOffset(0).format("YYYY-MM-DDTHH:mm");
                let eventStart = event.start;

                if (moment(eventStart).isBetween(moment(start), moment(end)) == true) {
                  this.bookingAvailability.employeeOverlap = true;
                  break;
                }
              }
            }

            if (subTreatment.device == 2 || subTreatment.device == 3) {
              // Device
              let deviceCounter = 0;
              let availableDevices = [];
              let deviceScores = [];

              if (subTreatment.deviceId && this.devices.length > 0) {
                new Promise((resolve) => {
                  // Check if device is available
                  console.log("Check if device is available")
                  devices[subTreatment.deviceId].forEach((device) => {
                    let unavailableDevices = [];
                    let availableRooms = [];
                    let unavailableRooms = [];
                    let mobileDevice = false;

                    let deviceAvailable = false;
                    if (device.events.length > 0) {
                      let counter = 0;
                      for (const event of device.events) {
                        if (
                          event.start == start ||
                          moment(event.start).isBetween(moment(start), moment(end)) == true ||
                          moment(start).isBetween(moment(event.start), moment(event.end)) == true ||
                          moment(end).isBetween(moment(event.start), moment(event.end)) == true
                        ) {
                          // console.log("Device niet beschikbaar |", device.resourceName);
                          // Device not available
                          unavailableDevices.push(device);
                          continue;
                        } 
                        else {
                          // Device available
                          // availableDevices.push(device);
                        }
                        counter++;
                        if (counter == device.events.length) {
                          if (unavailableDevices.length == 0) {
                            availableDevices.push(device);
                            deviceAvailable = true;
                          }
                          // console.log("Device Events bekeken |", device.name);
                        }
                      }
                    }
                    else { // Device available
                      // console.log("Device beschikbaar (No device events) |", device.name);
                      deviceAvailable = true;
                      // availableDevices.push(device);
                    }

                    // If device need rooms, check if rooms are available;
                    if (device.rooms && device.rooms.length > 0) {
                      device.rooms.forEach((room) => { // Loop trough all rooms of device
                        // console.log("room console", room);
                        if (rooms[room] && rooms[room].events.length > 0) { // Check if room has events
                          let counter = 0;
                          let roomAvailable = true;
                          // console.log("Events for: ", rooms[room].resourceName, ": ", rooms[room].events);
                          for (const event of rooms[room].events) {
                            
                            console.log("Ruimte check |", device.name, rooms[room].name, subTreatment.name);
                            console.log("event.start == start: ", event.start, start);
                            if (
                              event.start == start ||
                              moment(event.start).isBetween(moment(start), moment(end)) == true ||
                              moment(start).isBetween(moment(event.start), moment(event.end)) == true ||
                              moment(end).isBetween(moment(event.start), moment(event.end)) == true
                            ) {
                               // console.log("Ruimte (device) niet beschikbaar |", device.resourceName, rooms[room].resourceName);
                              unavailableRooms.push(rooms[room]);
                              roomAvailable = false;
                            }
                            else {
                              // console.log("Ruimte (device) beschikbaar |", device.resourceName, rooms[room].resourceName);
                            }
                            counter++;
                            if (counter == rooms[room].events.length) {
                              if (roomAvailable) {
                                availableRooms.push(rooms[room]);
                                subTreatment.availableRoom = rooms[room];
                              }
                              console.log("Device Room Events bekeken |", device.name, rooms[room].name);
                            }
                          }
                        }
                        else {
                          // console.log("No Room (device) events |", device.resourceName, rooms[room].resourceName);
                          subTreatment.availableRoom = rooms[room];
                          availableRooms.push(rooms[room]);
                        }
                      });
                    } 
                    else {
                      mobileDevice = true
                      // console.log("No Rooms with device |", subTreatment.name);
                    }

                    // console.log("Check before Score: deviceAvailable", deviceAvailable, "availableRooms.length", availableRooms.length);
                    let score = {
                      score: null,
                      room: null,
                      device: null,
                      unavailableRooms: null,
                      unavailableDevices: null,
                    };

                    if (deviceAvailable && (availableRooms.length > 0 || mobileDevice)) {
                      availableDevices.push(device);
                      score.score = "A";
                      score.device = device;
                      score.room = availableRooms[0];
                    } else if (deviceAvailable && availableRooms.length == 0) {
                      score.device = device;
                      score.score = "B";
                      score.unavailableRooms = unavailableRooms;
                    } else if (!deviceAvailable && (availableRooms.length > 0 || mobileDevice)) {
                      score.score = "C";
                      score.room = availableRooms[0];
                      score.unavailableDevices = unavailableDevices;
                    } else if (!deviceAvailable && availableRooms.length == 0) {
                      score.score = "D";
                      score.unavailableRooms = unavailableRooms;
                      score.unavailableDevices = unavailableDevices;
                    }

                    deviceScores.push(score);
                    deviceCounter++;
                    // console.log("Klaar met device controleren", device.resourceName);
                    if (deviceCounter == devices[subTreatment.deviceId].length) {
                      resolve();
                    }
                  });
                })
                .then(() => {
                  deviceScores.sort((a, b) => (a.score > b.score ? 1 : b.score > a.score ? -1 : 0));
                  console.log("availableDevices: ", availableDevices)
                  // if(availableDevices.length > 0){
                  //   subTreatment.availableDevice = availableDevices[0].device;
                  // }
                  subTreatment.availableDevice = deviceScores[0].device;
                  subTreatment.availableRoom = deviceScores[0].room;
                  console.log("deviceScores: ", deviceScores)
                  if (deviceScores && deviceScores[0].score != "A") { // Set data for feedback dialog         
                    if (deviceScores[0].score == "B") {
                      this.bookingAvailability.roomOverlap = true;
                      this.bookingAvailability.overlapRooms = deviceScores[0].unavailableRooms;
                    } else if (deviceScores[0].score == "C") {
                      this.bookingAvailability.deviceOverlap = true;
                      this.bookingAvailability.overlapDevices = deviceScores[0].unavailableDevices;
                    } else if (deviceScores[0].score == "D") {
                      this.bookingAvailability.deviceOverlap = true;
                      this.bookingAvailability.roomOverlap = true;
                      this.bookingAvailability.overlapRooms = deviceScores[0].unavailableRooms;
                      this.bookingAvailability.overlapDevices = deviceScores[0].unavailableDevices;
                    }
                  }
                });
              }
            } 
            
            if (subTreatment.device == 5 || subTreatment.device == 6) {
              // Room
              let availableRooms = [];
              let unavailableRoomsForBooking = [];
              if (subTreatment.rooms && subTreatment.rooms.length > 0) {
                let roomCounter = 0;
                new Promise((resolve) => {
                  subTreatment.rooms.forEach((room) => {
                    Object.size = function (obj) {
                      var size = 0,
                        key;
                      for (key in obj) {
                        if (obj.hasOwnProperty(key)) size++;
                      }
                      return size;
                    };

                    if (Object.size(rooms) > 0) {
                      for (const [key, value] of Object.entries(rooms)) {
                        if (value.id == room.id) {
                          if (value.events.length > 0) {
                            let available = true;
                            let counter = 0;
                            value.events.forEach((event) => {
                              console.log("event.start: ", event.start)
                              console.log("start: ", start)
                              console.log("end: ", end)
                              if (moment(start).isBetween(moment(event.start), moment(event.end)) == true || event.start == start || moment(end).isBetween(moment(event.start), moment(event.end)) == true ) {
                                unavailableRoomsForBooking.push(value);
                                available = false;
                                // console.log("Ruimte niet beschikbaar");
                              } 
                              else {
                                //  console.log("Ruimte beschikbaar");
                              }
                              counter++;
                              if (counter == value.events.length) {
                                if (available) {
                                  availableRooms.push(value);
                                }
                              }
                            });
                          } else {
                            // console.log("Ruimte beschikbaar (geen events)");
                            availableRooms.push(value);
                          }
                        }
                      }
                    }
                    else{ // Deze Room is niet aanwezig
                      this.bookingAvailability.missingRooms.push(room)
                    }

                    roomCounter++;
                    if (roomCounter == subTreatment.rooms.length) {
                      resolve();
                    }
                  });
                })
                .then(() => {
                  if (availableRooms.length > 0) {
                    subTreatment.availableRoom = availableRooms[0];
                  } 
                  else {
                    this.bookingAvailability.roomOverlap = true;
                    this.bookingAvailability.overlapRooms = this.bookingAvailability.overlapRooms.concat(unavailableRoomsForBooking);
                  }
                });
              }
            }

            minutes = minutes + subTreatment.time;

            subtreatmentsCounter++;
            if (subtreatmentsCounter == this.selectedTreatment.fullTreatment.subTreatments.length) {
              resolve();
            }
          });
        })
        .then(() => {
          this.bookingAvailability.deviceTypeMissing = this.bookingAvailability.missingDeviceTypes.length > 0 ? true : false;
          this.bookingAvailability.roomMissing = this.bookingAvailability.missingRooms.length > 0 ? true : false;

          this.bookingAvailability.overlapRooms = _.uniqBy(this.bookingAvailability.overlapRooms, "docId");
          this.bookingAvailability.overlapDevices = _.uniqBy(this.bookingAvailability.overlapDevices, "docId");
          this.bookingAvailability.missingRooms = _.uniq(this.bookingAvailability.missingRooms);
          this.bookingAvailability.missingDeviceTypes = _.uniq(this.bookingAvailability.missingDeviceTypes);
          this.bookingAvailability.overlapDevices = _.uniqBy(this.bookingAvailability.overlapDevices, "docId");
          this.bookingAvailability.overlapRoom = this.bookingAvailability.overlapRooms.length > 0 ? this.bookingAvailability.overlapRooms[0] : "";
          this.bookingAvailability.overlapDevice = this.bookingAvailability.overlapDevices.length > 0 ? this.bookingAvailability.overlapDevices[0] : "";
        });

        if (this.bookingAvailability.roomMissing || this.bookingAvailability.deviceTypeMissing || this.bookingAvailability.employeeOverlap || this.bookingAvailability.deviceOverlap || this.bookingAvailability.roomOverlap) {
          this.bookingAvailability.dialog = true;
        }
        return;
      },

      async checkResourceAuthorisation() { // Check if employee is authorized to give selected treatment
        if (this.selectedTreatment.treatment.employees) {
          if (_.indexOf(this.selectedTreatment.treatment.employees, this.chosenEmployee.resourceDocId) >= 0) { // Employee is authorized
            return true;
          } 
          else { // Employee is not authorized
            return false;
          }
        } 
        else { // Employees are not defined
          return true;
        }
      },

      continueWithoutAutorisation() {
        this.autorisationModal = false;
        if (!this.bookingAvailability.dialog) {
          this.step = 1;
          this.findPreSelectedClientAndContinue();
        }
      },
      
      continueWithoutAvailability() {
        this.bookingAvailability.dialog = false;
        if (!this.autorisationModal) {
          this.step = 1;
          this.findPreSelectedClientAndContinue();
        }
      },

      findPreSelectedClientAndContinue(){
        let clientData =  _.find(this.clients, ['id', this.preSelectedClientId]);
        // console.log("clientData: ", clientData)
        // console.log("preSelectedClientId: ", this.preSelectedClientId)
          if(clientData){
            this.selectClient(clientData, null)
            this.next();
          }
      },

      async next() {
        if (this.step == 0) {
          this.nextLoading = true;
          this.checkAvailability().then(() => {
            console.log("Door nadat availability is afgerond");
            this.checkResourceAuthorisation().then((value) => {
              if (value) {
                this.step = 1;
                this.findPreSelectedClientAndContinue();
                this.nextLoading = false;
              }
              else {
                this.autorisationModal = true;
                this.nextLoading = false;
              }
            });
          });
        } 
        else if (this.step == 1) {
          if(this.newClient){
            this.clientData.emails = new Array()
            if(this.clientData.email){
              this.clientData.emails = [{email:this.clientData.email, primary: true}]
            }
            this.clientData.selectedEmail = this.clientData.email
            this.clientData.selectedPhone = this.clientData.phones && this.clientData.phones.length > 0 ? this.clientData.phones.find(phone =>  phone.primary ) : null;
            if(this.clientData.minor){
              this.checkForGuardianContactInfo(this.clientData.guardianClientIds)
            }
          }

          this.downPaymentData = await this.$downPaymentInformation(this.chosenTimeslot.start, this.clientData, this.selectedTreatment.treatment.treatmentPrice) // Check if downpayment is requiered for this client (based on branch settings)
          // await this.checkDownPaymentSettings()
          this.step = 2;
        }
      },

      stepBack() {
        if (this.step == 1) {
          this.step = 0;
        }
        else if (this.step == 2) {
          this.clientData.firstName=  "";
          this.clientData.surname = "";
          this.clientData.emails = "";
          this.clientData.selectedEmail = "";
          this.clientData.id = null;
          this.clientData.phone = "";
          this.step = 1;
        }
      },
    },

    computed: {
      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.companyLanguages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
      },
      
      nextValid() {
        if (this.step == 0) {
          if (this.selectedTreatment.treatment) {
            return true;
          } else {
            return false;
          }
        }
        else if (this.step == 1) {
          if (this.clientData.id || (this.newClient && this.clientData.firstName && this.clientData.surname)) {
            if(this.newClient){
              if(this.downPaymentData.downPaymentRequired && !this.newClient){
                return false;
              }
              else{
                return true;
              }
            }     
            else{
              return true;
            }
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      },
    },
  };
</script>

<style>
  .no-authority {
    height: 150px;
    width: 250px;
    margin: 0 auto;
    background: url("../../assets/icons/userrights.png") no-repeat center center;
    background-size: 200px auto;
  }

  .event-overlap {
    height: 150px;
    width: 250px;
    margin: 0 auto;
    background: url("../../assets/icons/overlap-icon.svg") no-repeat center center;
    background-size: 200px auto;
  }

  .client-list-card {
    max-height: 270px;
    overflow-y: scroll;
  }
  .client-list-card.fullscreen {
    max-height: calc(100% - 300px);
  }
/* 
  .wolfloadModal {
    z-index: 90;
  }
  .wolfloadModal .blackboxModal {
    position: absolute;
    float: right;
    top: 18px;
    right: 14px;
    display: inline-block !important;
    background-color: none;
    padding: 0px;
    height: 30px;
    width: 30px;
  } */

  .clientlast .v-list.theme--light {
    padding: 0px;
  }

  .clientlast {
    height: 200px;
    overflow-y: scroll;
  }

  .timeslotCSS {
    display: none;
  }

  .treatmentslotCSS {
    display: none;
  }



  .treatmentbox {
    background-color: var(--v-background-base);
  }

  .v-card__text.booking-card .v-stepper {
    width: 100%;
  }

  .booking-item h3 {
    display: block;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: var(--v-text-base);
  }

  /* .booking-item {
  padding: 7px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.booking-item.treatment {
  background-color: var(--v-menu-base);
  border-top: none;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
} */

  .booking-item:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .booking-item.treatment:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }

  p.treatment-desc {
    display: block;
    font-size: 12px;
    padding: 0;
    margin: 0;

    color: rgba(0, 0, 0, 0.7);
  }

  span.treatment-time {
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .choose-event-wrapper {
    padding: 0 0 10px 0;
  }

  .choose-event-button {
    text-transform: lowercase !important;
  }

  .choose-employeep-intro {
    font-size: 12px;
    padding: 0 0 5px 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
  }

  .choose-time-button {
    min-width: 60px !important;
    max-width: 60px !important;
    height: 30px !important;
    text-transform: lowercase !important;
  }

  .employee-avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    float: left;
    margin: 0 15px 0 0;
    background-color: rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }

  .employee-avatar img {
    width: 100%;
    height: auto;
  }

  .employee-info {
    float: left;
    height: 40px;
    line-height: 40px;
  }

  .choose-time-button {
    min-width: 70px;
    max-width: 70px;
    height: 30px;
  }
/* 
  .wolfload-time {
    position: relative;
    width: 100%;
    height: 60px;
  }

  .wolfload-time .blackbox {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    height: 40px;
    width: 40px;
    z-index: 90;
  } */

  .booking-datepicker {
    box-shadow: none !important;
  }

  .booking-confirm-summary {
    padding: 0 0 15px 0;
  }

  .booking-confirm-summary h3 {
    padding: 0 0 5px 0;
  }

  .booking-confirm-intro {
    padding: 0 0 15px 0;
  }

  .branch-avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    float: left;
    margin: 0 15px 0 0;
    background-color: rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }

  .branch-avatar img {
    width: 100%;
    height: auto;
  }

  .branch-info {
    float: left;
    height: 40px;
  }

  .branch-info .branch-name {
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 2px 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
  }

  .branch-info .branch-address {
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  /* createbooking */

  .newClient {
    background-color: #f4f7fc;
    border-radius: 10px;
  }

  .thrivebut {
    border: 1px solid #687387 !important;
  }

  .searchbox {
    background-color: white;
    /* height: 56px; */
  }

  .thrivebut:active {
    background-color: #00adef !important;
    color: white !important;
    border: none !important;
  }

  .client-info-input-wrapper {
    padding: 12px;
  }

  .special-events-wrapper {
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  .v-list-item:hover {
    background: rgba(0, 0, 0, 0.075);
  }
  .v-list-item-custom {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-family: Sofia Pro, sans-serif !important;
    cursor: pointer;
  }

  .v-list-item-custom:hover {
    cursor: pointer;
  }

  /* .searchbox .v-input__slot {
    border: 2px solid #00adef !important;
} */

  @media screen and (max-width: 600px) {
    .modal-button-cancel {
      margin: 5px 15px 0 0 !important;
    }
    .modal-button-next {
      padding: 14px 45px 14px 10px !important;
    }
  }

  .booking-treatment-wrapper .booking-expantion-header {
    border-bottom: 1px solid var(--v-menu-base) !important;
    border-radius: none;
  }
</style>

